import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApi from '../api';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import RowDelete from '../ui/RowDelete';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/components/ui/table';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { Separator } from '@/components/ui/separator';
import { Input } from '@/components/ui/input';
import useAnalytics from '../analytics/analytics';

const WebsiteList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [websites, setWebsites] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const { get, remove } = useApi();
  const { captureEvent } = useAnalytics();

  // get search params
  // const search = new URLSearchParams(window.location.search);

  useEffect(() => {
    getWebsites();
  }, []);

  const getWebsites = async () => {
    try {
      setLoading(true);
      const data = await get('/websites/');
      setWebsites(data.data);
      captureEvent('viewed_websites', {});
    } catch (error) {
      console.error('Error fetching websites:', error);
    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      await remove(`/websites/${id}`);
      setWebsites(websites.filter((website: any) => website.id !== id));
      toast.success('Website deleted successfully', toastConfig);
      captureEvent('website_deleted', {
        website_id: id,
      });
    } catch (error) {
      console.error('Error deleting website:', error);
    }
    setLoading(false);
  };

  const handleSearch = async (search: string) => {
    setSearchInput(search);
    try {
      setLoading(true);
      const data = await get(`/websites/?search=${search}`);
      setWebsites(data.data);
    } catch (error) {
      console.error('Error fetching websites:', error);
    }
    setLoading(false);
  };

  // Define columns for TanStack Table
  const columns = React.useMemo(() => [
    {
      accessorKey: 'url',
      header: 'URL',
      cell: ({ row }: any) => (
        <Link
          to={`/websites/${row.original.id}`}
          className="text-sm font-semibold hover:text-blue-500 hover:underline"
        >
          {row.original.url}
        </Link>
      ),
    },
    {
      accessorKey: 'title',
      header: 'Title',
      cell: ({ getValue }: any) => (
        <span className="text-sm">
          {getValue()}
        </span>
      ),
    },
    {
      accessorKey: 'meta_description',
      header: 'Meta Description',
      cell: ({ getValue }: any) => (
        <span className="text-sm">
          {getValue()}
        </span>
      ),
    },
    {
      accessorKey: 'index_status',
      header: 'Index Status',
      cell: ({ getValue }: any) => (
        <span className="text-sm">
          {getValue()}
        </span>
      ),
    },
    {
      accessorKey: 'indexed_at',
      header: 'Indexed At',
      cell: ({ getValue }: any) => (
        <span className="text-sm">
          {getValue()}
        </span>
      ),
    },
    {
      id: 'actions',
      header: 'Action',
      cell: ({ row }: any) => (
        <RowDelete deleteCallback={() => handleDelete(row.original.id)} />
      ),
    },
  ], [handleDelete]);

  // Create table instance
  const table = useReactTable({
    data: websites,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const WebsitesLoadingSkeleton = () => (
    <TableBody>
      {[1, 2].map((i) => (
        <TableRow key={i} className="animate-pulse">
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-64" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-48" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-full" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-24" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-32" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-16 ml-auto" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <>
      <div className="p-12 overflow-y-auto max-h-[calc(100vh-1px)] scrollbar-thin grow">
        <h1 className='text-4xl font-bold flex items-center gap-2'>Websites</h1>
        <p className='mt-4 text-sm text-slate-500'>Manage websites here to train your agent knowledge base.</p>
        <Separator className="my-4" />
        <div className='mx-auto flex flex-row'>
            <Input
              className='w-1/3'
              type="text"
              id="search"
              placeholder="Search websites..."
              autoComplete='off'
              value={searchInput}
              onChange={(e: any) => setSearchInput(e.target.value)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleSearch(e.currentTarget.value);
                }
              }}
            />
        </div>
        <div className="flex flex-col mt-4">
          <div className="-m-1.5 overflow-x-auto pb-12">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="border rounded-lg shadow overflow-hidden dark:border-slate-900">
                  <Table>
                    <TableHeader>
                      {table.getHeaderGroups().map(headerGroup => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map(header => (
                            <TableHead key={header.id}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                            </TableHead>
                          ))}
                        </TableRow>
                      ))}
                    </TableHeader>
                    {loading ? (
                      <WebsitesLoadingSkeleton />
                    ) : (
                      <TableBody>
                        {table.getRowModel().rows.length ? (
                          table.getRowModel().rows.map(row => (
                            <TableRow key={row.id}>
                              {row.getVisibleCells().map(cell => (
                                <TableCell key={cell.id}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={columns.length} className="h-24 text-center">
                              No websites
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                  </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteList;
