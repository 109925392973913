import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import useApi from '../api';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../documentation/Spinner';

interface NewPageProp {
    websiteId: string;
}

const NewPage: React.FC<NewPageProp> = ({ websiteId }) => {
  const [open, setOpen] = React.useState(false);
  const [pageUrl, setPageUrl] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { post } = useApi();

  const indexPage = () => {
    if (!pageUrl) {
      toast.error('Please enter a page URL', toastConfig);
      return;
    }

    setLoading(true);

    post(`/index-page/${websiteId}`, { url: pageUrl })
      .then(() => {
        toast.success('Page indexing started', toastConfig);
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.error('Error indexing page:', error);
        toast.error('Error indexing page', toastConfig);
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="secondary" className="w-full">
          Add Page
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Add Page for Indexing</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="url">Page URL</Label>
            <Input
              id="url"
              type="text"
              value={pageUrl}
              onChange={(e) => setPageUrl(e.target.value)}
              disabled={loading}
            />
          </div>
          <div className="flex justify-end space-x-2">
            <Button
              variant="secondary"
              onClick={() => setOpen(false)}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              onClick={indexPage}
              disabled={loading}
            >
              {loading ? <Spinner /> : 'Index Page'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NewPage;