import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import useApi from '../api';
import { useUser } from '../user/UserContext';
import { useNavigate } from 'react-router-dom';
import RowDelete from '../ui/RowDelete';

import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";

interface User {
    id: string;
    email: string;
    name: string;
}

interface Organization {
    id: string;
    name: string;
    users?: User[];
    license_type: string;
    trial_end_date: string;
    settings: any;
    operator: boolean;
}

const Admin = () => {
    const navigate = useNavigate();
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { get, put, remove } = useApi();
    const { organization, setOrg, user } = useUser();
    const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
    const [isUsersModalOpen, setIsUsersModalOpen] = useState(false);

    const getOrganizations = async () => {
        try {
            const response = await get('/organizations/');
            setOrganizations(response.data || []);
        } catch (error) {
            console.error('Error fetching organizations:', error);
            toast.error('Failed to load organizations');
        }
    };

    const handleOrgChange = async (newOrg: Organization) => {
        await put(`/users/${user?.id}/organization/${newOrg.id}`, {});
        setOrg(newOrg);
        navigate('/');
    };

    const handleDeleteOrg = async (orgId: string) => {
        try {
            await remove(`/organizations/${orgId}`);
            toast.success('Organization deleted successfully');
            getOrganizations();
        } catch (error) {
            console.error('Error deleting organization:', error);
            toast.error('Failed to delete organization');
        }
    };

    const handleDeleteUser = async (userId: string) => {
        try {
            await remove(`/users/${userId}`);
            toast.success('User deleted successfully');
            // Refresh the users list in the modal
            if (selectedOrg) {
                const updatedOrg = await get(`/organizations/${selectedOrg.id}`);
                setSelectedOrg(updatedOrg.data);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            toast.error('Failed to delete user');
        }
    };

    const showUsersModal = async (org: Organization) => {
        try {
            const response = await get(`/organizations/${org.id}`);
            setSelectedOrg(response.data);
            setIsUsersModalOpen(true);
        } catch (error) {
            console.error('Error fetching organization details:', error);
            toast.error('Failed to load organization details');
        }
    };
    
    useEffect(() => {
        getOrganizations();
    }, []);

    const filteredOrgs = organizations.filter(org =>
        org.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="px-3 mb-4 space-y-6">
            {/* Org Selector */}
            <Card>
                <CardContent className="p-2">
                    <Input
                        type="text"
                        placeholder="Search organizations..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {searchTerm && (
                        <div className="max-h-48 overflow-y-auto mt-2">
                            {filteredOrgs.length > 0 ? (
                                filteredOrgs.map((org) => (
                                    <Button
                                        key={org.id}
                                        variant="ghost"
                                        onClick={() => handleOrgChange(org)}
                                        className={`w-full justify-start ${
                                            organization?.id === org.id ? 'bg-accent' : ''
                                        }`}
                                    >
                                        {org.name}
                                    </Button>
                                ))
                            ) : (
                                <div className="px-4 py-2 text-sm text-muted-foreground">
                                    No organizations found
                                </div>
                            )}
                        </div>
                    )}
                </CardContent>
            </Card>

            {/* Organizations Table */}
            <Card>
                <CardHeader>
                    <CardTitle>Organizations</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Name</TableHead>
                                    <TableHead className="text-right">Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {organizations.map((org) => (
                                    <TableRow key={org.id}>
                                        <TableCell>{org.name}</TableCell>
                                        <TableCell className="text-right">
                                            <div className="flex justify-end space-x-2">
                                                <Button
                                                    variant="ghost"
                                                    onClick={() => showUsersModal(org)}
                                                    className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300"
                                                >
                                                    View Users
                                                </Button>
                                                <RowDelete
                                                    deleteCallback={() => handleDeleteOrg(org.id)}
                                                    deleteMessage={`Are you sure you want to delete ${org.name}?`}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </CardContent>
            </Card>

            {/* Users Modal */}
            <Dialog open={isUsersModalOpen} onOpenChange={setIsUsersModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Users in {selectedOrg?.name}</DialogTitle>
                    </DialogHeader>
                    
                    <div className="mt-4">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Name</TableHead>
                                    <TableHead>Email</TableHead>
                                    <TableHead className="text-right">Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {selectedOrg?.users?.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell className="text-right">
                                            <RowDelete
                                                deleteCallback={() => handleDeleteUser(user.id)}
                                                deleteMessage={`Are you sure you want to delete user ${user.name}?`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>

                    <DialogFooter>
                        <Button
                            variant="secondary"
                            onClick={() => setIsUsersModalOpen(false)}
                        >
                            Close
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Admin;