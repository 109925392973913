import { useEffect, useState } from 'react'
import useApi from '../api';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';

import { FormField, DocumentationInterface } from '../types'
import Spinner from '../documentation/Spinner';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import DeployAgent from './DeployAgent';
import useAnalytics from '../analytics/analytics';
import PublishAgent from './PublishAgent';
import { conLog } from '../utils/conLog';
import { IconList } from '../ui/heroicons-picker/iconType';
import { useUser } from '../user/UserContext';
import { useSearchParams } from 'react-router-dom';
import { AimdocComponentContext } from '@aimdoc/sdk-react';

// shadcn UI components
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { Badge } from '@/components/ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

// Icons
import {  
    FileText, 
    Palette, 
    SaveIcon, 
    Zap,
    Bot,
    ChevronLeft,
    Settings,
    Brain,
} from 'lucide-react';

// Import tab components
import InstructionsTab from './tabs/InstructionsTab';
import KnowledgeTab from './tabs/KnowledgeTab';
import CustomizationTab from './tabs/CustomizationTab';
import FormTab from './tabs/FormTab';
import ActionsTab from './tabs/ActionsTab';
import AppearanceTab from './tabs/AppearanceTab';

interface EditorProps {
    docId?: string | undefined;
    agentId?: string | undefined;
    fields?: FormField[];
    modalRef?: any;
    toggleModal?: any
    setAgents?: any
    modalVisible?: boolean
}

// Add this constant outside the component
const TAB_CONFIG = {
  website: [
    { name: 'instructions', icon: <Bot className="w-4 h-4" />, label: 'Instructions' },
    { name: 'knowledge', icon: <Brain className="w-4 h-4" />, label: 'Knowledge' },
    { name: 'customization', icon: <Settings className="w-4 h-4" />, label: 'Configuration' },
    { name: 'appearance', icon: <Palette className="w-4 h-4" />, label: 'Appearance' },
    { name: 'form', icon: <FileText className="w-4 h-4" />, label: 'Form' },
    { name: 'actions', icon: <Zap className="w-4 h-4" />, label: 'Actions' }
  ],
  product: [
    { name: 'capabilities', icon: <Zap className="w-4 h-4" />, label: 'Configuration' },
    { name: 'knowledge', icon: <Brain className="w-4 h-4" />, label: 'Knowledge' },
    { name: 'appearance', icon: <Palette className="w-4 h-4" />, label: 'Appearance' },
  ]
};

export default function ConfigureAgent({ docId, agentId, fields, modalRef, toggleModal, setAgents, modalVisible }: EditorProps) {
    const { id } = useParams();
    const [doc, setDoc] = useState<DocumentationInterface | undefined>();
    const [docFields, setDocFields] = useState<FormField[] | undefined>(fields ? fields : []);
    const [agId, setAgId] = useState<string | undefined>(() => {
        if (agentId) {
            return agentId;
        } else {
            if (window.location.pathname.includes('agents')) {
                return id;
            }
        }
    });
    const [links, setLinks] = useState<any>([]);
    const [starters, setStarters] = useState<any>([]);
    const [agentName, setAgentName] = useState<string>('');
    const [agentDescription, setAgentDescription] = useState<string>('');
    const [goal, setGoal] = useState<string>('');
    const [persona, setPersona] = useState<string>('');
    const [userInput, setUserInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedFields, setSelectedFields] = useState<FormField[] | undefined>([]);
    const [editName, setEditName] = useState(false);
    const [needsUpdate, setNeedsUpdate] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [iconFile, setIconFile] = useState<File | null>(null);
    const [fileChanged, setFileChanged] = useState(false);
    const [iconFileChanged, setIconFileChanged] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [iconPreviewUrl, setIconPreviewUrl] = useState('');
    const [agentType, setAgentType] = useState<string | undefined>('Website');
    const [color, setColor] = useState("#000000");
    const [endColor, setEndColor] = useState("#000000");
    const [isColorValid, setIsColorValid] = useState(true);
    const [isEndColorValid, setIsEndColorValid] = useState(true);
    const [website, setWebsite] = useState<string>('');
    const [indexedPages, setIndexedPages] = useState<any[]>([]);
    const [selectedPages, setSelectedPages] = useState<Set<string>>(new Set());
    const [colorScheme, setColorScheme] = useState<string>('Solid');
    const [welcomeMessage, setWelcomeMessage] = useState<string>('Welcome to our website! How can I help you today?');
    const [font, setFont] = useState<string>('open-sans');
    const [refreshPreview, setRefreshPreview] = useState<boolean>(false);
    const [autoOpenEnabled, setAutoOpenEnabled] = useState<boolean>(false);
    const [openOncePerSession, setOpenOncePerSession] = useState<boolean>(false);
    const [enableFollowUps, setEnableFollowUps] = useState<boolean>(false);
    const [followUpInstructions, setFollowUpInstructions] = useState<string>('');
    const [enableVisitorLocation, setEnableVisitorLocation] = useState<boolean>(false);
    const [enablePageAwareness, setEnablePageAwareness] = useState<boolean>(false);
    const [autoOpenSeconds, setAutoOpenSeconds] = useState<number>(0);
    const [popupMessageEnabled, setPopupMessageEnabled] = useState<boolean>(false);
    const [contextualPopupEnabled, setContextualPopupEnabled] = useState<boolean>(true);
    const [contextualStartersEnabled, setContextualStartersEnabled] = useState<boolean>(true);
    const [chatbotEnabled, setChatbotEnabled] = useState<boolean>(false);
    const [searchBarEnabled, setSearchBarEnabled] = useState<boolean>(false);
    const [searchBarPlaceholder, setSearchBarPlaceholder] = useState<string>('');
    const [hideAimdoc, setHideAimdoc] = useState<boolean>(false);
    const [popupMessage, setPopupMessage] = useState<string>('');
    const [agentIconType, setAgentIconType] = useState<string>('Icon');
    const [agentIcon, setAgentIcon] = useState<IconList>('FireIcon');
    const [draftId, setDraftId] = useState<string | undefined>();
    const [form, setForm] = useState<any[]>([]);
    const [qualifyVisitor, setQualifyVisitor] = useState<boolean>(false);
    const [actions, setActions] = useState<Record<string, any>>({});
    const [enableCopilot, setEnableCopilot] = useState<boolean>(false);
    const [escalationEnabled, setEscalationEnabled] = useState<boolean>(false);
    const [escalationButtons, setEscalationButtons] = useState<any[]>([]);
    const [gdprEnabled, setGdprEnabled] = useState<boolean>(false);
    const { get, post, put, remove } = useApi();

    const navigate = useNavigate();
    const { captureEvent } = useAnalytics();
    const { checkLicenseType, organization } = useUser();

    const [searchParams, setSearchParams] = useSearchParams();
    const initialTab = searchParams.get('tab');
    const [selectedTab, setSelectedTab] = useState(initialTab);

    const handleTabChange = (value: string) => {
        setSelectedTab(value);
        setSearchParams({ tab: value });
    };

    useEffect(() => {
        const tab = searchParams.get('tab') || "instructions";
        setSelectedTab(tab);
    }, [searchParams]);

    const createAgent = async (silent?: boolean) => {
        const colors = compareColors(color, endColor);
        if (agentName === "") {
            toast.error('Agent name is required.', toastConfig);
            return;
        }
        if (isColorValid === false) {
            toast.error('First color is not valid.', toastConfig);
            return;
        };
        if (isEndColorValid === false) {
            toast.error('Second color is not valid.', toastConfig);
            return;
        }
        if (agentIconType === 'Image' && !iconPreviewUrl) {
            toast.error('Please upload an icon image.', toastConfig);
            return;
        }

        const data = {
            document_id: docId ? docId : doc?.id,
            fields: selectedFields,
            name: agentName,
            description: agentDescription,
            goal: goal,
            type: agentType ? agentType : "Website",
            persona: persona,
            tools: actions,
            links: links,
            starters: starters,
            config: {
                font: font,
                scheme: colorScheme,
                color: color,
                endColor: endColor,
                mixedColor: mixColors(color, endColor),
                darkerColor: colors.darkerColor,
                lighterColor: colors.lighterColor,
                welcomeMessage: welcomeMessage,
                autoOpenEnabled: autoOpenEnabled,
                autoOpenSeconds: autoOpenSeconds,
                openOncePerSession: openOncePerSession,
                popupMessageEnabled: popupMessageEnabled,
                popupMessage: popupMessage,
                contextualPopupEnabled: contextualPopupEnabled,
                contextualStartersEnabled: contextualStartersEnabled,
                chatbotEnabled: chatbotEnabled,
                searchBarEnabled: searchBarEnabled,
                searchBarPlaceholder: searchBarPlaceholder,
                agentMessageIconType: agentIconType,
                agentMessageIcon: agentIcon,
                enableFollowUps: enableFollowUps,
                followUpInstructions: followUpInstructions,
                enableVisitorLocation: enableVisitorLocation,
                enablePageAwareness: enablePageAwareness,
                hideAimdoc: hideAimdoc,
                form: form,
                qualifyVisitor: qualifyVisitor,
                enableCopilot: enableCopilot,
                escalationEnabled: escalationEnabled,
                escalationButtons: escalationButtons,
                gdprEnabled: gdprEnabled,
            },
            website
        }

        conLog(data)

        try {
            if (agId) {
                put(`/agents/${agId}`, data)
                    .then(async (response) => {
                        if (previewUrl === "") {
                            deleteLogo();
                        } else {
                            uploadLogo(agId, file);
                        }
                        if (iconPreviewUrl === "") {
                            deleteIcon();
                        } else {
                            uploadIcon(agId, iconFile);
                        }
                        setLoading(false);
                        if (!silent && toggleModal) {
                            toggleModal();
                        }
                        toast.success(`${agentName} succesfully updated.`, toastConfig);
                        captureEvent('agent_updated', {
                            agent_id: response.data.id,
                            agent_name: agentName,
                        });
                    })
                    .catch(error => {
                        console.error('Error creating agent:', error);
                        toast.error(`${agentName} failed to update.`, toastConfig);
                        setLoading(false);
                    });
            } else {
                data.config.form = [
                    {
                        name: 'first_name',
                        label: 'First Name',
                        type: 'text',
                        required: true,
                    },
                    {
                        name: 'last_name',
                        label: 'Last Name',
                        type: 'text',
                        required: true,
                    },
                    {
                        name: 'email',
                        label: 'Email',
                        type: 'email',
                        required: true,
                    }
                ];
                post('/agents/', data)
                    .then(async (response) => {
                        uploadLogo(response.data.id, file);
                        uploadIcon(response.data.id, iconFile);
                        setLoading(false);
                        if (!silent && toggleModal) {
                            toggleModal();
                        }
                        if (docId) {
                            setAgId(response.data.id);
                            setAgents((prev: any) => [...prev, response.data]);
                        } else {
                            navigate(`/agents/${response.data.id}`);
                        }

                        toast.success(`${agentName} succesfully created.`, toastConfig);
                        captureEvent('agent_created', {
                            agent_id: response.data.id,
                            agent_name: agentName,
                        });
                    })
                    .catch(error => {
                        console.error('Error creating agent:', error);
                        toast.error(`${error.detail}`, toastConfig);
                        setLoading(false);
                    });

            }

            setRefreshPreview(true);

        } catch (error) {
            toast.error(`${agentName} failed to update.`, toastConfig);
            console.error('Error creating content:', error);
        }

        setLoading(false);
        setUserInput('');
    };

    useEffect(() => {
        if (agId) {
            const fetchContent = async () => {
                setLoading(true)
                try {
                    const response = await get(`/agents/${agId}`);
                    const data = response.data;
                    conLog(data)
                    setDraftId(data.id);
                    setAgentName(data.name);
                    setAgentDescription(data.description);
                    setGoal(data.goal);
                    setPersona(data.persona);
                    setSelectedFields(data.fields);
                    setActions(data.tools ? data.tools : {});
                    setLinks(data.links ? data.links : []);
                    setStarters(data.starters ? data.starters : []);
                    setPreviewUrl(data.logo_url ? data.logo_url : '');
                    setIconPreviewUrl(data.message_icon_url ? data.message_icon_url : '');
                    setAgentType(data.type);
                    if (data.type === 'product') {
                        setSelectedTab('capabilities');
                    } else {
                        setSelectedTab('instructions');
                    }
                    setColorScheme(data.config.scheme);
                    setFont(data.config.font);
                    setDoc(data.document);
                    setColor(data.config.color);
                    setEndColor(data.config.endColor);
                    setWelcomeMessage(data.config.welcomeMessage);
                    setAutoOpenEnabled(data.config.autoOpenEnabled ? data.config.autoOpenEnabled : false);
                    setAutoOpenSeconds(data.config.autoOpenSeconds ? data.config.autoOpenSeconds : 0);
                    setOpenOncePerSession(data.config.openOncePerSession ? data.config.openOncePerSession : false);
                    setPopupMessageEnabled(data.config.popupMessageEnabled ? data.config.popupMessageEnabled : false);
                    setPopupMessage(data.config.popupMessage ? data.config.popupMessage : '');
                    setAgentIconType(data.config.agentMessageIconType ? data.config.agentMessageIconType : 'Icon');
                    setAgentIcon(data.config.agentMessageIcon ? data.config.agentMessageIcon : 'FireIcon');
                    setEnableFollowUps(data.config.enableFollowUps ? data.config.enableFollowUps : false);
                    setEnableVisitorLocation(data.config.enableVisitorLocation ? data.config.enableVisitorLocation : false);
                    setFollowUpInstructions(data.config.followUpInstructions ? data.config.followUpInstructions : '');
                    setHideAimdoc(data.config.hideAimdoc ? data.config.hideAimdoc : false);
                    setForm(data.config.form ? data.config.form : []);
                    setContextualPopupEnabled(data.config.contextualPopupEnabled ? data.config.contextualPopupEnabled : false);
                    setContextualStartersEnabled(data.config.contextualStartersEnabled ? data.config.contextualStartersEnabled : false);
                    setEnablePageAwareness(data.config.enablePageAwareness ? data.config.enablePageAwareness : false);
                    setChatbotEnabled(data.config.chatbotEnabled ? data.config.chatbotEnabled : true);
                    setSearchBarEnabled(data.config.searchBarEnabled ? data.config.searchBarEnabled : false);
                    setSearchBarPlaceholder(data.config.searchBarPlaceholder ? data.config.searchBarPlaceholder : '');
                    setQualifyVisitor(data.config.qualifyVisitor ?? false);
                    setEnableCopilot(data.config.enableCopilot ? data.config.enableCopilot : false);
                    setEscalationEnabled(data.config.escalationEnabled ?? false);
                    setEscalationButtons(data.config.escalationButtons ?? []);
                    setGdprEnabled(data.config.gdprEnabled ?? false);
                } catch (error) {
                    console.error('Error fetching content:', error);
                }
                setLoading(false);
            };
            fetchContent();
        }
    }, [agId]);

    useEffect(() => {
        if (!agId && id) {
            setSelectedFields(fields);
        }
    }, []);

    useEffect(() => {
        if (needsUpdate) {
            createAgent(true);
            setNeedsUpdate(false);
        };
    }, [needsUpdate]);

    useEffect(() => {
        if (doc) {
            setDocFields(doc.doc_schema);
        }
    }, [doc]);

    useEffect(() => {
        if (file) {
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
            return () => URL.revokeObjectURL(url);
        }
    }, [file]);

    function uploadLogo(id: string, file: any) {
        const formData = new FormData();
        if (file && fileChanged) {
            formData.append('file', file);
            post(`/agents/${id}/logo`, formData)
                .then(response => {
                    conLog('Success');
                    return response.data;
                })
                .catch((error) => {
                    console.error('Error:', error.response.data);
                });
        }
    }

    const deleteLogo = () => {
        remove(`/agents/${agId}/logo`)
            .then(response => {
                setFile(null);
                setPreviewUrl('');
                return response.data;
            })
            .catch((error) => {
                console.error('Error:', error.response.data);
            });
    };

    useEffect(() => {
        if (iconFile) {
            const url = URL.createObjectURL(iconFile);
            setIconPreviewUrl(url);
            return () => URL.revokeObjectURL(url);
        }
    }, [iconFile]);

    function uploadIcon(id: string, file: any) {
        const formData = new FormData();
        if (iconFile && iconFileChanged) {
            formData.append('file', file);
            post(`/agents/${id}/message-icon`, formData)
                .then(response => {
                    conLog('Success');
                    return response.data;
                })
                .catch((error) => {
                    console.error('Error:', error.response.data);
                });
        }
    }

    const deleteIcon = () => {
        remove(`/agents/${agId}/message-icon`)
            .then(response => {
                setIconFile(null);
                setIconPreviewUrl('');
                response.data;
            })
            .catch((error) => {
                console.error('Error:', error.response.data);
            });
    };


    function mixColors(color1: string, color2: string, weight = 0.5) {
        let [r1, g1, b1] = hexToRgb(color1);
        let [r2, g2, b2] = hexToRgb(color2);

        let r = Math.round(r1 * (1 - weight) + r2 * weight);
        let g = Math.round(g1 * (1 - weight) + g2 * weight);
        let b = Math.round(b1 * (1 - weight) + b2 * weight);

        return rgbToHex(r, g, b);
    }

    function hexToRgb(hex: any) {
        let r = parseInt(hex.slice(1, 3), 16);
        let g = parseInt(hex.slice(3, 5), 16);
        let b = parseInt(hex.slice(5, 7), 16);
        return [r, g, b];
    }

    function rgbToHex(r: number, g: number, b: number) {
        return "#" + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');
    }

    function luminance(r: number, g: number, b: number) {
        var a = [r, g, b].map(function (v) {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }

    function compareColors(hex1: string, hex2: string) {
        var rgb1 = hexToRgb(hex1);
        var rgb2 = hexToRgb(hex2);
        var lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
        var lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);

        if (lum1 < lum2) {
            return { darkerColor: hex1, lighterColor: hex2 };
        } else {
            return { darkerColor: hex2, lighterColor: hex1 };
        }
    }

    return (
        <ScrollArea className="h-full w-full border-r">
            {agId && agentName === "" ? <Spinner /> : (
                <div className="h-full w-full border-r p-6">
                    <AimdocComponentContext
                        componentDescription="You are currently in the agent builder. You will see tabs for Instructions, Knowledge, Customization, Form, Actions, and Sessions."
                        componentName="Agent Builder"
                    />
                    
                    <div className="flex items-center space-x-4 mb-6">
                        <Button
                            onClick={() => navigate('/agents')}
                            variant="ghost"
                            className="flex items-center gap-2"
                        >
                            <ChevronLeft className="h-4 w-4" />
                            Back to Agents
                        </Button>
                        
                        <Separator orientation="vertical" className="h-6" />
                        
                        {agId && (
                            <div className="flex items-center gap-2">
                                <h2 className="text-sm font-bold">{agId}</h2>
                                <Separator orientation="vertical" className="ml-4 h-6" />
                                <Badge variant="outline" className="ml-2">
                                    {agentType === 'product' ? 'Operator' : 'Flagship Sales Agent'}
                                </Badge>
                            </div>
                        )}
                    </div>

                    {!agId && (
                        <Card className="mb-6">
                            <CardHeader>
                                <CardTitle>Create New Agent</CardTitle>
                                <CardDescription>
                                    Configure your new AI agent by providing basic information
                                </CardDescription>
                            </CardHeader>
                            <CardContent>
                                <div className="space-y-4">
                                    <div className="space-y-2">
                                        <Label htmlFor="name">Agent Name</Label>
                                        <Input
                                            id="name"
                                            placeholder="Enter your agent name..."
                                            value={agentName}
                                            onChange={(e) => setAgentName(e.target.value)}
                                            className="max-w-md"
                                        />
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    )}
                    {/* Agent Builder / Customization, already created in the AgentsList.tsx */}
                    <div className="flex flex-col h-full w-full rounded-lg">
                        {agId && (
                            <PublishAgent 
                                agId={agId} 
                                editName={editName} 
                                setEditName={setEditName} 
                                setAgentName={setAgentName} 
                                agentName={agentName} 
                            />
                        )}
                        
                        <div className="flex flex-row overflow-y-auto min-h-[800px]">
                            <div className="flex flex-grow min-h-full w-5/6 mr-8">
                                <div className="flex flex-col h-full w-full grow">
                                    <div className="flex h-full">
                                        <div className="w-full h-full">
                                            <div className="flex justify-between items-center mb-6">
                                                <Tabs 
                                                    value={selectedTab || 'instructions'} 
                                                    onValueChange={handleTabChange} 
                                                    className="w-full"
                                                >
                                                    <div className="flex justify-between items-center">
                                                    <TabsList>
                                                        {TAB_CONFIG[agentType === 'product' ? 'product' : 'website'].map((tab) => (
                                                            <TabsTrigger 
                                                                key={tab.name}
                                                                value={tab.name}
                                                                className="flex items-center gap-2"
                                                            >
                                                                {tab.icon}
                                                                {tab.label}
                                                            </TabsTrigger>
                                                        ))}
                                                    </TabsList>

                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger asChild>
                                                                <Button
                                                                    onClick={() => createAgent()}
                                                                    className="flex items-center gap-2"
                                                                    variant="secondary"
                                                                >
                                                                    Save Draft
                                                                    <SaveIcon className="w-4 h-4" />
                                                                </Button>
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                Save your agent configuration as a draft.<br/>Remember to publish your agent to make it live.
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                    </div>

                                                    <TabsContent value="instructions">
                                                        <InstructionsTab
                                                            agId={agId}
                                                            agentName={agentName}
                                                            goal={goal}
                                                            setGoal={setGoal}
                                                            persona={persona}
                                                            setPersona={setPersona}
                                                            agentType={agentType || 'Website'}
                                                            setAgentType={setAgentType}
                                                            setNeedsUpdate={setNeedsUpdate}
                                                        />
                                                    </TabsContent>

                                                    <TabsContent value="knowledge">
                                                        <KnowledgeTab
                                                            agId={agId}
                                                            agentName={agentName}
                                                            website={website}
                                                            setWebsite={setWebsite}
                                                            selectedPages={selectedPages}
                                                            setSelectedPages={setSelectedPages}
                                                            indexedPages={indexedPages}
                                                            setIndexedPages={setIndexedPages}
                                                            setNeedsUpdate={setNeedsUpdate}
                                                        />
                                                    </TabsContent>

                                                    <TabsContent value="appearance">
                                                        <AppearanceTab
                                                            agId={agId}
                                                            agentName={agentName}
                                                            color={color}
                                                            setColor={setColor}
                                                            endColor={endColor}
                                                            setEndColor={setEndColor}
                                                            colorScheme={colorScheme}
                                                            setColorScheme={setColorScheme}
                                                            font={font}
                                                            setFont={setFont}
                                                            welcomeMessage={welcomeMessage}
                                                            setWelcomeMessage={setWelcomeMessage}
                                                            links={links}
                                                            setLinks={setLinks}
                                                            starters={starters}
                                                            setStarters={setStarters}
                                                            autoOpenEnabled={autoOpenEnabled}
                                                            setAutoOpenEnabled={setAutoOpenEnabled}
                                                            openOncePerSession={openOncePerSession}
                                                            setOpenOncePerSession={setOpenOncePerSession}
                                                            autoOpenSeconds={autoOpenSeconds}
                                                            setAutoOpenSeconds={setAutoOpenSeconds}
                                                            popupMessageEnabled={popupMessageEnabled}
                                                            setPopupMessageEnabled={setPopupMessageEnabled}
                                                            popupMessage={popupMessage}
                                                            setPopupMessage={setPopupMessage}
                                                            contextualPopupEnabled={contextualPopupEnabled}
                                                            setContextualPopupEnabled={setContextualPopupEnabled}
                                                            contextualStartersEnabled={contextualStartersEnabled}
                                                            setContextualStartersEnabled={setContextualStartersEnabled}
                                                            enableFollowUps={enableFollowUps}
                                                            setEnableFollowUps={setEnableFollowUps}
                                                            followUpInstructions={followUpInstructions}
                                                            setFollowUpInstructions={setFollowUpInstructions}
                                                            file={file}
                                                            setFile={setFile}
                                                            previewUrl={previewUrl}
                                                            setPreviewUrl={setPreviewUrl}
                                                            iconFile={iconFile}
                                                            setIconFile={setIconFile}
                                                            iconPreviewUrl={iconPreviewUrl}
                                                            setIconPreviewUrl={setIconPreviewUrl}
                                                            agentIconType={agentIconType}
                                                            setAgentIconType={setAgentIconType}
                                                            agentIcon={agentIcon}
                                                            setAgentIcon={setAgentIcon}
                                                            setNeedsUpdate={setNeedsUpdate}
                                                            setFileChanged={setFileChanged}
                                                            setIconFileChanged={setIconFileChanged}
                                                            escalationEnabled={escalationEnabled}
                                                            setEscalationEnabled={setEscalationEnabled}
                                                            escalationButtons={escalationButtons}
                                                            setEscalationButtons={setEscalationButtons}
                                                            gdprEnabled={gdprEnabled}
                                                            setGdprEnabled={setGdprEnabled}
                                                        />
                                                    </TabsContent>

                                                    <TabsContent value="customization">
                                                        <CustomizationTab
                                                            agId={agId}
                                                            agentName={agentName}
                                                            color={color}
                                                            setColor={setColor}
                                                            endColor={endColor}
                                                            setEndColor={setEndColor}
                                                            colorScheme={colorScheme}
                                                            setColorScheme={setColorScheme}
                                                            font={font}
                                                            setFont={setFont}
                                                            welcomeMessage={welcomeMessage}
                                                            setWelcomeMessage={setWelcomeMessage}
                                                            links={links}
                                                            setLinks={setLinks}
                                                            starters={starters}
                                                            setStarters={setStarters}
                                                            autoOpenEnabled={autoOpenEnabled}
                                                            setAutoOpenEnabled={setAutoOpenEnabled}
                                                            autoOpenSeconds={autoOpenSeconds}
                                                            setAutoOpenSeconds={setAutoOpenSeconds}
                                                            openOncePerSession={openOncePerSession}
                                                            setOpenOncePerSession={setOpenOncePerSession}
                                                            popupMessageEnabled={popupMessageEnabled}
                                                            setPopupMessageEnabled={setPopupMessageEnabled}
                                                            popupMessage={popupMessage}
                                                            setPopupMessage={setPopupMessage}
                                                            contextualPopupEnabled={contextualPopupEnabled}
                                                            setContextualPopupEnabled={setContextualPopupEnabled}
                                                            contextualStartersEnabled={contextualStartersEnabled}
                                                            setContextualStartersEnabled={setContextualStartersEnabled}
                                                            enableFollowUps={enableFollowUps}
                                                            setEnableFollowUps={setEnableFollowUps}
                                                            followUpInstructions={followUpInstructions}
                                                            setFollowUpInstructions={setFollowUpInstructions}
                                                            file={file}
                                                            setFile={setFile}
                                                            previewUrl={previewUrl}
                                                            setPreviewUrl={setPreviewUrl}
                                                            iconFile={iconFile}
                                                            setIconFile={setIconFile}
                                                            iconPreviewUrl={iconPreviewUrl}
                                                            setIconPreviewUrl={setIconPreviewUrl}
                                                            agentIconType={agentIconType}
                                                            setAgentIconType={setAgentIconType}
                                                            agentIcon={agentIcon}
                                                            setAgentIcon={setAgentIcon}
                                                            setNeedsUpdate={setNeedsUpdate}
                                                            setFileChanged={setFileChanged}
                                                            setIconFileChanged={setIconFileChanged}
                                                            escalationEnabled={escalationEnabled}
                                                            setEscalationEnabled={setEscalationEnabled}
                                                            escalationButtons={escalationButtons}
                                                            setEscalationButtons={setEscalationButtons}
                                                            gdprEnabled={gdprEnabled}
                                                            setGdprEnabled={setGdprEnabled}
                                                        />
                                                    </TabsContent>

                                                    {agentType !== 'product' && (
                                                        <TabsContent value="form">
                                                            <FormTab
                                                                agId={agId}
                                                                agentName={agentName}
                                                                form={form}
                                                                setForm={setForm}
                                                                qualifyVisitor={qualifyVisitor}
                                                                setQualifyVisitor={setQualifyVisitor}
                                                                setNeedsUpdate={setNeedsUpdate}
                                                            />
                                                        </TabsContent>
                                                    )}

                                                    <TabsContent value="actions">
                                                        <ActionsTab
                                                            agId={agId}
                                                            agentName={agentName}
                                                            actions={actions}
                                                            setActions={setActions}
                                                            agentType={agentType || 'Website'}
                                                            form={form}
                                                            setNeedsUpdate={setNeedsUpdate}
                                                            escalationEnabled={escalationEnabled}
                                                        />
                                                    </TabsContent>

                                                    <TabsContent value="capabilities">
                                                        <ActionsTab
                                                            agId={agId}
                                                            agentName={agentName}
                                                            actions={actions}
                                                            setActions={setActions}
                                                            agentType={agentType || 'Website'}
                                                            form={form}
                                                            setNeedsUpdate={setNeedsUpdate}
                                                            escalationEnabled={escalationEnabled}
                                                        />
                                                    </TabsContent>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {agId && (
                                <DeployAgent 
                                    agId={agId} 
                                    draftId={draftId} 
                                    refresh={refreshPreview} 
                                    setRefreshPreview={setRefreshPreview} 
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </ScrollArea>
    );
}