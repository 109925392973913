import { toast } from 'react-toastify';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useState, useEffect, useRef } from 'react';
import useApi from '../api';
import { useOutsideAlerter } from '../useOutsideAlerter';
import Confetti from 'react-confetti';
import BeatLoader from '../ui/BeatLoader';
import { RocketIcon } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '@/components/ui/tooltip';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/components/ui/table';
import { ScrollArea } from '@/components/ui/scroll-area';
import { ShinyButton } from '../magicui/shiny-button';
import { Card, CardContent } from "@/components/ui/card";

interface PublishAgentProps {
    agId: string;
    editName: any;
    setEditName: any;
    agentName: string;
    setAgentName: any;
}

const formatSettingName = (key: string): string => {
    // Split by camelCase and underscore
    const words = key.split(/(?=[A-Z])|_/);
    // Capitalize first letter of each word and join with spaces
    return words.map(word => 
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
};

const formatSettingValue = (value: any): string | JSX.Element => {
    if (value === null || value === undefined) return '(empty)';
    
    // Handle objects
    if (typeof value === 'object' && !Array.isArray(value)) {
        try {
            if (value.value !== undefined) return String(value.value);
            if (value.name !== undefined) return String(value.name);
            
            // Return a JSX element for objects instead of string concatenation
            return (
                <div className="flex flex-col gap-2">
                    {Object.entries(value).map(([key, val]) => (
                        <div key={key} className="flex flex-col gap-1">
                            <span className="font-medium text-sm text-muted-foreground">
                                {formatSettingName(key)}
                            </span>
                            <span className="font-medium">
                                {typeof val === 'object' ? formatSettingValue(val) : String(val)}
                            </span>
                        </div>
                    ))}
                </div>
            );
        } catch (e) {
            return String(value);
        }
    }
    
    // If it's an array
    if (Array.isArray(value)) {
        return (
            <div className="flex flex-col gap-2">
                {value.map((item, index) => {
                    let content;
                    if (typeof item === 'object') {
                        if (item.value !== undefined) {
                            content = item.value;
                        } else if (item.name !== undefined) {
                            content = item.name;
                        } else {
                            content = Object.entries(item)
                                .map(([key, val]) => (
                                    <div key={key} className="flex flex-col gap-1">
                                        <span className="font-medium text-sm text-muted-foreground">
                                            {formatSettingName(key)}
                                        </span>
                                        <span className="font-medium">
                                            {typeof val === 'object' ? formatSettingValue(val) : String(val)}
                                        </span>
                                    </div>
                                ));
                        }
                    } else {
                        content = String(item);
                    }

                    return (
                        <Card key={index} className="shadow-sm">
                            <CardContent className="p-3 flex flex-col gap-3">
                                {content}
                            </CardContent>
                        </Card>
                    );
                })}
            </div>
        );
    }

    // If it's a string that looks like JSON
    if (typeof value === 'string' && (value.startsWith('{') || value.startsWith('['))) {
        try {
            const parsed = JSON.parse(value);
            return formatSettingValue(parsed);
        } catch (e) {
            return value;
        }
    }

    return String(value);
};

const PublishAgent: React.FC<PublishAgentProps> = ({ agId, editName, setEditName, agentName, setAgentName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [confettiVisible, setConfettiVisible] = useState(false);
    const [changes, setChanges] = useState({});
    const [loading, setLoading] = useState(false);
    const { get, post } = useApi();

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    const nameRef = useRef(null);
    useOutsideAlerter(nameRef, () => {
        setAgentName(agentName);
        setEditName(false);
    });

    const publishAgent = async () => {
        setLoading(true);
        try {
            const response = await post(`/agents/${agId}/publish`, changes);
            toast.success('Agent published successfully');
            setChanges({});
            setConfettiVisible(true);
            setTimeout(() => {
                setConfettiVisible(false);
            }, 5000)
            setIsOpen(false);
            return response.data;
        } catch (error) {
            toast.error('Error publishing agent');
        }
        setLoading(false);
        closeModal();
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
    
        const fetchChanges = () => {
            if (agId) {
                get(`/agents/${agId}/changes`)
                    .then(response => {
                        setChanges(response.data.changes);
                    })
                    .catch(error => {
                        console.error('Error fetching draft changes:', error);
                    });
            }
        };
    
        fetchChanges();
        intervalId = setInterval(fetchChanges, 10000);
    
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [agId, isOpen]);

    const numberOfChanges = Object.keys(changes).length;

    return (
        <>
            {confettiVisible && <Confetti recycle={false}/>}
            <div className="flex mb-4 w-full justify-between border-b">
                <div className="mb-4 p-2 flex hover:shadow-lg hover:cursor-pointer rounded-lg justify-start border" onClick={() => setEditName(true)} ref={nameRef}>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                {editName || !agId ? (
                                    <div className="flex rounded-md text-xl">
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            autoComplete="off"
                                            autoFocus={true}
                                            placeholder="Enter you agent name..."
                                            value={agentName || ''}
                                            onChange={(e) => setAgentName(e.target.value)}
                                        />
                                    </div>
                                ) : (
                                    <h2 className="text-2xl font-semibold leading-7 py-1.5">{agentName !== "" ? agentName : "Agent Name"}</h2>
                                )}
                            </TooltipTrigger>
                            <TooltipContent>Rename agent here</TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                {!isOpen && (
                    <div className="flex mr-6 items-center space-x-3">
                        {numberOfChanges > 0 && (
                            <div className="flex flex-row items-center justify-center text-xs font-semibold text-yellow-500">
                                <div className="mr-2 rounded full w-2 h-2 bg-yellow-500 animate-pulse">
                                </div>
                                {numberOfChanges} unpublished change{numberOfChanges > 1 ? 's' : ''}
                            </div>
                        )}
                        <Button onClick={openModal} className="group flex flex-row justify-between items-center mr-8 px-4 py-2 text-sm text-blue-500 border border-blue-200 bg-blue-50 hover:cursor-pointer hover:bg-blue-100 dark:hover:bg-blue-800 rounded-lg font-bold dark:bg-blue-900 dark:border-blue-800 dark:text-blue-100 transition duration-200">
                            <div className="mr-2">
                                Publish
                            </div>
                            <RocketIcon className="w-6 h-6" />
                        </Button>
                    </div>
                )}
            </div>

            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogContent className="grow max-w-5xl">
                    <DialogHeader>
                        <DialogTitle className="text-center text-xl">
                            Publish Agent
                        </DialogTitle>
                    </DialogHeader>
                    
                    <div className="flex flex-col h-full justify-between w-full">
                        <div>
                            <div className="mb-2 font-semibold border-b py-2 text-xl font-light leading-6">
                                Review Changes
                            </div>
                            <ScrollArea className="h-full max-h-[400px]">
                                {Object.keys(changes).length > 0 ? (
                                    <Table>
                                        <TableHeader>
                                            <TableRow>
                                                <TableHead className="w-[200px]">Setting</TableHead>
                                                <TableHead className="w-[200px]">Current</TableHead>
                                                <TableHead className="w-[200px] grow">Change</TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {Object.entries(changes).flatMap(([key, value]: [string, any]) => {
                                                const formattedKey = key === 'logo_key'
                                                    ? 'Logo'
                                                    : key.charAt(0).toUpperCase() + key.slice(1).replace('_key', '');

                                                // Handle simple string/null values
                                                if (typeof value.live !== 'object' || typeof value.draft !== 'object') {
                                                    return [(
                                                        <TableRow key={key}>
                                                            <TableCell className="font-medium">
                                                                <div className="flex items-center space-x-2">
                                                                    <div className="w-2 h-2 rounded-full bg-yellow-400 animate-pulse" />
                                                                    <span>{formattedKey}</span>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="text-muted-foreground">
                                                                {formatSettingValue(value.live)}
                                                            </TableCell>
                                                            <TableCell className="font-medium">
                                                                {formatSettingValue(value.draft)}
                                                            </TableCell>
                                                        </TableRow>
                                                    )];
                                                }

                                                // Handle nested objects
                                                const allKeys = new Set([
                                                    ...Object.keys(value.live || {}),
                                                    ...Object.keys(value.draft || {})
                                                ]);

                                                return Array.from(allKeys).map(nestedKey => {
                                                    const liveValue = value.live?.[nestedKey];
                                                    const draftValue = value.draft?.[nestedKey];

                                                    // Skip if values are equal
                                                    if (JSON.stringify(liveValue) === JSON.stringify(draftValue)) {
                                                        return null;
                                                    }

                                                    return (
                                                        <TableRow key={`${key}-${nestedKey}`}>
                                                            <TableCell className="font-medium">
                                                                <div className="flex items-center space-x-2">
                                                                    <div className="w-2 h-2 rounded-full bg-yellow-400 animate-pulse" />
                                                                    <span>{`${formattedKey} > ${formatSettingName(nestedKey)}`}</span>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="text-muted-foreground whitespace-pre-wrap max-w-[300px] break-words">
                                                                {typeof liveValue === 'string' ? formatSettingValue(liveValue) : (
                                                                    <div className="py-1">{formatSettingValue(liveValue)}</div>
                                                                )}
                                                            </TableCell>
                                                            <TableCell className="font-medium whitespace-pre-wrap max-w-[300px] break-words">
                                                                {typeof draftValue === 'string' ? formatSettingValue(draftValue) : (
                                                                    <div className="py-1">{formatSettingValue(draftValue)}</div>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }).filter(Boolean);
                                            })}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <div className="flex text-slate-500 font-light text-sm h-full justify-center items-center">
                                        No changes to publish
                                    </div>
                                )}
                                {loading && <BeatLoader />}
                            </ScrollArea>
                        </div>
                        
                        <div className="flex justify-center pt-4">
                            <ShinyButton 
                                onClick={publishAgent}
                                disabled={Object.keys(changes).length === 0 || loading}
                                className="w-1/2 space-x-2"
                            >
                                <div className="flex flex-row gap-2 justify-center items-center">
                                    <span>Publish Agent</span>
                                    <RocketIcon className="w-5 h-5" />
                                </div>
                            </ShinyButton>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default PublishAgent;