import { useState } from 'react'
import useApi from '../api'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"

interface ChiliPiperProps {
    cpDomainName: string;
    cpInboundRouter: string;
    createIntegration: any;
    modalVisible: boolean;
    setModalVisible: any;
}

export default function ChiliPiper({ cpDomainName, cpInboundRouter, createIntegration, modalVisible, setModalVisible }: ChiliPiperProps) {
    const [domainName, setDomainName] = useState(cpDomainName ? cpDomainName : '');
    const [inboundRouter, setInboundRouter] = useState(cpInboundRouter ? cpInboundRouter : '');
    const { get, getStream, post, postStream, put, patch, remove } = useApi();

    function createChiliPiperIntegration() {
        createIntegration({
            domainName: domainName,
            inboundRouter: inboundRouter,
            appName: 'chili-piper'
        });
    }

    return (
        <Dialog open={modalVisible} onOpenChange={setModalVisible}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Connect ChiliPiper</DialogTitle>
                    <DialogDescription>
                        Enter your ChiliPiper domain and inbound router details.
                    </DialogDescription>
                </DialogHeader>

                <div className="grid gap-4 py-4">
                    <div className="space-y-2">
                        <Input
                            value={domainName}
                            onChange={(e) => setDomainName(e.target.value)}
                            placeholder="Domain Name"
                        />
                    </div>
                    <div className="space-y-2">
                        <Input
                            value={inboundRouter}
                            onChange={(e) => setInboundRouter(e.target.value)}
                            placeholder="Inbound Router Name"
                        />
                    </div>
                    <a
                        href="https://help.chilipiper.com/hc/en-us/articles/360053021874-Configuring-Your-Concierge-Inbound-Router"
                        target="_blank"
                        className="text-sm text-blue-600 dark:text-blue-400 underline hover:text-blue-800 dark:hover:text-blue-300 transition-colors"
                    >
                        How to find your Chili Piper Inbound Router name
                    </a>
                </div>

                <DialogFooter>
                    <Button
                        variant="outline"
                        onClick={() => setModalVisible(false)}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={createChiliPiperIntegration}
                    >
                        Connect
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
