import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import config from '../../config.json';

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Switch } from "@/components/ui/switch";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

import CopyCodeModal from './CopyCodeModal';
import { ShinyButton } from '../magicui/shiny-button';
import { ArrowDown } from 'lucide-react';

// Import embedded bundle dynamically
const loadEmbedded = () => {
    const script = document.createElement('script');
    script.src = '/embedded.bundle.js';
    script.async = true;
    return new Promise((resolve) => {
        script.onload = () => resolve((window as any).Agent);
        document.body.appendChild(script);
    });
};

interface DeployAgentProps {
    agId: string;
    draftId: string | undefined;
    refresh: boolean;
    setRefreshPreview: (refresh: boolean) => void;
}

const DeployAgent: React.FC<DeployAgentProps> = ({ agId, draftId, refresh, setRefreshPreview }) => {
    const [togglePreview, setTogglePreview] = useState<boolean>(false);
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [liveAgent, setLiveAgent] = useState<boolean>(false);
    const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState("preview");

    const generatePreview = (e: any) => {
        e.preventDefault();

        if (!agId) {
            toast.error('Please provide a valid website URL and Agent ID', toastConfig);
            return;
        }

        const encodedUrl = encodeURIComponent(previewUrl);
        const previewLink = `${config.env === "production" ? "https://app.aimdoc.ai" : "http://localhost:3000"}/agent-embed-preview/${liveAgent ? agId : draftId}?url=${encodedUrl}`;

        window.open(previewLink, '_blank');
    };

    useEffect(() => {
        const cleanup = () => {
            const existingScripts = document.querySelectorAll('script[src*="embedded.bundle.js"]');
            existingScripts.forEach(script => script.remove());

            const container = document.getElementById('preview-ai-assistant');
            const wrapper = document.querySelector('.wrapper-transition');
            if (container) {
                container.innerHTML = '';
            }
            if (wrapper) {
                wrapper.remove();
            }
        };

        if (!togglePreview && agId && selectedTab === "preview") {
            cleanup();

            if (config.env === "production") {
                const script = document.createElement('script');
                script.src = "https://app.aimdoc.ai/embedded.bundle.js";
                script.async = true;
                script.onload = () => {
                    (window as any).Agent.initAgentChat('preview-ai-assistant', {
                        agentId: draftId
                    });
                };
                document.body.appendChild(script);
            } else {
                loadEmbedded().then((Agent: any) => {
                    Agent.initAgentChat('preview-ai-assistant', {
                        agentId: draftId
                    });
                });
            }
        }

        return cleanup;
    }, [selectedTab, togglePreview, agId, draftId]);

    return (
        <div className="flex px-4 flex-col w-[25%] max-w-[30%] scrollbar-thin border-l grow">
            <TooltipProvider>
                <Tabs value={selectedTab} onValueChange={setSelectedTab} className="h-full w-full flex flex-col">
                    <TabsList className="mb-4">
                        <TabsTrigger
                            value="preview"
                            className="flex items-center grow space-x-1"
                        >
                            <span className="text-sm font-medium">Preview</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>
                        </TabsTrigger>
                        {agId && (
                            <TabsTrigger
                                value="deploy"
                                className="flex items-center grow space-x-1"
                            >
                                <span className="text-sm font-medium">Deploy</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5" />
                                </svg>
                            </TabsTrigger>
                        )}
                    </TabsList>

                    <TabsContent value="deploy" className="flex-1 overflow-y-auto">
                        <div className="flex flex-col w-full items-center justify-between p-3 rounded-lg border">
                            <div className="flex flex-col w-full text-center justify-between">
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Label className="text-lg font-medium">
                                            Website Embed Code
                                        </Label>
                                    </TooltipTrigger>
                                    <TooltipContent>Get the code to add your agent to your website</TooltipContent>
                                </Tooltip>
                                <p className="text-sm font-light leading-6 mt-1">
                                    Embed the agent widget within your site
                                </p>
                            </div>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <ShinyButton
                                        onClick={() => setIsCodeModalOpen(true)}
                                        className="mt-4"
                                    >
                                        View Embed Code
                                    </ShinyButton>
                                </TooltipTrigger>
                                <TooltipContent>Click to see and copy the embed code</TooltipContent>
                            </Tooltip>
                        </div>
                        <CopyCodeModal
                            isOpen={isCodeModalOpen}
                            setIsOpen={setIsCodeModalOpen}
                            agId={agId}
                        />
                    </TabsContent>

                    <TabsContent value="preview" className="flex-1 overflow-y-auto">
                        <div className="flex flex-col border rounded-lg p-2 w-full items-center">
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Label className="mt-2 text-lg font-medium">
                                        Preview Widget
                                    </Label>
                                </TooltipTrigger>
                                <TooltipContent>Preview your changes to the widget</TooltipContent>
                            </Tooltip>
                            <div className="mt-6 flex items-center space-x-2">
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Label className={`text-sm font-bold`}>Widget</Label>
                                    </TooltipTrigger>
                                    <TooltipContent>Preview the chat widget directly</TooltipContent>
                                </Tooltip>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <div>
                                            <Switch
                                                checked={togglePreview}
                                                onCheckedChange={setTogglePreview}
                                            />
                                        </div>
                                    </TooltipTrigger>
                                    <TooltipContent>Toggle between widget and website preview</TooltipContent>
                                </Tooltip>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Label className={`text-sm font-bold`}>Website</Label>
                                    </TooltipTrigger>
                                    <TooltipContent>Preview the agent on a website</TooltipContent>
                                </Tooltip>
                            </div>
                            {togglePreview ? (
                                <div className="flex flex-col w-full items-center mt-4 justify-between p-4 mx-4 border-dashed rounded-lg border">
                                    <div className="flex flex-col w-full justify-between">
                                        <Label className="text-lg font-medium">
                                            Preview on Website
                                        </Label>
                                        <p className="text-sm font-light leading-6 mt-1">
                                            Enter your website URL to preview the agent.
                                        </p>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <Input
                                                    className="mt-2"
                                                    onChange={(e) => setPreviewUrl(e.target.value)}
                                                    required={true}
                                                    placeholder="https://your-website.com"
                                                />
                                            </TooltipTrigger>
                                            <TooltipContent>Enter the full URL including https://</TooltipContent>
                                        </Tooltip>
                                        <div className="mt-4 flex items-center space-x-2">
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Label className={`text-sm font-bold`}>Draft</Label>
                                                </TooltipTrigger>
                                                <TooltipContent>Preview the draft version of your agent</TooltipContent>
                                            </Tooltip>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div>
                                                        <Switch
                                                            checked={liveAgent}
                                                            onCheckedChange={setLiveAgent}
                                                        />
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent>Switch between draft and live versions</TooltipContent>
                                            </Tooltip>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Label className={`text-sm font-bold`}>Live</Label>
                                                </TooltipTrigger>
                                                <TooltipContent>Preview the live version of your agent</TooltipContent>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                onClick={generatePreview}
                                                variant="secondary"
                                                className="w-full mt-4"
                                            >
                                                Generate Preview
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>Open a new window with your agent embedded in the website</TooltipContent>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div className="flex flex-col w-full items-center mt-2 justify-between p-6">
                                    <div className="flex flex-col w-full justify-between">
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <ShinyButton className="text-center">
                                                    <div className="flex justify-center items-center gap-2">
                                                        Preview below <ArrowDown className="w-4 h-4" />
                                                    </div>
                                                </ShinyButton>
                                            </TooltipTrigger>
                                            <TooltipContent>The chat widget will appear here for testing</TooltipContent>
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                        </div>
                    </TabsContent>
                </Tabs>
            </TooltipProvider>
        </div>
    );
}

export default DeployAgent;