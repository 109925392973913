import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import clsx from 'clsx';
import Links from '../builder/Links';
import Starters from '../builder/Starters';
import EscalationButtons from '../builder/EscalationButtons';
import { CustomizationTabProps } from './types';

const FormGroup = ({ children, className }: { children: React.ReactNode, className?: string }) => (
  <div className={clsx("space-y-2", className)}>
    {children}
  </div>
);

const Description = ({ children }: { children: React.ReactNode }) => (
  <p className="text-sm text-muted-foreground">
    {children}
  </p>
);

export default function CustomizationTab({
  agId,
  agentName,
  welcomeMessage,
  setWelcomeMessage,
  links,
  setLinks,
  starters,
  setStarters,
  autoOpenEnabled,
  setAutoOpenEnabled,
  autoOpenSeconds,
  setAutoOpenSeconds,
  popupMessageEnabled,
  setPopupMessageEnabled,
  popupMessage,
  setPopupMessage,
  contextualPopupEnabled,
  setContextualPopupEnabled,
  contextualStartersEnabled,
  setContextualStartersEnabled,
  enableFollowUps,
  setEnableFollowUps,
  followUpInstructions,
  setFollowUpInstructions,
  setNeedsUpdate,
  escalationEnabled,
  setEscalationEnabled,
  escalationButtons,
  setEscalationButtons,
  gdprEnabled,
  setGdprEnabled,
  openOncePerSession,
  setOpenOncePerSession
}: CustomizationTabProps) {

  return (
    <div className="mt-6 space-y-4">
      <div className="border rounded-lg p-6">
        <Label className="block text-xl font-semibold leading-6">
          Chat Settings
        </Label>
        <Label className="block text-sm font-light leading-6">
          Welcome message, auto-open, and auto-open duration.
        </Label>

        <FormGroup className="mt-4">
          <Label className="text-md font-medium">Welcome Message</Label>
          <Description>This is the first message your agent will send to visitors.</Description>
          <Input
            value={welcomeMessage}
            onChange={(e) => setWelcomeMessage(e.target.value)}
            placeholder="Enter welcome message..."
          />
        </FormGroup>

        <FormGroup className="mt-4">
          <Label className="text-md font-medium">Pop-up Message</Label>
          <Description>Default pop-up message shown next to the collapsed widget.</Description>
          <Switch
            checked={popupMessageEnabled}
            onCheckedChange={setPopupMessageEnabled}
          />
          {popupMessageEnabled && (
            <Input
              value={popupMessage}
              onChange={(e) => setPopupMessage(e.target.value)}
              placeholder="Enter pop-up message..."
              maxLength={80}
            />
          )}
        </FormGroup>

        {popupMessageEnabled && (
          <FormGroup className="mt-4">
            <Label className="text-md font-medium">Contextual Pop-ups</Label>
            <Description>AI generated pop-ups based on the page the visitor is currently on.</Description>
            <Switch
              checked={contextualPopupEnabled}
              onCheckedChange={setContextualPopupEnabled}
            />
          </FormGroup>
        )}

        <FormGroup className="mt-4">
          <Label className="text-md font-medium">Auto-open</Label>
          <Description>The agent will automatically open after the specified duration.</Description>
          <div className="flex flex-row items-center mt-2">
            <Switch
              checked={autoOpenEnabled}
              onCheckedChange={setAutoOpenEnabled}
            />
            {autoOpenEnabled && (
              <div className="flex flex-row items-center ml-2">
                <Input
                  type="number"
                  value={autoOpenSeconds}
                  onChange={(e) => setAutoOpenSeconds(Number(e.target.value))}
                  className="w-16 text-center"
                />
                <span className="ml-2 text-slate-400 text-xs">seconds</span>
              </div>
            )}
          </div>
        </FormGroup>
        {autoOpenEnabled && (
          <FormGroup className="mt-4">
            <Label className="text-md font-medium">Open Once Per Session</Label>
            <Description>The agent will only open once per session.</Description>
          <Switch
            checked={openOncePerSession}
            onCheckedChange={setOpenOncePerSession}
            />
          </FormGroup>
        )}
        <FormGroup className="mt-4">
          <Label className="text-md font-medium">GDPR Opt-In</Label>
          <div className="flex flex-row items-center mt-2">
            <Switch
              checked={gdprEnabled}
              onCheckedChange={setGdprEnabled}
            />
          </div>
        </FormGroup>
      </div>

      <div className="border rounded-lg p-6">
        <Label className="block text-xl font-semibold leading-6">
          Links
        </Label>
        <Label className="block text-sm font-light leading-6">
          Links that will display in the side panel of the agent screen. Your visitors can click on these links.
        </Label>
        <div className="mt-2">
          <Links links={links} setLinks={setLinks} setNeedsUpdate={setNeedsUpdate} agId={agId} agentName={agentName} />
        </div>
      </div>

      <div className="border rounded-lg p-6">
        <Label className="block text-xl font-semibold leading-6">
          Conversation Starters
        </Label>
        <Label className="block text-sm font-light leading-6">
          These are default conversation starters that will display below your welcome message. Your visitors can click on these starters to start a conversation.
        </Label>
        <div className="mt-2">
          <Starters
            agId={agId}
            agentName={agentName}
            starters={starters}
            setStarters={setStarters}
            setNeedsUpdate={setNeedsUpdate}
            enableFollowUps={enableFollowUps}
            setEnableFollowUps={setEnableFollowUps}
            followUpInstructions={followUpInstructions}
            setFollowUpInstructions={setFollowUpInstructions}
            contextualStartersEnabled={contextualStartersEnabled}
            setContextualStartersEnabled={setContextualStartersEnabled}
          />
        </div>
      </div>

      <div className="border rounded-lg p-6">
        <Label className="block text-xl font-semibold leading-6">
          Escalation Buttons
        </Label>
        <Label className="block text-sm font-light leading-6">
          Configure buttons that help escalate or redirect conversations when needed.
        </Label>
        <div className="mt-2">
          <EscalationButtons
            agId={agId}
            agentName={agentName}
            escalationButtons={escalationButtons}
            setEscalationButtons={setEscalationButtons}
            setNeedsUpdate={setNeedsUpdate}
            escalationEnabled={escalationEnabled}
            setEscalationEnabled={setEscalationEnabled}
          />
        </div>
      </div>
    </div>
  );
} 