import { useState, useRef } from 'react';
import { useOutsideAlerter } from '../../useOutsideAlerter';
import CreateEscalationButton from './CreateEscalationButton';
import { toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import useAnalytics from '../../analytics/analytics';
import { v4 as uuidv4 } from 'uuid';
import { Field, Label, Description } from '@headlessui/react'
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';

interface EscalationButton {
    id: string;
    caption: string;
    instructions: string;
}

const EscalationButtons = ({
    escalationButtons,
    setEscalationButtons,
    setNeedsUpdate,
    agId,
    agentName,
    escalationEnabled,
    setEscalationEnabled
}: {
    escalationButtons: EscalationButton[],
    setEscalationButtons: (buttons: EscalationButton[]) => void,
    setNeedsUpdate: (value: boolean) => void,
    agId: string | undefined,
    agentName: string | undefined,
    escalationEnabled: boolean,
    setEscalationEnabled: (enabled: boolean) => void
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [activeButton, setActiveButton] = useState<EscalationButton | null>(null);
    const { captureEvent } = useAnalytics();

    const toggleModal = (button?: EscalationButton) => {
        if (modalVisible) {
            setActiveButton(null);
        } else {
            setActiveButton(button || null);
        }
        setModalVisible(!modalVisible);
    };

    const addButton = (caption: string, instructions: string) => {
        if (caption === "" || instructions === "") {
            toast.error('Both caption and instructions are required', toastConfig);
            return false;
        }

        if (escalationButtons.length >= 2) {
            toast.error('You can only have 2 escalation buttons', toastConfig);
            return false;
        }

        const button = {
            id: uuidv4(),
            caption,
            instructions
        };
        setEscalationButtons([...escalationButtons, button]);
        setNeedsUpdate(true);
        captureEvent('escalation_button_added', {
            agent_id: agId,
            agent_name: agentName,
        });
        return true;
    };

    const editButton = (id: string, caption: string, instructions: string) => {
        const updatedButtons = escalationButtons.map(button => 
            button.id === id ? { ...button, caption, instructions } : button
        );
        setEscalationButtons(updatedButtons);
        setNeedsUpdate(true);
    };

    const deleteButton = (id: string) => {
        setEscalationButtons(escalationButtons.filter(button => button.id !== id));
        setNeedsUpdate(true);
        captureEvent('escalation_button_deleted', {
            agent_id: agId,
            agent_name: agentName,
        });
    };

    return (
        <div className='mb-6'>
            <Field>
                <Switch
                    checked={escalationEnabled}
                    onCheckedChange={setEscalationEnabled}
                />
            </Field>

            {escalationEnabled && (
                <>
                    {escalationButtons.length > 0 && (
                        <div className="flex flex-col mt-4">
                            <div className="overflow-x-auto">
                                <div className="flex flex-col gap-4">
                                    {escalationButtons.map((button) => (
                                        <div key={button.id} className="border shadow-sm rounded-lg p-4 flex flex-col leading-normal">
                                            <div className="flex flex-row justify-between">
                                                <div className="flex flex-col">
                                                    <p onClick={() => toggleModal(button)} className="font-medium hover:cursor-pointer">
                                                        {button.caption}
                                                    </p>
                                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                                        {button.instructions}
                                                    </p>
                                                </div>
                                                <div onClick={() => deleteButton(button.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 shrink-0 stroke-slate-400 hover:stroke-red-400 hover:cursor-pointer dark:stroke-slate-300">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="mt-4">
                        <Button
                            variant="secondary"
                            onClick={() => toggleModal()}
                        >
                            Add escalation button
                        </Button>
                    </div>
                </>
            )}

            {modalVisible && (
                <CreateEscalationButton
                    addButton={addButton}
                    editButton={editButton}
                    setModalVisible={setModalVisible}
                    button={activeButton}
                />
            )}
        </div>
    );
};

export default EscalationButtons; 