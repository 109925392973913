import { useState, useRef } from 'react';
import { useOutsideAlerter } from '../../useOutsideAlerter';
import CreateStarter from './CreateStarter';
import { toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import useAnalytics from '../../analytics/analytics';
import { v4 as uuidv4 } from 'uuid';
import { Field, Label, Description } from '@headlessui/react'
import { useUser } from '../../user/UserContext';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';

const Starters = ({
    starters,
    setStarters,
    setNeedsUpdate,
    agId,
    agentName,
    enableFollowUps,
    setEnableFollowUps,
    followUpInstructions,
    setFollowUpInstructions,
    contextualStartersEnabled,
    setContextualStartersEnabled
}: {
    starters: any,
    setStarters: any,
    setNeedsUpdate: any,
    agId: string | undefined,
    agentName: string | undefined,
    enableFollowUps: boolean,
    setEnableFollowUps: any,
    followUpInstructions: string,
    setFollowUpInstructions: any,
    contextualStartersEnabled: boolean,
    setContextualStartersEnabled: any
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [activestarter, setActivestarter] = useState<any>(null);
    const { captureEvent } = useAnalytics();
    const { checkLicenseType } = useUser();

    const toggleModal = (starter?: string) => {
        if (modalVisible) {
            setActivestarter(null);
        } else {
            setActivestarter(starter);
        }
        setModalVisible(!modalVisible);
    };

    const addStarter = (starterValue: string) => {
        if (starterValue === "") {
            toast.error('starter name is required', toastConfig);
            return false;
        }

        if (starters.length >= 3) {
            toast.error('You can only have 3 starters', toastConfig);
            return false;
        }
        const starter = {
            id: uuidv4(),
            value: starterValue,
        };
        setStarters((prevState: any) => [...prevState, starter]);
        setNeedsUpdate(true);
        captureEvent('agent_starter_added', {
            agent_id: agId,
            agent_name: agentName,
        });
        return true;
    };

    const editStarter = (id: string, starterValue: string) => {
        let updatedStarters = starters.filter((starter: any) => starter.id !== id);

        updatedStarters = [...updatedStarters, { id: id, value: starterValue }];

        setStarters(updatedStarters);
        setNeedsUpdate(true);
    };

    const deletestarter = (id: string) => {
        setStarters(starters.filter((starter: any) => starter.id !== id));
        setNeedsUpdate(true);
        captureEvent('agent_starter_deleted', {
            agent_id: agId,
            agent_name: agentName,
        });
    };

    const modalRef = useRef(null);

    useOutsideAlerter(modalRef, () => toggleModal());

    return (
        <div className='mb-6'>
            {starters && starters.length > 0 && (
                <div className="flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="flex flex-col gap-4 hover:cursor-pointer">
                            {starters.length > 0 &&
                                starters.map((starter: any) => (
                                    <div className="border shadow-sm rounded-lg p-4 flex flex-col leading-normal">
                                        <div className="flex flex-row justify-between">
                                            <p onClick={() => toggleModal(starter)} className="font-medium hover:cursor-pointer">{starter.value}</p>
                                            <div onClick={() => deletestarter(starter.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 shrink-0 stroke-slate-400 hover:stroke-red-400 hover:cursor-pointer dark:stroke-slate-300">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
            <div className="mt-4 flex flex-row items-center">
                <Button
                    variant="secondary"
                    onClick={() => toggleModal()}
                >
                    Add starter
                </Button>
            </div>
            <Field className='mt-4'>
                <Label className="text-md font-medium">{`Contextual Starters`}</Label>
                <Description className="text-sm/6 mb-2">Show AI generated starters based on the current page the visitor is on. You can modify these in your website page settings.</Description>
                <Switch
                    checked={contextualStartersEnabled}
                    onCheckedChange={setContextualStartersEnabled}
                />
            </Field>
            <Field className='mt-4'>
                <Label className="text-md font-medium">{`AI Follow-ups ${checkLicenseType('pro') ? '' : ' (Upgrade to Pro)'}`}</Label>
                <Description className="text-sm/6 mb-2">Show follow-up questions based on the conversation.</Description>
                <Switch
                    checked={enableFollowUps}
                    onCheckedChange={setEnableFollowUps}
                    disabled={!checkLicenseType('pro')}
                />
                {enableFollowUps && (
                    <div className="mt-3">
                        <Label className="text-md font-medium">Instructions</Label>
                        <Description className="text-sm">Give instructions on how to generate follow-ups on-demand.</Description>
                        <div className="flex w-full mt-4 rounded-md shadow-sm">
                            <Textarea
                                name="welcome"
                                id="welcome"
                                autoComplete="off"
                                placeholder="Generate follow-ups based on the conversation to convert the visitor into a paying customer."
                                value={followUpInstructions || ''}
                                onChange={(e) => setFollowUpInstructions(e.target.value)}
                                maxLength={200}
                            />
                        </div>
                    </div>
                )}
            </Field>
            {modalVisible && (
                <CreateStarter
                    addStarter={addStarter}
                    editStarter={editStarter}
                    setModalVisible={setModalVisible}
                    starter={activestarter}
                />
            )}
        </div>
    );
};

export default Starters;