import { useState, useEffect } from 'react'
import useApi from '../api';
import { useParams, useNavigate } from 'react-router-dom';
import Sessions from '../sessions/Sessions';
import { formatDate } from '../utils/dateFormatter';
import { QuestionMarkCircleIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Table, TableHead, TableRow, TableBody, TableHeader, TableCell } from '@/components/ui/table';
import SalesforceIcon from '../icons/salesforce_logo.png'
import HubspotIcon from '../icons/hubspot_logo.webp'
import RowDelete from '../ui/RowDelete';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

interface Lead {
    id: string;
    first_name: string;
    last_name: string;
    company: string;
    email: string;
    job_title: string;
    attributes: any;
    created_at: string;
    email_verified: boolean;
    integrations: {
        salesforce: string;
        salesforce_error?: string;
        hubspot: string;
        hubspot_error?: string;
    };
}

function formatAttributeName(key: string): string {   
    let formatted = key.replace(/[_-]/g, ' ');
    
    formatted = formatted.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    
    return formatted;
}

export default function Lead() {
    const { id } = useParams();
    const [, setIsOpen] = useState(false)
    const { get, remove } = useApi();
    const [lead, setLead] = useState<Lead | undefined>();
    const [conversations, setConversations] = useState<any[]>([]);
    const [, setInteracted] = useState<boolean>(false);
    const [message] = useState<any>(null);
    const navigate = useNavigate();

    function openModal() {
        setIsOpen(true)
    }

    useEffect(() => {
        if (id) {
            get(`/leads/${id}`).then((response) => {
                setLead(response.data);
            });
            get(`/leads/${id}/conversations`).then((response) => {
                setConversations(response.data);
            });
        }
    }, [id]);

    const hasInteracted = () => {
        for (let conversation of conversations) {
            for (let message of conversation.messages) {
                if (message.role === "user" && (message.content !== "" && message.content !== null)) {
                    setInteracted(true);
                }
            }
        }
    };

    function isLink(str: string): boolean {
        try {
            new URL(str);
            return true;
        } catch {
            return false;
        }
    }

    useEffect(() => {
        hasInteracted();
    }, [conversations]);

    useEffect(() => {
        if (message) {
            openModal();
        }
    }, [message]);

    const handleDelete = async () => {
        try {
            await remove(`/leads/${id}`);
            navigate('/leads');
            toast.success('Lead deleted successfully', toastConfig);
        } catch (error) {
            console.error('Error deleting Lead:', error);
            toast.error('Failed to delete lead', toastConfig);
        }
    };

    return (
        <>
            {lead && (
                <div className="flex w-full h-full overflow-y-auto scrollbar-thin justify-center text-center">
                    <div className="w-full transform h-full p-6 text-left align-middle">
                        <div className="col-span-2 text-xl font-medium leading-6 mb-6 px-4 py-2 text-center border rounded-md flex justify-between items-center">
                            <div className="w-8">
                                {/* Empty div for spacing */}
                            </div>
                            <div>Lead Details</div>
                            <div className="w-8">
                                <RowDelete deleteCallback={handleDelete} />
                            </div>
                        </div>
                        <div className="flex flex-row w-full items-start gap-12 justify-between mb-2">
                            <div className="flex items-start gap-4 p-6 border rounded-xl shadow-sm">
                                <div className="flex items-center justify-center w-14 h-14 rounded-full bg-gradient-to-br from-yellow-400 to-yellow-600 text-white font-semibold text-lg">
                                    {(lead.first_name?.[0] || '') + (lead.last_name?.[0] || '')}
                                </div>
                                <div className="ml-6 space-y-1.5">
                                    <div className="text-2xl font-semibold tracking-tight dark:text-white">
                                        {lead.first_name} {lead.last_name}
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <span className="text-sm text-slate-600 dark:text-slate-300">
                                            {lead.email}
                                        </span>
                                        {lead.email_verified === null ? (
                                            <div className="group relative">
                                                <QuestionMarkCircleIcon className="h-5 w-5 text-blue-500 stroke-2" />
                                                <span className="absolute z-40 bottom-full mb-2 hidden group-hover:block bg-slate-200 text-slate-800 dark:bg-slate-800 dark:text-white text-xs rounded py-1 px-2 whitespace-nowrap shadow-lg">
                                                    Email not verified
                                                </span>
                                            </div>
                                        ) : lead.email_verified ? (
                                            <div className="group relative">
                                                <CheckCircleIcon className="h-5 w-5 text-green-500 stroke-2" />
                                                <span className="absolute z-40 bottom-full mb-2 hidden group-hover:block bg-slate-200 text-slate-800 dark:bg-slate-800 dark:text-white text-xs rounded py-1 px-2 whitespace-nowrap shadow-lg">
                                                    Email verified
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="group relative">
                                                <XCircleIcon className="h-5 w-5 text-red-500 stroke-2" />
                                                <span className="absolute z-40 bottom-full mb-2 hidden group-hover:block bg-slate-200 text-slate-800 dark:bg-slate-800 dark:text-white text-xs rounded py-1 px-2 whitespace-nowrap shadow-lg">
                                                    Invalid email
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 border rounded-xl shadow-sm p-6">
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>Property</TableHead>
                                            <TableHead>Value</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="font-medium">Time Submitted At</TableCell>
                                            <TableCell>{formatDate(lead.created_at)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="font-medium">Company</TableCell>
                                            <TableCell>{lead.company || "N/A"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="font-medium">Title</TableCell>
                                            <TableCell>{lead.job_title || "N/A"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="font-medium">Page Submitted On</TableCell>
                                            <TableCell>
                                                {lead.attributes && lead.attributes.aimdoc_parent_url ? (
                                                    isLink(lead.attributes.aimdoc_parent_url) ? (
                                                        <a 
                                                            href={lead.attributes.aimdoc_parent_url} 
                                                            className="text-blue-500 dark:text-blue-400 underline break-words text-sm font-medium" 
                                                            target="_blank" 
                                                            rel="noopener noreferrer"
                                                        >
                                                            {lead.attributes.aimdoc_parent_url}
                                                        </a>
                                                    ) : (
                                                        <span className="text-sm dark:text-slate-200">
                                                            {lead.attributes.aimdoc_parent_url}
                                                        </span>
                                                    )
                                                ) : (
                                                    "N/A"
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        {lead.attributes && Object.keys(lead.attributes).map((key) => {
                                            const disallowedFields = [
                                                'loc',
                                                'org',
                                                'ip_address', 
                                                'email',
                                                'first_name',
                                                'last_name',
                                                'company',
                                                'job_title',
                                                'aimdoc_parent_url'
                                            ];
                                            
                                            if (disallowedFields.includes(key)) {
                                                return null;
                                            }

                                            return (
                                                <TableRow key={key}>
                                                    <TableCell className="font-medium">
                                                        {formatAttributeName(key)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {isLink(lead.attributes[key]) ? (
                                                            <a 
                                                                href={lead.attributes[key]} 
                                                                className="text-blue-500 dark:text-blue-400 underline break-words text-sm font-medium"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {lead.attributes[key]}
                                                            </a>
                                                        ) : (
                                                            <span className="text-sm dark:text-slate-200">
                                                                {lead.attributes[key]}
                                                            </span>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                            {lead.integrations && (
                                <div className="flex items-center gap-2 ml-2">
                                    {(lead.integrations.salesforce || lead.integrations.salesforce_error) && (
                                        <div className="group relative h-full">
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <a
                                                            href={lead.integrations.salesforce}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className={cn(
                                                                "p-1 rounded-md items-center cursor-pointer flex h-full w-full border",
                                                                lead.integrations.salesforce_error && "border-red-500 dark:border-red-500"
                                                            )}
                                                        >
                                                            <img
                                                                src={SalesforceIcon}
                                                                alt="Salesforce"
                                                                className="w-16"
                                                            />
                                                        </a>
                                                    </TooltipTrigger>
                                                    {lead.integrations.salesforce_error ? (
                                                        <TooltipContent>
                                                            <p>Error: {lead.integrations.salesforce_error}</p>
                                                        </TooltipContent>
                                                    ) : (
                                                        <TooltipContent>
                                                            <p>View in Salesforce</p>
                                                        </TooltipContent>
                                                    )}
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                    )}
                                    {(lead.integrations.hubspot || lead.integrations.hubspot_error) && (
                                        <div className="group relative h-full">
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <a
                                                            href={lead.integrations.hubspot}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className={cn(
                                                                "p-1 rounded-md items-center cursor-pointer flex h-full w-full border",
                                                                lead.integrations.hubspot_error && "border-red-500 dark:border-red-500"
                                                            )}
                                                        >
                                                            <img
                                                                src={HubspotIcon}
                                                                alt="HubSpot"
                                                                className="w-10"
                                                            />
                                                        </a>
                                                    </TooltipTrigger>
                                                    {lead.integrations.hubspot_error ? (
                                                        <TooltipContent>
                                                            <p>Error: {lead.integrations.hubspot_error}</p>
                                                        </TooltipContent>
                                                    ) : (
                                                        <TooltipContent>
                                                            <p>View in HubSpot</p>
                                                        </TooltipContent>
                                                    )}
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        
                        <div className="flex w-full mt-10 pb-10">
                            <div className="flex flex-col w-full space-y-6 items-center justify-center">
                                <div
                                    className="text-xl w-full font-medium leading-6 px-4 py-2 text-center border rounded-md"
                                >
                                    Session History
                                </div>
                                <Sessions leadId={id} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
