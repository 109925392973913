import * as React from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { SalesforceFieldSelect, SalesforceField } from "./SalesforceFieldSelect";
import { X } from "lucide-react";

interface SalesforceDefaultFieldProps {
    salesforceField: string;
    value: string;
    onUpdate: (salesforceField: string, value: string) => void;
    onRemove: () => void;
    availableFields: SalesforceField[];
}

export function SalesforceDefaultField({
    salesforceField,
    value,
    onUpdate,
    onRemove,
    availableFields
}: SalesforceDefaultFieldProps) {
    const selectedField = availableFields.find(f => f.name === salesforceField);

    return (
        <div className="space-y-2">
            <div className="flex items-center gap-2">
                <div className="flex-1">
                    <SalesforceFieldSelect
                        fields={availableFields}
                        value={salesforceField}
                        onSelect={(field) => onUpdate(field, value)}
                        placeholder="Select Salesforce field"
                        simpleDisplay
                    />
                </div>
                <Input
                    value={value}
                    onChange={(e) => onUpdate(salesforceField, e.target.value)}
                    placeholder="Default value"
                    className="flex-1"
                />
                <Button
                    variant="ghost"
                    size="icon"
                    onClick={onRemove}
                    className="h-8 w-8 text-destructive hover:text-destructive/90"
                >
                    <X className="h-4 w-4" />
                </Button>
            </div>
        </div>
    );
} 