import { useState, useEffect } from 'react';
import useApi from '../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { conLog } from '../utils/conLog';
// import { Link } from 'react-router-dom';
import { Skeleton } from '@/components/ui/skeleton';
import { Card } from '@/components/ui/card';
const UsageAnalytics = ({ organization } : { organization: any }) => {
    const { get } = useApi();
    const [usage, setUsage] = useState<any>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchAnalyticsData();
    }, []);

    const fetchAnalyticsData = async () => {
        setLoading(true);
        try {
            const response = await get('/usage/');
            conLog(response.data);
            setUsage(response.data);
        } catch (error) {
            toast.error('Error fetching usage data');
        } finally {
            setLoading(false);
        }
    };

    const getTextColorClass = (percentage: number) => {
        if (percentage < 50) return 'text-green-600';
        if (percentage >= 50 && percentage <= 75) return 'text-yellow-500';
        return 'text-red-600';
    };

    const getBgColorClass = (percentage: number) => {
        if (percentage < 50) return 'bg-green-600';
        if (percentage >= 50 && percentage <= 75) return 'bg-yellow-500';
        return 'bg-red-600';
      };

    const getLicenseLimits = (type: string) => {
        switch (type) {
            case 'trial':
                return { charLimit: 500000, messageLimit: 500, agentLimits: 1 };
            case 'starter':
                return { charLimit: 5000000, messageLimit: 1000, agentLimits: 1 };
            case 'standard':
                return { charLimit: 10000000, messageLimit: 3000, agentLimits: 2 };
            case 'pro':
                return { charLimit: 20000000, messageLimit: 6500, agentLimits: 5 };
            case 'business':
                return { charLimit: 50000000, messageLimit: 30000, agentLimits: 20 };
            default:
                return { charLimit: 50000000000, messageLimit: 10000000, agentLimits: 1000 };
        }
    };

    const LoadingSkeleton = () => (
        <Skeleton className="mt-8 h-8 w-full animate-pulse" />
    );

    if (loading || !usage || !organization) {
        return <LoadingSkeleton />;
    }
    
    const { charLimit, messageLimit } = getLicenseLimits(organization.license_type);

    const charUsagePercentage = Math.round((Number(usage.total_char_count) / charLimit) * 100);
    const messageUsagePercentage = Math.round((Number(usage.total_messages) / messageLimit) * 100);

    return (
        <div className="flex flex-col w-full">
            {/* <div className="flex justify-between text-left text-xl font-medium items-center">
                <h2 className="text-2xl font-bold tracking-tight">
                    Platform Usage
                </h2>
                <Link 
                    to="/settings/billing"
                    className="font-semibold text-sm justify-center text-blue-600 hover:text-blue-700 hover:underline dark:text-blue-400 dark:hover:text-blue-300"
                >
                    Upgrade Plan →
                </Link>
            </div> */}
            <div className="flex flex-row h-full space-x-4">
                <Card className="flex flex-col w-1/2 border rounded-lg p-4 transition duration-200">
                    <div className="text-xl text-start font-medium">
                        Agent Messages
                    </div>
                    <div className="flex flex-row justify-between">
                        <div className="text-sm text-start mt-2">
                            <span className={`${getTextColorClass(messageUsagePercentage)}`}>
                                {usage.total_messages}
                        </span>
                        <span className="text-muted-foreground">
                                {` / ${messageLimit}`} messages
                            </span>
                        </div>
                        <div className="text-sm text-end mt-2">
                            <span className="text-muted-foreground">
                                {`${Math.min(messageUsagePercentage, 100)}%`}
                            </span>
                        </div>
                    </div>
                    <div className="flex w-full bg-muted rounded-full h-2.5 items-center mt-2">
                        <div 
                            className={`h-2 rounded-full ${getBgColorClass(messageUsagePercentage)}`}
                            style={{ width: `${Math.min(messageUsagePercentage, 100)}%` }}
                        ></div>
                    </div>
                </Card>
                <Card className="flex flex-col w-1/2 border rounded-lg p-4">
                    <div className="text-xl text-start font-medium">
                        Storage Usage
                    </div>
                    <div className="flex flex-row justify-between">
                        <div className="text-sm text-start mt-2">
                            <span className={`${getTextColorClass(charUsagePercentage)}`}>
                                {(usage.total_char_count / 1000000).toFixed(2)}M
                        </span>
                        <span className="text-muted-foreground">
                            {` / ${(charLimit / 1000000).toFixed(1)}M`} knowledge base tokens
                        </span>
                        </div>
                        <div className="text-sm text-end mt-2">
                            <span className="text-muted-foreground">
                                {`${Math.min(charUsagePercentage, 100)}%`}
                            </span>
                        </div>
                    </div>
                    <div className="flex w-full bg-muted rounded-full h-2.5 items-center mt-2">
                        <div 
                            className={`h-2 rounded-full ${getBgColorClass(charUsagePercentage)}`}
                            style={{ width: `${Math.min(charUsagePercentage, 100)}%` }}
                        ></div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default UsageAnalytics;
