import { useState, useRef } from 'react';
import { useOutsideAlerter } from '../../useOutsideAlerter';
import CreateLink from './CreateLink';
import { toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import useAnalytics from '../../analytics/analytics';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';

const Links = ({ links, setLinks, setNeedsUpdate, agId, agentName }: { links: any, setLinks: any, setNeedsUpdate: any, agId: string | undefined, agentName: string | undefined }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState<any>(null);
  const { captureEvent } = useAnalytics();

  const toggleModal = (link?: any) => {
    if (isOpen) {
      setActiveLink(null);
    } else {
      setActiveLink(link);
    }
    setIsOpen(!isOpen);
  };

  const formatUrl = (url: string) => {
    // Remove leading/trailing whitespace
    url = url.trim();
    
    // If URL doesn't start with http:// or https://, prepend https://
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url;
    }
    
    try {
      const urlObj = new URL(url);
      // Check if URL has a valid hostname (at least one dot and valid TLD-like pattern)
      if (!urlObj.hostname.includes('.') || urlObj.hostname.endsWith('.')) {
        return null;
      }
      // Check if hostname only contains valid characters
      if (!/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(urlObj.hostname)) {
        return null;
      }
      return url;
    } catch {
      return null;
    }
  };

  const addLink = (linkName: string, linkDescription: string, linkUrl: string) => {
    if (linkDescription.length > 50) {
      toast.error('Link description must be less than 50 characters', toastConfig);
      return false;
    }
    if (linkName === "") {
      toast.error('Link name is required', toastConfig);
      return false;
    }

    if (linkUrl === "") {
      toast.error('Link URL is required', toastConfig);
      return false;
    }

    const formattedUrl = formatUrl(linkUrl);
    if (!formattedUrl) {
      toast.error('Please enter a valid URL', toastConfig);
      return false;
    }

    if (links.length >= 4) {
      toast.error('You can only have 4 links', toastConfig);
      return false;
    }
    const link = {
      id: uuidv4(),
      name: linkName,
      description: linkDescription,
      url: formattedUrl
    };
    setLinks((prevState: any) => [...prevState, link]);
    setNeedsUpdate(true);
    captureEvent('agent_link_added', {
      agent_id: agId,
      agent_name: agentName,
    });
    return true;
  };

  const editLink = (id: string, linkName: string, linkDescription: string, linkUrl: string) => {
    const formattedUrl = formatUrl(linkUrl);
    if (!formattedUrl) {
      toast.error('Please enter a valid URL', toastConfig);
      return false;
    }

    let updatedLinks = links.filter((link: any) => link.id !== id);
    updatedLinks = [...updatedLinks, { 
      id: id, 
      name: linkName, 
      description: linkDescription, 
      url: formattedUrl 
    }];
    setLinks(updatedLinks);
    setNeedsUpdate(true);
    return true;
  };

  const deleteLink = (id: string) => {
    setLinks(links.filter((link: any) => link.id !== id));
    setNeedsUpdate(true);
    captureEvent('agent_link_deleted', {
      agent_id: agId,
      agent_name: agentName,
    });
  };

  const modalRef = useRef(null);

  useOutsideAlerter(modalRef, () => toggleModal());

  return (
    <div className='mb-6'>
      <div className="flex flex-row items-center">
        <Button
          variant="secondary"
          onClick={() => toggleModal()}
        >
          Add Link
        </Button>
      </div>
      {links && links.length > 0 && (
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="grid grid-cols-3 gap-4 mt-4 w-3/4">
              {links.map((link: any) => (
                <div key={link.id} className="border rounded-lg p-4 flex flex-col leading-normal">
                  <div className="flex flex-row justify-between">
                    <h2 onClick={() => toggleModal(link)} className="font-medium text-lg hover:cursor-pointer">
                      {link.name}
                    </h2>
                    <div onClick={() => deleteLink(link.id)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 shrink-0 hover:cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <p className="text-xs font-normal">{link.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <Dialog open={isOpen} onOpenChange={(open) => !open && toggleModal()}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{activeLink ? 'Edit Link' : 'Add Link'}</DialogTitle>
          </DialogHeader>
          <CreateLink
            addLink={addLink}
            editLink={editLink}
            setModalVisible={setIsOpen}
            link={activeLink}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Links;