import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from '@/components/ui/dialog'

interface StarterProps {
    addStarter: any;
    editStarter: any;
    setModalVisible: any;
    starter: any;
}

export default function CreateStarter({ addStarter, editStarter, setModalVisible, starter }: StarterProps) {
    const [isOpen, setIsOpen] = useState(true)
    const [starterValue, setStarterValue] = useState(starter ? starter.value : '');

    function closeModal() {
        setModalVisible(false)
        setIsOpen(false)
    }

    function addNewStarter() {
        const added = addStarter(starterValue);
        if (added) {
            closeModal();
        }
    }

    function updateStarter() {
        editStarter(starter.id, starterValue);
        closeModal();
    }

    return (
        <Dialog open={isOpen} onOpenChange={(open) => !open && closeModal()}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        Add Conversation Starter
                    </DialogTitle>
                </DialogHeader>

                <div className="space-y-4">
                    <div className="space-y-2">
                        <Label>Conversation Starter</Label>
                        <Textarea
                            value={starterValue}
                            onChange={(e) => setStarterValue(e.target.value)}
                            placeholder="Enter conversation starter"
                            autoFocus
                        />
                    </div>
                </div>

                <DialogFooter>
                    <Button
                        onClick={starter ? updateStarter : addNewStarter}
                    >
                        {starter ? 'Save starter' : 'Create starter'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
