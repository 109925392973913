import { createClient } from '@supabase/supabase-js'
import config from '../config.json'

// Use import.meta.env.MODE to select the correct variables
const supabaseUrl = config.env === 'development'
  ? import.meta.env.VITE_SUPABASE_URL_DEV  // Ensure this exists in your .env if needed
  : import.meta.env.VITE_SUPABASE_URL_PROD

const supabaseAnonKey = config.env === 'development'
  ? import.meta.env.VITE_SUPABASE_ANON_KEY_DEV  // Ensure this exists in your .env if needed
  : import.meta.env.VITE_SUPABASE_ANON_KEY_PROD

if (!supabaseUrl) {
  throw new Error('Supabase URL is missing')
}

if (!supabaseAnonKey) {
  throw new Error('Supabase Anon Key is missing')
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey)
