import { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import Websites from './websites/Websites';
import Documents from './documentation/Documents';
import Questions from './questions/Questions';
import { AimdocComponentContext } from '@aimdoc/sdk-react';

const Knowledge = () => {
  const [activeTab, setActiveTab] = useState('websites');

  return (
    <div className="h-full w-full">
      <AimdocComponentContext
        componentDescription="You are in the knowledge section. Here you can manage websites, documents, and questions that make up your agent's knowledge base."
        componentName="Knowledge"
      />

      <Tabs
        defaultValue="websites"
        value={activeTab}
        onValueChange={setActiveTab}
        className="h-full w-full"
      >

        <div className="border-b px-12 mt-8">
          <h1 className="mt-2 text-4xl font-bold">Knowledge Base</h1>
          <TabsList className="p-4 w-full">
            <TabsTrigger value="websites" className="w-1/3">Websites</TabsTrigger>
            <TabsTrigger value="documents" className="w-1/3">Documents</TabsTrigger>
            <TabsTrigger value="questions" className="w-1/3">Questions</TabsTrigger>
            </TabsList>
        </div>

        <TabsContent value="websites" className="h-full">
          <Websites />
        </TabsContent>

        <TabsContent value="documents" className="h-full">
          <Documents />
        </TabsContent>

        <TabsContent value="questions" className="h-full">
          <Questions />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Knowledge;
