import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";

interface TaskDefinitionStepModalProps {
    isOpen: boolean;
    closeModal: () => void;
    onSave: (step: string) => void;
    initialStep?: string;
    isEditing: boolean;
}

export default function TaskDefinitionStepModal({
    isOpen,
    closeModal,
    onSave,
    initialStep = '',
    isEditing
}: TaskDefinitionStepModalProps) {
    const [step, setStep] = useState(initialStep);

    useEffect(() => {
        setStep(initialStep);
    }, [initialStep, isOpen]);

    const handleSave = () => {
        if (step.trim()) {
            onSave(step.trim());
            closeModal();
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={closeModal}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {isEditing ? 'Edit Task Step' : 'Add Task Step'}
                    </DialogTitle>
                </DialogHeader>

                <div className="mt-4">
                    <Textarea
                        value={step}
                        onChange={(e) => setStep(e.target.value)}
                        placeholder="Enter the step instructions..."
                        rows={4}
                    />
                </div>

                <DialogFooter>
                    <Button
                        variant="outline"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        disabled={!step.trim()}
                    >
                        {isEditing ? 'Save Changes' : 'Add Step'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
} 