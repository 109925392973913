import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import { Input } from '@/components/ui/input'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from '@/components/ui/dialog'

interface EscalationButtonProps {
    addButton: (caption: string, instructions: string) => boolean;
    editButton: (id: string, caption: string, instructions: string) => void;
    setModalVisible: (visible: boolean) => void;
    button: {
        id: string;
        caption: string;
        instructions: string;
    } | null;
}

export default function CreateEscalationButton({ 
    addButton, 
    editButton, 
    setModalVisible, 
    button 
}: EscalationButtonProps) {
    const [isOpen, setIsOpen] = useState(true)
    const [caption, setCaption] = useState(button ? button.caption : '');
    const [instructions, setInstructions] = useState(button ? button.instructions : '');

    function closeModal() {
        setModalVisible(false)
        setIsOpen(false)
    }

    function addNewButton() {
        const added = addButton(caption, instructions);
        if (added) {
            closeModal();
        }
    }

    function updateButton() {
        if (button) {
            editButton(button.id, caption, instructions);
            closeModal();
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={(open) => !open && closeModal()}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {button ? 'Edit Escalation Button' : 'Add Escalation Button'}
                    </DialogTitle>
                </DialogHeader>

                <div className="space-y-4">
                    <div className="space-y-2">
                        <Label>Button Caption</Label>
                        <Input
                            value={caption}
                            onChange={(e) => setCaption(e.target.value)}
                            placeholder="e.g., Talk to Sales"
                            maxLength={30}
                            autoFocus
                        />
                        <p className="text-sm text-gray-500">
                            {caption.length}/30 characters
                        </p>
                    </div>

                    <div className="space-y-2">
                        <Label>AI Response Instructions</Label>
                        <Textarea
                            value={instructions}
                            onChange={(e) => setInstructions(e.target.value)}
                            placeholder="Instructions for how the AI should respond when this button is clicked"
                        />
                    </div>
                </div>

                <DialogFooter>
                    <Button
                        onClick={button ? updateButton : addNewButton}
                    >
                        {button ? 'Save button' : 'Create button'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
} 