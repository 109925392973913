import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DocumentFileInterface } from '../types';
import useApi from '../api';
import { useOutsideAlerter } from '../useOutsideAlerter';
import DocUpload from './DocUpload';
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import IntegrationList from '../integrations/IntegrationList';
import RowDelete from '../ui/RowDelete';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/components/ui/table';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { Separator } from '@/components/ui/separator';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Upload } from 'lucide-react';

const Documents: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState<DocumentFileInterface[]>([]);
  const [showUpload, setShowUpload] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();
  const { get, remove } = useApi();

  const search = new URLSearchParams(window.location.search);
  const createType = search.get('createType');
  const newDocument = search.get('new');

  const toggleUpload = () => {
    setShowUpload(!showUpload);
    navigate('/documents');
  };

  const modalRef = useRef(null);

  useOutsideAlerter(modalRef, toggleUpload);

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    try {
      setLoading(true);

      const data = await get('/documentation/');

      setDocuments(data.data);
    } catch (error) {
      console.error('Error fetching products:', error);

    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    const document = documents.find((doc) => doc.id === id);
    if (!document) {
      return;
    }

    try {
      if (document.type === 'document') {
        const response = await remove(`/documentation/${id}`);
        setDocuments(documents.filter((document: DocumentFileInterface) => document.id !== id));
        toast.success('Documentation deleted successfully', toastConfig);
        return response.data;
      } else if (document.type === 'file') {
        const response = await remove(`/files/${id}`);
        setDocuments(documents.filter((document: DocumentFileInterface) => document.id !== id));
        toast.success('File deleted successfully', toastConfig);
        return response.data;
      }
    } catch (error) {
      console.error('Error deleting documentation:', error);
    }
    setLoading(false);
  };

  const handleSearch = async (search: string) => {
    setSearchInput(search);
    try {
      setLoading(true);
      const data = await get(`/documentation/?search=${search}`);
      setDocuments(data.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (newDocument) {
      setShowUpload(true);
    }
  }, [newDocument]);

  // Define columns for TanStack Table
  const columns = React.useMemo(() => [
    {
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row }: any) => {
        const document = row.original;
        return document.type === 'file' ? (
          <div className="text-md font-semibold">
            {document.name}
          </div>
        ) : (
          <Link
            to={`/documents/${document.id}`}
            className="text-md font-semibold hover:underline"
          >
            {document.name || "DRAFT"}
          </Link>
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Description',
      cell: ({ getValue }: any) => (
        <span className="text-md">
          {getValue() || ""}
        </span>
      ),
    },
    {
      accessorKey: 'type',
      header: 'Type',
      cell: ({ getValue }: any) => (
        <span className="text-md">
          {getValue()}
        </span>
      ),
    },
    {
      id: 'actions',
      header: 'Action',
      cell: ({ row }: any) => (
        <RowDelete
          deleteCallback={() => handleDelete(row.original.id)}
          deleteMessage={`Are you sure you want to delete ${row.original.name}? This will remove the document from your live agent's knowledge base. This cannot be undone.`}
        />
      ),
    },
  ], [handleDelete]);

  // Create table instance
  const table = useReactTable({
    data: documents,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const DocumentsLoadingSkeleton = () => (
    <TableBody>
      {[1, 2].map((i) => (
        <TableRow key={i} className="animate-pulse">
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-3/4" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-full" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-1/3" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-1/4 ml-auto" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <>
      <ToastContainer />
      {!createType && (
        <div className="p-12 overflow-y-auto max-h-[calc(100vh-1px)] scrollbar-thin grow">
          <h1 className='text-4xl font-bold flex items-center gap-2'>Knowledge Files</h1>
          <p className='mt-4 text-sm text-slate-500'>Manage documents here to your agent's knowledge base.</p>
          <Separator className="my-4" />
          <div className="flex justify-between w-full gap-2">
            <Input
              type="text"
              id="search"
              className='w-1/3'
              placeholder="Search for documentation.."
              autoComplete='off'
              value={searchInput}
              onChange={(e: any) => setSearchInput(e.target.value)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleSearch(e.currentTarget.value);
                }
                }}
            />
            <Button onClick={() => setShowUpload(true)}>
              Upload New Document <Upload/>
            </Button>
          </div>
          <div className="flex flex-col mt-4">
            <div className="-m-1.5 overflow-x-auto pb-12">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="border rounded-lg shadow overflow-hidden dark:border-slate-800">
                  <Table>
                    <TableHeader>
                      {table.getHeaderGroups().map(headerGroup => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map(header => (
                            <TableHead key={header.id}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                            </TableHead>
                          ))}
                        </TableRow>
                      ))}
                    </TableHeader>
                    {loading ? (
                      <DocumentsLoadingSkeleton />
                    ) : (
                      <TableBody>
                        {table.getRowModel().rows.length ? (
                          table.getRowModel().rows.map(row => (
                            <TableRow key={row.id}>
                              {row.getVisibleCells().map(cell => (
                                <TableCell key={cell.id}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={columns.length} className="h-24 text-center">
                              No documents
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>

      )}
      <DocUpload 
        open={showUpload} 
        onClose={() => setShowUpload(false)} 
      />
    </>
  );
};

export default Documents;
