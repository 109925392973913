import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import useApi from '../api';
import toastConfig from '../config/Toast';
import { useParams, Link } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";

export default function QuestionView({ questionId }: { questionId?: string }) {
    console.log('QuestionView component rendered with questionId:', questionId);
    const [question, setQuestion] = useState<string>('');
    const [answer, setAnswer] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [conversation, setConversation] = useState<any>();
    const [messages, setMessages] = useState<any[]>([]);
    const params = useParams();
    const id = params.id || questionId;
    const { get, put } = useApi();

    useEffect(() => {
        console.log('QuestionView useEffect - id:', id);
        if (id) {
            fetchQuestion();
        }
    }, [id]);

    const fetchQuestion = async () => {
        if (!id) return;
        
        try {
            setLoading(true);
            console.log('Fetching question with ID:', id);
            const response = await get(`/questions/${id}`);
            setQuestion(response.data.question);
            setAnswer(response.data.answer);
            setConversation(response.data.first_conversation);
            setMessages(response.data.messages);
        } catch (error) {
            console.error('Error fetching question:', error);
            toast.error('Failed to fetch question.', toastConfig);
        } finally {
            setLoading(false);
        }
    };

    const updateQuestion = async () => {
        if (question === "") {
            toast.error('Question is required.', toastConfig);
            return;
        }
        if (answer === "") {
            toast.error('Answer is required.', toastConfig);
            return;
        }

        const data = {
            question: question,
            answer: answer,
        }

        try {
            setLoading(true);
            await put(`/questions/${id}`, data);
            toast.success('Question successfully updated.', toastConfig);
        } catch (error) {
            console.error('Error updating question:', error);
            toast.error('Failed to update question.', toastConfig);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mt-4 flex flex-col gap-8 p-8 w-full mx-auto">
            <div>
                <h2 className="text-2xl font-bold">Question</h2>
                <p className="text-muted-foreground mb-4">Update your question here.</p>
                <Textarea
                    id="question"
                    placeholder="Enter your question..."
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    />
            </div>

            <div>
                <h2 className="text-2xl font-bold">Answer</h2>
                <p className="text-muted-foreground mb-4">Update the answer to your question.</p>
                <Textarea
                    id="answer"
                    placeholder="Enter your answer..."
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                />
            </div>

            {conversation && (
                <div>
                    <h2 className="text-2xl font-bold mb-4">Related Conversation</h2>
                    <Link to={`/sessions/${conversation.id}`}>
                        <div className="border rounded-lg p-4 hover:bg-accent">
                            {messages
                                .filter(msg => msg.conversation_id === conversation.id)
                                .map((msg, index) => (
                                    <div key={index} className="mb-2 last:mb-0">
                                        {msg.content}
                                    </div>
                                ))
                            }
                        </div>
                    </Link>
                </div>
            )}
            <Button
                onClick={updateQuestion}
                className="w-full"
                disabled={loading}
            >
                {loading ? 'Updating...' : 'Update Question'}
            </Button>
        </div>
    );
}