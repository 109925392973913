import { memo } from 'react';

const ChiliPiperWidget = memo(({ 
    domain, 
    inbound_router, 
    session_id, 
    email,
    gettingFullResponse 
}: { 
    domain: string, 
    inbound_router: string, 
    session_id: string, 
    email: string,
    gettingFullResponse?: boolean 
}) => {
    return (
        <div className="p-4 m-4 rounded-lg border">
            <div className="space-y-3">
                <div className="flex items-center space-x-2">
                    <div className="h-5 w-5 text-green-500 dark:text-green-400">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <span className="font-medium">
                        Chili Piper calendar link was presented to visitor
                    </span>
                </div>
                
                <div className="pl-7 space-y-2">
                    <p className="text-sm text-slate-600 dark:text-slate-300">
                        Visitor email: <span className="font-medium">{email}</span>
                    </p>
                    <p className="text-sm text-slate-500 dark:text-slate-400">
                        Router: {inbound_router}
                    </p>
                </div>
            </div>
        </div>
    );
});

export default ChiliPiperWidget;