import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";

interface HubspotField {
    name: string;
    label: string;
    type: string;
    description: string;
    groupName: string;
    required: boolean;
}

interface HubspotFieldSelectProps {
    fields: HubspotField[];
    value?: string;
    onSelect: (value: string) => void;
    placeholder?: string;
    simpleDisplay?: boolean;
}

export function HubspotFieldSelect({ fields, value, onSelect, placeholder = "Select a field...", simpleDisplay = false }: HubspotFieldSelectProps) {
    const [open, setOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState("");

    const selectedField = fields.find(field => field.name === value);

    const filteredFields = fields.filter(field => 
        field.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        field.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        field.groupName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-full justify-between"
                >
                    {selectedField ? (
                        simpleDisplay ? (
                            <span>{selectedField.label}</span>
                        ) : (
                            <div className="flex flex-col items-start">
                                <span>{selectedField.label}</span>
                                <span className="text-xs text-muted-foreground">
                                    {selectedField.groupName} • {selectedField.name}
                                </span>
                            </div>
                        )
                    ) : (
                        <span className="text-muted-foreground">{placeholder}</span>
                    )}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent 
                className="p-0 w-[--radix-popover-trigger-width]" 
                align="center" 
                sideOffset={4}
                onWheel={(e) => e.stopPropagation()}
            >
                <div className="flex flex-col">
                    <div className="p-2">
                        <Input
                            placeholder="Search fields..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="h-8"
                        />
                    </div>
                    <ScrollArea className="h-[300px]">
                        <div className="flex flex-col gap-1 p-1">
                            {filteredFields.map((field) => (
                                <Button
                                    key={field.name}
                                    variant="ghost"
                                    role="option"
                                    onClick={() => {
                                        onSelect(field.name);
                                        setOpen(false);
                                        setSearchQuery("");
                                    }}
                                    className="justify-start gap-2"
                                >
                                    <div className="flex flex-col items-start">
                                        <span>{field.label}</span>
                                        <span className="text-xs text-muted-foreground">
                                            {field.groupName} • {field.name}
                                        </span>
                                    </div>
                                </Button>
                            ))}
                        </div>
                    </ScrollArea>
                </div>
            </PopoverContent>
        </Popover>
    );
} 