import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useApi from '../api';
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import RowDelete from '../ui/RowDelete';
import { FilterInterface } from '../types';
import PageModal from './Page';
import NewPage from './NewPage';
import useAnalytics from '../analytics/analytics';
import Spinner from '../documentation/Spinner';
import { formatDate } from '../utils/dateFormatter';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

interface PagesProps {
  websiteId?: string;
}

const initialFilters: FilterInterface[] = [
  {
    id: 'indexed',
    name: 'Indexed',
    options: [
      { value: 'true', label: 'Yes', checked: false },
      { value: 'false', label: 'No', checked: false }
    ],
  }
];

const Pages: React.FC<PagesProps> = ({ websiteId }) => {
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const filtersRef = useRef<FilterInterface[]>(initialFilters);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [restrictToCurrentPage, setRestrictToCurrentPage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { get, remove, post } = useApi();
  const { captureEvent } = useAnalytics();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentPage = parseInt(params.get('page') || '1');
    const currentSearch = params.get('search') || '';
    const currentFilters = filters.map(filter => ({
      ...filter,
      options: filter.options.map(option => ({
        ...option,
        checked: params.get(filter.id) === option.value
      }))
    }));
    setPage(currentPage);
    setSearchInput(currentSearch);
    setFilters(currentFilters);
    filtersRef.current = currentFilters;
    getPages(currentPage, currentSearch, currentFilters);
  }, [location.search]);

  const getPages = async (page: number = 1, search: string = '', filters: FilterInterface[] = []) => {
    try {
      setLoading(true);
      const params = new URLSearchParams({
        page: page.toString(),
        per_page: '25',
        search,
      });
      if (websiteId) {
        params.append('website_id', websiteId);
      }
      filters.forEach(filter => {
        filter.options.forEach(option => {
          if (option.checked) {
            params.append(filter.id, option.value);
          }
        });
      });
      const data = await get(`/pages/?${params.toString()}`);
      setPages(data.data.items);
      setPage(data.data.page);
      setTotalPages(data.data.pages);
    } catch (error) {
      console.error('Error fetching pages:', error);
    }
    setLoading(false);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      const params = new URLSearchParams(location.search);
      params.set('page', (page + 1).toString());
      filtersRef.current.forEach(filter => {
        const selectedOption = filter.options.find(option => option.checked);
        if (selectedOption) {
          params.set(filter.id, selectedOption.value);
        }
      });
      navigate(`?${params.toString()}`);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      const params = new URLSearchParams(location.search);
      params.set('page', (page - 1).toString());
      filtersRef.current.forEach(filter => {
        const selectedOption = filter.options.find(option => option.checked);
        if (selectedOption) {
          params.set(filter.id, selectedOption.value);
        }
      });
      navigate(`?${params.toString()}`);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      await remove(`/pages/${id}`);
      setPages(pages.filter((page: any) => page.id !== id));
      setSelectedPages(selectedPages.filter((selectedId) => selectedId !== id));
      toast.success('Page deleted successfully', toastConfig);
      captureEvent('page_deleted', {
        page_id: id,
      });
    } catch (error) {
      console.error('Error deleting page:', error);
    }
    setLoading(false);
  };

  const handleSearch = (search: string) => {
    const params = new URLSearchParams({ search, page: '1' });
    filters.forEach(filter => {
      const selectedOption = filter.options.find(option => option.checked);
      if (selectedOption) {
        params.set(filter.id, selectedOption.value);
      }
    });
    if (websiteId) {
      params.set('website_id', websiteId);
    }
    navigate(`?${params.toString()}`);
  };

  const handleFilterChange = (newFilters: FilterInterface[]) => {
    setFilters(newFilters);
    filtersRef.current = newFilters;
    const params = new URLSearchParams({ search: searchInput, page: '1' });
    newFilters.forEach(filter => {
      const selectedOption = filter.options.find(option => option.checked);
      if (selectedOption) {
        params.set(filter.id, selectedOption.value);
      }
    });
    if (websiteId) {
      params.set('website_id', websiteId);
    }
    navigate(`?${params.toString()}`);
  };

  const handleRowClick = (id: string) => {
    if (!id) return;
    setSelectedPageId(id);
  };

  const closeModal = () => {
    setSelectedPageId(null);
  };

  const handleReindex = async (page_url: string) => {
    try {
      setLoading(true);
      await post(`/index-page/${websiteId}`, { url: page_url });
      toast.success('Reindexing started!', toastConfig);
      captureEvent('reindexed_page', {
        page_url,
      });
    } catch (error: any) {
      toast.error(error.detail, toastConfig);
      console.error('Reindexing error:', error);
    }
    setLoading(false);

  };

  const selectAllCheckboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectAllCheckboxRef.current) {
      const allSelected = pages.every((page: any) => selectedPages.includes(page.id));
      const someSelected = pages.some((page: any) => selectedPages.includes(page.id));
      selectAllCheckboxRef.current.indeterminate = !allSelected && someSelected;
    }
  }, [pages, selectedPages]);

  const areAllCurrentPageSelected = pages.every((page: any) => selectedPages.includes(page.id));

  const handleSelectAllCurrentPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newSelected = [...selectedPages];
      pages.forEach((page: any) => {
        if (!newSelected.includes(page.id)) {
          newSelected.push(page.id);
        }
      });
      setSelectedPages(newSelected);
    } else {
      setSelectedPages(selectedPages.filter((id: string) => !pages.some((page: any) => page.id === id)));
    }
  };

  const handleSelectRow = (id: string) => {
    if (selectedPages.includes(id)) {
      setSelectedPages(selectedPages.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedPages([...selectedPages, id]);
    }
  };

  const handleBulkAction = async () => {
    let pagesToActOn = pages.filter((page: any) => selectedPages.includes(page.id));
    
    try {
      if (restrictToCurrentPage) {
        pagesToActOn = pagesToActOn.filter((page: any) => {
          return !(page.page_config && page.page_config.current_page_only);
        });
        if (pagesToActOn.length > 0) {
          await post(`/pages/bulk-action`, { page_ids: pagesToActOn.map((p: any) => p.id), action: 'restrict_pages' });
        }
      } else {
        pagesToActOn = pagesToActOn.filter((page: any) => {
          return page.page_config && page.page_config.current_page_only;
        });
        if (pagesToActOn.length > 0) {
          await post(`/pages/bulk-action`, { page_ids: pagesToActOn.map((p: any) => p.id), action: 'release_pages' });
        }
      }
    
      setSelectedPages([]);
      setRestrictToCurrentPage(false);
      toast.success('Pages updated successfully', toastConfig);
    } catch (error) {
      console.error('Error performing bulk action:', error);
      toast.error('Failed to update pages', toastConfig);
    }

  };

  return (
    <div className="flex flex-row w-full scrollbar-hidden">
      {selectedPageId && <PageModal isOpen={!!selectedPageId} closeModal={closeModal} pageId={selectedPageId} />}
      <div className={`w-full ${websiteId ? '' : 'mt-12'} grow`}>
        <div className="flex flex-row">
          {selectedPages.length > 0 && (
            <div className="flex flex-col justify-between w-64 h-64 p-4 mt-6 rounded-lg border">
              <div>
                <h2 className="text-lg font-semibold">Bulk Actions</h2>
                <div className="text-xs mb-4">
                  Selected {selectedPages.length} pages
                </div>
                <div className="mb-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={restrictToCurrentPage}
                      onChange={(e) => setRestrictToCurrentPage(e.target.checked)}
                      className="mr-2"
                    />
                    <span className="text-xs">Restrict search to current page</span>
                  </label>
                </div>
              </div>
              <Button onClick={handleBulkAction} variant="secondary">
                Save
              </Button>
            </div>
          )}
          <div className='flex flex-col w-full'>
            <div className='flex flex-row justify-between'>
            <div className='flex items-center w-full gap-4'>
              <Input
                className="grow max-w-full"
                type="text"
                id="search"
                placeholder="Search pages by URL or meta..."
                autoComplete='off'
                value={searchInput}
                onChange={(e: any) => setSearchInput(e.target.value)}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleSearch(e.currentTarget.value);
                  }
                }}
              />
              <div className='max-w-1/3 flex-row'>
                {websiteId && <NewPage websiteId={websiteId} />}
              </div>
            </div>
          </div>
            <div className="flex flex-col mt-4 min-h-full scrollbar-hidden mb-8">
              {loading && (
                <Spinner />
              )}
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-12">
                          <input
                            type="checkbox"
                            checked={areAllCurrentPageSelected}
                            onChange={handleSelectAllCurrentPage}
                            ref={selectAllCheckboxRef}
                          />
                        </TableHead>
                        <TableHead className="w-12">Reindex</TableHead>
                        <TableHead className="w-1/4">URL</TableHead>
                        <TableHead>Last Indexed</TableHead>
                        <TableHead>Index Status</TableHead>
                        <TableHead className="text-right">Action</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {pages.length > 0 &&
                        pages.map((page: any) => (
                          <TableRow key={page.id}>
                            <TableCell>
                              <input
                                type="checkbox"
                                checked={selectedPages.includes(page.id)}
                                onChange={() => handleSelectRow(page.id)}
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="secondary"
                                onClick={() => handleReindex(page.url)}
                              >
                                Reindex
                              </Button>
                            </TableCell>
                            <TableCell 
                              className="hover:cursor-pointer break-all"
                              onClick={() => handleRowClick(page.id)}
                            >
                              {page.url}
                            </TableCell>
                            <TableCell>
                              {page.indexed_at ? formatDate(page.indexed_at) : ''}
                            </TableCell>
                            <TableCell>
                              {page.index_status}
                            </TableCell>
                            <TableCell className="text-right">
                              <RowDelete deleteCallback={() => handleDelete(page.id)} />
                            </TableCell>
                          </TableRow>
                        ))}
                      {pages.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={6} className="text-center py-16">
                            No pages
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div className="flex justify-between items-center mt-4">
                <Button
                  variant="outline"
                  onClick={handlePreviousPage}
                  disabled={page === 1}
                >
                  Previous
                </Button>
                <span className="text-sm font-semibold">
                  Page {page} of {totalPages}
                </span>
                <Button
                  variant="outline"
                  onClick={handleNextPage}
                  disabled={page === totalPages}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pages;