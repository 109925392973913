import { AimdocComponentContext } from '@aimdoc/sdk-react';
import { Label } from '@/components/ui/label';
import Form from '../builder/Form';
import { FormTabProps } from './types';

export default function FormTab({
  agId,
  agentName,
  form,
  setForm,
  qualifyVisitor,
  setQualifyVisitor,
  setNeedsUpdate
}: FormTabProps) {
  return (
    <div className="mt-6 border rounded-lg p-6">
      <AimdocComponentContext
        componentDescription="You are in the form tab of the agent builder. Here you can modify the form that will display in the chat, such as adding, modifying, or removing fields."
        componentName="Form Tab"
      />
      
      <Label className="block text-xl font-semibold leading-6">
        Chat Form
      </Label>
      <Label className="block text-sm font-light leading-6">
        This is the form that will display in the chat. Your visitors can fill out this form.
      </Label>

      <Form
        agId={agId}
        agentName={agentName}
        form={form}
        setForm={setForm}
        setNeedsUpdate={setNeedsUpdate}
        qualifyVisitor={qualifyVisitor}
        setQualifyVisitor={setQualifyVisitor}
      />
    </div>
  );
} 