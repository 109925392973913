import { useState, useEffect } from 'react';
import useApi from '../../api';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Search, Hash } from 'lucide-react';
import { cn } from "@/lib/utils";
import { ScrollArea } from "@/components/ui/scroll-area";

interface Channel {
    id: string;
    name: string;
}

interface NotificationConfig {
    channelId: string;
    notifications: {
        newVisitor: boolean;
        newLead: boolean;
        sessionActive: boolean;
        newQuestion: boolean;
    };
}

interface SlackNotificationsProps {
    integration: any;
    value: any;
    onChange: (value: any) => void;
    escalationEnabled: boolean;
}

export default function SlackNotifications({ integration, value, onChange, escalationEnabled }: SlackNotificationsProps) {
    const { get } = useApi();
    const [channels, setChannels] = useState<Channel[]>([]);
    const [loading, setLoading] = useState(true);
    const [configs, setConfigs] = useState<NotificationConfig[]>(value?.configs || []);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchChannels();
    }, []);

    const fetchChannels = async () => {
        try {
            const response = await get(`/slack/channels/${integration.id}`);
            setChannels(response.data);
        } catch (error) {
            console.error('Error fetching channels:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChannelToggle = (channelId: string) => {
        const existingConfig = configs.find(c => c.channelId === channelId);
        
        if (existingConfig) {
            setConfigs(configs.filter(c => c.channelId !== channelId));
        } else {
            const notifications: any = {
                newVisitor: true,
                newLead: true,
                sessionActive: true,
                newQuestion: true
            }

            if (escalationEnabled) {
                notifications.sessionEscalation = true;
            }

            setConfigs([...configs, {
                channelId,
                notifications
            }]);
        }
    };

    const handleNotificationToggle = (channelId: string, notificationType: keyof NotificationConfig['notifications']) => {
        const newConfigs = configs.map(config => {
            if (config.channelId === channelId) {
                return {
                    ...config,
                    notifications: {
                        ...config.notifications,
                        [notificationType]: !config.notifications[notificationType]
                    }
                };
            }
            return config;
        });
        setConfigs(newConfigs);
    };

    useEffect(() => {
        onChange({ configs });
    }, [configs]);

    const filteredChannels = channels.filter(channel => 
        channel.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (loading) {
        return (
            <div className="animate-pulse space-y-4">
                {[1, 2, 3].map((i) => (
                    <div key={i} className="h-24 bg-muted rounded-lg"></div>
                ))}
            </div>
        );
    }

    return (
        <div className="space-y-4">
            <div className="relative">
                <Input
                    type="text"
                    placeholder="Search channels..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-10"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
            </div>

            <ScrollArea className="h-[400px]">
                <div className="space-y-4 pr-4">
                    {filteredChannels.map(channel => {
                        const config = configs.find(c => c.channelId === channel.id);
                        return (
                            <div key={channel.id} className="p-4 rounded-lg border bg-card">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-3">
                                        <Hash className="h-4 w-4 text-muted-foreground" />
                                        <span className="text-lg font-medium">
                                            {channel.name}
                                        </span>
                                    </div>
                                    <Button
                                        onClick={() => handleChannelToggle(channel.id)}
                                        variant={config ? "destructive" : "default"}
                                        size="sm"
                                    >
                                        {config ? 'Remove' : 'Add'}
                                    </Button>
                                </div>
                                {config && (
                                    <div className="grid grid-cols-2 gap-4 mt-4 pt-4 border-t">
                                        {Object.entries(config.notifications).map(([key, value]) => (
                                            <label key={key} className="flex items-center space-x-3">
                                                <Checkbox
                                                    checked={value}
                                                    onCheckedChange={() => 
                                                        handleNotificationToggle(channel.id, key as keyof NotificationConfig['notifications'])
                                                    }
                                                />
                                                <span className="text-sm">
                                                    {key === 'newVisitor' ? 'New Visitor' :
                                                     key === 'newLead' ? 'New Lead' :
                                                     key === 'sessionActive' ? 'Session Active' :
                                                     key === 'sessionEscalation' ? 'Session Escalation' :
                                                     'New Question'}
                                                </span>
                                            </label>
                                        ))}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    
                    {filteredChannels.length === 0 && searchQuery && (
                        <div className="text-center p-8 border border-dashed rounded-lg">
                            <p className="text-muted-foreground">
                                No channels found matching "{searchQuery}"
                            </p>
                        </div>
                    )}
                    
                    {channels.length === 0 && !searchQuery && (
                        <div className="text-center p-8 border border-dashed rounded-lg">
                            <p className="text-muted-foreground">
                                No channels found. Make sure you have channels in your Slack workspace.
                            </p>
                        </div>
                    )}
                </div>
            </ScrollArea>
        </div>
    );
} 