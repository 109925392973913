import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { X } from 'lucide-react';
import { cn } from "@/lib/utils";

interface SchedulingFieldsModalProps {
    isOpen: boolean;
    closeModal: () => void;
    formFields: any[];
    onSave: (fieldMappings: Record<string, string>) => void;
    existingMappings?: Record<string, string>;
    integrationName: string;
}

export default function SchedulingFieldsModal({ 
    isOpen, 
    closeModal, 
    formFields, 
    onSave,
    existingMappings = {},
    integrationName
}: SchedulingFieldsModalProps) {
    const [fieldMappings, setFieldMappings] = useState<Record<string, string>>(existingMappings);
    const [selectedField, setSelectedField] = useState<string | null>(null);
    const [mappingValue, setMappingValue] = useState('');

    useEffect(() => {
        if (isOpen) {
            setFieldMappings(existingMappings);
        }
    }, [isOpen, existingMappings]);

    const handleFieldSelect = (fieldName: string) => {
        setSelectedField(fieldName);
        setMappingValue(fieldMappings[fieldName] || '');
    };

    const handleSaveMapping = () => {
        if (selectedField) {
            if (mappingValue.trim()) {
                setFieldMappings(prev => ({
                    ...prev,
                    [selectedField]: mappingValue.trim()
                }));
            } else {
                const newMappings = { ...fieldMappings };
                delete newMappings[selectedField];
                setFieldMappings(newMappings);
            }
            setSelectedField(null);
            setMappingValue('');
        }
    };

    const handleClearMapping = (fieldName: string, e: React.MouseEvent) => {
        e.stopPropagation();
        const newMappings = { ...fieldMappings };
        delete newMappings[fieldName];
        setFieldMappings(newMappings);
    };

    const handleCancel = () => {
        setFieldMappings(existingMappings);
        setSelectedField(null);
        setMappingValue('');
        closeModal();
    };

    const handleSaveAll = () => {
        if (Object.keys(fieldMappings).length === 0) {
            return;
        }
        onSave(fieldMappings);
        closeModal();
    };

    const isEditing = Object.keys(existingMappings).length > 0;

    return (
        <Dialog open={isOpen} onOpenChange={closeModal}>
            <DialogContent className="sm:max-w-2xl">
                <DialogHeader>
                    <DialogTitle>Configure Action</DialogTitle>
                    <DialogDescription>
                        Your AI agent will collect this information from the visitor before it is able to use this action
                    </DialogDescription>
                </DialogHeader>

                <div className="mt-6 space-y-4">
                    {formFields.map((field) => (
                        <div
                            key={field.name}
                            className={cn(
                                'p-4 rounded-lg border cursor-pointer transition-all duration-200',
                                selectedField === field.name
                                    ? 'border-primary bg-primary/5'
                                    : 'hover:border-primary/50'
                            )}
                            onClick={() => handleFieldSelect(field.name)}
                        >
                            <div className="flex items-center justify-between">
                                <div>
                                    <h3 className="text-md font-medium">
                                        {field.label}
                                    </h3>
                                    {fieldMappings[field.name] && (
                                        <div className="flex items-center gap-2">
                                            <p className="text-sm text-muted-foreground mt-1">
                                                Mapped to: {fieldMappings[field.name]}
                                            </p>
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={(e) => handleClearMapping(field.name, e)}
                                                className="h-4 w-4 text-destructive hover:text-destructive/90"
                                            >
                                                <X className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {selectedField && (
                    <div className="mt-6">
                        <label className="block text-sm font-medium mb-2">
                            {`Enter ${integrationName} field ID`}
                        </label>
                        <div className="flex gap-2">
                            <Input
                                value={mappingValue}
                                onChange={(e) => setMappingValue(e.target.value)}
                                placeholder="Enter field ID"
                                className="flex-1"
                            />
                            <Button
                                onClick={handleSaveMapping}
                            >
                                Map Field
                            </Button>
                        </div>
                    </div>
                )}

                <DialogFooter>
                    <Button
                        variant="outline"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveAll}
                        disabled={Object.keys(fieldMappings).length === 0}
                    >
                        {isEditing ? 'Save Action' : 'Create Action'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
} 