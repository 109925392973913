import React, { useState, useEffect, useRef } from 'react';
import Message from '../documentation/Message';
import BeatLoader from '../ui/BeatLoader';
import useApi from '../api';
import { MessageType } from '../types';
import config from '../../config.json';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../user/UserContext';
import { formatDate } from '../utils/dateFormatter';
import { debounce } from 'lodash';
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { CheckCircle, Sparkles, MousePointerClick, SendHorizontal, Cog } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert"
import { AlertCircle } from "lucide-react"

interface ChatProps {
    chatId: string | null;
    status: string;
    setStatus: (status: string) => void;
    leadId: string | null;
    setLeadId: (leadId: string) => void;
}

interface GroupedMessage {
    url: string;
    messages: MessageType[];
}

const Chat: React.FC<ChatProps> = ({ chatId, status, setStatus, leadId, setLeadId }) => {
    const [loading, setLoading] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState<MessageType[]>([]);
    const [conversationId, setConversationId] = useState('');
    const [conversation, setConversation] = useState<any>(null);
    const [assistantResponse, setAssistantResponse] = useState('');
    const [takeOverChat, setTakeOverChat] = useState(false);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const [shouldScroll, setShouldScroll] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isVisitorTyping, setIsVisitorTyping] = useState(false);
    const [isAiResponding, setIsAiResponding] = useState(false);
    const [humanAgentId, setHumanAgentId] = useState('');
    const [humanAgentConnected, setHumanAgentConnected] = useState(false);
    const { get, post, connectWebSocket } = useApi();
    const { user } = useUser();

    const chatRef = useRef<HTMLDivElement>(null);
    const socketRef = useRef<WebSocket | null>(null);
    const takeOverChatRef = useRef<boolean>(takeOverChat);
    const humanAgentConnectedRef = useRef<boolean>(humanAgentConnected);
    const shouldReconnectRef = useRef(true);

    useEffect(() => {
        takeOverChatRef.current = takeOverChat;
    }, [takeOverChat]);

    useEffect(() => {
        humanAgentConnectedRef.current = humanAgentConnected;
    }, [humanAgentConnected]);

    const scrollToBottom = () => {
        if (chatRef.current) {
            const scrollContainer = chatRef.current.querySelector('[data-radix-scroll-area-viewport]');
            if (scrollContainer) {
                scrollContainer.scrollTop = scrollContainer.scrollHeight;
            }
        }
    };

    const sendTypingStatus = (typing: boolean) => {
        if (socketRef.current) {
            socketRef.current.send(JSON.stringify({
                type: 'agent_typing',
                is_typing: typing,
                conversation_id: conversationId
            }));
        }
    };

    const debouncedSendTypingStatus = useRef(
        debounce((typing: boolean) => sendTypingStatus(typing), 300)
    ).current;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setUserInput(newValue);

        if (newValue && !isTyping) {
            setIsTyping(true);
            sendTypingStatus(true);
        } else if (!newValue && isTyping) {
            setIsTyping(false);
            sendTypingStatus(false);
        } else {
            debouncedSendTypingStatus(!!newValue);
        }
    };

    useEffect(() => {
        return () => {
            if (isTyping) {
                sendTypingStatus(false);
            }
            debouncedSendTypingStatus.cancel();
        };
    }, [isTyping, debouncedSendTypingStatus]);

    useEffect(() => {
        scrollToBottom();
        setShouldScroll(false);
    }, [shouldScroll]);

    const handleTakeOverChat = () => {
        if (status !== 'active') {
            toast.error('Session is not active', toastConfig);
            return;
        }

        if (socketRef.current) {
            setTakeOverChat(true);
            socketRef.current.send(JSON.stringify({
                type: 'take_over_chat',
                conversation_id: conversationId
            }));
        }
    };

    const handleHandBackChat = () => {
        if (socketRef.current) {
            setTakeOverChat(false);
            setIsAiResponding(true);
            socketRef.current.send(JSON.stringify({
                type: 'hand_back_chat',
                conversation_id: conversationId
            }));
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [conversationId, assistantResponse]);

    const sendMessage = () => {
        if (socketRef.current && userInput.trim()) {
            setIsTyping(false);
            const message = {
                type: 'message',
                content: userInput,
                role: 'assistant',
                created_at: new Date().toISOString(),
                human_agent: user
            };
            setShouldScroll(true);
            socketRef.current.send(JSON.stringify(message));

            setMessages((prevMessages) => [...prevMessages, message]);
            setUserInput('');
        }
    };

    useEffect(() => {
        let trackHumanAgentConnected = false;
        const getConversation = async (chatId: string) => {
            try {
                setLoading(true);
                const response = await get(`/sessions/${chatId}`);
                setMessages(response.data.messages);
                setConversationId(response.data.id);
                setConversation(response.data);
                setStatus(response.data.status);
                setHumanAgentId(response.data.human_agent_id);
                setHumanAgentConnected(response.data.is_human_agent);
                trackHumanAgentConnected = response.data.is_human_agent;
            } catch (error) {
                console.error('Error fetching conversation:', error);
            }
            setLoading(false);
            return trackHumanAgentConnected;
        };

        const initializeChat = async () => {
            if (!chatId) return;
            
            await getConversation(chatId);
            
            if (chatId && !socketRef.current) {
                const initializeWebSocket = async () => {
                    try {
                        const ws = await connectWebSocket(`/ws/${chatId}?role=assistant`);
                        if (!ws) {
                            console.error('WebSocket connection failed');
                            return;
                        }
                        socketRef.current = ws;

                        ws.onmessage = (event) => {
                            try {
                                const data = JSON.parse(event.data);
                                if (data.type === 'message') {
                                    if (data.role === 'user') {
                                        const newMessage: MessageType = {
                                            id: data.id,
                                            role: data.role,
                                            content: data.content,
                                            created_at: new Date().toISOString(),
                                            message_metadata: data.message_metadata
                                        };
                                        setMessages((prevMessages) => [...prevMessages, newMessage]);
                                        setIsVisitorTyping(false);

                                    } else if ((data.role === 'assistant' || data.role === 'system') && !takeOverChatRef.current) {
                                        const newMessage: MessageType = {
                                            role: data.role,
                                            content: data.content,
                                            created_at: new Date().toISOString(),
                                        };
                                        setMessages((prevMessages) => [...prevMessages, newMessage]);
                                    }
                                }
                                if (data.type === 'assistant' && data.final_message) {
                                    const newMessage: MessageType = {
                                        role: 'assistant',
                                        content: data.final_message,
                                        created_at: new Date().toISOString(),
                                    };
                                    setMessages((prevMessages) => [...prevMessages, newMessage]);
                                }
                                if (data.type === 'take_over_chat') {
                                    if (!takeOverChatRef.current) {
                                        setHumanAgentConnected(true);
                                        if (socketRef.current) {
                                            socketRef.current.send(JSON.stringify({
                                                type: 'silent_close_connection',
                                                conversation_id: conversationId
                                            }));
                                        }
                                    }
                                    setMessages((prevMessages) => [...prevMessages, data]);
                                }
                                if (data.type === 'hand_back_chat') {
                                    setTakeOverChat(false);
                                    if (socketRef.current && humanAgentConnectedRef.current) {
                                        setHumanAgentConnected(false);
                                        socketRef.current.close();
                                        socketRef.current = null;
                                    }
                                    setMessages((prevMessages) => [...prevMessages, data]);
                                }
                                if (data.type === 'status') {
                                    setStatus(data.status);
                                    if (data.status === 'inactive') {
                                        toast.info('Visitor closed the chat', toastConfig);
                                    }
                                }
                                if (data.type === 'visitor_typing') {
                                    setIsVisitorTyping(data.is_typing);
                                    setShouldScroll(true);
                                }
                                if (data.type === 'ai_responding') {
                                    setIsAiResponding(data.responding);
                                    setShouldScroll(true);
                                }
                            } catch (error) {
                                console.error('Error parsing WebSocket message:', error);
                            }
                        };

                        ws.onopen = () => {
                            setReconnectAttempts(0);
                        };

                        ws.onclose = (event) => {
                            if (shouldReconnectRef.current) {
                                const delay = Math.min(1000 * 2 ** reconnectAttempts, 30000);
                                setTimeout(() => {
                                    setReconnectAttempts((prev) => prev + 1);
                                    initializeWebSocket();
                                }, delay);
                            }
                        };

                        ws.onerror = (error) => {
                            console.error('WebSocket error:', error);
                        };
                    } catch (error) {
                        console.error('Failed to establish WebSocket connection:', error);
                        if (shouldReconnectRef.current) {
                            const delay = Math.min(1000 * 2 ** reconnectAttempts, 30000);
                            setTimeout(() => {
                                setReconnectAttempts((prev) => prev + 1);
                                initializeWebSocket();
                            }, delay);
                        }
                    }
                };

                initializeWebSocket();
            }
        };

        initializeChat();

        return () => {
            shouldReconnectRef.current = false;
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null;
            }
        };
    }, [chatId]);

    useEffect(() => {
        return () => {
            if (socketRef.current && humanAgentConnected) {
                socketRef.current.send(JSON.stringify({
                    type: 'silent_close_connection',
                    conversation_id: conversationId
                }));
            }
        };
    }, [humanAgentConnected]);



    useEffect(() => {
        return () => {
            shouldReconnectRef.current = false;
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (status === 'active') {
            toast.info('Visitor is active', toastConfig);
        }
    }, [status]);

    const normalizeUrl = (url: string): string => {
        try {
            const urlObject = new URL(url);
            return `${urlObject.protocol}//${urlObject.hostname}${urlObject.pathname}`;
        } catch (error) {
            console.error('Error parsing URL:', error);
            return url;
        }
    };

    const groupMessagesByUrl = (messages: MessageType[]): GroupedMessage[] => {
        const grouped: GroupedMessage[] = [];
        let currentGroup: GroupedMessage | null = null;

        messages.forEach((message: MessageType) => {
            const fullUrl = message.message_metadata?.parent_window_url;

            const normalizedUrl = fullUrl
                ? normalizeUrl(fullUrl)
                : currentGroup?.url || 'Unknown URL';

            if (!currentGroup || currentGroup.url !== normalizedUrl) {
                if (currentGroup) {
                    grouped.push(currentGroup);
                }
                currentGroup = { url: normalizedUrl, messages: [] };
            }

            currentGroup.messages.push(message);
        });

        if (currentGroup) {
            grouped.push(currentGroup);
        }

        return grouped;
    };

    const sortedMessages = [...messages].sort((a, b) => {
        const dateA = new Date(a.created_at.endsWith('Z') ? a.created_at : `${a.created_at}Z`);
        const dateB = new Date(b.created_at.endsWith('Z') ? b.created_at : `${b.created_at}Z`);
        return dateA.getTime() - dateB.getTime();
    });

    const groupedMessages = groupMessagesByUrl(sortedMessages);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full w-full">
                <BeatLoader />
            </div>
        );
    }

    return (
        <div className="flex flex-col w-full h-screen">
            {loading ? (
                <div className="flex-1 flex justify-center items-center">
                    <BeatLoader />
                </div>
            ) : (
                <>
                    <ScrollArea className="flex-1 px-4" ref={chatRef}>
                        <div className="py-4 space-y-4">
                            {groupedMessages.map((group, groupIndex) => (
                                <Card key={groupIndex} className="p-4">
                                    <div className="text-center font-semibold text-lg mb-4 text-muted-foreground">
                                        {group.url}
                                    </div>
                                    <div className="space-y-4">
                                        {group.messages.map((message, index) => (
                                            <div key={index}>
                                                {(message.role === 'user' || message.role === 'assistant') && (
                                                    <Message
                                                        role={message.role}
                                                        content={message.content}
                                                        created_at={message.created_at}
                                                        human_agent={message.human_agent}
                                                        message_metadata={message.message_metadata}
                                                        conversationId={conversationId}
                                                    />
                                                )}
                                                {message.role === 'system' && (
                                                    <div className="flex flex-col items-center space-y-2">
                                                        {message.type === 'take_over_chat' && (
                                                            <div className="flex items-center space-x-2 bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200 px-3 py-2 rounded-lg">
                                                                <CheckCircle className="h-5 w-5 flex-shrink-0" />
                                                                <span>{`${message.agent_name || 'Agent'} has taken over the chat.`}</span>
                                                            </div>
                                                        )}
                                                        {message.type === 'hand_back_chat' && (
                                                            <div className="flex items-center space-x-2 bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 px-3 py-2 rounded-lg">
                                                                <Sparkles className="h-5 w-5 flex-shrink-0" />
                                                                <span>{`${message.agent_name || 'Agent'} handed the chat back to Aimdoc AI.`}</span>
                                                            </div>
                                                        )}
                                                        {message.type === 'click_event' && (
                                                            <div className="flex items-center space-x-2">
                                                                <MousePointerClick className="h-5 w-5 flex-shrink-0" />
                                                                <span>{message.content}</span>
                                                            </div>
                                                        )}
                                                        {message.type === 'user' && (
                                                            <div className="flex items-center space-x-2">
                                                                <Cog className="h-5 w-5 flex-shrink-0" />
                                                                <span>{message.content}</span>
                                                            </div>
                                                        )}
                                                        <span className="text-xs text-muted-foreground">
                                                            {formatDate(message.created_at)}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            ))}
                            {(isVisitorTyping || isAiResponding) && (
                                <Message
                                    role={isVisitorTyping ? "user" : "assistant"}
                                    content={assistantResponse}
                                    isVisitorTyping={true}
                                    conversationId={conversationId}
                                />
                            )}
                        </div>
                    </ScrollArea>

                    <div className="border-t p-4 space-y-4">
                        {!takeOverChat && status === 'active' && !humanAgentConnected && (
                            <Button
                                variant="secondary"
                                className="w-full"
                                onClick={handleTakeOverChat}
                                disabled={isAiResponding}
                            >
                                Take Over Chat
                            </Button>
                        )}
                        {humanAgentConnected && (
                            <Alert variant="destructive" className="flex items-center justify-center">
                                <AlertDescription>
                                    Visitor is already connected to a human agent.
                                </AlertDescription>
                            </Alert>
                        )}

                        {takeOverChat && status === 'active' && (
                            <>
                                <Alert variant="destructive" className="flex items-center justify-center">
                                    <AlertDescription>
                                        If you close this tab or navigate away, the conversation will automatically return to AI control.
                                    </AlertDescription>
                                </Alert>
                                <Button
                                    variant="secondary"
                                    className="w-full"
                                    onClick={handleHandBackChat}
                                >
                                    Hand Back to AI
                                </Button>

                                <div className="flex space-x-2">
                                    <Input
                                        className="flex-1"
                                        placeholder="Type your message..."
                                        value={userInput}
                                        onChange={handleInputChange}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault();
                                                sendMessage();
                                            }
                                        }}
                                    />
                                    <Button
                                        onClick={sendMessage}
                                        disabled={!takeOverChat}
                                        size="icon"
                                    >
                                        <SendHorizontal className="h-5 w-5" />
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </>
            )
            }
        </div >
    );
};


export default Chat;