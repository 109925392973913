import { useState, useEffect } from 'react';
import { useUser } from '../user/UserContext';
import Upgrade from '../modals/Upgrade';

const UpgradeButton = () => {
   const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);
   const [trialExpired, setTrialExpired] = useState(false);
   const { organization } = useUser();

   // get param called session_id
   const search = new URLSearchParams(window.location.search);
   const sessionId = search.get('session_id');

   const calculateRemainingDays = (trialEndDate: string) => {
      const endDate = new Date(trialEndDate);
      const currentDate = new Date();
      const diffTime = endDate.getTime() - currentDate.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays > 0 ? diffDays : 0;
   };

   useEffect(() => {
      if (sessionId || trialExpired) {
         setUpgradeModalVisible(true);
      }
   }, [sessionId, trialExpired]);

   useEffect(() => {
      if (organization && organization.license_type === 'trial' && organization.trial_end_date && calculateRemainingDays(organization.trial_end_date) <= 0) {
         setTrialExpired(true);
      }
   }, [organization]);

   if (!organization || organization.license_type === 'business') {
      return null;
   }

   return (
      <div className="">
         <div onClick={() => setUpgradeModalVisible(true)} 
              className="hover:cursor-pointer flex flex-row justify-between items-center h-full px-3 py-2 text-sm text-blue-500 bg-background border rounded-lg font-bold transition duration-200 hover:bg-muted">
               <div className="flex flex-row items-center justify-between space-x-2">
                  <div className='items-center'>
                     Upgrade Plan
                  </div>
                  {organization.license_type === 'trial' && organization.trial_end_date && (
                  <div className="items-center text-xs font-light text-muted-foreground">
                     {calculateRemainingDays(organization.trial_end_date)} days left
                  </div>
                  )}
               </div>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 shrink-0">
                  <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
               </svg>
         </div>
         {upgradeModalVisible && (
            <Upgrade 
               showModal={upgradeModalVisible} 
               setShowModal={setUpgradeModalVisible} 
               sessionId={sessionId} 
               trialExpired={trialExpired} 
            />
         )}
      </div>
   );
};

export default UpgradeButton; 