import { AimdocComponentContext } from '@aimdoc/sdk-react';
import { Label } from '@/components/ui/label';
import Website from '../builder/Website';
import Files from '../builder/Files';
import { KnowledgeTabProps } from './types';

export default function KnowledgeTab({
  agId,
  agentName,
  website,
  setWebsite,
  selectedPages,
  setSelectedPages,
  indexedPages,
  setIndexedPages,
}: KnowledgeTabProps) {
  return (
    <div className="mt-6">
      <AimdocComponentContext
        componentDescription="You are in the knowledge tab of the agent builder. Here you can add websites and files to the agent's knowledge base."
        componentName="Knowledge Tab"
      />
      
      <div className="p-6 border rounded-lg mb-4">
        <Label className="block text-xl font-semibold leading-6">
          Websites
        </Label>
        <Label className="block text-sm font-light leading-6">
          Aimdoc will index all of the content on your website and use it to train your agent. This will allow your agent to answer questions about your product or service.
        </Label>
        <Website 
          agentId={agId} 
          agentName={agentName} 
          website={website} 
          selectedPages={selectedPages} 
          setSelectedPages={setSelectedPages} 
          setWebsite={setWebsite} 
          indexedPages={indexedPages} 
          setIndexedPages={setIndexedPages}
        />
      </div>

      <div className="p-6 border rounded-lg">
        <Label className="block text-xl font-semibold leading-6">
          Files
        </Label>
        <Label className="block text-sm font-light leading-6">
          Upload Word Documents and PDFs
        </Label>
        {agId && <Files agentId={agId} />}
      </div>
    </div>
  );
} 