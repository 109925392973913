import { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import UserCircle from '../user/UserCircle';
import LogoSvg from '../../logo_main.svg';
import toastConfig from '../config/Toast';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from '../api';
import { useOutsideAlerter } from '../useOutsideAlerter';
import { supabase } from '../supabaseClient'
import { useUser } from '../user/UserContext';
import UpgradeButton from '../../components/upgrade/UpgradeButton';
import useAnalytics from '../analytics/analytics';
import { conLog } from '../utils/conLog';
import {
   Dialog,
   DialogContent,
   DialogDescription,
   DialogFooter,
   DialogHeader,
   DialogTitle,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import ThemeSwitcher from '../ThemeSwitcher';
import { Bot, UserRoundCheck, Menu, ThumbsUp, Library, Cog, LogOut, Shield, Settings } from 'lucide-react';
import { AimdocComponentContext } from '@aimdoc/sdk-react';
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"

// Update constants to use theme-aware classes
const ACTIVE_CLASS = "bg-muted border";
const INACTIVE_CLASS = "hover:bg-muted hover:text-primary-900";

const NavBar = ({ selectedItem }: any) => {
   const [settingDropdown, setSettingDropdown] = useState(false);
   const [addDropdown, setAddDropdown] = useState(false);
   const [modalVisible, setModalVisible] = useState(false);
   const [helpModal, setHelpModal] = useState(false);
   const [feedback, setFeedback] = useState('');
   const [emails, setEmails] = useState<string[]>(['']);
   const [message, setMessage] = useState('');
   const [loading, setLoading] = useState(false);
   const { post, connectWebSocket } = useApi();
   const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);
   const [trialExpired, setTrialExpired] = useState(false);
   const [tocOpen, setTocOpen] = useState(true);
   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
   const navigate = useNavigate();
   const { user, organization, refresh } = useUser();
   const { captureEvent } = useAnalytics();
   const [websocket, setWebsocket] = useState<WebSocket | null>(null);
   const [reconnectAttempts, setReconnectAttempts] = useState(0);
   const shouldReconnectRef = useRef(true);
   const socketRef = useRef<WebSocket | null>(null);

   // get param called session_id
   const search = new URLSearchParams(window.location.search);
   const sessionId = search.get('session_id');
   conLog('sessionId:', sessionId)
   // refresh organization when session id is present
   useEffect(() => {
      if (sessionId) {
         refresh();
      }
   }, [sessionId]);

   useEffect(() => {
      refresh();
   }, []);

   const modalRef = useRef(null);
   useOutsideAlerter(modalRef, () => toggleModal());

   const toggleModal = () => {
      setModalVisible(!modalVisible);
   };

   const feedbackRef = useRef(null);
   useOutsideAlerter(feedbackRef, () => toggleHelpModal());

   const toggleHelpModal = () => {
      setHelpModal(!helpModal);
   };

   const settingsRef = useRef(null);
   useOutsideAlerter(settingsRef, () => toggleDropdown());

   const toggleDropdown = () => {
      setSettingDropdown(!settingDropdown);
   };

   const addDropRef = useRef(null);
   useOutsideAlerter(addDropRef, () => toggleAdd());

   const toggleAdd = () => {
      setAddDropdown(!addDropdown);
   };

   const handleLogout = async () => {
      await supabase.auth.signOut();
      captureEvent('logged_out', {});
      navigate('/login');
   };

   const handleSendInvites = async () => {
      const nonEmptyEmails = emails.filter(email => email.trim() !== '');
      const invalidEmails = nonEmptyEmails.filter(email => !validateEmail(email));
      if (invalidEmails.length > 0) {
         toast.error(`Invalid emails: ${invalidEmails.join(', ')}`, toastConfig);
         return;
      }
      if (nonEmptyEmails.length === 0) {
         toast.error("No emails entered", toastConfig);
         return;
      }

      try {
         const response = await post('/invite/', { emails: nonEmptyEmails, message });
         setEmails(['']);
         setMessage('');
         toggleModal();
         toast.success('Invites sent!', toastConfig);
         captureEvent('sent_invite', {
            emails: nonEmptyEmails,
            message: message
         });
         return response.data;
      } catch (error) {
         console.error('Error sending invite:', error);
         toast.error(`Error sending invite. Please contact ben@aimdoc.ai or cam@aimdoc.ai if the problem persists.`, toastConfig);
      }
   };

   const handleSubmitFeedback = async () => {
      setLoading(true);
      try {
         const response = await post('/feedback/', { feedback: feedback });
         setMessage('');
         setHelpModal(false);
         toast.success('Feedback sent!', toastConfig);
         captureEvent('feedback_submitted', {
            feedback: feedback
         });
         return response.data;
      } catch (error) {
         console.error('Error sending feedback:', error);
         toast.error(`Error sending feedback. Please contact`)
      }
   };

   const validateEmail = (email: string) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
   };

   const setCurrentEmail = (value: string, index: number) => {
      const newEmails = [...emails];
      newEmails[index] = value;
      if (index === emails.length - 1 && value && emails.length < 10) {
         newEmails.push('');
      }
      if (!value) {
         while (newEmails.length > 1 && !newEmails[newEmails.length - 1]) {
            newEmails.pop();
         }
         if (newEmails.length < 10 && newEmails[newEmails.length - 1] !== '') {
            newEmails.push('');
         }
      }

      setEmails(newEmails);
   };

   const calculateRemainingDays = (trialEndDate: string) => {
      const endDate = new Date(trialEndDate);
      const currentDate = new Date();
      const diffTime = endDate.getTime() - currentDate.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays > 0 ? diffDays : 0;
   };

   useEffect(() => {
      if (sessionId || trialExpired) {
         setUpgradeModalVisible(true);
      }
   }, [sessionId, trialExpired]);

   useEffect(() => {
      if (organization && organization.license_type === 'trial' && organization.trial_end_date && calculateRemainingDays(organization.trial_end_date) <= 0) {
         setTrialExpired(true);
      }
   }, [organization]);

   const toggleToc = () => {
      setTocOpen(!tocOpen);
   };

   const refreshPage = () => {
      window.location.reload();
   };

   const getInitialTab = () => {
      const params = new URLSearchParams(window.location.search);
      const typeParam = params.get('type');
      if (typeParam === 'sales' || typeParam === 'operator') {
         return typeParam;
      }
      return localStorage.getItem('selectedTab') || 'sales';
   };

   const [activeTab, setActiveTab] = useState(getInitialTab());
   const [type, setType] = useState(getInitialTab());

   const handleTabChange = (value: string) => {
      setActiveTab(value);
      setType(value);
      localStorage.setItem('selectedTab', value);
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('type', value);
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      refreshPage();
   };

   const navLinksSection1 = [
      {
         id: "home",
         href: "/",
         label: "Home",
         icon: (
            <svg
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               strokeWidth={1.5}
               stroke="currentColor"
               className="w-5 h-5 stroke-primary-500 flex-shrink-0"
            >
               <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
               />
            </svg>
         ),
         isActive: selectedItem === "" || selectedItem === "/" || selectedItem === "home",
         extraComponent: null,
      },
   ];

   const navLinksSection2 = [
      {
         id: "agents",
         href: "/agents",
         label: type === "sales" ? "Agents" : "Operators",
         icon: (
            <Bot className="w-5 h-5 flex-shrink-0" />
         ),
         isActive: selectedItem.includes("agents"),
         extraComponent: (
            <AimdocComponentContext
               componentDescription="This button navigates to the Agents list page. You'll need to here to do anything agent related, such as training an agent on a website or file, updating the agent's form (like adding a new field), modifying the agent's persona or goal, customizing the agent's logo or icon, or creating a new agent."
               componentName="Agents"
            />
         ),
      },
      {
         id: "leads",
         href: "/leads",
         type: "sales",
         label: "Leads",
         icon: (
            <UserRoundCheck className="w-5 h-5 flex-shrink-0" />
         ),
         isActive: selectedItem.includes("leads"),
         extraComponent: null,
      },
      {
         id: "sessions",
         href: "/sessions",
         label: "Sessions",
         icon: (
            <svg
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               strokeWidth={1.5}
               stroke="currentColor"
               className="w-5 h-5  flex-shrink-0"
            >
               <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
               />
            </svg>
         ),
         isActive: selectedItem.includes("sessions"),
         extraComponent: null,
      },
      // TODO: Add analytics back with more advanced analytics than on Dashboard page
      // {
      //    id: "analytics",
      //    href: "/analytics",
      //    label: "Analytics",
      //    type: "operator",
      //    icon: (
      //       <svg
      //          xmlns="http://www.w3.org/2000/svg"
      //          fill="none"
      //          viewBox="0 0 24 24"
      //          strokeWidth={1.5}
      //          stroke="currentColor"
      //          className="w-5 h-5  flex-shrink-0"
      //       >
      //          <path
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //             d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
      //          />
      //       </svg>
      //    ),
      //    isActive: selectedItem.includes("analytics"),
      //    extraComponent: null,
      // },
      {
         id: "integrations",
         href: "/integrations",
         label: "Integrations",
         icon: (
            <svg
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               strokeWidth={1.5}
               stroke="currentColor"
               className="w-5 h-5  flex-shrink-0"
            >
               <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z"
               />
            </svg>
         ),
         isActive: selectedItem.includes("integrations"),
         extraComponent: null,
      }
   ];

   const kbNavItems = [
      {
         id: "documents",
         href: "/documents",
         label: "Files",
         icon: (
            <svg
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               strokeWidth={1.5}
               stroke="currentColor"
               className="w-5 h-5 flex-shrink-0"
            >
               <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
               />
            </svg>
         ),
         isActive: selectedItem.includes("documents"),
      },
      {
         id: "websites",
         href: "/websites",
         label: "Websites",
         icon: (
            <svg
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               strokeWidth={1.5}
               stroke="currentColor"
               className="w-5 h-5  flex-shrink-0"
            >
               <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
               />
            </svg>
         ),
         isActive: selectedItem.includes("websites"),
      },
      {
         id: "questions",
         href: "/questions",
         label: "Questions",
         icon: (
            <svg
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               strokeWidth={1.5}
               stroke="currentColor"
               className="w-5 h-5  flex-shrink-0"
            >
               <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
               />
            </svg>
         ),
         isActive: selectedItem.includes("questions"),
      },
   ];

   const toggleMobileMenu = () => {
      setMobileMenuOpen(!mobileMenuOpen);
   };

   // Add WebSocket connection setup
   useEffect(() => {
      if (organization?.id) {
         const initializeWebSocket = async () => {
            try {
               const ws = await connectWebSocket(`/ws/organization/${organization.id}`);
               if (!ws) {
                  console.error('WebSocket connection failed');
                  return;
               }
               socketRef.current = ws;
               setWebsocket(ws);

               ws.onmessage = (event) => {
                  try {
                     const data = JSON.parse(event.data);
                     if (data.type === 'session_active') {
                        toast.info(
                           <div>
                              <div className="font-semibold">New Active Session</div>
                              <div className="text-sm">A new session has started</div>
                              <Link 
                                 to={`/sessions/${data.conversation_id}`} 
                                 className="text-sm text-blue-500 hover:text-blue-700"
                              >
                                 View Session →
                              </Link>
                           </div>,
                           {
                              ...toastConfig,
                              autoClose: 8000,
                              onClick: () => {
                                 navigate(`/sessions/${data.conversation_id}`);
                              }
                           }
                        );
                     }
                  } catch (error) {
                     console.error('Error parsing WebSocket message:', error);
                  }
               };

               ws.onopen = () => {
                  console.log('Connected to organization notifications');
                  setReconnectAttempts(0);
               };

               ws.onclose = () => {
                  console.log('Disconnected from organization notifications');
                  if (shouldReconnectRef.current) {
                     const delay = Math.min(1000 * 2 ** reconnectAttempts, 30000);
                     setTimeout(() => {
                        setReconnectAttempts((prev) => prev + 1);
                        initializeWebSocket();
                     }, delay);
                  }
               };

               ws.onerror = (error) => {
                  console.error('WebSocket error:', error);
               };
            } catch (error) {
               console.error('Failed to establish WebSocket connection:', error);
               if (shouldReconnectRef.current) {
                  const delay = Math.min(1000 * 2 ** reconnectAttempts, 30000);
                  setTimeout(() => {
                     setReconnectAttempts((prev) => prev + 1);
                     initializeWebSocket();
                  }, delay);
               }
            }
         };

         initializeWebSocket();

         return () => {
            shouldReconnectRef.current = false;
            if (socketRef.current?.readyState === WebSocket.OPEN) {
               socketRef.current.send(JSON.stringify({ type: 'silent_close_connection' }));
               socketRef.current.close();
            }
            socketRef.current = null;
         };
      }
   }, [organization?.id]);

   // Add visibility change handler to reconnect when tab becomes visible
   useEffect(() => {
      const handleVisibilityChange = () => {
         if (document.visibilityState === 'visible' && !socketRef.current) {
            setWebsocket(null); // Trigger reconnection
         }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
         document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
   }, []);

   return (
      <>
         <button
            onClick={toggleMobileMenu}
            className="fixed top-4 left-4 p-2 rounded-lg bg-background border md:hidden z-30"
         >
            <Menu className="h-6 w-6" />
         </button>

         <div className="bg-background grow">
            <div className={`transition-all duration-20 xl:translate-x-0 flex justify-start items-start min-h-screen h-full w-full flex-col md:w-64`}>
               <aside id="sidebar" className={`fixed ${mobileMenuOpen ? 'block' : 'hidden'} md:block z-20 h-full top-0 left-0 flex lg:flex flex-shrink-0 flex-col transition-all duration-200 w-64`}>
                  <div className="bg-background relative flex-1 flex flex-col h-full border-r pt-0 transition-all duration-500">
                     <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto transition-all duration-500">
                        <a href="/" className="flex items-center w-full justify-center">
                           <img src={LogoSvg} className="h-7 mr-2 justify-center" alt="Logo" />
                        </a>

                        {organization?.operator && (
                           <div className="px-3 mt-4">
                              <Tabs
                                 defaultValue={activeTab}
                                 className="w-full"
                                 onValueChange={handleTabChange}
                                 value={activeTab}
                              >
                                 <TabsList className="grid w-full grid-cols-2">
                                    <TabsTrigger value="sales">Sales Agent</TabsTrigger>
                                    <TabsTrigger value="operator">Operator</TabsTrigger>
                                 </TabsList>
                              </Tabs>
                           </div>
                        )}

                        <div className="mt-6 flex-1 px-3 divide-y space-y-1 transition-all duration-500">
                           <ul className="space-y-2 pb-2">
                              {navLinksSection1.map(item => (
                                 <li key={item.id}>
                                    {/* <Link
                                          id={item.id}
                                          to={item.href}
                                          className={`${item.isActive ? ACTIVE_CLASS : INACTIVE_CLASS} text-sm font-semibold rounded-lg group transition-all duration-500 flex items-center p-2`}
                                       >
                                          {item.icon}
                                          <span className="ml-3">{item.label}</span>
                                          {item.extraComponent}
                                       </Link> */}
                                    <a href={item.href} className="text-sm font-semibold rounded-lg group transition-all duration-500 flex items-center p-2">
                                       {item.icon}
                                       <span className="ml-3">{item.label}</span>
                                       {item.extraComponent}
                                    </a>
                                 </li>
                              ))}
                           </ul>

                           <>
                              <ul className="space-y-2 pb-2 pt-3">
                                 {navLinksSection2.map(item => (
                                    (!item.type || item.type === activeTab) && (
                                       <li key={item.id}>
                                          <Link
                                             id={item.id}
                                             to={item.href}
                                             className={`${item.isActive ? ACTIVE_CLASS : INACTIVE_CLASS} text-sm font-semibold rounded-lg group transition-all duration-500 flex items-center p-2`}
                                          >
                                             {item.icon}
                                             <span className="ml-3">{item.label}</span>
                                             {item.extraComponent}
                                          </Link>
                                       </li>
                                    )
                                 ))}
                              </ul>

                              <ul className="space-y-2 pb-2 pt-3">
                                 <li>
                                    <button
                                       onClick={toggleToc}
                                       className="w-full flex items-center justify-between px-4 py-2 text-sm font-semibold rounded-lg"
                                    >
                                       <span>Knowledge Base</span>
                                       <svg
                                          className={`w-5 h-5 transition-transform duration-300 ${tocOpen ? "rotate-180" : ""}`}
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                       >
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                       </svg>
                                    </button>
                                    {tocOpen && (
                                       <ul className="space-y-2 pb-2 pt-3">
                                          {kbNavItems.map(item => (
                                             <li key={item.id}>
                                                <Link
                                                   id={item.id}
                                                   to={item.href}
                                                   className={`${item.isActive ? ACTIVE_CLASS : INACTIVE_CLASS} text-sm font-semibold rounded-lg group transition-all duration-500 flex items-center p-2`}
                                                >
                                                   {item.icon}
                                                   <span className="ml-3">{item.label}</span>
                                                </Link>
                                             </li>
                                          ))}
                                       </ul>
                                    )}
                                 </li>
                              </ul>
                           </>

                           <div className="space-y-2 pt-2">
                              <a onClick={() => window.open("https://docs.aimdoc.ai/", "_blank")} target="_blank" className="text-sm font-semibold rounded-lg group transition-all hover:bg-muted duration-500 flex items-center p-2 hover:cursor-pointer">
                                 <Library className="w-5 h-5 flex-shrink-0" />
                                 <span className="ml-3">Documentation</span>
                              </a>
                              <a onClick={() => toggleHelpModal()} target="_blank" className="text-sm font-semibold rounded-lg group transition-all hover:bg-muted duration-500 flex items-center p-2 hover:cursor-pointer">
                                 <ThumbsUp className="w-5 h-5 flex-shrink-0" />
                                 <span className="ml-3">Feedback</span>
                              </a>
                              <a onClick={() => setModalVisible(true)} target="_blank" className="text-sm font-semibold rounded-lg group transition-all hover:bg-muted duration-500 flex items-center p-2 hover:cursor-pointer">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5  flex-shrink-0">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                 </svg>
                                 <span className="ml-3 flex-shrink-0">Invite Teammates</span>
                              </a>
                              {/* <a onClick={handleLogout} className="text-sm font-semibold rounded-lg flex items-center p-2 group transition-all hover:bg-muted duration-500 hover:cursor-pointer">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5  flex-shrink-0">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                                 </svg>
                                 <span className="ml-3 flex-1 whitespace-nowrap">Sign Out</span>
                              </a> */}
                           </div>
                        </div>

                        <div className="flex flex-col px-4 items-center rounded-lg overflow-hidden">
                           <div className='w-full mt-4 flex flex-col gap-2'>
                              <UpgradeButton />
                              <ThemeSwitcher />
                           </div>
                        </div>

                        <div className="px-4 mt-4">
                           <AimdocComponentContext
                              componentDescription="The button below opens a pop up, which will contain the link to the user settings page."
                              componentName="Stacked Icon"
                           />
                           <div className="flex flex-row px-2 justify-between py-2 border rounded-lg items-start">
                              <div className="space-y-2">
                                 <UserCircle />
                                 <div className="text-xs text-primary-600">
                                    {user && user.email}
                                 </div>
                              </div>

                              <Popover>
                                 <PopoverTrigger asChild>
                                    <button>
                                       <Menu className="mr-2 stroke-primary hover:cursor-pointer" />
                                    </button>
                                 </PopoverTrigger>
                                 <PopoverContent className="w-48 p-0">
                                    <div className="flex flex-col">
                                       <Link to="/user-settings" className="px-3 py-2 text-sm hover:bg-muted flex items-center">
                                          <Settings className="h-4 w-4 mr-2" />
                                          <AimdocComponentContext
                                             componentDescription="The button below navigates to the user settings page."
                                             componentName="User Settings"
                                             path="/user-settings"
                                          />
                                          Settings
                                       </Link>
                                       <Separator />
                                       <Link to="/privacy" className="px-3 py-2 text-sm hover:bg-muted flex items-center">
                                          <Shield className="h-4 w-4 mr-2" />
                                          Privacy Policy
                                       </Link>
                                       <Separator />
                                       <Link to="/logout" onClick={handleLogout} className="px-3 py-2 text-sm hover:bg-muted flex items-center text-red-500">
                                          <LogOut className="h-4 w-4 mr-2" />
                                          Sign out
                                       </Link>
                                    </div>
                                 </PopoverContent>
                              </Popover>
                           </div>
                        </div>

                     </div>
                  </div>
               </aside>

               {mobileMenuOpen && (
                  <div
                     className="fixed inset-0 bg-background md:hidden z-10 mb-16 w-full"
                     onClick={() => setMobileMenuOpen(false)}
                  />
               )}
            </div>

            <Dialog open={modalVisible} onOpenChange={setModalVisible}>
               <DialogContent>
                  <DialogHeader>
                     <DialogTitle>Invite members to your team!</DialogTitle>
                  </DialogHeader>

                  <div className="grid gap-4 py-4">
                     {emails.map((email, index) => (
                        <Input
                           key={index}
                           type="email"
                           value={email}
                           onChange={(e) => setCurrentEmail(e.target.value, index)}
                           placeholder={`Add new email i.e ${(index + 1) % 2 === 0 ? 'cam@aimdoc.ai' : 'ben@aimdoc.ai'}`}
                           className={!validateEmail(email) && email ? 'border-red-500' : ''}
                        />
                     ))}
                  </div>

                  <DialogFooter>
                     <Button
                        variant="outline"
                        onClick={() => setModalVisible(false)}
                        disabled={loading}
                     >
                        Cancel
                     </Button>
                     <Button
                        onClick={handleSendInvites}
                        disabled={loading}
                     >
                        {loading ? (
                           <>
                              <svg className="animate-spin -ml-1 mr-2 h-4 w-4">
                                 <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                 <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                              Sending...
                           </>
                        ) : (
                           'Send Invites'
                        )}
                     </Button>
                  </DialogFooter>
               </DialogContent>
            </Dialog>

            <Dialog open={helpModal} onOpenChange={setHelpModal}>
               <DialogContent>
                  <DialogHeader>
                     <DialogTitle>Submit feedback or ask questions below!</DialogTitle>
                     <DialogDescription>
                        We will get back to you shortly!
                     </DialogDescription>
                  </DialogHeader>

                  <div className="grid gap-4 py-4">
                     <Textarea
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder="Feedback or questions..."
                        rows={4}
                     />
                  </div>

                  <DialogFooter>
                     <Button
                        onClick={handleSubmitFeedback}
                        disabled={loading}
                     >
                        Submit
                     </Button>
                  </DialogFooter>
               </DialogContent>
            </Dialog>

         </div>
         <script async defer src="https://buttons.github.io/buttons.js"></script>
      </>
   );
};

export default NavBar;