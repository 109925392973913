import { useState, useEffect } from 'react';
import useApi from '../../api';
import { conLog } from '../../utils/conLog';
import { toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import BeatLoader from '../../ui/BeatLoader';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

export default function SelectWebsites({ agentId, setShowWebsites, setWebsitesSelected }: { agentId: string | undefined, setShowWebsites: any, setWebsitesSelected: any }) {
  const [isOpen, setIsOpen] = useState(true);
  const [websites, setWebsites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedWebsites, setSelectedWebsites] = useState<Set<string>>(new Set());
  const { get, post } = useApi();

  function closeModal() {
    setIsOpen(false);
    setShowWebsites(false);
  }

  function toggleWebsiteSelection(websiteId: string) {
    setSelectedWebsites((prevSelectedWebsites) => {
      const updatedSelectedWebsites = new Set(prevSelectedWebsites);
      if (updatedSelectedWebsites.has(websiteId)) {
        updatedSelectedWebsites.delete(websiteId);
      } else {
        updatedSelectedWebsites.add(websiteId);
      }
      return updatedSelectedWebsites;
    });
  }

  function associateWebsitesWithAgent() {
    const selectedWebsitesArray = Array.from(selectedWebsites);

    post(`/agents/${agentId}/websites`, { website_ids: selectedWebsitesArray })
      .then((response) => {
        setWebsitesSelected(selectedWebsitesArray);
        setShowWebsites(false);
        closeModal();
        toast.success('Websites associated successfully', toastConfig);
        return response.data;
      })
      .catch((error) => {
        console.error('Error associating websites:', error);
        toast.error('Error associating websites', toastConfig);
      });
  }

  useEffect(() => {
    if (!isOpen) return;

    const url = agentId ? `/websites/?exclude_agent_id=${agentId}` : '/websites/';

    get(url)
      .then((response) => {
        conLog(response.data);
        setWebsites(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching websites:', error);
      });
  }, [isOpen, agentId]);

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && closeModal()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select Websites</DialogTitle>
        </DialogHeader>

        <div className="overflow-y-auto max-h-96 space-y-3">
          {websites && websites.length > 0 && (
            websites.map((website: any) => (
              <div key={website.id} className="flex items-center space-x-3 p-2 rounded-md">
                <input
                  type="checkbox"
                  id={website.id}
                  checked={selectedWebsites.has(website.id)}
                  onChange={() => toggleWebsiteSelection(website.id)}
                  className="form-checkbox h-5 w-5"
                />
                <label htmlFor={website.id} className="text-sm break-all">
                  {website.url}
                </label>
              </div>
            ))
          )}
          {loading && (
            <BeatLoader />
          )}
          {websites.length === 0 && !loading && (
            <p>No websites available</p>
          )}
        </div>

        <DialogFooter>
          <Button
            disabled={selectedWebsites.size === 0}
            onClick={associateWebsitesWithAgent}
          >
            Select Websites
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}