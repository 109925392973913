import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const HotkeyNavigation = () => {
  const navigate = useNavigate();
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if Command (Mac) or Control (Windows) key is pressed
      if (event.metaKey || event.ctrlKey) {
        switch (event.key.toLowerCase()) {
          // Create New Agent (⌘/Ctrl + J)
          case 'j':
            event.preventDefault();
            navigate('/agents/new');
            toast.info(`${isMac ? '⌘' : 'Ctrl'} + J: Create New Agent`);
            break;

          // Knowledge Base (⌘/Ctrl + K)
          case 'k':
            event.preventDefault();
            navigate('/knowledge');
            toast.info(`${isMac ? '⌘' : 'Ctrl'} + K: Knowledge Base`);
            break;

          // Documents (⌘/Ctrl + U)
          case 'u':
            event.preventDefault();
            navigate('/agents');
            toast.info(`${isMac ? '⌘' : 'Ctrl'} + U: Agents`);
            break;

          // Analytics (⌘/Ctrl + I)
          case 'i':
            event.preventDefault();
            navigate('/analytics');
            toast.info(`${isMac ? '⌘' : 'Ctrl'} + I: Analytics`);
            break;

          // Documentation (⌘/Ctrl + .)
          case '.':
            event.preventDefault();
            window.open('https://docs.aimdoc.ai', '_blank');
            toast.info(`${isMac ? '⌘' : 'Ctrl'} + .: Documentation`);
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [navigate, isMac]);

  return null; // This component doesn't render anything
};

export default HotkeyNavigation; 