import React, { useState, useEffect } from 'react';
import { useUser } from './user/UserContext';
import useApi from './api';
import { toast } from 'react-toastify';
import toastConfig from './config/Toast';
import Logo from '../logo_symbol.png';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { ShinyButton } from './magicui/shiny-button';
import { RocketIcon } from "lucide-react";
import useAnalytics from './analytics/analytics';

const AgentFromAI = ({ user, setComplete }: { user?: any, setComplete?: any }) => {
    const [step, setStep] = useState<'input' | 'selection' | 'processing'>('input');
    const [website, setWebsite] = useState('');
    const [currentTask, setCurrentTask] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [agentName, setAgentName] = useState('');
    const [subtaskIndex, setSubtaskIndex] = useState(0);
    const [runSubmit, setRunSubmit] = useState(false);
    const { put, post } = useApi();
    const { refreshUser, organization} = useUser();
    const [selectedTypes, setSelectedTypes] = useState<string[]>(() => {
        return organization?.operator ? [] : ['Website'];
    });
    const { captureEvent } = useAnalytics();
    // Define subtasks first
    const reviewSubTasks = [
        'Reviewing',
        'Creating visitor profile',
        'Setting a goal'
    ];

    // Define tasks before using them
    const tasks = [
        'Reviewing your website',
        agentName ? `Creating ${agentName}` : 'Creating your agents',
        'Initiating training'
    ];

    const getTitle = () => {
        if (step === 'input') return "Let's build your agent";
        if (step === 'selection') return "Choose your agent type";
        if (currentTask >= tasks.length) return "All set!";
        if (currentTask === 0) {
            const displayUrl = website.replace(/^https?:\/\//, '');
            return subtaskIndex === 0 
                ? `${reviewSubTasks[subtaskIndex]} ${displayUrl}`
                : reviewSubTasks[subtaskIndex];
        }
        if (currentTask === 2) return agentName ? `Training ${agentName}` : 'Training your agent';
        return agentName ? `Building ${agentName}` : 'Building your agent';
    };

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (currentTask === 0 && step === 'processing') {
            interval = setInterval(() => {
                setSubtaskIndex((prevIndex) => (prevIndex + 1) % reviewSubTasks.length);
            }, 4000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [currentTask, step]);

    const [title, setTitle] = useState(getTitle());

    const isInitialRender = React.useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }

        setIsTransitioning(true);
        const timer = setTimeout(() => {
            setTitle(getTitle());
            setIsTransitioning(false);
        }, 300);
        return () => clearTimeout(timer);
    }, [step, currentTask, subtaskIndex]);

    const handleWebsiteSubmit = async () => {
        let cleanedUrl = website.trim().toLowerCase();

        if (!cleanedUrl.startsWith('http://') && !cleanedUrl.startsWith('https://')) {
            cleanedUrl = 'https://' + cleanedUrl;
        }

        if (cleanedUrl.startsWith('http://')) {
            cleanedUrl = 'https://' + cleanedUrl.slice(7);
        }

        try {
            new URL(cleanedUrl);
            captureEvent('website_submitted', {
                website: cleanedUrl
            });
        } catch (error) {
            toast.error('Invalid URL format', toastConfig);
            console.error('Invalid URL format');
            return;
        }

        setWebsite(cleanedUrl);
        if (organization?.operator) {
            setStep('selection');
        } else {
            setRunSubmit(true);
        }
    };

    useEffect(() => {
        if (runSubmit) {
            handleSubmit();
        }
    }, [runSubmit]);

    const handleTypeSelection = (type: string) => {
        setSelectedTypes(prev => {
            if (prev.includes(type)) {
                return prev.filter(t => t !== type);
            }
            return [...prev, type];
        });
        captureEvent('type_selected', {
            type: type
        });
    };

    const handleSubmit = async () => {
        setStep('processing');
        // Task 1: Review website
        setCurrentTask(0);

        let agents;

        try {
            const reviewResponse = await post('/agents/review-website/', {
                website: website,
                types: selectedTypes
            });
            agents = reviewResponse.data.agents;
            setAgentName(agents[0]?.name || "Your AI Agent");
            captureEvent('agent_review_complete', {
                agent_name: agents[0]?.name,
                agent_type: agents[0]?.type,
                agent_goal: agents[0]?.goal,
                agent_pop_up: agents[0]?.pop_up,
                agent_starters: agents[0]?.starters
            });
        } catch (error) {
            console.error('Error reviewing website:', error);
            return;
        }

        setCurrentTask(1);
        try {
            
            for (const agent of agents) {
                const createResponse = await post('/agents/from-ai/', {
                    name: agent.name,
                    persona: agent.persona,
                    goal: agent.goal,
                    pop_up: agent.pop_up,
                    starters: agent.starters,
                    type: agent.type.toLowerCase()
                });
                
                await post(`/agents/${createResponse.data.id}/website/`, {
                    url: website
                });
                
                captureEvent('agent_created', {
                    agent_name: agent.name,
                    agent_type: agent.type,
                    agent_goal: agent.goal,
                    agent_pop_up: agent.pop_up,
                    agent_starters: agent.starters
                });
            }
            
            setCurrentTask(2);
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            setCurrentTask(3);
            await new Promise(resolve => setTimeout(resolve, 1000));
        } catch (error: any) {
            console.error('Error creating agents:', error);
            toast.error(error.detail, toastConfig);
            await handleSkip();
            return;
        }

        try {
            if (user) {
                await put(`/users/${user.id}/walkthrough-complete/`, {
                    auth_id: user.sub,
                    email: user.email,
                    username: user.nickname,
                    website: website
                });
                setComplete(true);
                refreshUser();
                captureEvent('walkthrough_complete', {});
            } else {
                setComplete(false);
            }
            window.location.href = '/agents';
        } catch (error) {
            console.error('Error completing walkthrough:', error);
            return;
        }

    };

    const handleSkip = async () => {
        if (!user) {
            setComplete(false);
            return;
        }
        try {
            await put(`/users/${user.id}/walkthrough-complete/`, {
                auth_id: user.sub,
                email: user.email,
                username: user.nickname,
                website: website
            });
            setComplete(true);
            refreshUser();
            captureEvent('walkthrough_skipped', {});
        } catch (error) {
            console.error('Error completing walkthrough:', error);
            // Handle completion error
            return;
        }
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-background">
            <Button
                variant="ghost"
                className="absolute bottom-8 right-8 text-muted-foreground hover:text-foreground"
                onClick={handleSkip}
            >
                skip
            </Button>

            <img
                src={Logo}
                alt="Aimdoc Logo"
                className="absolute top-[20%] left-1/2 transform -translate-x-1/2 w-20 h-20"
            />

            <Card className="max-w-2xl w-full mx-8">
                <CardHeader>
                    <CardTitle 
                        className={cn(
                            "text-4xl text-center transition-opacity duration-300",
                            isTransitioning && "opacity-0"
                        )}
                    >
                        {title}
                    </CardTitle>
                </CardHeader>

                <CardContent>
                    {step === 'input' ? (
                        <div className="space-y-6">
                            <Input
                                type="url"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                                placeholder="Enter your website URL"
                                className="text-lg"
                            />
                            <ShinyButton
                                className="w-full"
                                disabled={!website}
                                onClick={handleWebsiteSubmit}
                            >
                                <div className="flex flex-row gap-2 justify-center items-center">
                                    <span className="text-lg">Next</span>
                                    <RocketIcon className="w-5 h-5" />
                                </div>
                            </ShinyButton>
                        </div>
                    ) : step === 'selection' ? (
                        <div className="space-y-6">
                            <div className="grid grid-cols-2 gap-4">
                                {['Product', 'Website'].map((type) => (
                                    <Button
                                        key={type}
                                        variant={selectedTypes.includes(type) ? "default" : "outline"}
                                        onClick={() => handleTypeSelection(type)}
                                        className={cn(
                                            "p-8 text-xl",
                                            selectedTypes.includes(type) && "border"
                                        )}
                                    >
                                        {type}
                                    </Button>
                                ))}
                            </div>
                            <ShinyButton
                                className="w-full"
                                disabled={selectedTypes.length === 0}
                                onClick={handleSubmit}
                            >
                                <div className="flex flex-row gap-2 justify-center items-center">
                                    <span className="text-lg">Choose Agent Types</span>
                                    <RocketIcon className="w-5 h-5" />
                                </div>
                            </ShinyButton>
                        </div>
                    ) : (
                        <div className="space-y-6">
                            {tasks.map((task, index) => (
                                <div key={index} className="flex items-center space-x-4">
                                    {index === currentTask ? (
                                        <svg width="24" height="24" className="fill-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" />
                                            <path d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z">
                                                <animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite" />
                                            </path>
                                        </svg>
                                    ) : index < currentTask ? (
                                        <CheckCircleIcon className="w-6 h-6 text-success" />
                                    ) : (
                                        <div className="w-6 h-6" />
                                    )}
                                    <span
                                        className={cn(
                                            "text-lg",
                                            index === currentTask && "text-foreground font-medium",
                                            index < currentTask && "text-success",
                                            index > currentTask && "text-muted-foreground"
                                        )}
                                    >
                                        {task}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default AgentFromAI;