import React, { useState, useMemo, useEffect } from 'react';
import { MessageProps } from '../types';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import aimdocLogo from '../../logo_symbol.png';
import rehypeRaw from "rehype-raw";
import UserCircle from '../user/UserCircle';
import Calendly from '../agents/actions/Calendly';
import YouTube from '../agents/actions/YouTube';
import TidyCal from '../agents/actions/TidyCal';
import ChiliPiper from '../agents/actions/ChiliPiper';
import * as HeroIconSolid from '@heroicons/react/24/solid'
import { formatDate } from '../utils/dateFormatter'
import Form from '../agents/actions/Form';
import { memo } from 'react';
import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Card } from "@/components/ui/card";
import Actions from '../agents/actions/Actions';

interface Component {
    type: string;
    [key: string]: any;
}

type ComponentMapping = {
    calendly: typeof Calendly;
    tidycal: typeof TidyCal;
    chili_piper: typeof ChiliPiper;
    youtube: typeof YouTube;
    form: typeof Form;
    form_field: typeof Form;
    actions: typeof Actions;
};

interface MemoizedComponentProps {
    component: Component;
    config: any;
    conversationId: string;
    gettingFullResponse: boolean;
}

const MemoizedComponent = memo(({
    component,
    config,
    conversationId,
    gettingFullResponse
}: MemoizedComponentProps) => {

    const FallbackComponent = ({ component, content }: { component: any, content: any }) => {
        const displayContent = typeof component === 'string' ? component : JSON.stringify(component, null, 2);

        if (displayContent && displayContent.includes('\n')) {
            return (
                <div className={`font-light mt-6 mb-6 rounded-xl ${config ? "" : "bg-blue-100"} text-slate-800 p-4`} style={{ backgroundColor: `${config ? config.end_color : ""}` }}>
                    <code className="whitespace-pre-wrap break-words">{displayContent}</code>
                </div>
            );
        } else {
            return (
                <code className="border p-1 rounded-md bg-blue-100 text-slate-800 break-words">{displayContent}</code>
            );
        }
    };

    switch (component.type) {
        case 'calendly':
            return <Calendly
                url={component.calendly_url}
                key={component.calendly_url}
                gettingFullResponse={gettingFullResponse || false}
            />;
        case 'form_field':
            return <Form />;
        case 'form':
            return <Form />;
        case 'tidycal':
            return <TidyCal
                url={component.tidy_cal_url}
                key={component.tidy_cal_url}
                gettingFullResponse={gettingFullResponse || false}
            />;
        case 'chili_piper':
            return <ChiliPiper
                domain={component.domain}
                inbound_router={component.inbound_router}
                session_id={conversationId || ''}
                email={component.email}
                key={component.url}
                gettingFullResponse={gettingFullResponse || false}
            />;
        case 'youtube':
            return <YouTube video_id={component.video_id} />;
        default:
            if (component.actions) {
                return (
                    <Actions
                        actions={component.actions}
                    />
                )
            } else {
                return <FallbackComponent
                    component={component.content || component}
                    content={component}
                />;
            }
    }
}, () => false);

const Message: React.FC<MessageProps> = ({
    role,
    content,
    created_at,
    leadEmail,
    config,
    scrollToBottom,
    gettingResponse,
    gettingFullResponse,
    human_agent,
    isVisitorTyping,
    conversationId,
    message_metadata
}) => {
    const [logo, setLogo] = useState<string>(aimdocLogo);
    const preprocessJson = (jsonString: any) => {
        let correctedString = jsonString.replace(/\\'/g, "'");
        correctedString = correctedString.replace(/,(?=\s*[}\]])/g, "");
        return correctedString;
    }
    const [newContent, setNewContent] = useState<string>(message_metadata?.task ? `Started task ${message_metadata?.task?.name}` : content);

    const componentMapping: ComponentMapping = {
        calendly: Calendly,
        tidycal: TidyCal,
        chili_piper: ChiliPiper,
        youtube: YouTube,
        form: Form,
        form_field: Form,
        actions: Actions
    };

    const getComponent = useMemo(() => (component: Component) => {
        return (
            <MemoizedComponent
                component={component}
                config={config}
                conversationId={conversationId || ''}
                gettingFullResponse={gettingFullResponse || false}
            />
        );
    }, [config, conversationId, gettingFullResponse]);

    const detectAndRenderUrls = (content: string): string => {
        if (!gettingFullResponse) {
            return content;
        }

        const urlPattern = /(https?:\/\/[^\s]+)/g;
        const markdownUrlPattern = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
        const markdownStartPattern = /\[/;

        const lastOpenBracketIndex = content.lastIndexOf('[');

        if (lastOpenBracketIndex !== -1) {
            const substringFromLastBracket = content.slice(lastOpenBracketIndex);
            if (!markdownUrlPattern.test(substringFromLastBracket)) {
                return content.slice(0, lastOpenBracketIndex);
            }
        }

        return content;
    };

    const RenderChatComponent = (props: any) => {
        let component;

        try {
            const clean = preprocessJson(props.children);
            component = JSON.parse(clean);

            if (Array.isArray(component)) {
                return (
                    <>
                        {component.map((comp, index) => {
                            return getComponent(comp);
                        })}
                    </>
                );
            } else {
                return getComponent(component);
            }
        } catch (e) {
            return <code></code>;
        }
    }

    const markdownRenderers = {
        ul: (props: any) => (
            <ul className="pl-8 pr-8 list-disc w-full max-w-full space-y-2">{props.children}</ul>
        ),
        ol: (props: any) => (
            <ol className="pl-8 pr-8 list-decimal w-full max-w-full space-y-2">{props.children}</ol>
        ),
        li: (props: any) => (
            <li className="leading-7">{props.children}</li>
        ),
        p: (props: any) => (
            <p className="leading-7 [&:not(:first-child)]:mt-6">{props.children}</p>
        ),
        a: (props: any) => (
            <a
                href={props.href}
                target="_blank"
                rel="noreferrer"
                className="font-medium underline underline-offset-4 hover:text-primary"
            >
                {props.children}
            </a>
        ),
        h1: (props: any) => (
            <h1 className="scroll-m-20 text-4xl font-bold tracking-tight">{props.children}</h1>
        ),
        h2: (props: any) => (
            <h2 className="scroll-m-20 text-2xl font-semibold tracking-tight">{props.children}</h2>
        ),
        h3: (props: any) => (
            <h3 className="scroll-m-20 text-xl font-semibold tracking-tight">{props.children}</h3>
        ),
        strong: (props: any) => (
            <strong className="font-medium">{props.children}</strong>
        ),
        code: RenderChatComponent
    };

    useEffect(() => {
        if (message_metadata) {
            content = `Started task ${message_metadata?.task?.name}`;
        }
    }, [message_metadata]);

    return (
        <div className={`flex flex-col pb-4`}>
            <div
                key={content}
                className={cn(
                    "flex mt-2 space-x-3",
                    role === 'assistant' ? 'ml-12 justify-end' : 'mr-4'
                )}
            >
                {role !== 'assistant' && (
                    <Avatar className="h-8 w-8">
                        <AvatarFallback className="bg-primary border text-primary-foreground">
                            A
                        </AvatarFallback>
                    </Avatar>
                )}
                <Card
                    className={cn(
                        "text-sm p-1",
                        role === 'assistant'
                            ? "rounded-l-lg rounded-br-lg bg-primary/5 border-primary/10"
                            : "rounded-r-lg rounded-bl-lg bg-muted",
                        gettingResponse && role !== 'assistant' && "bg-muted/50"
                    )}
                >
                    {isVisitorTyping ? (
                        <div className='flex space-x-2 justify-center items-center'>
                            <div className='h-3 w-3 rounded-full animate-bounce [animation-delay:-0.3s] bg-muted-foreground/50'></div>
                            <div className='h-3 w-3 rounded-full animate-bounce [animation-delay:-0.15s] bg-muted-foreground/50'></div>
                            <div className='h-3 w-3 rounded-full animate-bounce bg-muted-foreground/50'></div>
                        </div>
                    ) : (
                        <div className="flex flex-col px-2 py-1">
                            <Markdown
                                remarkPlugins={[remarkBreaks]}
                                rehypePlugins={[rehypeRaw]}
                                children={detectAndRenderUrls(newContent)}
                                components={markdownRenderers}
                            />
                        </div>
                    )}
                    {role === 'system' && (
                        <div>
                            <span className="inline-flex items-center rounded-md bg-destructive/10 px-2 py-1 text-xs font-medium text-destructive ring-1 ring-inset ring-destructive/20">
                                System Message
                            </span>
                        </div>
                    )}
                </Card>
                {message_metadata?.task && (
                    <div
                        className="flex-shrink-0 text-xs text-foreground font-semibold bg-background px-2 py-1 rounded-md border border-border flex items-center gap-1.5 group relative"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                            />
                        </svg>
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none whitespace-nowrap">
                            {message_metadata?.task?.name}
                        </div>
                    </div>
                )}
                {role === 'assistant' && !human_agent && (
                    <Avatar className="h-8 w-8">
                        <AvatarImage src={logo} alt="aimdoc logo" />
                        <AvatarFallback>AI</AvatarFallback>
                    </Avatar>
                )}
                {role === 'assistant' && human_agent && (
                    <UserCircle leadEmail={human_agent.email} />
                )}
            </div>
            {created_at && (
                <span className={cn(
                    "mt-3 text-xs text-muted-foreground leading-none",
                    role === 'assistant' ? 'text-right mr-14' : 'text-left ml-14'
                )}>
                    {formatDate(created_at)}
                </span>
            )}
        </div>
    );
};

export default Message;