import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Login from './login/Login';
import NavBar from "./navbar/NavBar";
import Documents from "./documentation/Documents";
import UserProfile from "./user/UserProfile";
import Dashboard from "./Dashboard";
import Integrations from "./integrations/Integrations";
import Vector from "./admin/MilvusAdmin";
import { useUser } from './user/UserContext';
import PrivacyPolicy from './PrivacyPolicy';
import AgentsList from './agents/AgentsList';
import ConfigureAgent from "./agents/ConfigureAgent";
import { AgentForm } from "./agents/CreateAgent";
import LeadList from "./leads/LeadsList";
import Lead from "./leads/Lead";
import DeleteOrganization from "./user/DeleteOrganization";
import Sessions from "./sessions/SessionsList";
import Session from "./sessions/SessionAlt";
import Websites from "./websites/Websites";
import Website from "./websites/Website";
import Analytics from "./analytics/AgentAnalytics";
import WebsitePreview from "./agents/WebsitePreview";
import Questions from "./questions/Questions";
import QuestionView from "./questions/QuestionView";
import Admin from "./admin/Admin";
import { conLog } from './utils/conLog';
import { ToastContainer } from 'react-toastify';
import logo_symbol from '../logo_symbol.png';
import { AimdocUserContext, AimdocTaskDefinition } from '@aimdoc/sdk-react';
import HotkeyNavigation from './HotkeyNavigation';
import Knowledge from './Knowledge';
import { useState, useEffect } from 'react';
import { useTheme } from './ThemeSwitcher';

const Main = () => {
  const location = useLocation();
  const { user, loading } = useUser();
  const { isDark } = useTheme();

  if (!user && !loading && location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password' && !location.pathname.includes('/agent/') && !location.pathname.includes('/agent-embed')) {
    conLog("User not logged in, redirecting to login page");
    return <Navigate to="/login" />;
  }

  const selectedTab = localStorage.getItem('selectedTab');
  if (!selectedTab) {
    localStorage.setItem('selectedTab', 'sales');
  }

  return (
    <div>
      <HotkeyNavigation />
      {loading && !user && !location.pathname.includes('/login') && (
        <div className="fixed inset-0 z-50 flex justify-center items-center backdrop-blur-sm">
          <img src={logo_symbol} alt="logo" className="w-16 h-16 animate-bounce" />
        </div>
      )}
      <ToastContainer />
      {user && <AimdocUserContext id={user?.id} email={user?.email} />}
      {user &&
        <AimdocTaskDefinition
          id="user-settings"
          name="Update User Settings"
          description="Update settings for the user."
          steps={[
            "Start by asking the user which settings they'd like to update.",
            "Click the stacked icon in the bottom left hand corner of the screen.",
            "There will be a small pop up that contains a 'User Settings' button.",
            "Click the 'User Settings' button to navigate to the settings page.",
            "You will see 4 notification options. Toggle them on or off based on the user's preferences. You only need to click it once.",
            "You will also see a 'First Name' and 'Last Name' field and an 'Organization Name' field.",
            "Click 'Save Changes' to save the changes."
          ]}
        />

      }
      <AimdocTaskDefinition
        id="add-agent-field"
        name="Add Agent Field"
        description="Add a new field to the agent's form."
        steps={[
          "Start by asking the user which field they'd like to add and to what agent.",
          "Determine where you are in Aimdoc.",
          "If you are in the agent builder, click the 'Form' tab. If you are not in the agent builder, you need to navigate to an existing agent.",
          "Click the 'Add Field' button to add a new field to the form. This will open a modal.",
          "You will the following fields: Label, Type, Required, and Options.",
          "Fill in the details if the user provided them, if not you should pause and ask. For dropdowns, you'll need to add options individually before creating the field.",
          "Click 'Create Field' to add the field to the form.",
          "The modal will close and the field will be added to the form.",
          "Ask the user if they'd like to publish the changes or not."
        ]}
      />
      <AimdocTaskDefinition
        id="publish-agent"
        name="Publish Agent"
        description="Publish the agent to the public."
        steps={[
         "Click the 'Publish' button in the top right hand corner of the screen.",
         "A modal will open that shows the changes you've made to the agent.",
         "Click 'Publish Agent' at the bottom of the modal to publish the agent to the public. You must click this to actually publish the agent.",
         "The modal will close and the agent will be published to the public.",
         "Ask the user if they need the embed script for the agent."
        ]}
      />
      <AimdocTaskDefinition
        id="get-embed-script"
        name="Get Embed Script"
        description="Get the embed script for the agent."
        steps={[
         "Determine where you are in Aimdoc. You need to be in the agent builder of an existing agent to do this. If you are not there, navigate there.",
         "Click the 'Deploy' button in the right hand panel of the agent builder.",
         "You'll see a 'Website Embed Code' section. Click 'View Embed Code' to view the embed code.",
         "You'll see a modal that contains the embed code.",
         "Click 'Copy to Clipboard' to copy the embed code."
        ]}
      />
      <AimdocTaskDefinition
        id="generate-agent-preview"
        name="Generate Agent Preview"
        description="Generate a preview of the agent."
        steps={[
         "Determine where you are in Aimdoc. You need to be in the agent builder of an existing agent to do this. If you are not there, navigate there.",
         "Click the 'Preview' button in the right hand panel of the agent builder.",
         "You'll see a toggle that is defaulted to 'Widget'. Toggle it to 'Website'.",
         "You'll then see a 'Preview on Website' section. In the input field, enter the URL of the website the user wants to preview the agent on. If you don't know, ask.",
         "You'll see a toggle below the URL input. This is defauled to draft mode, which will display the draft version of the agent. Toggle it to live mode to display the live version of the agent.",
         "Click 'Generate Preview' to generate the preview."
        ]}
      />
      <div className="h-full w-full flex overflow-y-hidden">
        {user && !location.pathname.includes('/agent-embed') && <NavBar selectedItem={location.pathname} />}
        <div className={`h-full w-full flex`}>
          <Routes>
            {user ? (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/admin/:id" element={<Vector />} />
                <Route path="/knowledge" element={<Knowledge />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/user-settings" element={<UserProfile />} />
                <Route path="/integrations" element={<Integrations />} />
                <Route path="/agents" element={<AgentsList />} />
                <Route path="/agents/:id" element={<ConfigureAgent />} />
                <Route path="/agents/new" element={<AgentForm setShowModal={() => {}} />} />
                <Route path="/websites" element={<Websites />} />
                <Route path="/websites/:id" element={<Website />} />
                <Route path="/leads" element={<LeadList />} />
                <Route path="/leads/:id" element={<Lead />} />
                <Route path="/sessions" element={<Sessions />} />
                <Route path="/sessions/:id" element={<Session />} />
                <Route path="/questions" element={<Questions />} />
                <Route path="/questions/:id" element={<QuestionView />} />
                {user?.is_admin && <Route path="/admin" element={<Admin />} />}
                {user?.is_admin && <Route path="/organizations/:id/delete" element={<DeleteOrganization />} />}
              </>
            ) : null}
            <Route path="/login" element={<Login />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/agent-embed-preview/:agentId/:websiteUrl" element={<WebsitePreview />} />
            <Route path="/agent-embed-preview/:agentId" element={<WebsitePreview />} />
          </Routes>
        </div>
      </div>
    </div>
  )

};

export default Main;
