import { useEffect, useState } from 'react'
import useApi from '../api'
import { useParams } from 'react-router-dom'
import Pages from './Pages'
import { toast } from 'react-toastify'
import toastConfig from '../config/Toast'
import 'react-toastify/dist/ReactToastify.css'
import { formatDate } from '../utils/dateFormatter'
import useAnalytics from '../analytics/analytics'
import { useUser } from '../user/UserContext'
import { Button } from "@/components/ui/button"
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@/components/ui/accordion"
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'

interface Website {
    id: string;
    url: string;
    base_url: string;
    title: string;
    meta_description: string;
    meta_keywords: string;
    language: string;
    created_at: string;
    updated_at: string;
    indexed: boolean;
    index_status: string;
    indexed_at: string;
}

export default function Website() {
    const { id } = useParams();
    const { get, post } = useApi();
    const [website, setWebsite] = useState<Website | undefined>();
    const { captureEvent } = useAnalytics();
    const { checkLicenseType } = useUser();
    const [urlsToIndex, setUrlsToIndex] = useState('');

    useEffect(() => {
        if (id) {
            get(`/websites/${id}`).then((response) => {
                setWebsite(response.data);
            });
            captureEvent('viewed_website', {
                website_id: id,
            });
        }
    }, [id]);

    const handleReindex = async () => {
        try {
            const response = await post('/re-index-pages/', { website_id: id });
            toast.success('Reindexing started!', toastConfig);
            captureEvent('reindexed_pages', {
                website_id: id,
            });
        } catch (error: any) {
            toast.error(error.detail, toastConfig);
            console.error('Reindexing error:', error);
        }
    };

    const handleRecrawl = () => {
        if (!checkLicenseType('pro')) {
            toast.error('Upgrade to the Pro plan to crawl your website.', toastConfig);
            return;
        }
        try {
            post('/recrawl-website/', { website_id: id }).then((res) => {
                toast.success('Crawling in progress', toastConfig);
                captureEvent('crawl_website', {
                    website_id: website,
                });
            }).catch((error) => {
                console.error('Error crawling website:', error);
                toast.error('Error getting pages.', toastConfig);
            });
        } catch (e) {
            toast.error('Invalid website URL', toastConfig);
        }
    };

    const handleUrlSubmit = () => {
        const urls = urlsToIndex.split(/[\s,]+/).filter(url => url.trim() !== '');
        if (urls.length === 0) {
            toast.error('Please enter at least one valid URL', toastConfig);
            return;
        }

        post('/add-urls-to-index/', { website_id: id, urls: urls })
            .then((res) => {
                toast.success('URLs submitted for indexing', toastConfig);
                setUrlsToIndex('');
                captureEvent('submitted_urls_for_indexing', {
                    website_id: id,
                    url_count: urls.length,
                });
            })
            .catch((error) => {
                console.error('Error submitting URLs:', error);
                toast.error('Error submitting URLs for indexing.', toastConfig);
            });
    };

    return (
        <>
            {website && (
                <div className="w-full h-full overflow-y-auto scrollbar-hidden p-6">
                    <Card className="mb-6">
                        <CardHeader>
                            <CardTitle className="text-3xl font-bold">{website.url}</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="flex gap-4 mb-6">
                                <Button variant="outline" onClick={handleReindex}>
                                    Update Pages
                                </Button>
                                <Button variant="outline" onClick={handleRecrawl}>
                                    Crawl Site
                                </Button>
                            </div>

                            <Accordion type="single" collapsible>
                                <AccordionItem value="website-details">
                                    <AccordionTrigger>Website Details</AccordionTrigger>
                                    <AccordionContent>
                                        <Table>
                                            <TableBody>
                                                {[
                                                    { label: 'URL', value: website.url },
                                                    { label: 'Base URL', value: website.base_url },
                                                    { label: 'Title', value: website.title },
                                                    { label: 'Meta Description', value: website.meta_description },
                                                    { label: 'Meta Keywords', value: website.meta_keywords },
                                                    { label: 'Language', value: website.language },
                                                    { label: 'Created At', value: formatDate(website.created_at) },
                                                    { label: 'Updated At', value: formatDate(website.updated_at) },
                                                    { label: 'Indexed', value: website.indexed ? 'Yes' : 'No' },
                                                    { label: 'Index Status', value: website.index_status },
                                                    { label: 'Indexed At', value: website.indexed_at ? formatDate(website.indexed_at) : 'N/A' }
                                                ].filter(item => item.value)
                                                  .map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell className="font-medium">{item.label}</TableCell>
                                                        <TableCell>{item.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </AccordionContent>
                                </AccordionItem>
                            </Accordion>

                            <div className="mt-6 space-y-4">
                                <div className="space-y-2">
                                    <Label htmlFor="urlsToIndex">Add URLs to Index</Label>
                                    <Textarea
                                        id="urlsToIndex"
                                        value={urlsToIndex}
                                        onChange={(e) => setUrlsToIndex(e.target.value)}
                                        placeholder="Enter space or comma separated URLs to index"
                                        className="h-40"
                                    />
                                    <Button onClick={handleUrlSubmit} className="w-full">
                                        Submit URLs for Indexing
                                    </Button>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader>
                            <CardTitle>Page History</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <Pages websiteId={id} />
                        </CardContent>
                    </Card>
                </div>
            )}
        </>
    )
}