import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useApi from '../api';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { formatDate } from '../utils/dateFormatter';
import RowDelete from '../ui/RowDelete';
import useAnalytics from '../analytics/analytics';
import { DateRange } from "react-day-picker";
import { Input } from "@/components/ui/input";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Separator } from '@/components/ui/separator';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { ArrowUpDown } from 'lucide-react';
import { InteractiveHoverButton } from '../magicui/interactive-hover-button';
import ExportSessionsButton from './ExportSessionsButton';
import debounce from 'lodash/debounce';
import { AimdocComponentContext } from '@aimdoc/sdk-react';

interface SessionsProps {
  agentId?: string;
  leadId?: string;
  dashboard?: boolean;
}

interface Session {
  id: string;
  created_at: string;
  last_activity: string;
  agent_id?: string;
  lead?: {
    id: string;
    email: string;
  };
  status: string;
}

interface Agent {
  id: string;
  parent_agent_id: string;
  name?: string;
  persona?: string;
  goal?: string;
  type?: string;
}

interface Lead {
  id: string;
  email: string;
  first_name?: string;
  last_name?: string;
  company?: string;
}

const TYPE_MAP = {
  'operator': 'product',
  'sales': 'website'
} as const;

const Sessions: React.FC<SessionsProps> = ({ agentId, leadId, dashboard }) => {
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedAgent, setSelectedAgent] = useState<string>('all');
  const [selectedLead, setSelectedLead] = useState<string>('all');
  const [dateRange, setDateRange] = useState<DateRange>();
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [selectedDateFilter, setSelectedDateFilter] = useState<string>('all');
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { get, remove } = useApi();
  const { captureEvent } = useAnalytics();
  const [allAgents, setAllAgents] = useState<Agent[]>([]);
  const [allLeads, setAllLeads] = useState<Lead[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedTab] = useState<string>(localStorage.getItem('selectedTab') || 'operator');

  const previousParams = React.useRef<string>('');
  
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success(`${text} copied to clipboard`, toastConfig);
  }

  const getDateRange = (filter: string): DateRange | undefined => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    switch (filter) {
      case 'today': {
        return { from: today, to: today };
      }
      case 'last3days': {
        const start = new Date(today);
        start.setDate(start.getDate() - 2);
        return { from: start, to: today };
      }
      case 'lastWeek': {
        const start = new Date(today);
        start.setDate(start.getDate() - 6);
        return { from: start, to: today };
      }
      case 'lastMonth': {
        const start = new Date(today);
        start.setDate(start.getDate() - 29);
        return { from: start, to: today };
      }
      case 'last90days': {
        const start = new Date(today);
        start.setDate(start.getDate() - 89);
        return { from: start, to: today };
      }
      case 'lastYear': {
        const start = new Date(today);
        start.setDate(start.getDate() - 364);
        return { from: start, to: today };
      }
      default:
        return undefined;
    }
  };

  const fetchFilterOptions = async () => {
    try {
      const agentsResponse = await get('/agents/');
      console.log('Agents response:', agentsResponse);
      if (agentsResponse?.data && Array.isArray(agentsResponse.data)) {
        setAllAgents(agentsResponse.data.map(agent => ({
          id: agent.id,
          parent_agent_id: agent.parent_agent_id,
          name: agent.name || agent.id,
          type: agent.type 
        })));
      } else {
        console.error('Unexpected agents response format:', agentsResponse);
        setAllAgents([]);
      }

      const leadsResponse = await get('/leads/');
      console.log('Leads response:', leadsResponse);
      if (leadsResponse?.data && Array.isArray(leadsResponse.data)) {
        setAllLeads(leadsResponse.data.map(lead => ({
          id: lead.id,
          email: lead.email
        })));
      } else {
        console.error('Unexpected leads response format:', leadsResponse);
        setAllLeads([]);
      }
    } catch (error) {
      console.error('Error fetching filter options:', error);
      setAllAgents([]);
      setAllLeads([]);
    }
  };

  useEffect(() => {
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    if (!allAgents.length) {
      setLoading(true);
      return;
    }

    const params = new URLSearchParams(location.search);
    const currentPage = parseInt(params.get('page') || '1');
    const agentParam = params.get('agent_id') || 'all';
    const leadParam = params.get('lead_id') || 'all';
    const isActiveParam = params.get('is_active');
    const statusParam = isActiveParam === 'true' ? 'active' :
      isActiveParam === 'false' ? 'inactive' : 'all';
    const dateFilterParam = params.get('date_filter') || 'all';
    const searchParam = params.get('search') || '';

    if (agentParam === 'all') {
      const agentType = TYPE_MAP[selectedTab as keyof typeof TYPE_MAP];
      const agentIds = allAgents
        .filter(agent => agent.type === agentType)
        .map(agent => agent.parent_agent_id);      
        if (agentIds.length > 0) {
        params.set('agent_ids', agentIds.join(','));
      }
    }

    const currentParamsString = params.toString();

    if (previousParams.current !== currentParamsString) {
      previousParams.current = currentParamsString;

      setPage(currentPage);
      setSelectedAgent(agentParam);
      console.log('Selected agent:', selectedAgent);
      setSelectedLead(leadParam);
      setSelectedStatus(statusParam);
      setSelectedDateFilter(dateFilterParam);
      setSearchInput(searchParam);

      if (dateFilterParam !== 'all') {
        const newRange = getDateRange(dateFilterParam);
        if (newRange) {
          setDateRange(newRange);
        }
      } else {
        setDateRange(undefined);
      }

      getSessions(currentPage, searchParam, statusParam);
    }
  }, [location.search, selectedTab, allAgents.length]);

  const getSessions = async (page: number = 1, search: string = '', status: string = 'all') => {
    try {
      setLoading(true);
      const baseParams = new URLSearchParams({
        page: page.toString(),
        per_page: '15',
        search,
      });

      if (selectedDateFilter !== 'all') {
        const dateFilterMap: { [key: string]: string } = {
          'today': 'today',
          'last3days': 'last_3_days',
          'lastWeek': 'last_week',
          'lastMonth': 'last_month',
          'last90days': 'last_90_days',
          'lastYear': 'last_year'
        };
        
        const backendDateFilter = dateFilterMap[selectedDateFilter];
        if (backendDateFilter) {
          baseParams.set('date_range', backendDateFilter);
        }
      }

      if (leadId) {
        baseParams.set('lead_id', leadId);
      } else if (selectedLead !== 'all') {
        baseParams.set('lead_id', selectedLead);
      }

      if (dashboard) {
        baseParams.set('is_active', 'true');
      } else if (status !== 'all') {
        baseParams.set('is_active', status === 'active' ? 'true' : 'false');
      }

      if (selectedAgent === 'all') {
        baseParams.set('agent_type', TYPE_MAP[selectedTab as keyof typeof TYPE_MAP]);
      } else {
        baseParams.set('agent_id', selectedAgent);
      }

      const data = await get(`/sessions/?${baseParams.toString()}`);
      setSessions(data.data.items);
      setPage(data.data.page);
      setTotalPages(data.data.pages);

      captureEvent('viewed_sessions', {});
    } catch (error) {
      console.error('Error fetching sessions:', error);
      setSessions([]);
      setTotalPages(1);
    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await remove(`/sessions/${id}`);
      setSessions(sessions.filter((agent: any) => agent.id !== id));
      toast.success('Session deleted successfully', toastConfig);
      captureEvent('delete_session', {
        session_id: id,
      });
      console.log('Session deleted successfully', response.data);
    } catch (error) {
      console.error('Error deleting Session:', error);
    }
    setLoading(false);
  };

  const columns = React.useMemo<ColumnDef<Session, any>[]>(
    () => [
      {
        accessorKey: 'created_at',
        header: ({ column }: any) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="-ml-4"
          >
            Session Started
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div>
            {formatDate(row.getValue('created_at'))}
          </div>
        ),
      },
      {
        accessorKey: 'last_activity',
        header: ({ column }: any) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="-ml-4"
          >
            Last Active
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div>
            {formatDate(row.getValue('last_activity'))}
          </div>
        ),
      },
      {
        id: 'duration',
        accessorFn: (row) => {
          const start = new Date(row.created_at);
          const end = new Date(row.last_activity);
          return Math.floor((end.getTime() - start.getTime()) / 1000);
        },
        header: ({ column }: any) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="-ml-4"
          >
            Duration
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const session = row.original;
          const start = new Date(session.created_at);
          const end = new Date(session.last_activity);
          const diffInSeconds = Math.floor((end.getTime() - start.getTime()) / 1000);
          const minutes = Math.floor(diffInSeconds / 60);
          const seconds = diffInSeconds % 60;
          return (
            <div>
              {`${minutes}m ${seconds}s`}
            </div>
          );
        },
      },
      {
        id: 'lead',
        accessorFn: (row) => row.lead?.email || '',
        header: ({ column }: any) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="-ml-4"
          >
            Lead
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const session = row.original;
          return (
            <div>
              {session.lead ? (
                <Link to={`/leads/${session.lead.id}`} className="hover:underline">
                  <Badge variant="secondary" onClick={() => copyToClipboard(session?.lead?.email || '')}>
                    {session.lead.email}
                  </Badge>
                </Link>
              ) : (
                <Badge variant="outline">
                  No user-provided information
                </Badge>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: 'status',
        header: ({ column }: any) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="-ml-4"
          >
            Status
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const status = row.getValue('status');
          return (
            <div className="flex justify-start">
              {status === 'active' ? (
                <Badge variant="secondary" className="">
                  <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
                  <div className="absolute">
                    <div className="w-4 h-4 -ml-1 bg-green-500 rounded-full animate-ping opacity-75"></div>
                  </div>
                  Active
                </Badge>
              ) : (
                <Badge variant="outline" className="text-slate-500">
                  Inactive
                </Badge>
              )}
            </div>
          );
        },
      },
      {
        id: 'delete',
        header: 'Delete',
        cell: ({ row }) => (
          <div className="flex justify-start">
            <AimdocComponentContext
              componentDescription="This is the button to delete this session. When you click it there will be a confirmation modal to ensure you want to delete the session."
              componentName="Delete Session Button"
              path="/sessions"
            />
            <RowDelete deleteCallback={() => handleDelete(row.original.id)} />
          </div>
        ),
      },
    ],
    [handleDelete, formatDate]
  );

  // Updated useReactTable configuration with TanStack pagination
  const table = useReactTable<Session>({
    data: sessions,
    columns,
    manualPagination: true, // enable manual pagination
    pageCount: totalPages,  // use totalPages from API response
    state: {
      pagination: {
        pageIndex: page - 1,  // TanStack page indexing is 0-based
        pageSize: 10,
      },
      sorting,
    },
    onPaginationChange: (updater) => {
      const newState =
        typeof updater === 'function'
          ? updater({ pageIndex: page - 1, pageSize: 25 })
          : updater;
      const newPage = newState.pageIndex + 1;
      const params = new URLSearchParams(location.search);
      params.set("page", newPage.toString());
      navigate(`?${params.toString()}`);
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  // Update the agentOptions memo
  const agentOptions = React.useMemo(() => {
    const filteredAgents = allAgents.filter(agent => 
      agent.type === TYPE_MAP[selectedTab as keyof typeof TYPE_MAP]
    );

    return [
      { value: 'all', label: `All ${selectedTab === 'sales' ? 'Sales' : 'Operator'} Agents` },
      ...filteredAgents.map(agent => ({
        value: agent.parent_agent_id,
        label: agent.name || agent.parent_agent_id
      }))
    ];
  }, [allAgents, selectedTab]);

  // Replace the uniqueLeadOptions memo with this
  const leadOptions = React.useMemo(() => {
    const options = [
      { value: 'all', label: 'All Leads' },
      ...(Array.isArray(allLeads) ? allLeads.map(lead => ({
        value: lead.id,
        label: lead.email
      })) : [])
    ];
    return options;
  }, [allLeads]);

  // Create a debounced search function
  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      const params = new URLSearchParams(location.search);
      if (searchValue) {
        params.set('search', searchValue);
      } else {
        params.delete('search');
      }
      params.set('page', '1'); // Reset to first page on search
      navigate(`?${params.toString()}`);
    }, 500), // 500ms delay
    [navigate]
  );

  // Update the date filter handler to use backend filtering
  const handleDateFilterChange = (value: string) => {
    const params = new URLSearchParams(location.search);
    
    if (value === 'all') {
      params.delete('date_filter');
      setDateRange(undefined);
    } else {
      params.set('date_filter', value);
      setDateRange(getDateRange(value));
    }
    
    setSelectedDateFilter(value);
    params.set('page', '1'); // Reset to first page when changing filter
    navigate(`?${params.toString()}`);
  };

  return (
    <div className={dashboard ? "" : "p-12 scrollbar-hidden h-full grow"}>
      {!dashboard ?
        <>
          <div className="flex flex-row justify-between items-end">
            <div className="flex flex-col">
              <h1 className='text-4xl font-bold flex items-center gap-2'>Sessions</h1>
              <p className='mt-4 text-sm text-slate-500'>Explore every conversation had between your customers and agents. Click on a session to view more details.</p>
            </div>
            <InteractiveHoverButton className="mr-12">
              Analyze Sessions with AI
            </InteractiveHoverButton>
          </div>
          <Separator className="my-4" />
        </>
        : (
          <></>
        )}
      <div className={`h-full mt-2 flex flex-row w-full bg-transparent`}>
        <div className={`${dashboard ? '' : 'pr-12'} w-full grow`}>
          <div className="flex flex-row mb-4 space-x-4">
            {/* Update search input */}
            <Input
              placeholder="Search leads and conversations..."
              className="w-full"
              value={searchInput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                setSearchInput(value);
                debouncedSearch(value);
              }}
            />

            {/* Session Started Date Filter using Select */}
            <Select
              value={selectedDateFilter}
              onValueChange={handleDateFilterChange}
            >
              <SelectTrigger className="flex items-center px-4 py-2 border rounded-lg">
                <SelectValue>
                  {selectedDateFilter === 'all'
                    ? 'All Dates'
                    : selectedDateFilter === 'today'
                      ? 'Today'
                      : selectedDateFilter === 'last3days'
                        ? 'Last 3 Days'
                        : selectedDateFilter === 'lastWeek'
                          ? 'Last Week'
                          : selectedDateFilter === 'lastMonth'
                            ? 'Last Month'
                            : selectedDateFilter === 'last90days'
                              ? 'Last 90 Days'
                              : selectedDateFilter === 'lastYear'
                                ? 'Last Year'
                                : selectedDateFilter}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Dates</SelectItem>
                <SelectItem value="today">Today</SelectItem>
                <SelectItem value="last3days">Last 3 Days</SelectItem>
                <SelectItem value="lastWeek">Last Week</SelectItem>
                <SelectItem value="lastMonth">Last Month</SelectItem>
                <SelectItem value="last90days">Last 90 Days</SelectItem>
                <SelectItem value="lastYear">Last Year</SelectItem>
              </SelectContent>
            </Select>

            {/* Agent Filter using Select */}
            <Select
              value={selectedAgent}
              onValueChange={(value) => {
                setSelectedAgent(value);
                const params = new URLSearchParams(location.search);
                
                if (value === 'all') {
                  // For 'all', we'll handle the filtering in getSessions
                  params.delete("agent_id");
                  params.delete("agent_ids");
                } else {
                  params.set("agent_id", value);
                }
                
                navigate(`?${params.toString()}`);
              }}
            >
              <SelectTrigger className="flex items-center px-4 py-2  border rounded-lg ">
                <SelectValue>
                  {selectedAgent === 'all'
                    ? 'All Agents'
                    : (agentOptions.find((o) => o.value === selectedAgent)?.label || selectedAgent)}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {agentOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {/* Lead Filter using Select */}
            <Select
              value={selectedLead}
              onValueChange={(value) => {
                console.log('Lead selection changed:', {
                  leadId, // log the prop
                  previousValue: selectedLead,
                  newValue: value
                });

                setSelectedLead(value);
                const params = new URLSearchParams(location.search);

                // Only modify lead_id param if we don't have a leadId prop
                if (!leadId) {
                  if (value === 'all') {
                    params.delete("lead_id");
                  } else {
                    params.set("lead_id", value);
                  }
                  navigate(`?${params.toString()}`);
                }
              }}
            >
              <SelectTrigger className="flex items-center px-4 py-2  border rounded-lg ">
                <SelectValue>
                  {selectedLead === 'all'
                    ? 'All Leads'
                    : (leadOptions.find((o) => o.value === selectedLead)?.label || selectedLead)}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {leadOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {/* Status Filter using Select */}
            <Select
              value={selectedStatus}
              onValueChange={(value) => {
                setSelectedStatus(value);
                const params = new URLSearchParams(location.search);
                if (value !== 'all') {
                  params.set("is_active", value === 'active' ? 'true' : 'false');
                } else {
                  params.delete("is_active");
                }
                navigate(`?${params.toString()}`);
              }}
            >
              <SelectTrigger className="flex items-center px-4 py-2 border rounded-lg">
                <SelectValue>
                  {selectedStatus === 'all'
                    ? 'All Status'
                    : selectedStatus.charAt(0).toUpperCase() + selectedStatus.slice(1)}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {[
                  { value: 'all', label: 'All Status' },
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' }
                ].map((status) => (
                  <SelectItem key={status.value} value={status.value}>
                    {status.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col mt-4 h-full">
            <div className="-m-1.5 overflow-x-auto scrollbar-hidden">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="border rounded-lg shadow overflow-hidden">
                  {loading ? (
                    <Table>
                      <TableHeader>
                        <TableRow>
                          {columns.map((col, index) => (
                            <TableHead key={index}>
                              {typeof col.header === 'function'
                                ? col.header({ column: {} as any, header: {} as any, table: {} as any })
                                : col.header}
                            </TableHead>
                          ))}
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {[1, 2].map((i) => (
                          <TableRow key={i} className="animate-pulse">
                            <TableCell className="px-6 py-4">
                              <div className="h-4 bg-muted rounded w-24"></div>
                            </TableCell>
                            <TableCell className="px-6 py-4">
                              <div className="h-4 bg-muted rounded w-32"></div>
                            </TableCell>
                            <TableCell className="px-6 py-4">
                              <div className="h-4 bg-muted rounded w-32"></div>
                            </TableCell>
                            <TableCell className="px-6 py-4">
                              <div className="h-4 bg-muted rounded w-32"></div>
                            </TableCell>
                            <TableCell className="px-6 py-4">
                              <div className="h-4 bg-muted rounded w-32"></div>
                            </TableCell>
                            <TableCell className="px-6 py-4">
                              <div className="h-4 bg-muted rounded w-32"></div>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <Table>
                      <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                          <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <TableHead key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                              </TableHead>
                            ))}
                          </TableRow>
                        ))}
                      </TableHeader>
                      <TableBody>
                        {table.getRowModel().rows?.length ? (
                          table.getRowModel().rows.map((row) => (
                            <TableRow
                              key={row.id}
                              className="cursor-pointer"
                              onClick={() => navigate(`/sessions/${row.original.id}`)}
                            >
                              {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={columns.length}
                              className="h-24 text-center"
                            >
                              No sessions.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                </div>
              </div>
            </div>
            {totalPages > 1 && (
              <div className="flex justify-between items-center mt-4">
                <Button
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  variant="outline"
                >
                  Previous
                </Button>
                <div className='flex flex-row items-center gap-4'>
                  <span className="text-sm font-semibold">
                    {totalPages > 1 ? `Page ${page} of ${totalPages}` : ''}
                  </span>
                  <Button
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                    variant="outline"
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}
            <ExportSessionsButton
              selectedAgent={selectedAgent}
              selectedLead={selectedLead}
              selectedStatus={selectedStatus}
              selectedDateFilter={selectedDateFilter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sessions;