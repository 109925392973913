import * as React from "react"
import { Bar, BarChart, CartesianGrid, XAxis } from "recharts"

import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"

interface ChartData {
    day: string;
    message_count: number;
    conversation_count: number;
}

const chartConfig = {
    views: {
        label: "Total",
    },
    messages: {
        label: "Messages",
        color: "hsl(var(--chart-1))"
    },
    conversations: {
        label: "Conversations",
        color: "hsl(var(--chart-2))"
    },
} satisfies ChartConfig

interface Props {
    data: ChartData[];
}

export function InteractiveBarChart({ data }: Props) {
    const [activeChart, setActiveChart] = 
        React.useState<"message_count" | "conversation_count">("message_count")

    const total = React.useMemo(
        () => ({
            message_count: data.reduce((acc, curr) => acc + curr.message_count, 0),
            conversation_count: data.reduce((acc, curr) => acc + curr.conversation_count, 0),
        }),
        [data]
    )

    const chartLabels = {
        message_count: "Messages",
        conversation_count: "Conversations"
    }

    return (
        <Card>
            <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
                <div className="flex">
                    {(["message_count", "conversation_count"] as const).map((key) => (
                        <button
                            key={key}
                            data-active={activeChart === key}
                            className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8 sm:py-6 border-r"
                            onClick={() => setActiveChart(key)}
                        >
                            <span className="text-xs text-muted-foreground">
                                {chartLabels[key]}
                            </span>
                            <span className="text-lg font-bold leading-none sm:text-3xl">
                                {total[key].toLocaleString()}
                            </span>
                        </button>
                    ))}
                </div>
                <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
                    <CardTitle>Activity Overview</CardTitle>
                    <CardDescription>
                        Messages and conversations over time
                    </CardDescription>
                </div>
            </CardHeader>
            <CardContent className="px-2 sm:p-6">
                <ChartContainer
                    config={chartConfig}
                    className="aspect-auto h-[250px] w-full"
                >
                    <BarChart
                        data={data}
                        margin={{
                            left: 12,
                            right: 12,
                        }}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis
                            dataKey="day"
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                            minTickGap={32}
                            tickFormatter={(value) => {
                                const date = new Date(value)
                                return date.toLocaleDateString("en-US", {
                                    month: "short",
                                    day: "numeric",
                                })
                            }}
                        />
                        <ChartTooltip
                            content={
                                <ChartTooltipContent
                                    className="w-[150px]"
                                    nameKey="views"
                                    labelFormatter={(value) => {
                                        return new Date(value).toLocaleDateString("en-US", {
                                            month: "short",
                                            day: "numeric",
                                            year: "numeric",
                                        })
                                    }}
                                />
                            }
                        />
                        <Bar 
                            dataKey={activeChart} 
                            fill={activeChart === "message_count" ? "var(--color-messages)" : "var(--color-conversations)"}
                            radius={[4, 4, 0, 0]}
                        />
                    </BarChart>
                </ChartContainer>
            </CardContent>
        </Card>
    )
} 