import { useState, useEffect } from 'react';
import useApi from '../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastConfig from '../config/Toast';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";

interface UserProfile {
  first_name: string;
  last_name: string;
  email: string;
  settings?: {
    interaction_notification?: boolean;
    new_lead_notification?: boolean;
    session_active_notification?: boolean;
    new_question_notification?: boolean;
  };
}

function UserProfile() {
  const [organizationName, setOrganizationName] = useState('');
  const { get, put } = useApi();
  const [interactNotification, setInteractNotification] = useState<boolean>(false);
  const [newLeadNotification, setNewLeadNotification] = useState<boolean>(false);
  const [sessionActiveNotification, setSessionActiveNotification] = useState<boolean>(false);
  const [newQuestionNotification, setNewQuestionNotification] = useState<boolean>(false);
  const [escalationNotification, setEscalationNotification] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [plan, setPlan] = useState<string>('');

  const handleSave = async (e: any) => {
    e.preventDefault();
    if (!organizationName) {
      toast.error('Organization name cannot be empty', toastConfig);
      return;
    }

    try {
      await put(`/users/me/organization`, {
        name: organizationName
      });

      await put(`/users/me`, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        settings: {
          interaction_notification: interactNotification,
          new_lead_notification: newLeadNotification,
          session_active_notification: sessionActiveNotification,
          new_question_notification: newQuestionNotification,
          escalation_notification: escalationNotification
        }
      });

      toast.success('Settings updated successfully', toastConfig);
    } catch (error: any) {
      console.error('Error updating settings:', error);
      toast.error('Failed to update settings', toastConfig);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await get(`/users/me`);
        setFirstName(response.data.first_name);
        setLastName(response.data.last_name);
        setEmail(response.data.email);
        const settings = response.data.settings || {};
        setInteractNotification(settings.interaction_notification || false);
        setNewLeadNotification(settings.new_lead_notification || false);
        setSessionActiveNotification(settings.session_active_notification || false);
        setNewQuestionNotification(settings.new_question_notification || false);
        setEscalationNotification(settings.escalation_notification || false);
      } catch (error: any) {
        console.error('Error fetching user:', error);
      }
    };

    const getOrganization = async () => {
      try {
        const response = await get(`/users/me/organization`);
        setOrganizationName(response.data.name);
        setPlan(response.data.license_type);
      } catch (error: any) {
        console.error('Error fetching organization:', error);
      }
    };

    getUser();
    getOrganization();
  }, []);

  return (
    <div className="flex flex-col p-8 space-y-6 w-full mx-auto">
      <form onSubmit={handleSave} className="w-full">
        <div className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>User Settings</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="firstName">First Name</Label>
                <Input
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="lastName">Last Name</Label>
                <Input
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Organization Settings</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="orgName">Organization Name</Label>
                <Input
                  id="orgName"
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label>Plan</Label>
                <Badge variant="secondary" className="ml-2 text-sm">
                  {plan}
                </Badge>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Notification Preferences</CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <Label>New Interaction Notification</Label>
                  <p className="text-sm text-muted-foreground">
                    Receive emails when a visitor interacts with the agent
                  </p>
                </div>
                <Switch
                  checked={interactNotification}
                  onCheckedChange={setInteractNotification}
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <Label>New Lead Notification</Label>
                  <p className="text-sm text-muted-foreground">
                    Receive emails when a visitor submits their email
                  </p>
                </div>
                <Switch
                  checked={newLeadNotification}
                  onCheckedChange={setNewLeadNotification}
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <Label>Session Active Notification</Label>
                  <p className="text-sm text-muted-foreground">
                    Receive emails when an existing session becomes active again
                  </p>
                </div>
                <Switch
                  checked={sessionActiveNotification}
                  onCheckedChange={setSessionActiveNotification}
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <Label>New Question Notification</Label>
                  <p className="text-sm text-muted-foreground">
                    Receive emails when the AI cannot answer a question
                  </p>
                </div>
                <Switch
                  checked={newQuestionNotification}
                  onCheckedChange={setNewQuestionNotification}
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <Label>Escalation Notification</Label>
                  <p className="text-sm text-muted-foreground">
                    Receive emails when a conversation is escalated
                  </p>
                </div>
                <Switch
                  checked={escalationNotification}
                  onCheckedChange={setEscalationNotification}
                />
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="fixed bottom-8">
          <Button type="submit">
            Save Changes
          </Button>
        </div>
      </form>
      <ToastContainer {...toastConfig} />
    </div>
  );
}

export default UserProfile;
