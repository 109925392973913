import { useEffect, useState, useRef } from "react";
import { useContext } from "react";
import { supabase } from './supabaseClient'
import config from '../config.json'

const useApi = () => {
    const [backendUrl] = useState(config.env === 'development' ? 'http://localhost:8000' : import.meta.env.VITE_API_URL as string);
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<any>(null);

    const tokenRef = useRef<string | null>(null);
    tokenRef.current = token;

    const generateHeaders = async (): Promise<Headers> => {

        const { data, error } = await supabase.auth.getSession()

        const accessToken = data?.session?.access_token

        const headers = new Headers({
            'Content-Type': 'application/json',
        });

        if (accessToken) {
            headers.append('Authorization', `Bearer ${accessToken}`);
        }

        return headers;
    };

    const handleResponse = async (response: any) => {
        if (!response.ok) {
            const error = await response.json();
            // Automatically log out on 401 status
            if (response.status === 401) {
                console.log("Unauthorized error occurred, logging out");
                supabase.auth.signOut();
            }
            // Reject promise with the error message
            return Promise.reject(error);
        }

        const data = await response.json();
        return { data };
    };

    const handleStreamedResponse = (response: any) => {
        if (!response.ok) {
            // Handle non-ok responses as before
            return response.json().then(Promise.reject.bind(Promise));
        }
    
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let content = '';
    
        // Read the stream
        const stream = new ReadableStream({
            async start(controller) {
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        break;
                    }
                    content += decoder.decode(value);
                    controller.enqueue(value);
                }
                controller.close();
                reader.releaseLock();
            }
        });
    
        // Directly return the stream for the consumer to read
        return {
            stream: stream,
            text: () => content, // Provide a way to get accumulated text if needed
        };
    };

    const get = async (url: string, options = {}) => {
        return fetch(`${backendUrl}${url}`, {
            method: 'GET',
            headers: await generateHeaders(),
            ...options,
        }).then(handleResponse);
    };

    const getStream = async (url: string, options = {}) => {
        return fetch(`${backendUrl}${url}`, {
            method: 'GET',
            headers: await generateHeaders(),
            ...options,
        }).then(handleStreamedResponse);
    };

    const post = async (url: string, data: any, options = {}) => {

        const headers = await generateHeaders();
        let body;

        if (data instanceof FormData) {
            // Handle multipart form data
            headers.delete('Content-Type'); // Let the browser set the content type automatically
            body = data;
        } else if (data instanceof URLSearchParams) {
            // Handle URL encoded form data
            headers.set('Content-Type', 'application/x-www-form-urlencoded');
            body = new URLSearchParams(data).toString();
        } else {
            body = JSON.stringify(data);
        }
        
        return fetch(`${backendUrl}${url}`, {
            method: 'POST',
            headers: headers,
            body: body,
            ...options,
        }).then(handleResponse);
    };

    const postStream = async (url: string, data: any, options = {}) => {
        return fetch(`${backendUrl}${url}`, {
            method: 'POST',
            headers: await generateHeaders(),
            body: JSON.stringify(data),
            ...options,
        }).then(handleStreamedResponse);
    };

    const put = async (url: string, data: any, options = {}) => {
        const headers = await generateHeaders();
        let body;

        if (data instanceof FormData) {
            // Handle multipart form data
            headers.delete('Content-Type'); // Let the browser set the content type automatically
            body = data;
        } else if (data instanceof URLSearchParams) {
            // Handle URL encoded form data
            headers.set('Content-Type', 'application/x-www-form-urlencoded');
            body = new URLSearchParams(data).toString();
        } else {
            body = JSON.stringify(data);
        }

        return fetch(`${backendUrl}${url}`, {
            method: 'PUT',
            headers: headers,
            body: body,
            ...options,
        }).then(handleResponse);
    };

    const patch = async (url: string, data: any, options = {}) => {
        const headers = await generateHeaders();
        let body;

        if (data instanceof FormData) {
            // Handle multipart form data
            headers.delete('Content-Type'); // Let the browser set the content type automatically
            body = data;
        } else if (data instanceof URLSearchParams) {
            // Handle URL encoded form data
            headers.set('Content-Type', 'application/x-www-form-urlencoded');
            body = new URLSearchParams(data).toString();
        } else {
            body = JSON.stringify(data);
        }

        return fetch(`${backendUrl}${url}`, {
            method: 'PATCH',
            headers: headers,
            body: body,
            ...options,
        }).then(handleResponse);
    };

    const remove = async (url: string, options = {}) => {
        return fetch(`${backendUrl}${url}`, {
            method: 'DELETE',
            headers: await generateHeaders(),
            ...options,
        }).then(handleResponse);
    };

    const connectWebSocket = async (url: string) => {
        const { data, error } = await supabase.auth.getSession();
        const accessToken = data?.session?.access_token;

        if (!accessToken) {
            console.error('No access token found');
            return;
        }

        let wsUrl = `${backendUrl}${url}`;

        if (wsUrl.startsWith('https://')) {
            wsUrl = wsUrl.replace('https://', 'wss://');
        } else if (wsUrl.startsWith('http://')) {
            wsUrl = wsUrl.replace('http://', 'ws://');
        } else {
            wsUrl = `ws://${wsUrl}`;
        }

        const separator = wsUrl.includes('?') ? '&' : '?';
        wsUrl = `${wsUrl}${separator}token=${encodeURIComponent(accessToken)}`;

        const socket = new WebSocket(wsUrl);

        socket.onopen = () => {
            console.log('WebSocket connection established');
        };

        socket.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        socket.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
        };

        return socket;
    };

    return {
        get,
        getStream,
        post,
        postStream,
        put,
        patch,
        remove,
        connectWebSocket,
    };
};

export default useApi;