import { useState, useEffect } from 'react'
import useApi from '../api';
import { useParams } from 'react-router-dom';
import { formatDate, addMinutes } from '../utils/dateFormatter';
import Chat from '../agents/Chat';
import SessionsCard from './SessionsCardAlt';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    TableHeader,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
} from '@/components/ui/table';

interface Lead {
    id: string;
    first_name: string;
    last_name: string;
    company: string;
    email: string;
    job_title: string;
    attributes: any;
    integrations: {
        salesforce: string;
        hubspot: string;
    };
    email_verified: boolean;
}

interface Message {
    id: string;
    role: string;
    content: string;
    created_at: string;
}

interface Conversation {
    id: string;
    created_at: string;
    last_activity: string;
    processed: boolean;
    processed_at: string;
    messages: Message[];
    interaction: boolean;
    visitor_id?: string;
    ip_address?: string;
    city: string;
    region: string;
    country: string;
    postal: string;
    status: string;
    lead?: Lead;
    form?: { [key: string]: string };
}

function formatAttributeName(key: string): string {
    if (key === 'aimdoc_parent_url') {
        return 'Source URL';
    }
    
    key = key.replace(/\?$/, '');
    
    let formatted = key.replace(/[_-]/g, ' ');
    
    formatted = formatted.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    
    return formatted;
}

export default function Session() {
    const { id } = useParams();
    const [modalVisible, setModalVisible] = useState(false);
    let [isOpen, setIsOpen] = useState(false);
    const { get } = useApi();
    const [session, setSession] = useState<Conversation | null>(null);
    const [message, setMessage] = useState<Message | null>(null);
    const [status, setStatus] = useState<string>('');
    const [leadId, setLeadId] = useState<string | null>(null);
    const [sessionId, setSessionId] = useState<string | null>(id || null);
    const navigate = useNavigate();
    function closeModal() {
        setModalVisible(false);
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    useEffect(() => {
        if (sessionId) {
            get(`/sessions/${sessionId}`).then((response) => {
                const newSession = response.data;
                setSession(newSession);
                if (newSession.status !== status) {
                    setStatus(newSession.status);
                }
            }).catch((error) => {
                console.error('Error fetching session:', error);
            });
        }
    }, [sessionId, leadId]);

useEffect(() => {
        if (id) {
            setSessionId(id);
        }
    }, [id]);

    useEffect(() => {
        if (message) {
            openModal();
        }
    }, [message]);

    function isLink(str: string): boolean {
        try {
            new URL(str);
            return true;
        } catch {
            return false;
        }
    }

    return (
        <>
            {session && (
     
                    <div className="flex w-full max-h-screen overflow-y-auto">
                        <SessionsCard 
                            setSessionId={setSessionId} 
                            selectedSessionId={sessionId} 
                            selectedSessionStatus={status}
                            setSelectedSessionStatus={setStatus}
                        />
                        <Chat chatId={session.id} status={status} setStatus={setStatus} leadId={leadId} setLeadId={setLeadId} />
                        
                        <div className="w-1/4 min-w-[300px] h-full border-l p-6 overflow-y-auto scrollbar-thin dark:bg-slate-900">
                            {status && (
                                <div className={`mb-4 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
                                    ${status === 'active' 
                                        ? 'bg-green-100 text-green-800 dark:bg-green-700 dark:text-green-200 animate-pulse' 
                                        : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'}`}>
                                    <span className="w-2 h-2 mr-2 rounded-full bg-current"></span>
                                    {status.charAt(0).toUpperCase() + status.slice(1)}
                                </div>
                            )}

                        {session.lead && (
                            <div className="mb-6">
                                <div onClick={() => navigate(`/leads/${session.lead?.id}`)} 
                                     className="flex items-center p-4 hover:cursor-pointer hover:shadow-md transition-all duration-200 border rounded-lg">
                                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-500 text-white font-medium">
                                        {(session.lead.first_name?.[0] || '') + (session.lead.last_name?.[0] || '')}
                                    </div>
                                    <div className="ml-4">
                                        <div className="text-lg font-medium dark:text-slate-200">
                                            {session.lead.first_name} {session.lead.last_name}
                                        </div>
                                        {session.lead.company && (
                                            <div className="text-sm text-slate-500 dark:text-slate-400">
                                                {session.lead.company}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {session.form && Object.keys(session.form).length > 0 && (
                            <div className="mb-8">
                                <div className="flex items-center mb-4">
                                    <h2 className="text-lg font-semibold dark:text-slate-100">
                                        Form Progress
                                    </h2>
                                    <div className="ml-auto px-2 py-1 bg-blue-100 text-blue-800 text-xs 
                                        rounded-full dark:bg-blue-900 dark:text-blue-200">
                                        {Object.keys(session.form).length} fields
                                    </div>
                                </div>
                                <Table className="w-full table-fixed">
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead className="w-1/3">Field</TableHead>
                                            <TableHead className="w-2/3">Value</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody className='scrollbar-hidden'>
                                        {Object.entries(session.form)
                                            .filter(([_, value]) => value != null && value !== '')
                                            .map(([key, value]) => (
                                                <TableRow key={key}>
                                                    <TableCell className="font-medium break-words">
                                                        {formatAttributeName(key)}
                                                    </TableCell>
                                                    <TableCell className="break-all">
                                                        {isLink(value) ? (
                                                            <a href={value} 
                                                                className="text-blue-500 hover:text-blue-600 
                                                                underline break-all" 
                                                                target="_blank" 
                                                                rel="noopener noreferrer"
                                                            >
                                                                {value}
                                                            </a>
                                                        ) : (
                                                            <span className="break-all">
                                                                {value}
                                                            </span>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </div>
                        )}

                        <div className="space-y-6">
                            <div>
                                <h2 className="text-lg font-semibold mb-4 dark:text-slate-100">Session Details</h2>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="font-medium">Started</TableCell>
                                            <TableCell>{formatDate(session.created_at)}</TableCell>
                                        </TableRow>
                                        {session.last_activity && (
                                            <TableRow>
                                                <TableCell className="font-medium">Last Active</TableCell>
                                                <TableCell>
                                                    {formatDate(session.last_activity)}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {session.processed && (
                                            <TableRow>
                                                <TableCell className="font-medium">Processed</TableCell>
                                                <TableCell>Yes</TableCell>
                                            </TableRow>
                                        )}
                                        {session.processed_at && (
                                            <TableRow>
                                                <TableCell className="font-medium">Last Processed</TableCell>
                                                <TableCell>{formatDate(session.processed_at)}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>

                            {session.ip_address && (
                                <div>
                                    <h3 className="text-md font-semibold mb-4 dark:text-slate-100">Location Information</h3>
                                    <Table>
                                        <TableBody>
                                            {session.city && (
                                                <TableRow>
                                                    <TableCell className="font-medium">City</TableCell>
                                                    <TableCell>{session.city}</TableCell>
                                                </TableRow>
                                            )}
                                            {session.region && (
                                                <TableRow>
                                                    <TableCell className="font-medium">Region</TableCell>
                                                    <TableCell>{session.region}</TableCell>
                                                </TableRow>
                                            )}
                                            {session.country && (
                                                <TableRow>
                                                    <TableCell className="font-medium">Country</TableCell>
                                                    <TableCell>{session.country}</TableCell>
                                                </TableRow>
                                            )}
                                            {session.postal && (
                                                <TableRow>
                                                    <TableCell className="font-medium">Postal Code</TableCell>
                                                    <TableCell>{session.postal}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
