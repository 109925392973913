import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useApi from '../api';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { formatDate } from '../utils/dateFormatter';
import RowDelete from '../ui/RowDelete';
import { FilterInterface } from '../types'
import useAnalytics from '../analytics/analytics';
import { DateRange } from "react-day-picker";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Button } from '@/components/ui/button';
import { ArrowUpDown } from 'lucide-react';

interface SessionsProps {
  agentId?: string;
  leadId?: string;
  dashboard?: boolean;
}

// Define the Session interface with the expected properties
interface Session {
  id: string;
  created_at: string;
  last_activity: string;
  agent_id?: string;
  lead?: {
    id: string;
    email: string;
  };
  status: string;
  // Add other properties as needed
}

// Add these new interfaces at the top with other interfaces
interface Agent {
  id: string;
  name?: string;
  persona?: string;
  goal?: string;
}

interface Lead {
  id: string;
  email: string;
  first_name?: string;
  last_name?: string;
  company?: string;
}

// Add this near the top with other interfaces
interface AgentType {
  type: string;
}

const initialFilters: FilterInterface[] = [
  {
    id: 'is_active',
    name: 'Active',
    options: [
      { value: 'true', label: 'Yes', checked: false },
      { value: 'false', label: 'No', checked: false },
    ],
  },
  {
    id: 'agent_id',
    name: 'Agent',
    options: [{ value: 'all', label: 'All Agents', checked: false }],
  },
  {
    id: 'lead_id',
    name: 'Lead',
    options: [{ value: 'all', label: 'All Leads', checked: false }],
  },
];

const Sessions: React.FC<SessionsProps> = ({ agentId, leadId, dashboard }) => {
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [page, setPage] = useState(1);
  const filtersRef = useRef<FilterInterface[]>(initialFilters);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedAgent, setSelectedAgent] = useState<string>('all');
  const [selectedLead, setSelectedLead] = useState<string>('all');
  const [dateRange, setDateRange] = useState<DateRange>();
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [selectedDateFilter, setSelectedDateFilter] = useState<string>('all');
  const navigate = useNavigate();
  const location = useLocation();
  const { get, remove } = useApi();
  const { captureEvent } = useAnalytics();
  const [allAgents, setAllAgents] = useState<Agent[]>([]);
  const [allLeads, setAllLeads] = useState<Lead[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [agentType, setAgentType] = useState<string | null>(null);

  const typeMap: { [key: string]: string } = {
    'operator': 'product',
    'sales': 'website'
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success(`${text} copied to clipboard`, toastConfig);
  }

  const getDateRange = (filter: string): DateRange | undefined => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    
    switch (filter) {
      case 'today': {
        return { from: today, to: today };
      }
      case 'last3days': {
        const start = new Date(today);
        start.setDate(start.getDate() - 2);
        return { from: start, to: today };
      }
      case 'lastWeek': {
        const start = new Date(today);
        start.setDate(start.getDate() - 6);
        return { from: start, to: today };
      }
      case 'lastMonth': {
        const start = new Date(today);
        start.setDate(start.getDate() - 29);
        return { from: start, to: today };
      }
      case 'last90days': {
        const start = new Date(today);
        start.setDate(start.getDate() - 89);
        return { from: start, to: today };
      }
      case 'lastYear': {
        const start = new Date(today);
        start.setDate(start.getDate() - 364);
        return { from: start, to: today };
      }
      default:
        return undefined;
    }
  };

  const filterSessionsByDate = (sessions: Session[], dateRange: DateRange | undefined) => {
    if (!dateRange?.from || !dateRange?.to) {
      return sessions;
    }

    const fromDate = new Date(dateRange.from);
    fromDate.setHours(0, 0, 0, 0);
    
    const toDate = new Date(dateRange.to);
    toDate.setHours(23, 59, 59, 999);

    return sessions.filter(session => {
      const sessionDate = new Date(session.created_at);
      return sessionDate >= fromDate && sessionDate <= toDate;
    });
  };

  const filterAgentsByType = (agents: Agent[]): Agent[] => {
    const userType = localStorage.getItem('userType');
    if (!userType) return agents;

    const filteredType = typeMap[userType];
    if (!filteredType) return agents;

    return agents.filter(agent => agent.persona === filteredType);
  };

  const fetchFilterOptions = async () => {
    try {
      // Fetch all unique agents
      const agentsResponse = await get('/agents/');
      console.log('Agents response:', agentsResponse);
      if (agentsResponse?.data && Array.isArray(agentsResponse.data)) {
        // Filter agents by type before setting them
        const filteredAgents = filterAgentsByType(agentsResponse.data);
        setAllAgents(filteredAgents.map(agent => ({
          id: agent.id,
          name: agent.name || agent.id
        })));
      } else {
        console.error('Unexpected agents response format:', agentsResponse);
        setAllAgents([]);
      }

      // Fetch all unique leads
      const leadsResponse = await get('/leads/');
      console.log('Leads response:', leadsResponse);
      if (leadsResponse?.data && Array.isArray(leadsResponse.data)) {
        setAllLeads(leadsResponse.data.map(lead => ({
          id: lead.id,
          email: lead.email
        })));
      } else {
        console.error('Unexpected leads response format:', leadsResponse);
        setAllLeads([]);
      }
    } catch (error) {
      console.error('Error fetching filter options:', error);
      setAllAgents([]);
      setAllLeads([]);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentPage = parseInt(params.get('page') || '1');
    const agentParam = params.get('agent_id') || 'all';
    const leadParam = params.get('lead_id') || 'all';
    const isActiveParam = params.get('is_active');
    const statusParam = isActiveParam === 'true' ? 'active' : 
                       isActiveParam === 'false' ? 'inactive' : 'all';
    const fromDate = params.get('from_date');
    const toDate = params.get('to_date');
    const dateFilterParam = params.get('date_filter') || 'all';

    setSelectedAgent(agentParam);
    setSelectedLead(leadParam);
    setSelectedStatus(statusParam);
    setSelectedDateFilter(dateFilterParam);
    
    if (fromDate && toDate) {
      setDateRange({
        from: new Date(fromDate),
        to: new Date(toDate)
      });
    } else if (dateFilterParam !== 'all') {
      const newRange = getDateRange(dateFilterParam);
      if (newRange) {
        setDateRange(newRange);
      }
    } else {
      setDateRange(undefined); // Reset date range when 'all' is selected
    }

    const currentFilters = initialFilters.map(filter => ({
      ...filter,
      options: filter.options.map(option => ({
        ...option,
        checked: params.get(filter.id) === option.value
      }))
    }));
    
    setPage(currentPage);
    filtersRef.current = currentFilters;
    getSessions(currentPage, '', currentFilters);
    fetchFilterOptions();
  }, [location.search]);

  const getSessions = async (page: number = 1, _search: string = '', filters: FilterInterface[] = []) => {
    try {
      setLoading(true);
      const params = new URLSearchParams({
        page: page.toString(),
        per_page: '12',
      });
      
      // Add base filters
      if (agentId) {
        params.append('agent_id', agentId);
      }
      if (leadId) {
        params.append('lead_id', leadId);
      }
      if (dashboard) {
        params.set('is_active', 'true');
      }

      // Add selected agent filter
      if (selectedAgent !== 'all') {
        params.set('agent_id', selectedAgent);
      }

      // Add selected lead filter
      if (selectedLead !== 'all') {
        params.set('lead_id', selectedLead);
      }

      // Update status filter to use is_active parameter
      if (selectedStatus !== 'all') {
        params.set('is_active', selectedStatus === 'active' ? 'true' : 'false');
      }

      // Add agent type filter based on user type
      const userType = localStorage.getItem('userType');
      if (userType && typeMap[userType]) {
        params.set('agent_type', typeMap[userType]);
      }

      // Add any additional filters
      filters.forEach(filter => {
        filter.options.forEach(option => {
          if (option.checked) {
            params.append(filter.id, option.value);
          }
        });
      });

      const data = await get(`/sessions/?${params.toString()}`);
      // Apply date filtering to the fetched sessions
      const filteredSessions = filterSessionsByDate(data.data.items, dateRange);
      setSessions(filteredSessions);
      setPage(data.data.page);
      setTotalPages(data.data.pages);
      captureEvent('viewed_sessions', {});
    } catch (error) {
      console.error('Error fetching sessions:', error);
    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await remove(`/sessions/${id}`);
      setSessions(sessions.filter((agent: any) => agent.id !== id));
      toast.success('Session deleted successfully', toastConfig);
      captureEvent('delete_session', {
        session_id: id,
      });
      console.log('Session deleted successfully', response.data);
    } catch (error) {
      console.error('Error deleting Session:', error);
    }
    setLoading(false);
  };

  const columns = React.useMemo<ColumnDef<Session, any>[]>(
    () => [
      {
        accessorKey: 'created_at',
        header: ({ column }: any) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="-ml-4"
          >
            Session Started
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div>
            {formatDate(row.getValue('created_at'))}
          </div>
        ),
      },
      {
        accessorKey: 'last_activity',
        header: ({ column }: any) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="-ml-4"
          >
            Last Active
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div>
            {formatDate(row.getValue('last_activity'))}
          </div>
        ),
      },
      {
        id: 'duration',
        accessorFn: (row) => {
          const start = new Date(row.created_at);
          const end = new Date(row.last_activity);
          return Math.floor((end.getTime() - start.getTime()) / 1000);
        },
        header: ({ column }: any) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="-ml-4"
          >
            Duration
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const session = row.original;
          const start = new Date(session.created_at);
          const end = new Date(session.last_activity);
          const diffInSeconds = Math.floor((end.getTime() - start.getTime()) / 1000);
          const minutes = Math.floor(diffInSeconds / 60);
          const seconds = diffInSeconds % 60;
          return (
            <div>
              {`${minutes}m ${seconds}s`}
            </div>
          );
        },
      },
      {
        id: 'lead',
        accessorFn: (row) => row.lead?.email || '',
        header: ({ column }: any) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="-ml-4"
          >
            Lead
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const session = row.original;
          return (
            <div>
              {session.lead ? (
                <Link to={`/leads/${session.lead.id}`} className="hover:underline">
                  <Badge variant="secondary" onClick={() => copyToClipboard(session?.lead?.email || '')}>
                    {session.lead.email}
                  </Badge>
                </Link>
              ) : (
                <Badge variant="outline">
                  No user-provided information
                </Badge>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: 'status',
        header: ({ column }: any) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="-ml-4"
          >
            Status
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const status = row.getValue('status');
          return (
            <div className="flex justify-start">
              {status === 'active' ? (
                <div className="flex items-center space-x-1 bg-green-100 border-green-200 border animate-pulse text-green-800 text-sm rounded-lg py-1 px-2 dark:bg-green-700 dark:border-green-600 dark:text-green-200">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                  </svg>
                  <span className="font-semibold">Active</span>
                </div>
              ) : (
                <div className="flex items-center space-x-1 bg-slate-100 border-slate-200 border text-slate-500 text-sm rounded-lg py-1 px-2 dark:bg-slate-800 dark:border-slate-600 dark:text-slate-400">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.412 15.655 9.75 21.75l3.745-4.012M9.257 13.5H3.75l2.659-2.849m2.048-2.194L14.25 2.25 12 10.5h8.25l-4.707 5.043M8.457 8.457 3 3m5.457 5.457 7.086 7.086m0 0L21 21" />
                  </svg>
                  <span className="font-semibold">Inactive</span>
                </div>
              )}
            </div>
          );
        },
      },
      {
        id: 'delete',
        header: 'Delete',
        cell: ({ row }) => (
          <div className="flex justify-start">
            <RowDelete deleteCallback={() => handleDelete(row.original.id)} />
          </div>
        ),
      },
    ],
    [handleDelete, formatDate]
  );

  // Updated useReactTable configuration with TanStack pagination
  const table = useReactTable<Session>({
    data: sessions,
    columns,
    manualPagination: true, // enable manual pagination
    pageCount: totalPages,  // use totalPages from API response
    state: {
      pagination: {
        pageIndex: page - 1,  // TanStack page indexing is 0-based
        pageSize: 10,
      },
      sorting,
    },
    onPaginationChange: (updater) => {
      const newState =
        typeof updater === 'function'
          ? updater({ pageIndex: page - 1, pageSize: 25 })
          : updater;
      const newPage = newState.pageIndex + 1;
      const params = new URLSearchParams(location.search);
      params.set("page", newPage.toString());
      navigate(`?${params.toString()}`);
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={`flex flex-row w-full bg-transparent`}>
      <div className={`w-full grow`}>
        <div className="flex flex-col h-full">
          <div className="-m-1.5 overflow-x-auto scrollbar-hidden">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="border rounded-lg shadow overflow-hidden dark:border-slate-900">
                {loading ? (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        {columns.map((col, index) => (
                          <TableHead key={index}>
                            {typeof col.header === 'function'
                              ? col.header({ column: {} as any, header: {} as any, table: {} as any })
                              : col.header}
                          </TableHead>
                        ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {[1, 2, 3].map((i) => (
                        <TableRow key={i} className="animate-pulse">
                          <TableCell className="px-6 py-4">
                            <div className="h-8 bg-slate-200 rounded w-24"></div>
                          </TableCell>
                          <TableCell className="px-6 py-4">
                            <div className="h-4 bg-slate-200 rounded w-32"></div>
                          </TableCell>
                          <TableCell className="px-6 py-4">
                            <div className="h-4 bg-slate-200 rounded w-32"></div>
                          </TableCell>
                          <TableCell className="px-6 py-4">
                            <div className="h-4 bg-slate-200 rounded w-32"></div>
                          </TableCell>
                          <TableCell className="px-6 py-4">
                            <div className="h-4 bg-slate-200 rounded w-32"></div>
                          </TableCell>
                          <TableCell className="px-6 py-4">
                            <div className="h-8 bg-slate-200 rounded w-12"></div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Table>
                    <TableHeader>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <TableHead key={header.id}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </TableHead>
                          ))}
                        </TableRow>
                      ))}
                    </TableHeader>
                    <TableBody>
                      {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                          <TableRow 
                            key={row.id}
                            className="cursor-pointer"
                            onClick={() => navigate(`/sessions/${row.original.id}`)}
                          >
                            {row.getVisibleCells().map((cell) => (
                              <TableCell key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={columns.length}
                            className="h-24 text-center"
                          >
                            No sessions.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </div>
            </div>
          </div>
          {totalPages > 1 && (
            <div className="flex justify-between items-center mt-4">
              <Button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                variant="outline"
              >
                Previous
              </Button>
              <span className="text-sm font-semibold">
                {totalPages > 1 ? `Page ${page} of ${totalPages}` : ''}
              </span>
              <Button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                variant="outline"
              >
                Next
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sessions;