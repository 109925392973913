import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../../api';
import { toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { conLog } from '../../utils/conLog';
import FilesList from './FilesList';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Card, CardContent } from "@/components/ui/card"
import { Button } from '@/components/ui/button';

export default function Files({ agentId }: { agentId: string }) {
    let [isOpen, setIsOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const { get, post, remove } = useApi();
    const [loading, setLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showFiles, setShowFiles] = useState(false);
    const [filesSelected, setFilesSelected] = useState(false);
    const navigate = useNavigate();
    const [selectedFileContent, setSelectedFileContent] = useState<string>('');
    const [isFileModalOpen, setIsFileModalOpen] = useState(false);
    const [selectedFileChunks, setSelectedFileChunks] = useState<any[]>([]);

    useEffect(() => {
        if (agentId) {
            get(`/files/?agent_id=${agentId}`).then((res) => {
                setFiles(res.data);
                setLoading(false);
            }).catch((error) => {
                console.error('Error fetching files:', error);
                setLoading(false);
            });
        }
    }, [agentId]);

    useEffect(() => {
        if (!showFiles && filesSelected && agentId) {  // Only fetch when modal closes with selection
            get(`/files/?agent_id=${agentId}`).then((res) => {
                setFiles(res.data);
                setLoading(false);
                setFilesSelected(false); // Reset the selection flag
            }).catch((error) => {
                console.error('Error fetching files:', error);
                setLoading(false);
            });
        }
    }, [showFiles]);

    function handleFileUpload(event: any) {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    }

    function handleIndexFile() {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('files', selectedFile);
            formData.append('type', 'file');

            setLoading(true);

            post(`/documents/file?agent_id=${agentId}`, formData)
                .then(response => {
                    conLog('File processing started:', response.data);
                    toast.success('File processing started', toastConfig);
                    setLoading(false);
                    setSelectedFile(null);
                    
                    get(`/files/?agent_id=${agentId}`).then((res) => {
                        setFiles(res.data);
                    }).catch((error) => {
                        console.error('Error fetching files:', error);
                    });
                })
                .catch(error => {
                    console.error('Error starting file processing:', error);
                    toast.error('Error starting file processing', toastConfig);
                    setLoading(false);
                });
        }
    }

    function deleteFileAssociation(fileId: string) {
        remove(`/agents/${agentId}/files/${fileId}`)
            .then((response) => {
                toast.success('File association deleted', toastConfig);
                setFiles((prevFiles) => prevFiles.filter((file: any) => file.id !== fileId));
            })
            .catch((error) => {
                console.error('Error deleting file association:', error);
                toast.error('Error deleting file association', toastConfig);
            });
    }

    function openFileModal(file: any) {
        setSelectedFileContent(file.file_metadata?.text || 'No content available');
        setSelectedFileChunks(file.file_metadata?.chunks || []);
        setIsFileModalOpen(true);
    }

    function closeFileModal() {
        setIsFileModalOpen(false);
        setSelectedFileContent('');
        setSelectedFileChunks([]);
    }

    function FileSkeleton() {
        return (
            <div className="flex flex-col w-full items-center p-4 rounded-md border shadow-sm">
                <div className="flex justify-end w-full">
                    <div className="h-5 w-5 rounded"></div>
                </div>
                <div className="w-full px-4 pb-5 pt-1">
                    <div className="flex flex-row justify-between w-full">
                        <div className="flex flex-row items-center space-x-3">
                            <div className="h-6 w-6 rounded"></div>
                            <div className="h-4 w-3/4 rounded"></div>
                        </div>
                        <div className="h-6 w-24 rounded"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-row w-full max-w-full pt-2">
                <div className="flex flex-col pr-6 pl-2 w-1/2 max-w-1/2">
                    <div className="p-4 border rounded-lg mt-4 items-center justify-center">
                        <input
                            type="file"
                            accept=".docx, .pdf"
                            onChange={handleFileUpload}
                            className="block w-full text-sm file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:hover:cursor-pointer transition duration-200"
                        />
                        {selectedFile && (
                            <div className="flex justify-center w-full mt-4">
                                <Button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md border px-4 py-2 text-sm font-medium transition duration-200"
                                    onClick={handleIndexFile}
                                >
                                    Train Agent
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-4 pr-2 pl-6 border-l grow w-1/2 max-w-1/2">
                    <div>
                        <Button
                            variant="secondary"
                            onClick={() => setShowFiles(true)}
                            className="w-full text-center rounded-md hover:cursor-pointer border px-4 py-2 text-sm font-medium"
                        >
                            Select Existing Files
                        </Button>
                        <div className="border-b mt-4 mb-2"></div>
                    </div>
                    <div className="flex flex-col pt-2 space-y-2">
                        {loading ? (
                            <div className="animate-pulse space-y-2">
                                <FileSkeleton />
                            </div>
                        ) : files.length > 0 ? (
                            files.map((file: any) => (
                                <div key={file.id} className="flex flex-col w-full items-center p-2 rounded-md border text-sm font-medium shadow-sm transition duration-200">
                                    <div className="flex justify-end w-full">
                                        <svg onClick={() => deleteFileAssociation(file.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-400 flex-shrink-0 hover:cursor-pointer hover:stroke-slate-600 transition duration-200 dark:stroke-slate-300">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                    <div 
                                        className="flex flex-row justify-between w-full px-4 pb-5 pt-1 cursor-pointer rounded-md transition-colors duration-200"
                                        onClick={() => openFileModal(file)}
                                    >
                                        <div className="flex flex-row items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-3 w-6 h-6 flex-shrink-0">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                            </svg>
                                            <span className="mr-6 break-all">
                                                {file.name.length > 50 ? file.name.substring(0, 50) + '...' : file.name}
                                            </span>
                                        </div>
                                        <div className="flex flex-row text-sm text-xs rounded-md px-2 py-1 items-center justify-end">
                                            <div>
                                                {`${file.characters} characters`}
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-4 h-4 stroke-green-500 flex-shrink-0">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="flex flex-col w-full items-center justify-between p-6 rounded-md border text-sm font-medium shadow-sm transition duration-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mb-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                </svg>
                                No trained files
                            </div>
                        )}
                        {showFiles && (
                            <FilesList
                                agentId={agentId}
                                setShowFiles={setShowFiles}
                                setFilesSelected={setFilesSelected}
                            />
                        )}
                    </div>
                </div>
            </div>

            <Dialog open={isFileModalOpen} onOpenChange={setIsFileModalOpen}>
                <DialogContent className="max-w-4xl">
                    <DialogHeader>
                        <DialogTitle>File Content</DialogTitle>
                    </DialogHeader>
                    
                    <div className="mt-2">
                        <ScrollArea className="h-[40vh] rounded-md border p-4">
                            <div className="text-sm">
                                {selectedFileContent}
                            </div>
                        </ScrollArea>
                    </div>

                    <div className="mt-6">
                        <h4 className="text-md font-medium mb-4">
                            Chunks ({selectedFileChunks.length})
                        </h4>
                        <ScrollArea className="h-[30vh]">
                            <div className="space-y-4">
                                {selectedFileChunks.map((chunk, index) => (
                                    <Card key={chunk.id}>
                                        <CardContent className="p-4">
                                            <div className="flex justify-between items-center mb-2">
                                                <span className="text-xs font-medium">
                                                    Chunk {index + 1} • {chunk.tokens} tokens
                                                </span>
                                                <span className="text-xs font-medium">
                                                    ID: {chunk.id.substring(0, 8)}...
                                                </span>
                                            </div>
                                            <div className="text-sm [white-space:pre-line]">
                                                {chunk.text}
                                            </div>
                                        </CardContent>
                                    </Card>
                                ))}
                            </div>
                        </ScrollArea>
                    </div>

                    <DialogFooter>
                        <Button onClick={() => setIsFileModalOpen(false)}>
                            Close
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
}