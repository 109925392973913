import { useState } from 'react'
import useApi from '../api'
import { toast } from 'react-toastify'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"

interface CalendlyProps {
    calendlyBookingUrl: string;
    createIntegration: any;
    modalVisible: boolean;
    setModalVisible: any;
}

export default function Calendly({ calendlyBookingUrl, createIntegration, modalVisible, setModalVisible }: CalendlyProps) {
    const [calendlyUrl, setCalendlyUrl] = useState(calendlyBookingUrl ? calendlyBookingUrl : '');
    const { get, getStream, post, postStream, put, patch, remove } = useApi();

    function validateCalendlyUrl(url: string): { isValid: boolean; error?: string } {
        const trimmedUrl = url.trim();
        
        const calendlyRegex = /^https:\/\/calendly\.com\/[\w-]+(?:\/[\w-]+)?$/;
        if (!calendlyRegex.test(trimmedUrl)) {
            return { 
                isValid: false, 
                error: 'Please enter a valid Calendly URL (e.g., https://calendly.com/username/30min)'
            };
        }

        if (!trimmedUrl.split('/')[4]) {
            return { 
                isValid: false, 
                error: 'Please include a specific meeting type in your URL (e.g., https://calendly.com/username/30min)'
            };
        }

        return { isValid: true };
    }

    function createCalendlyIntegration() {
        const validation = validateCalendlyUrl(calendlyUrl);
        if (!validation.isValid) {
            toast.error(validation.error);
            return;
        }

        createIntegration({
            calendlyUrl: calendlyUrl.trim(),
            appName: 'calendly'
        });
    }

    return (
        <Dialog open={modalVisible} onOpenChange={setModalVisible}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Connect Calendly</DialogTitle>
                    <DialogDescription>
                        Enter your booking URL for your desired meeting type.
                    </DialogDescription>
                </DialogHeader>

                <div className="grid gap-4 py-4">
                    <Input
                        value={calendlyUrl}
                        onChange={(e) => setCalendlyUrl(e.target.value)}
                        placeholder="https://calendly.com/username/30min"
                        className={!validateCalendlyUrl(calendlyUrl).isValid && calendlyUrl ? 'border-red-500' : ''}
                    />
                </div>

                <DialogFooter>
                    <Button
                        variant="outline"
                        onClick={() => setModalVisible(false)}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={createCalendlyIntegration}
                    >
                        Connect
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
