import { Fieldset, Field } from '@headlessui/react'
import { Input } from '@/components/ui/input'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useApi from '../../api';
import { toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import useAnalytics from '../../analytics/analytics';
import WebsitesList from './WebsitesList';
import { useUser } from '../../user/UserContext';
import { Button } from '@/components/ui/button';
import { SquareMousePointer, WandSparkles } from 'lucide-react';
import { Progress } from "@/components/ui/progress"

function WebsiteSkeleton() {
    return (
        <div className="flex flex-col w-full items-center justify-between p-1 rounded-md border shadow-sm">
            <div className="flex justify-end w-full p-2">
                <div className="h-5 w-5 rounded"></div>
            </div>
            <div className="w-full px-4 mb-2">
                <div className="flex flex-row justify-between w-full pb-2">
                    <div className="flex items-center w-full space-x-3">
                        <div className="h-6 w-6 rounded"></div>
                        <div className="h-4 w-3/4 rounded"></div>
                    </div>
                    <div className="h-6 w-24 rounded"></div>
                </div>
                <div className="h-2 w-full rounded-full mt-2"></div>
            </div>
        </div>
    );
}

export default function Website({
    agentId,
    indexedPages,
    setIndexedPages,
    selectedPages,
    setSelectedPages,
    website,
    setWebsite,
    agentName
}: {
    agentId: string | undefined,
    indexedPages: any,
    setIndexedPages: any,
    selectedPages: any,
    setSelectedPages: any,
    website: any,
    setWebsite: any,
    agentName: string
}) {
    let [isOpen, setIsOpen] = useState(false)
    const [pages, setPages] = useState(indexedPages ? indexedPages.map((page: any) => page.url) : []);
    const { get, post, remove } = useApi();
    const [loading, setLoading] = useState(true);
    const [websites, setWebsites] = useState<any[]>([]);
    const [scraping, setScraping] = useState<boolean>(false);
    const [showWebsites, setShowWebsites] = useState<boolean>(false);
    const [websiteSelected, setWebsiteSelected] = useState<any>();
    const { captureEvent } = useAnalytics();
    const { checkLicenseType } = useUser();
    const navigate = useNavigate();
    const [gettingPages, setGettingPages] = useState<boolean>(false);
    const [crawling, setCrawling] = useState<boolean>(false);

    const handleCheckboxChange = (url: string) => {
        setSelectedPages((prevSelected: any) => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(url)) {
                newSelected.delete(url);
            } else {
                newSelected.add(url);
            }
            return newSelected;
        });
    };

    const scrapeWebsite = () => {
        setGettingPages(true);
        setPages([]);
        setSelectedPages(new Set());
        try {
            const url = new URL(website);
            post('/get-website-urls/', { url: website }).then((res) => {
                setIsOpen(true);
                setPages(res.data.urls);
                setWebsite(res.data.base_url);
                setGettingPages(false);
                toast.success('Pages retrieved successfully', toastConfig);
                captureEvent('get_website_urls', {
                    website: website,
                    urls: res.data.urls,
                });
            }).catch((error) => {
                console.error('Error scraping website:', error);
                toast.error('Error getting pages.', toastConfig);
                setGettingPages(false);
            });
        } catch (e) {
            toast.error('Invalid website URL', toastConfig);
            setGettingPages(false);
        }
    };

    const crawlWebsite = () => {
        if (!checkLicenseType('standard')) {
            toast.error('Upgrade to the Standard plan to crawl your website.', toastConfig);
            return;
        }
        setCrawling(true);
        setPages([]);
        try {
            const url = new URL(website);
            post('/crawl-website/', { website: website, agent_id: agentId }).then((res) => {
                toast.success('Crawling in progress', toastConfig);
                setCrawling(false);
                captureEvent('crawl_website', {
                    website: website,
                    agent_id: agentId,
                });
                return res.data;
            }).catch((error) => {
                console.error('Error crawling website:', error);
                toast.error('Error getting pages.', toastConfig);
                setCrawling(false);
            });
        } catch (e) {
            toast.error('Invalid website URL', toastConfig);
            setCrawling(false);
        }
    };

    const indexPages = () => {
        if (selectedPages.size === 0) {
            toast.error('No pages selected for indexing', toastConfig);
            return;
        }
        setScraping(true);
        const selectedUrls = Array.from(selectedPages);
        post('/index-pages/', { website, urls: selectedUrls, agent_id: agentId }).then((res) => {
            setIndexedPages(res.data.pages);
            setScraping(false);
            setIsOpen(false);
            setWebsites((prevWebsites: any) => [
                ...prevWebsites,
                {
                    id: res.data.id,
                    url: website,
                    index_status: 'In Progress',
                    page_count: 0
                }
            ]);
            toast.success('Your website is indexing! Check the websites module for progress.', toastConfig);
            captureEvent('indexing_pages', {
                website: website,
                urls: selectedUrls,
            });
        }).catch((error) => {
            console.error('Error indexing pages:', error);
            toast.error(error.detail, toastConfig);
            setScraping(false);
        });
    };

    const clear = () => {
        setSelectedPages(new Set());
    };

    const selectAllPages = () => {
        setSelectedPages(new Set(pages));
    };

    useEffect(() => {
        if (indexedPages && indexedPages.length > 0) {
            setSelectedPages(new Set(indexedPages.map((page: any) => page.url)));
        }
    }, [indexedPages]);

    useEffect(() => {
        if (agentId) {
            get(`/websites/?agent_id=${agentId}`).then((res) => {
                setWebsites(res.data);
                setLoading(false);
            }).catch((error) => {
                console.error('Error fetching websites:', error);
                setLoading(false);
            });
        }
    }, [agentId]);

    useEffect(() => {
        if (!showWebsites && websiteSelected && agentId) {
            get(`/websites/?agent_id=${agentId}`).then((res) => {
                setWebsites(res.data);
                setLoading(false);
                setWebsiteSelected(undefined);
            }).catch((error) => {
                console.error('Error fetching websites:', error);
                setLoading(false);
            });
        }
    }, [showWebsites]);

    function deleteWebsiteAssociation(websiteId: string) {
        remove(`/agents/${agentId}/websites/${websiteId}`)
            .then((response) => {
                toast.success('Website association deleted', toastConfig);
                setWebsites((prevWebsites) => prevWebsites.filter((website: any) => website.id !== websiteId));
                return response.data;
            })
            .catch((error) => {
                console.error('Error deleting website association:', error);
                toast.error('Error deleting website association', toastConfig);
            });
    }

    return (
        <>
            <div className="flex flex-row w-full pt-2 max-w-full">
                <Fieldset className="flex flex-col space-y-6 rounded-xl pr-6 pl-2 w-1/2 max-w-1/2">
                    {scraping && (
                        <div>
                            <span className={`text-sm font-light leading-6 ${scraping ? 'animate-pulse' : ''}`}>If your website has a lot of content, this may take up to 2 minutes</span>
                        </div>
                    )}
                    <Field>
                        <div className="flex flex-col w-full max-w-full">
                            <Input
                                placeholder="https://aimdoc.ai"
                                className="w-full mt-4"
                                onChange={(e) => setWebsite(e.target.value)}
                                value={website}
                            />
                            <div className="flex flex-row w-full justify-between space-x-4 mt-3">
                                <div className="flex-1 flex flex-col justify-between">
                                    <div className="text-xs font-light leading-6">
                                        Manually select pages to train on.
                                    </div>
                                    <Button
                                        disabled={gettingPages || crawling}
                                        variant="secondary"
                                        className={`mt-4 w-full text-center rounded-md hover:cursor-pointer border px-4 py-2 text-sm font-medium flex items-center justify-center`}
                                        onClick={() => scrapeWebsite()}
                                    >
                                        <SquareMousePointer className="w-4 h-4" />
                                        {gettingPages ? (
                                            <div className="inline-flex items-center">
                                                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-slate-600 dark:text-slate-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                Getting Pages...
                                            </div>
                                        ) : (
                                            'Fetch Pages'
                                        )}
                                    </Button>
                                </div>
                                {checkLicenseType('pro') && (
                                    <div>
                                        <div className="flex border-l"></div>

                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className="text-xs font-light leading-6 flex ">
                                                Automatically crawl and train agent.
                                            </div>
                                            <Button
                                                disabled={gettingPages || crawling}
                                                variant="secondary"
                                                className={`mt-4 w-full text-center rounded-md hover:cursor-pointer border px-4 py-2 text-sm font-medium flex items-center justify-center`}
                                                onClick={() => crawlWebsite()}
                                            >
                                                <WandSparkles className="w-4 h-4" />
                                                {crawling ? (
                                                    <div className="inline-flex items-center">
                                                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-slate-600 dark:text-slate-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        Crawling...
                                                    </div>
                                                ) : (
                                                    'Crawl Site'
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Field>
                    {pages && pages.length > 0 && isOpen && (
                        <div className="space-y-2 p-4 border shadow-sm rounded-lg w-full max-w-full">
                            <label className="block text-md font-medium leading-6">
                                Select Pages
                            </label>
                            <label className="block text-sm font-light leading-6">
                                It is recommended to only select pages aligned with your agent's goal and assigned persona.
                            </label>
                            <div className="flex flex-row justify-between items-center mb-4 pt-2 pb-2">
                                <div className="space-x-2">
                                    <Button
                                        variant="secondary"
                                        onClick={() => selectAllPages()}
                                    >
                                        Select All Pages
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={() => clear()}
                                    >
                                        Reset
                                    </Button>
                                </div>
                                <div className="text-sm text-muted-foreground">
                                    {pages.length} Pages Found
                                </div>
                            </div>
                            <div className="space-y-6 px-2 pb-4 pt-2 overflow-y-auto max-h-80">
                                {pages.map((page: any) => (
                                    <div key={page} className="relative flex items-start">
                                        <div className="flex items-center h-5">
                                            <Input
                                                id={page}
                                                name={page}
                                                type="checkbox"
                                                checked={selectedPages.has(page)}
                                                onChange={() => handleCheckboxChange(page)}
                                            />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label htmlFor={page} className="font-medium hover:cursor-pointer break-all">
                                                {page}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-center w-full">
                                <Button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md border"
                                    onClick={() => indexPages()}
                                >
                                    Train Agent
                                </Button>
                            </div>
                        </div>
                    )}
                </Fieldset>
                <div className="flex flex-col mt-4 pr-2 pl-6 border-l w-1/2 max-w-1/2">
                    <div>
                        <Button
                            onClick={() => setShowWebsites(true)}
                            variant="secondary"
                            className="w-full text-center rounded-md hover:cursor-pointer border px-4 py-2 text-sm font-medium"
                        >
                            Select Existing Site
                        </Button>
                        <div className="border-b mt-4 mb-2"></div>
                    </div>
                    <div className="flex flex-col pt-2 space-y-2 w-full max-w-full">
                        {loading ? (
                            <div className="animate-pulse space-y-2">
                                <WebsiteSkeleton />
                            </div>
                        ) : websites.length > 0 ? (
                            websites.map((website: any) => (
                                <div className="flex flex-col w-full items-center justify-between p-2 rounded-md border text-sm font-medium">
                                    <div className="flex justify-end w-full">
                                        <svg onClick={() => deleteWebsiteAssociation(website.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-400 flex-shrink-0 hover:cursor-pointer hover:stroke-slate-600 transition duration-200 dark:stroke-slate-300">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                    <div className="w-full px-4 mb-2">
                                        <div className="flex flex-row justify-between w-full max-w-full pb-2">
                                            <div onClick={() => navigate(`/websites/${website.id}`)} className="flex hover:cursor-pointer items-center w-full max-w-full">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${website.index_status === 'In Progress' ? 'animate-pulse' : ''} mr-3 w-6 h-6 stroke-slate-400 flex-shrink-0 dark:stroke-slate-300`}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                                </svg>
                                                <div key={website.id} className={`text-sm ${website.index_status === 'In Progress' ? 'animate-pulse' : ''} mr-6 dark:text-slate-200 break-all`}>
                                                    {website.url}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="flex-grow h-2 rounded-full overflow-hidden w-full">
                                            <Progress 
                                                value={(website.progress ? website.progress : 0) * 100}
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="flex w-full flex-row justify-between">
                                        <div className="text-sm text-start mt-2">
                                            <span className={`${website.index_status === 'Completed' ? 'text-green-500' : 'text-blue-500'}`}>
                                                {website.page_count}
                                            </span>
                                            <span className="text-muted-foreground">
                                                {` pages indexed`}
                                            </span>
                                        </div>
                                        <div className="text-sm text-end mt-2">
                                            <span className="text-muted-foreground">
                                                {`${Math.round((website.progress ? website.progress : 0) * 100)}%`}
                                            </span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="flex flex-col w-full items-center justify-between p-6 rounded-md border text-sm font-medium">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mb-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                </svg>
                                No trained websites
                            </div>
                        )}
                        {showWebsites && (
                            <WebsitesList
                                agentId={agentId}
                                setShowWebsites={setShowWebsites}
                                setWebsitesSelected={setWebsiteSelected}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
