import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';

interface LinkProps {
    addLink: any;
    editLink: any;
    setModalVisible: any;
    link: any;
}

export default function CreateLink({ addLink, editLink, setModalVisible, link }: LinkProps) {
    const [linkName, setLinkName] = useState(link ? link.name : '');
    const [linkUrl, setLinkUrl] = useState(link ? link.url : '');
    const [linkDescription, setLinkDescription] = useState(link ? link.description : '');

    function closeModal() {
        setModalVisible(false);
    }

    function addNewLink() {
        const added = addLink(linkName, linkDescription, linkUrl);
        if (added) {
            closeModal();
        }
    }

    function updateLink() {
        editLink(link.id, linkName, linkDescription, linkUrl);
        closeModal();
    }

    return (
        <div className="space-y-4">
            <div className="space-y-2">
                <Label>Link Text</Label>
                <Textarea
                    value={linkName}
                    onChange={(e) => setLinkName(e.target.value)}
                    placeholder="Enter link text"
                />
            </div>

            <div className="space-y-2">
                <Label>Link Description</Label>
                <Textarea
                    value={linkDescription}
                    onChange={(e) => setLinkDescription(e.target.value)}
                    placeholder="Enter link description"
                />
            </div>

            <div className="space-y-2">
                <Label>Link URL</Label>
                <Textarea
                    value={linkUrl}
                    onChange={(e) => setLinkUrl(e.target.value)}
                    placeholder="Enter URL"
                />
            </div>

            <div className="flex justify-end">
                <Button
                    onClick={link ? updateLink : addNewLink}
                >
                    {link ? 'Save Link' : 'Create Link'}
                </Button>
            </div>
        </div>
    );
}
