import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { X } from 'lucide-react';
import { cn } from "@/lib/utils";
import useApi from '../../api';
import { ScrollArea } from "@/components/ui/scroll-area";
import { SalesforceFieldSelect } from "./SalesforceFieldSelect";
import { SalesforceDefaultField } from "./SalesforceDefaultField";

interface SalesforceField {
    name: string;
    label: string;
    type: string;
    description: string;
    required: boolean;
    picklistValues?: Array<{ value: string; active: boolean }>;
}

interface RequiredField {
    name: string;
    label: string;
}

interface SystemField {
    name: string;
    label: string;
    description: string;
}

interface SalesforceFieldsModalProps {
    isOpen: boolean;
    closeModal: () => void;
    formFields: any[];
    onSave: (fieldMappings: Record<string, string>, defaultFields: Record<string, string>) => void;
    existingMappings?: Record<string, string>;
    defaultFields?: Record<string, string>;
    integrationId: string;
}

const SYSTEM_FIELDS: SystemField[] = [
    { name: 'aimdoc_parent_url', label: 'Source URL', description: 'URL where the form was submitted' },
    { name: 'city', label: 'City', description: 'Visitor city' },
    { name: 'region', label: 'Region', description: 'Visitor region/state' },
    { name: 'country', label: 'Country', description: 'Visitor country' }
];

export default function SalesforceFieldsModal({
    isOpen,
    closeModal,
    formFields,
    onSave,
    existingMappings = {},
    defaultFields = {},
    integrationId
}: SalesforceFieldsModalProps) {
    const { get } = useApi();
    const [fieldMappings, setFieldMappings] = useState<Record<string, string>>(existingMappings);
    const [selectedField, setSelectedField] = useState<string | null>(null);
    const [salesforceFields, setSalesforceFields] = useState<SalesforceField[]>([]);
    const [requiredFields, setRequiredFields] = useState<RequiredField[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [defaultFieldMappings, setDefaultFieldMappings] = useState<Record<string, string>>(defaultFields);

    useEffect(() => {
        if (isOpen) {
            setFieldMappings(existingMappings);
            setDefaultFieldMappings(defaultFields);
            fetchSalesforceFields();
        }
    }, [isOpen, integrationId, existingMappings, defaultFields]);

    const fetchSalesforceFields = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await get(`/integrations/${integrationId}/contact-fields`);
            if (response.data.status === 'success') {
                setSalesforceFields(response.data.fields);
                setRequiredFields(response.data.required_fields);
            } else {
                setError('Failed to load Salesforce fields');
            }
        } catch (err) {
            setError('Failed to load Salesforce fields');
            console.error('Error fetching Salesforce fields:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFieldSelect = (fieldName: string) => {
        setSelectedField(fieldName);
    };

    const handleSalesforceFieldSelect = (salesforceFieldName: string) => {
        if (selectedField) {
            setFieldMappings(prev => ({
                ...prev,
                [selectedField]: salesforceFieldName
            }));
            setSelectedField(null);
        }
    };

    const handleClearMapping = (fieldName: string, e: React.MouseEvent) => {
        e.stopPropagation();
        const newMappings = { ...fieldMappings };
        delete newMappings[fieldName];
        setFieldMappings(newMappings);
    };

    const handleCancel = () => {
        setFieldMappings(existingMappings);
        setSelectedField(null);
        closeModal();
    };

    const handleSaveAll = () => {
        if (Object.keys(fieldMappings).length === 0) {
            return;
        }
        onSave(fieldMappings, defaultFieldMappings);
        closeModal();
    };

    const getSalesforceFieldLabel = (fieldName: string) => {
        const field = salesforceFields.find(f => f.name === fieldName);
        return field ? field.label : fieldName;
    };

    const isEditing = Object.keys(existingMappings).length > 0;

    const handleDefaultFieldUpdate = (salesforceField: string, value: string) => {
        setDefaultFieldMappings(prev => {
            const newMappings = { ...prev };
            delete newMappings[""];
            newMappings[salesforceField] = value;
            return newMappings;
        });
    };

    const handleRemoveDefaultField = (salesforceField: string) => {
        const newMappings = { ...defaultFieldMappings };
        delete newMappings[salesforceField];
        setDefaultFieldMappings(newMappings);
    };

    const handleAddDefaultField = () => {
        setDefaultFieldMappings(prev => ({
            ...prev,
            "": ""
        }));
    };

    const checkRequiredFieldsMapped = () => {
        const mappedFields = new Set([
            ...Object.values(fieldMappings),
            ...Object.keys(defaultFieldMappings)
        ]);
        return requiredFields.every(field => mappedFields.has(field.name));
    };

    const getSystemFieldLabel = (fieldName: string) => {
        const field = SYSTEM_FIELDS.find(f => f.name === fieldName);
        return field?.label || fieldName;
    };

    if (error) {
        return (
            <Dialog open={isOpen} onOpenChange={closeModal}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Error</DialogTitle>
                    </DialogHeader>
                    <div className="text-red-500">{error}</div>
                    <DialogFooter>
                        <Button onClick={closeModal}>Close</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Dialog open={isOpen} onOpenChange={closeModal}>
            <DialogContent className="max-w-2xl h-[90vh] flex flex-col overflow-hidden">
                <DialogHeader>
                    <DialogTitle>Configure Salesforce Lead Sync</DialogTitle>
                    <DialogDescription>
                        Map your form fields to Salesforce lead fields. Required fields must be mapped.
                    </DialogDescription>
                </DialogHeader>

                <ScrollArea className="flex-1 -mx-6 px-6">
                    {isLoading ? (
                        <div className="py-8 text-center">Loading Salesforce fields...</div>
                    ) : (
                        <div className="space-y-6 py-6">
                            <div className="border-b pb-4">
                                <h3 className="text-sm font-medium mb-1">Required Fields</h3>
                                <p className="text-sm text-muted-foreground mb-3">
                                    Required fields must be mapped or have a default value
                                </p>
                                <div className="grid grid-cols-2 gap-2">
                                    {requiredFields.map((field) => {
                                        const mappedFormField = Object.entries(fieldMappings)
                                            .find(([_, sfField]) => sfField === field.name)?.[0];
                                        const hasDefaultValue = field.name in defaultFieldMappings;
                                        
                                        return (
                                            <div 
                                                key={field.name}
                                                className={cn(
                                                    "p-2 rounded-lg border flex items-center justify-between",
                                                    (!mappedFormField && !hasDefaultValue) && "border-destructive"
                                                )}
                                            >
                                                <span className="font-medium">{field.label}</span>
                                                {(mappedFormField || hasDefaultValue) && (
                                                    <span className="text-sm text-muted-foreground ml-2">
                                                        {mappedFormField ? '✓ Mapped' : '✓ Default'}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="space-y-4">
                                <h3 className="text-sm font-medium mb-2">Form Fields</h3>
                                {formFields.map((field) => (
                                    <div
                                        key={field.name}
                                        className={cn(
                                            'p-4 rounded-lg border cursor-pointer transition-all duration-200',
                                            selectedField === field.name
                                                ? 'border-primary bg-primary/5'
                                                : 'hover:border-primary/50'
                                        )}
                                        onClick={() => handleFieldSelect(field.name)}
                                    >
                                        <div className="flex items-center justify-between">
                                            <div>
                                                <h3 className="text-md font-medium">
                                                    {field.label}
                                                </h3>
                                                {fieldMappings[field.name] && (
                                                    <div className="flex items-center gap-2">
                                                        <p className="text-sm text-muted-foreground mt-1">
                                                            Mapped to: {getSalesforceFieldLabel(fieldMappings[field.name])}
                                                        </p>
                                                        <Button
                                                            variant="ghost"
                                                            size="icon"
                                                            onClick={(e) => handleClearMapping(field.name, e)}
                                                            className="h-4 w-4 text-destructive hover:text-destructive/90"
                                                        >
                                                            <X className="h-4 w-4" />
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {selectedField && selectedField === field.name && (
                                            <div className="mt-6">
                                                <label className="block text-sm font-medium mb-2">
                                                    Select Salesforce Field
                                                </label>
                                                <SalesforceFieldSelect
                                                    fields={salesforceFields}
                                                    value={fieldMappings[selectedField]}
                                                    onSelect={handleSalesforceFieldSelect}
                                                    placeholder="Select a Salesforce field"
                                                    simpleDisplay={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            <div className="border-t pt-6">
                                <h3 className="text-sm font-medium mb-2">System Fields</h3>
                                <p className="text-sm text-muted-foreground mb-4">
                                    Map automatically collected system data to Salesforce fields.
                                </p>
                                <div className="space-y-4">
                                    {SYSTEM_FIELDS.map((field) => (
                                        <div
                                            key={field.name}
                                            className={cn(
                                                'p-4 rounded-lg border cursor-pointer transition-all duration-200',
                                                selectedField === field.name
                                                    ? 'border-primary bg-primary/5'
                                                    : 'hover:border-primary/50'
                                            )}
                                            onClick={() => handleFieldSelect(field.name)}
                                        >
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <h3 className="text-md font-medium">
                                                        {field.label}
                                                    </h3>
                                                    <p className="text-sm text-muted-foreground">
                                                        {field.description}
                                                    </p>
                                                    {fieldMappings[field.name] && (
                                                        <div className="flex items-center gap-2 mt-2">
                                                            <p className="text-sm text-muted-foreground">
                                                                Mapped to: {getSalesforceFieldLabel(fieldMappings[field.name])}
                                                            </p>
                                                            <Button
                                                                variant="ghost"
                                                                size="icon"
                                                                onClick={(e) => handleClearMapping(field.name, e)}
                                                                className="h-4 w-4 text-destructive hover:text-destructive/90"
                                                            >
                                                                <X className="h-4 w-4" />
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {selectedField && selectedField === field.name && (
                                            <div className="mt-6">
                                                <label className="block text-sm font-medium mb-2">
                                                    Select Salesforce Field
                                                </label>
                                                <SalesforceFieldSelect
                                                    fields={salesforceFields}
                                                    value={fieldMappings[selectedField]}
                                                    onSelect={handleSalesforceFieldSelect}
                                                    placeholder="Select a Salesforce field"
                                                    simpleDisplay={true}
                                                />
                                            </div>
                                        )}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="mt-6">
                                <h3 className="text-sm font-medium mb-2">Default Fields</h3>
                                <p className="text-sm text-muted-foreground mb-4">
                                    Add fields that should always be sent to Salesforce with specific values.
                                </p>
                                <div className="space-y-2">
                                    {Object.entries(defaultFieldMappings).map(([salesforceField, value]) => (
                                        <SalesforceDefaultField
                                            key={salesforceField || 'new'}
                                            salesforceField={salesforceField}
                                            value={value}
                                            onUpdate={handleDefaultFieldUpdate}
                                            onRemove={() => handleRemoveDefaultField(salesforceField)}
                                            availableFields={salesforceFields}
                                        />
                                    ))}
                                </div>
                                <Button
                                    variant="outline"
                                    className="mt-2"
                                    onClick={handleAddDefaultField}
                                >
                                    Add Default Field
                                </Button>
                            </div>
                        </div>
                    )}
                </ScrollArea>

                <DialogFooter className="flex-shrink-0 mt-4">
                    <Button variant="outline" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveAll}
                        disabled={!checkRequiredFieldsMapped()}
                    >
                        {isEditing ? 'Save Mappings' : 'Create Mappings'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
} 