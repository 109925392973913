import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Button } from "@/components/ui/button"

export default function GenericDropdown({ value, selectValues, setValue, defaultValue } : { value: string | undefined, selectValues: string[], setValue: any, defaultValue: string}) {
  const currentValue = value ? value : defaultValue;

  return (
    <div className="w-72 mt-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="w-full justify-between">
            <span className="block truncate">
              {currentValue}
            </span>
            <ChevronUpDownIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="w-72">
          {selectValues.map((val, idx) => (
            <DropdownMenuItem
              key={idx}
              className="cursor-pointer flex items-center"
              onClick={() => setValue(val)}
            >
              {val === currentValue && (
                <CheckIcon className="h-5 w-5 mr-2" aria-hidden="true" />
              )}
              <span className={val === currentValue ? 'font-medium' : 'font-normal'}>
                {val}
              </span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
