import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useApi from '../api';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import RowDelete from '../ui/RowDelete';
import useAnalytics from '../analytics/analytics';
import BeatLoader from '../ui/BeatLoader';
import { formatDate, addMinutes } from '../utils/dateFormatter';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { Badge } from "@/components/ui/badge";
import { SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Select } from '@/components/ui/select';

interface SessionsCardProps {
    setSessionId: (id: string) => void;
    selectedSessionId: string | null;
    selectedSessionStatus: string;
    setSelectedSessionStatus: (status: string) => void;
}

const SessionsCard: React.FC<SessionsCardProps> = ({ setSessionId, selectedSessionId, selectedSessionStatus, setSelectedSessionStatus }) => {
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState<any[]>([]);
    const [searchInput, setSearchInput] = useState('');
    const [page, setPage] = useState(1);
    const [timeFilter, setTimeFilter] = useState('last_90_days');
    const [agentType, setAgentType] = useState<string | null>(null);

    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const { get, remove } = useApi();
    const { captureEvent } = useAnalytics();

    const typeMap: { [key: string]: string } = {
        'operator': 'product',
        'sales': 'website'
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const currentPage = parseInt(params.get('page') || '1');
        const currentSearch = params.get('search') || '';
        setPage(currentPage);
        setSearchInput(currentSearch);
        getSessions(currentPage, currentSearch);
    }, [location.search, timeFilter]);

    const filterSessionsByTime = (sessions: any[], timeFilter: string) => {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        return sessions.filter((session) => {
            const sessionDate = new Date(session.created_at);

            switch (timeFilter) {
                case 'today':
                    return sessionDate >= today;
                case 'yesterday':
                    return sessionDate >= yesterday && sessionDate < today;
                case 'last_3_days':
                    const threeDaysAgo = new Date(today);
                    threeDaysAgo.setDate(today.getDate() - 3);
                    return sessionDate >= threeDaysAgo;
                case 'this_week':
                    const weekAgo = new Date(today);
                    weekAgo.setDate(today.getDate() - 7);
                    return sessionDate >= weekAgo;
                case 'this_month':
                    const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
                    return sessionDate >= monthStart;
                case 'last_90_days':
                    const ninetyDaysAgo = new Date(today);
                    ninetyDaysAgo.setDate(today.getDate() - 90);
                    return sessionDate >= ninetyDaysAgo;
                case 'last_year':
                    const yearAgo = new Date(today);
                    yearAgo.setFullYear(today.getFullYear() - 1);
                    return sessionDate >= yearAgo;
                default:
                    return true;
            }
        });
    };

    const getSessions = async (page: number = 1, search: string = '') => {
        try {
            setLoading(true);
            const params = new URLSearchParams({
                page: page.toString(),
                per_page: '25',
                search,
            });

            const selectedTab = localStorage.getItem('selectedTab');
            if (selectedTab && typeMap[selectedTab]) {
                params.set('agent_type', typeMap[selectedTab]);
            }

            const data = await get(`/sessions/?${params.toString()}`);
            const filteredSessions = filterSessionsByTime(data.data.items, timeFilter);
            setSessions(filteredSessions);
            setPage(page);
            setTotalPages(Math.ceil(filteredSessions.length / 25));
            captureEvent('viewed_sessions', {});
        } catch (error) {
            console.error('Error fetching sessions:', error);
        }
        setLoading(false);
    };

    const handleDelete = async (id: string, index: number) => {
        try {
            const response = await remove(`/sessions/${id}`);

            const nextSession = sessions[index + 1] || sessions[index - 1];
            if (nextSession) {
                setSessionId(nextSession.id);
                navigate(`/sessions/${nextSession.id}`);
            } else {
                setSessionId('');
                navigate('/sessions');
            }

            setSessions(sessions.filter((agent: any) => agent.id !== id));
            setSelectedSessionStatus('');
            toast.success('Session deleted successfully', toastConfig);
            captureEvent('delete_session', {
                session_id: id,
            });
            return response.data;
        } catch (error) {
            console.error('Error deleting Session:', error);
        }
        setLoading(false);
    };


    const handleSearch = (search: string) => {
        const params = new URLSearchParams({ search, page: '1' });
        navigate(`?${params.toString()}`);
    };

    useEffect(() => {
        if (selectedSessionId && selectedSessionStatus) {
            setSessions(prevSessions =>
                prevSessions.map(session =>
                    session.id === selectedSessionId && session.status !== selectedSessionStatus
                        ? { ...session, status: selectedSessionStatus }
                        : session
                )
            );
        }
    }, [selectedSessionId, selectedSessionStatus]);

    const getInitial = (email: string) => {
        return email ? email[0].toUpperCase() : '?';
    };

    return (
        <div className={`pt-4 flex flex-row w-1/4 border-r max-h-screen overflow-y-auto scrollbar-hidden`}>
            <div className={`w-full grow`}>
                <div className='mx-4 rounded-md flex flex-row'>
                    <div className="relative flex-col gap-4 space-y-2 w-full">
                        <Input
                            type="text"
                            id="search"
                            placeholder="Search sessions by lead..."
                            autoComplete='off'
                            value={searchInput}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchInput(e.target.value)}
                            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') {
                                    handleSearch(e.currentTarget.value);
                                }
                            }}
                        />
                        <Select
                            value={timeFilter}
                            onValueChange={(value) => {
                                setTimeFilter(value);
                                const params = new URLSearchParams(location.search);
                                params.set('time_filter', value);
                                params.set('page', '1');
                                navigate(`?${params.toString()}`);
                            }}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Filter by time" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="today">Today</SelectItem>
                                <SelectItem value="yesterday">Yesterday</SelectItem>
                                <SelectItem value="last_3_days">Last 3 days</SelectItem>
                                <SelectItem value="this_week">This past week</SelectItem>
                                <SelectItem value="this_month">This month</SelectItem>
                                <SelectItem value="last_90_days">Last 90 days</SelectItem>
                                <SelectItem value="last_year">Last year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <Separator className="mt-4" />
                <div className="h-full overflow-y-auto scrollbar-hidden pb-12">
                    <Table>
                        <TableBody>
                            {sessions.length > 0 && !loading &&
                                sessions.map((session: any, index: number) => (
                                    <TableRow
                                        key={session.id}
                                        className={`cursor-pointer ${selectedSessionId === session.id ? 'bg-primary/5' : ''
                                            }`}
                                        onClick={() => {
                                            setSessionId(session.id);
                                            setSelectedSessionStatus(session.status);
                                            navigate(`/sessions/${session.id}`, { replace: true });
                                        }}
                                    >
                                        <TableCell>
                                            <div className="flex items-center justify-between">
                                                <div className="flex flex-col space-y-3">
                                                    <div className="flex flex-row items-center space-x-2">
                                                    {session.status === 'active' && (
                                                            <div className="flex items-center">
                                                                <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                                                                <div className="absolute">
                                                                    <div className="w-4 h-4 -ml-1 bg-green-500 rounded-full animate-ping opacity-75"></div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {session.lead && (
                                                            <div className="w-8 h-8 rounded-full bg-blue-500 dark:bg-blue-900 flex items-center justify-center">
                                                                <span className="text-sm font-medium text-white dark:text-blue-300">
                                                                    {getInitial(session.lead.email)}
                                                                </span>
                                                            </div>
                                                        )}
                                                        <span className="text-sm text-slate-700 dark:text-slate-300">
                                                            {session.lead ? (session.lead.name || session.lead.email) : "Unidentified Visitor"}
                                                        </span>
                                                    </div>
                                                    <div className="text-xs text-slate-400 dark:text-slate-500">
                                                        {formatDate(session.created_at, 'short')}
                                                    </div>
                                                </div>

                                                <div className="flex flex-col space-y-3 items-center">

                                                    <div className="flex justify-end w-full" onClick={(e) => e.stopPropagation()}>
                                                        <RowDelete deleteCallback={() => handleDelete(session.id, index)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>

                    {sessions.length === 0 && !loading && (
                        <div className="text-center py-16 text-slate-500 dark:text-slate-400">
                            No sessions
                        </div>
                    )}

                    {loading && (
                        <div className="text-center py-16">
                            <BeatLoader />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SessionsCard;