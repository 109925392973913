import * as React from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { HubspotFieldSelect } from "./HubspotFieldSelect";
import { X } from "lucide-react";

interface HubspotDefaultFieldProps {
    hubspotField: string;
    value: string;
    onUpdate: (hubspotField: string, value: string) => void;
    onRemove: () => void;
    availableFields: any[];
}

export function HubspotDefaultField({
    hubspotField,
    value,
    onUpdate,
    onRemove,
    availableFields
}: HubspotDefaultFieldProps) {
    return (
        <div className="flex items-center gap-2">
            <div className="flex-1">
                <HubspotFieldSelect
                    fields={availableFields}
                    value={hubspotField}
                    onSelect={(field) => onUpdate(field, value)}
                    placeholder="Select HubSpot field"
                    simpleDisplay
                />
            </div>
            <Input
                value={value}
                onChange={(e) => onUpdate(hubspotField, e.target.value)}
                placeholder="Default value"
                className="flex-1"
            />
            <Button
                variant="ghost"
                size="icon"
                onClick={onRemove}
                className="h-8 w-8 text-destructive hover:text-destructive/90"
            >
                <X className="h-4 w-4" />
            </Button>
        </div>
    );
} 