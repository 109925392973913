import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from './api';
import AgentFromAI from './AgentFromAI';
import { useUser } from './user/UserContext';
import UsageAnalytics from './analytics/UsageAnalytics';
import { AimdocComponentContext, AimdocTaskDefinition, startTask } from '@aimdoc/sdk-react';
import { Separator } from '../../components/ui/separator';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { Button } from '../../components/ui/button';
import { PlusCircle, BarChart2, Upload, Library, ArrowRight, Bot, Coffee } from 'lucide-react';
// import WeeklyLeads from './analytics/WeeklyLeads';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import DashboardAnalytics from './analytics/DashboardAnalytics';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs';

interface SessionResponse {
  items: Array<{
    status: string;
    [key: string]: any;
  }>;
  total: number;
  page: number;
  per_page: number;
  pages: number;
  total_sessions: number;
}

const Dashboard = () => {
    const navigate = useNavigate();
    const { get, put } = useApi();
    const { user, organization, refresh } = useUser();
    const [agents, setAgents] = useState<[] | undefined>(undefined);
    const [activeSessions, setActiveSessions] = useState<SessionResponse | null>(null);
    const [initWalkthroughDone, setInitWalkthroughDone] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                getAgents(),
                getActiveSessions()
            ]);
        };
        fetchData();
        startTask('setup-assistance');
    }, []);

    useEffect(() => {
        if (agents?.length === 0 && user && (initWalkthroughDone || user?.walkthrough_complete)) {
            openModal();
        }
    }, [agents, user, initWalkthroughDone]);


    const getAgents = async () => {
        try {
            const response = await get('/agents/');
            setAgents(response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching agents:', error);
            setError('Failed to load agents. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const getActiveSessions = async () =>{
        try {
            const response = await get('/sessions/?is_active=true');
            setActiveSessions(response.data);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch active sessions');
            setError('Could not fetch active sessions');
        }
    }

    useEffect(() => {
        const updateInvitedUser = async () => {
            if (user && user.was_invited && !user.walkthrough_complete) {
                try {
                    await put(`/users/${user.id}/walkthrough-complete/`, {});
                    refresh();
                } catch (error) {
                    console.error('Error updating walkthrough status:', error);
                }
            }
        };

        updateInvitedUser();
    }, [user]);

    return (
        <div className="p-12 overflow-y-auto max-h-[calc(100vh-1px)] scrollbar-thin grow">
            <AimdocComponentContext
                componentDescription="You are currently on Aimdoc's Dashboard. This gives an overview of new leads, usage and active sessions."
                componentName="Dashboard"
                path="/"
            />
            <AimdocTaskDefinition
                id="create-agent"
                name="Create Agent"
                description="Create a new agent for the user."
                steps={[
                    "Click the 'Create New Agent' button in the 'What would you like to do today?' section.",
                    "Is the user provided you details, fill them in. If not, ask for the details.",
                    "Fill our the type of agent you want to create.",
                    "If the user wants to create a sales agent, fill out the 'Goal' and 'Persona' fields. Use the information they provide or fill it out based on your best judgement.",
                    'Once you have filled the details, click the "Create" button to create the agent.',
                ]}
            />
            {user && user.walkthrough_complete !== true && user.was_invited !== true && <AgentFromAI setComplete={setInitWalkthroughDone} user={user} />}
            
            <div className="space-y-6">
                <div className="flex items-start justify-between">
                    <div className='flex flex-col'>
                        <h1 className='text-4xl font-bold flex items-center gap-2'>Welcome back! 👋</h1>
                        <p className='mt-4 text-sm text-slate-500'>Embed an AI-powered sales agent into your app to convert your users and decrease time to value.</p>
                    </div>
                    {(activeSessions?.total_sessions ?? 0) > 0 ? 
                    <div>
                        <Button variant="ghost" className="flex items-center gap-4" onClick={() => navigate('/sessions?is_active=true')}>
                            <div className="relative">
                                <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                                <div className="absolute -inset-1">
                                    <div className="w-5 h-5 bg-green-500 rounded-full animate-ping opacity-75"></div>
                                </div>
                            </div>
                            {activeSessions?.total_sessions ?? 0} Active Sessions
                        </Button>
                    </div> : null}
                </div>

                <Separator className="my-8" />
                
                <UsageAnalytics organization={organization} />

                <Tabs defaultValue={agents && agents.length > 0 ? "overview" : "get-started"} className="space-y-6">
                    <TabsList>
                        <TabsTrigger value="overview"><BarChart2 className='w-4 h-4 mr-2' /> Overview</TabsTrigger>
                        <TabsTrigger value="get-started"><Coffee className='w-4 h-4 mr-2' /> Quickstart</TabsTrigger>
                    </TabsList>

                    <TabsContent value="get-started" className="space-y-6">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                            <div className="space-y-4">
                                <h2 className="text-2xl font-bold tracking-tight">
                                    What would you like to do today?
                                </h2>
                                <div className="grid gap-5">
                                    {[
                                        {
                                            icon: PlusCircle,
                                            title: "Create New Agent",
                                            description: "Set up a new AI agent for your documentation",
                                            path: "/agents/new",
                                            shortcut: "⌘J"
                                        },
                                        {
                                            icon: BarChart2,
                                            title: "View Analytics", 
                                            description: "Check your usage and performance metrics",
                                            path: "/analytics",
                                            shortcut: "⌘I"
                                        },
                                        {
                                            icon: Bot,
                                            title: "Customize & Manage Agents",
                                            description: "View and configure your existing agents",
                                            path: "/agents",
                                            shortcut: "⌘U"
                                        },
                                        {
                                            icon: Upload,
                                            title: "Update Knowledge Base",
                                            description: "Add documentation to train your agents",
                                            path: "/knowledge",
                                            shortcut: "⌘K"
                                        },
                                        {
                                            icon: Library,
                                            title: "View SDK Documentation",
                                            description: "View your Aimdoc SDK documentation",
                                            path: "https://docs.aimdoc.ai",
                                            shortcut: "⌘ ."
                                        }
                                    ].map(({ icon: Icon, title, description, path, shortcut }) => (
                                        <Button
                                            key={title}
                                            variant="outline"
                                            className="w-full h-16 justify-between px-4"
                                            onClick={() => navigate(path)}
                                        >
                                            <div className="flex items-center">
                                                <Icon className="mr-4 h-5 w-5" />
                                                <div className="flex flex-col items-start">
                                                    <span className="font-semibold">{title}</span>
                                                    <span className="text-sm text-muted-foreground">{description}</span>
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <span className="text-sm text-muted-foreground">{shortcut}</span>
                                                <ArrowRight className="h-5 w-5" />
                                            </div>
                                        </Button>
                                    ))}
                                </div>
                            </div>

                            <div className="space-y-4">
                                <h2 className="text-2xl font-bold tracking-tight">
                                    Learn More About Aimdoc
                                </h2>
                                <div className="h-[400px] border rounded-lg p-4"> {/* Adjusted height to match left column */}
                                    <iframe
                                        className="w-full h-full rounded-lg"
                                        src="https://www.youtube.com/embed/AysMVprRg9I?si=Tpr0Mf4IdmYFyqqX"
                                        title="Aimdoc Introduction"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </TabsContent>
                    <TabsContent value="overview">
                        <DashboardAnalytics />
                    </TabsContent>
                </Tabs>

                {error && (
                    <Alert variant="destructive">
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}
            </div>

            {/* Walkthrough Modal */}
            {/* <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Setup Assistance
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            It looks like you haven't created any agents yet. Would you like to create your first agent?
                                        </p>
                                    </div>
                                    <div className="mt-4 flex justify-end gap-4">
                                        <Button variant="outline" onClick={closeModal}>
                                            Maybe Later
                                        </Button>
                                        <Button variant="default" onClick={() => { closeModal(); navigate('/agents/new'); }}>
                                            Create Agent
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition> */}
        </div>
    );
};

export default Dashboard;