import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import config from '../../config.json';

declare global {
  interface Window {
    Agent: any;
    __AGENT_INITIALIZED?: boolean;
  }
}

const AgentPreview = () => {
  const { agentId, websiteUrl } = useParams();
  const location = useLocation();
  const [iframeError, setIframeError] = useState(false);

  const getQueryParam = (param: string) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  const finalWebsiteUrl = websiteUrl || getQueryParam('url');

  useEffect(() => {
    // Remove any existing wrapper elements
    const existingWrapper = document.querySelector('.wrapper-transition');
    if (existingWrapper) {
      existingWrapper.remove();
    }

    // Remove any existing agent scripts
    const existingAgentScripts = document.querySelectorAll('script[src*="embedded.bundle.js"]');
    existingAgentScripts.forEach(script => script.remove());

    // Reset agent container and initialization flag
    if (window.Agent) {
      const container = document.getElementById('ai-assistant');
      if (container) {
        container.innerHTML = '';
      }
      window.__AGENT_INITIALIZED = false;
    }

    // Create and add our agent script
    let script = document.getElementById('ai-agent-script') as HTMLScriptElement | null;
    if (!script) {
      script = document.createElement('script');
      script.id = 'ai-agent-script';
      script.async = true;
      script.src =
        config.env === 'production'
          ? 'https://app.aimdoc.ai/embedded.bundle.js'
          : '/embedded.bundle.js';

      script.onload = () => {
        if (
          window.Agent &&
          typeof window.Agent.initAgentChat === 'function' &&
          !window.__AGENT_INITIALIZED
        ) {
          window.Agent.initAgentChat('ai-assistant', { agentId });
          window.__AGENT_INITIALIZED = true;
        }
      };

      document.body.appendChild(script);
    } else {
      if (
        window.Agent &&
        typeof window.Agent.initAgentChat === 'function' &&
        !window.__AGENT_INITIALIZED
      ) {
        window.Agent.initAgentChat('ai-assistant', { agentId });
        window.__AGENT_INITIALIZED = true;
      }
    }

    return () => {
      // Cleanup function
      const container = document.getElementById('ai-assistant');
      if (container) {
        container.innerHTML = '';
      }
      const wrapper = document.querySelector('.wrapper-transition');
      if (wrapper) {
        wrapper.remove();
      }
      // Remove our script on unmount
      const agentScript = document.getElementById('ai-agent-script');
      if (agentScript) {
        agentScript.remove();
      }
      window.__AGENT_INITIALIZED = false;
    };
  }, [agentId, location.search, websiteUrl]);

  const decodedWebsiteUrl = decodeURIComponent(finalWebsiteUrl || '');

  const handleIframeError = () => {
    setIframeError(true);
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        background: 'transparent',
      }}
    >
      {finalWebsiteUrl ? (
        iframeError ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              textAlign: 'center',
              backgroundColor: '#f8f9fa',
              color: '#333',
              padding: '20px',
              borderRadius: '10px',
            }}
          >
            <h1 style={{ fontSize: '2rem', marginBottom: '10px', color: '#dc3545' }}>
              Preview Unavailable
            </h1>
            <p style={{ fontSize: '1.2rem', marginBottom: '20px' }}>
              The website you're trying to preview has security settings that prevent it
              from being loaded in an iframe.
            </p>
            <p style={{ fontSize: '1rem', color: '#6c757d' }}>Please visit the website directly at:</p>
            <a
              href={decodedWebsiteUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: '1.1rem',
                color: '#007bff',
                textDecoration: 'none',
                marginTop: '10px',
              }}
            >
              {decodedWebsiteUrl}
            </a>
          </div>
        ) : (
          <>
            {/* Iframe for the target website */}
            <iframe
              id="target-site"
              src={decodedWebsiteUrl}
              title="Target Website"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
                zIndex: 1,
                backgroundColor: 'transparent',
              }}
              allowTransparency={true}
              onError={handleIframeError}
            />
            <div
              id="ai-assistant"
              style={{
                position: 'fixed',
                bottom: '1px',
                right: '1px',
                width: '400px',
                height: '100px',
                zIndex: 1000000000,
                borderRadius: '10px',
                overflow: 'hidden',
                backgroundColor: 'transparent',
              }}
            ></div>
          </>
        )
      ) : (
        <div
          id="ai-assistant"
          style={{
            position: 'fixed',
            bottom: '1px',
            right: '1px',
            width: '400px',
            height: '100px',
            zIndex: 1000000000,
            borderRadius: '10px',
            overflow: 'hidden',
            backgroundColor: 'transparent',
          }}
        ></div>
      )}
    </div>
  );
};

export default AgentPreview;