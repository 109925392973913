import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useApi from '../api';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { formatDate } from '../utils/dateFormatter';
import RowDelete from '../ui/RowDelete';
import { FilterInterface } from '../types';
import useAnalytics from '../analytics/analytics';
import CreateQuestion from './CreateQuestion';
import { Separator } from '@/components/ui/separator';
import { PlusCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Badge } from '@/components/ui/badge';
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from '@/components/ui/tooltip';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/components/ui/table';
import { useReactTable, getCoreRowModel, getSortedRowModel, flexRender } from '@tanstack/react-table';
import { Select, SelectItem, SelectContent, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Drawer, DrawerContent } from '@/components/ui/drawer';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import QuestionView from './QuestionView';
import { Question } from '../types';

interface QuestionsProps {
    dashboard?: boolean;
}

const initialFilters: FilterInterface[] = [
    {
        id: 'status',
        name: 'Status',
        options: [
            { value: 'answered', label: 'Answered', checked: false },
            { value: 'unanswered', label: 'Unanswered', checked: false },
        ],
    },
];

const Questions: React.FC<QuestionsProps> = ({ dashboard = false }) => {
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [searchInput, setSearchInput] = useState('');
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState(initialFilters);
    const filtersRef = useRef<FilterInterface[]>(initialFilters);
    const [totalPages, setTotalPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [statusFilter, setStatusFilter] = useState('');
    const [sorting, setSorting] = useState<any[]>([]);
    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'answered', label: 'Answered' },
        { value: 'unanswered', label: 'Unanswered' },
    ];
    const navigate = useNavigate();
    const location = useLocation();
    const { get, remove } = useApi();
    const { captureEvent } = useAnalytics();
    const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const currentPage = parseInt(params.get('page') || '1');
        const currentSearch = params.get('search') || '';
        const currentFilters = initialFilters.map((filter) => ({
            ...filter,
            options: filter.options.map((option) => ({
                ...option,
                checked: params.get(filter.id) === option.value,
            })),
        }));
        setPage(currentPage);
        setSearchInput(currentSearch);
        setFilters(currentFilters);
        filtersRef.current = currentFilters;
        setStatusFilter(params.get('status') || '');
        getQuestions(currentPage, currentSearch, currentFilters);
    }, [location.search]);

    useEffect(() => {
        console.log('State changed:', {
            selectedQuestionId,
            drawerOpen,
            selectedQuestion: selectedQuestion?.id
        });
    }, [selectedQuestionId, drawerOpen, selectedQuestion]);

    const getQuestions = async (
        page: number = 1,
        search: string = '',
        filters: FilterInterface[] = []
    ) => {
        try {
            setLoading(true);
            const params = new URLSearchParams({
                page: page.toString(),
                per_page: '25',
                search,
            });
            filters.forEach((filter) => {
                filter.options.forEach((option) => {
                    if (option.checked) {
                        params.append(filter.id, option.value);
                    }
                });
            });
            const data = await get(`/questions/?${params.toString()}`);
            setQuestions(data.data.items);
            setPage(data.data.page);
            setTotalPages(data.data.pages);
            captureEvent('viewed_questions', {});
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
        setLoading(false);
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            const params = new URLSearchParams(location.search);
            params.set('page', (page + 1).toString());
            filtersRef.current.forEach((filter) => {
                const selectedOption = filter.options.find((option) => option.checked);
                if (selectedOption) {
                    params.set(filter.id, selectedOption.value);
                }
            });
            navigate(`?${params.toString()}`);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            const params = new URLSearchParams(location.search);
            params.set('page', (page - 1).toString());
            filtersRef.current.forEach((filter) => {
                const selectedOption = filter.options.find((option) => option.checked);
                if (selectedOption) {
                    params.set(filter.id, selectedOption.value);
                }
            });
            navigate(`?${params.toString()}`);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            await remove(`/questions/${id}`);
            setQuestions(questions.filter((question: any) => question.id !== id));
            toast.success('Question deleted successfully', toastConfig);
            captureEvent('delete_question', {
                question_id: id,
            });
        } catch (error) {
            console.error('Error deleting question:', error);
        }
        setLoading(false);
    };

    const handleSearch = (search: string) => {
        const params = new URLSearchParams({ search, page: '1' });
        filters.forEach((filter) => {
            const selectedOption = filter.options.find((option) => option.checked);
            if (selectedOption) {
                params.set(filter.id, selectedOption.value);
            }
        });
        navigate(`?${params.toString()}`);
    };

    // const handleFilterChange = (newFilters: FilterInterface[]) => {
    //     setFilters(newFilters);
    //     filtersRef.current = newFilters;
    //     const params = new URLSearchParams({ search: searchInput, page: '1' });
    //     newFilters.forEach((filter) => {
    //         const selectedOption = filter.options.find((option) => option.checked);
    //         if (selectedOption) {
    //             params.set(filter.id, selectedOption.value);
    //         }
    //     });
    //     navigate(`?${params.toString()}`);
    // };

    const handleStatusChange = (newStatus: string) => {
        setStatusFilter(newStatus);
        const params = new URLSearchParams(location.search);
        if (newStatus && newStatus !== 'all') {
            params.set('status', newStatus);
        } else {
            params.delete('status');
        }
        params.set('page', '1');
        navigate(`?${params.toString()}`);
    };

    const handleRowClick = (event: React.MouseEvent, rowData: any) => {
        if ((event.target as HTMLElement).closest('[data-delete-action]')) {
            return;
        }
        const questionId = rowData.id;
        console.log('handleRowClick - clicked question:', questionId);
        
        setSelectedQuestionId(questionId);
        setSelectedQuestion(rowData);
        setDrawerOpen(true);
    };

    // Define columns for TanStack Table
    const columns = React.useMemo(() => [
        {
            accessorKey: 'question',
            header: 'Question',
            enableSorting: true,
            cell: ({ getValue }: any) => (
                <div className="flex flex-row justify-start text-md font-semibold">
                    {getValue()}
                </div>
            ),
        },
        {
            accessorKey: 'answer',
            header: 'Answer',
            enableSorting: true,
            cell: ({ getValue }: any) => (
                <div className="flex flex-row justify-start text-md">
                    {getValue()}
                </div>
            ),
        },
        {
            accessorKey: 'agent',
            header: 'Agent',
            enableSorting: true,
            cell: ({ getValue }: any) => {
                const agent = getValue();
                return (
                    <div className="flex flex-row justify-start text-md">
                        {agent ? agent.name : ''}
                    </div>
                );
            },
        },
        {
            accessorKey: 'status',
            header: 'Status',
            enableSorting: true,
            cell: ({ getValue }: any) => {
                const status = getValue();
                return (
                    <div className="flex flex-row justify-start">
                        <Badge variant={status === 'answered' ? 'outline' : 'destructive'}>{status.toUpperCase()}</Badge>
                    </div>
                );
            },
        },
        {
            accessorKey: 'created_at',
            header: 'Created At',
            enableSorting: true,
            cell: ({ getValue }: any) => (
                <div className="flex flex-row justify-start text-md">
                    {formatDate(getValue())}
                </div>
            ),
        },
        {
            id: 'actions',
            header: 'Delete',
            enableSorting: false,
            cell: ({ row }: any) => (
                <div data-delete-action>
                    <RowDelete deleteCallback={() => handleDelete(row.original.id)} />
                </div>
            ),
        },
    ], [handleDelete]);

    // Create table instance with sorting enabled
    const table = useReactTable({
        data: questions,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const QuestionsLoadingSkeleton = () => (
        <TableBody>
            {[1, 2, 3].map((i) => (
                <TableRow key={i} className="animate-pulse">
                    <TableCell className="px-6 py-4">
                        <div className="flex justify-start">
                            <div className="h-8 bg-muted rounded w-20" />
                        </div>
                    </TableCell>
                    <TableCell className="px-6 py-4">
                        <div className="flex justify-center">
                            <div className="h-8 bg-muted rounded w-20" />
                        </div>
                    </TableCell>
                    <TableCell className="px-6 py-4">
                        <div className="flex justify-center">
                            <div className="h-4 bg-muted rounded w-32" />
                        </div>
                    </TableCell>
                    <TableCell className="px-6 py-4">
                        <div className="flex justify-center">
                            <div className="h-4 bg-muted rounded w-40" />
                        </div>
                    </TableCell>
                    <TableCell className="px-6 py-4">
                        <div className="flex justify-center">
                            <div className="h-4 bg-muted rounded w-32" />
                        </div>
                    </TableCell>
                    <TableCell className="px-6 py-4">
                        <div className="flex justify-center">
                            <div className="h-8 bg-muted rounded w-24" />
                        </div>
                    </TableCell>
                    <TableCell className="px-6 py-4">
                        <div className="flex justify-center">
                            <div className="h-4 bg-muted rounded w-16" />
                        </div>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );

    return (
        <div className="p-12 overflow-y-auto max-h-full scrollbar-hidden grow">
            <h1 className='text-4xl font-bold flex items-center gap-2'>Training Questions</h1>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <p className='mt-4 text-sm text-slate-500'>When a customer asks a question that the agent does not know how to answer, it will be added here for you to review. The agent will use answers going forward.</p>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Filter questions by their answer status</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <Separator className="my-4" />
            
            <div className="flex justify-between mb-4">
                <div className="flex grow gap-4">
                    <Input
                        type="text"
                        className="w-1/3"
                        id="search"
                        placeholder="Search questions..."
                        autoComplete="off"
                        value={searchInput}
                        onChange={(e: any) => setSearchInput(e.target.value)}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === 'Enter') {
                                handleSearch(e.currentTarget.value);
                            }
                        }}
                    />
                    <div className="w-40">
                        <Select
                            value={statusFilter || 'all'}
                            onValueChange={handleStatusChange}
                        >
                            <SelectTrigger className="w-full">
                                <SelectValue>
                                    {statusFilter
                                        ? statusOptions.find(opt => opt.value === statusFilter)?.label || 'All'
                                        : 'All'}
                                </SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                                {statusOptions.map(opt => (
                                    <SelectItem key={opt.value} value={opt.value}>
                                        {opt.label}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className="w-40">
                    <Button
                        onClick={() => setShowModal(true)}
                        variant="default"
                    >
                        New Question <PlusCircle />
                    </Button>
                </div>
            </div>

            <div className="max-h-full overflow-x-auto scrollbar-thin">
                <div className="border rounded-lg shadow overflow-hidden dark:border-slate-900">
                    <Table>
                        <TableHeader>
                            {table.getHeaderGroups().map(headerGroup => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <TableHead 
                                            key={header.id}
                                            className={
                                                header.column.id === 'question' ? 'w-[20%]' :
                                                header.column.id === 'answer' ? 'w-[25%]' :
                                                header.column.id === 'agent' ? 'w-[15%]' :
                                                header.column.id === 'status' ? 'w-[10%]' :
                                                header.column.id === 'created_at' ? 'w-[15%]' :
                                                header.column.id === 'actions' ? 'w-[5%]' : ''
                                            }
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                                                    style={{ cursor: header.column.getCanSort() ? 'pointer' : 'default' }}
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {{
                                                        asc: <span> 🔼</span>,
                                                        desc: <span> 🔽</span>
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                </div>
                                            )}
                                        </TableHead>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHeader>
                        {loading ? (
                            <QuestionsLoadingSkeleton />
                        ) : (
                            <TableBody>
                                {table.getRowModel().rows.length ? (
                                    table.getRowModel().rows.map(row => {
                                        return (
                                            <TableRow 
                                                key={row.id}
                                                className="cursor-pointer hover:bg-muted/50"
                                                onClick={(e) => handleRowClick(e, row.original)}
                                            >
                                                {row.getVisibleCells().map(cell => (
                                                    <TableCell key={cell.id}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} className="h-24 text-center">
                                            No questions
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </div>
            </div>

            {totalPages > 1 && (
                <div className="flex justify-between items-center">
                    <Button
                        onClick={handlePreviousPage}
                        disabled={page === 1}
                        variant="outline"
                        size="sm"
                    >
                        Previous
                    </Button>
                    <span className="flex items-center gap-1">
                        <div className="text-sm font-medium">Page</div>
                        <div className="text-sm font-medium">{page}</div>
                        <div className="text-sm font-medium">of</div>
                        <div className="text-sm font-medium">{totalPages}</div>
                    </span>
                    <Button
                        onClick={handleNextPage}
                        disabled={page === totalPages}
                        variant="outline"
                        size="sm"
                    >
                        Next
                    </Button>
                </div>
            )}

            <Dialog open={showModal} onOpenChange={setShowModal}>
                <DialogContent className="sm:max-w-[425px]">
                    <CreateQuestion 
                        open={showModal} 
                        onOpenChange={setShowModal}
                    />
                </DialogContent>
            </Dialog>

            <Drawer 
                open={drawerOpen} 
                onOpenChange={(open) => {
                    console.log('Drawer onOpenChange:', { 
                        open, 
                        selectedQuestionId,
                        selectedQuestion 
                    });
                    setDrawerOpen(open);
                    if (!open) {
                        setSelectedQuestionId(null);
                        setSelectedQuestion(null);
                    }
                }}
            >
                <DrawerContent className="px-4">
                    <div className="mx-auto w-full max-w-4xl mb-12">
                        {selectedQuestionId && (
                            <QuestionView 
                                key={selectedQuestionId} 
                                questionId={selectedQuestionId} 
                            />
                        )}
                    </div>
                </DrawerContent>
            </Drawer>
        </div>
    );
};

export default Questions;
