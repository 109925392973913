const longOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
};

const shortOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
};

export const formatDate = (dateString: string | Date, type: 'short' | 'long' = 'long') => {
    let date: Date;
    if (typeof dateString === 'string') {
        date = dateString.endsWith('Z') ? new Date(dateString) : new Date(dateString + 'Z');
    } else {
        date = dateString;
    }
    const options = type === 'short' ? shortOptions : longOptions;
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const addMinutes = (dateString: string, minutes: number, type: 'short' | 'long' = 'long') => {
    const date = new Date(dateString + 'Z');
    date.setUTCMinutes(date.getUTCMinutes() + minutes);
    const options = type === 'short' ? shortOptions : longOptions;
    return new Intl.DateTimeFormat('en-US', options).format(date);
};