import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApi from '../api';
import toastConfig from '../config/Toast';
import useAnalytics from '../analytics/analytics';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { SaveIcon } from 'lucide-react';
import {
    Dialog,
    DialogContent,
} from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Check } from 'lucide-react';
import { useUser } from '../user/UserContext';

interface CreateAgentProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    setAgents?: (agents: any) => void;
}

interface AgentFormData {
    name: string;
    type: string;
    goal: string;
    description: string;
    persona: string;
    website: string;
}

export function AgentForm({ setShowModal }: { setShowModal: (show: boolean) => void }) {
    const [loading, setLoading] = useState(false);
    const [agentName, setAgentName] = useState<string>('');
    const [agentType, setAgentType] = useState<string>(() => {
        const selectedTab = localStorage.getItem('selectedTab');
        return selectedTab === 'operator' ? 'product' : 'website';
    });    
    const [goal, setGoal] = useState<string>('');
    const [persona, setPersona] = useState<string>('');
    const [website, setWebsite] = useState<string>('');
    const { organization } = useUser();

    const navigate = useNavigate();
    const { post } = useApi();
    const { captureEvent } = useAnalytics();

    const handleCreateAgent = async (formData: AgentFormData) => {
        if (loading) return;
        setLoading(true);

        const data = {
            ...formData,
            fields: [],
            tools: {},  // Default empty tools/actions
            links: [],  // Default empty links
            starters: [], // Default empty starters
            config: {
                font: 'montserrat',  // Default font
                scheme: 'Solid',    // Default color scheme
                color: '#000000',   // Default primary color
                endColor: '#000000', // Default secondary color
                mixedColor: '#000000', // Default mixed color
                darkerColor: '#000000', // Will be calculated if needed
                lighterColor: '#000000', // Will be calculated if needed
                welcomeMessage: 'Welcome! How can I help you today?', // Default welcome message
                autoOpenEnabled: false,
                autoOpenSeconds: 0,
                openOncePerSession: false,
                popupMessageEnabled: false,
                popupMessage: '',
                contextualPopupEnabled: true,
                contextualStartersEnabled: true,
                chatbotEnabled: true,
                searchBarEnabled: false,
                searchBarPlaceholder: '',
                agentMessageIconType: 'Icon',
                agentMessageIcon: 'FireIcon',
                enableFollowUps: false,
                followUpInstructions: '',
                enableVisitorLocation: false,
                enablePageAwareness: false,
                hideAimdoc: false,
                enableCopilot: false,
                qualifyVisitor: false,
                form: [
                    {
                        name: 'first_name',
                        label: 'First Name',
                        type: 'text',
                        required: true,
                    },
                    {
                        name: 'last_name',
                        label: 'Last Name',
                        type: 'text',
                        required: true,
                    },
                    {
                        name: 'email',
                        label: 'Email',
                        type: 'email',
                        required: true,
                    }
                ]
            }
        };

        try {
            const response = await post('/agents/', data);
            setShowModal(false);
            navigate(`/agents/${response.data.id}`);

            toast.success(`${formData.name} successfully created.`, toastConfig);
            captureEvent('agent_created', {
                agent_id: response.data.id,
                agent_name: formData.name,
            });
        } catch (error: any) {
            console.error('Error creating agent:', error);
            toast.error(error.detail || 'Failed to create agent', toastConfig);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = () => {
        handleCreateAgent({
            name: agentName,
            type: agentType,
            description: `New agent created by user to ${goal} on ${website} on ${new Date().toLocaleDateString()} at ${new Date().toLocaleTimeString()}.`,
            goal,
            persona,
            website,
        });
    };

    return (
        <div className="w-full px-6 space-y-2">
            <div className="px-6 space-y-6 w-full justify-center items-center">
                <div className="flex flex-col items-start">
                    <h2 className="mt-4 text-2xl font-bold">Create New Agent</h2>
                    <p className="text-sm text-muted-foreground">
                        Configure a new Aimdoc agent to embed within your website!
                    </p>
                </div>
                <div className="space-y-2">
                    <Label htmlFor="name">Name *</Label>
                    <Input
                        id="name"
                        placeholder="Enter your agent name..."
                        value={agentName}
                        onChange={(e) => setAgentName(e.target.value)}
                        className="max-w-md"
                    />
                </div>

                {organization && organization.operator && (
                    <div className="space-y-2">
                        <Label htmlFor="type">Type *</Label>
                        <Select
                            value={agentType}
                            onValueChange={(value) => setAgentType(value)}
                            defaultValue="website"
                        >
                            <SelectTrigger className="max-w-md">
                                <SelectValue>
                                    {agentType === 'website' ? 'Sales Agent' : 'Operator'}
                                </SelectValue>
                            </SelectTrigger>
                            <SelectContent position="popper">
                                <SelectItem value="website">
                                    Sales Agent 💰
                                    <div className="flex flex-col gap-1 mt-1">
                                        <div className="flex items-center gap-2">
                                            <Check className="h-3 w-3 text-muted-foreground" />
                                            <span className="text-xs text-muted-foreground">AI agent optimized for sales and lead generation</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Check className="h-3 w-3 text-muted-foreground" />
                                            <span className="text-xs text-muted-foreground">Appears as chat widget in bottom right</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Check className="h-3 w-3 text-muted-foreground" />
                                            <span className="text-xs text-muted-foreground">Uses knowledge base, integrations and lead qualification</span>
                                        </div>
                                    </div>
                                </SelectItem>
                                <SelectItem value="product">
                                    Operator 🤖
                                    <div className="flex flex-col gap-1 mt-1">
                                        <div className="flex items-center gap-2">
                                            <Check className="h-3 w-3 text-muted-foreground" />
                                            <span className="text-xs text-muted-foreground">Automates the UI of your product using AI ✨</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Check className="h-3 w-3 text-muted-foreground" />
                                            <span className="text-xs text-muted-foreground">Appears as a toolbar in center of your app</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Check className="h-3 w-3 text-muted-foreground" />
                                            <span className="text-xs text-muted-foreground">Useful for onboarding, autofilling forms and improving the time to value of your app.</span>
                                        </div>
                                    </div>
                                </SelectItem>
                            </SelectContent>
                        </Select>
                        {agentType === 'product' && (
                            <Button variant="link" className="p-0 text-sm text-muted-foreground">
                                <Link to="https://aimdoc.ai/operator" target="_blank">
                                    Learn more about Aimdoc Operator →
                                </Link>
                            </Button>
                        )}
                    </div>
                )}



                <div className="space-y-2">
                    <Label htmlFor="website">Website URL (optional)</Label>
                    <Input
                        id="website"
                        placeholder="Enter your website URL..."
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        className="max-w-md"
                    />
                </div>

                {agentType === 'website' && (
                    <>
                        <div className="space-y-2">
                            <Label htmlFor="goal">Goal *</Label>
                            <Textarea
                                id="goal"
                                placeholder="Define the primary objective of your AI sales agent..."
                                value={goal}
                                onChange={(e) => setGoal(e.target.value)}
                                className="max-w-full min-h-24"
                            />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="persona">Persona *</Label>
                            <Textarea
                                id="persona"
                                placeholder="Your buyer's persona, including their pain points, needs and goals..."
                                value={persona}
                                onChange={(e) => setPersona(e.target.value)}
                                className="max-w-full min-h-24"
                            />
                        </div>
                    </>
                )}

                <div>
                    <Button
                        onClick={handleSubmit}
                        disabled={!agentName || !agentType || (agentType !== 'product' && (!goal || !persona)) || loading}
                        className="flex items-center gap-2"
                    >
                        {loading ? 'Creating...' : `Create ${agentType === 'website' ? 'Sales' : 'Operator'} Agent `}
                        →
                    </Button>
                    <p className="text-sm text-muted-foreground mt-2">
                        * Required fields
                    </p>
                </div>
            </div>
        </div>
    );
}

export default function CreateAgent({ showModal, setShowModal }: CreateAgentProps) {
    return (
        <Dialog open={showModal} onOpenChange={setShowModal}>
            <DialogContent className="w-full max-w-3xl">
                <AgentForm setShowModal={setShowModal} />
            </DialogContent>
        </Dialog>
    );
}
