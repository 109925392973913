import { memo } from 'react';

interface Action {
    action: string;
    id: string;
}

interface ActionsProps {
    actions: Action[];
    gettingFullResponse?: boolean;
}

const Actions = memo(({ actions, gettingFullResponse }: ActionsProps) => {
    const getActionDescription = (action: Action) => {
        switch (action.action) {
            case 'click':
                return `Clicked element with ID: ${action.id}`;
            default:
                return `${action.action} action on ${action.id}`;
        }
    };

    return (
        <div className="p-4 m-4 rounded-lg border">
            <div className="space-y-3">
                <div className="flex items-center space-x-2">
                    <div className="h-5 w-5 flex items-center justify-center text-blue-500 dark:text-blue-400">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.5 10.5a.75.75 0 000-1.5H10.5V6a.75.75 0 00-1.5 0v5.25H4.5a.75.75 0 000 1.5h4.5V18a.75.75 0 001.5 0v-5.25H16.5z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <span className="font-medium">
                        Actions Taken
                    </span>
                </div>
                
                <div className="pl-7 space-y-2">
                    {actions.map((action, index) => (
                        <div 
                            key={`${action.id}-${index}`}
                            className="text-sm text-slate-600 dark:text-slate-300 flex items-center space-x-2"
                        >
                            <div className="h-2 w-2 rounded-full bg-blue-500"></div>
                            <span>{getActionDescription(action)}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default Actions;
