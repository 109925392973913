import { useState, useEffect } from 'react';
import useApi from './../api';
import { toast } from 'react-toastify';
import { Bar, BarChart, CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts';
import { conLog } from './../utils/conLog';
import logo_symbol from '../../logo_symbol.png';

// shadcn imports
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { Separator } from '@/components/ui/separator';

interface AnalyticsData {
    total_messages_last_7_days: number;
    total_conversations_last_7_days: number;
    messages_per_day: {
        day: string;
        message_count: number;
    }[];
    conversations_per_day: {
        day: string;
        conversation_count: number;
    }[];
    messages_by_hour: {
        hour: number;
        message_count: number;
    }[];
    average_conversation_duration_minutes: number;
}

// Add this interface for chart config
interface ChartConfig {
    colors?: string[];
    height?: number;
    width?: number;
    [key: string]: any;
}

const chartConfig: ChartConfig = {
    colors: ["hsl(var(--primary))"],
    tooltip: {
        style: {
            backgroundColor: "hsl(var(--background))",
            color: "hsl(var(--foreground))",
            fontSize: "12px",
        }
    }
};

const Analytics = () => {
    const { get } = useApi();
    const [analyticsData, setAnalyticsData] = useState<AnalyticsData | null>(null);

    useEffect(() => {
        fetchAnalyticsData();
    }, []);

    const fetchAnalyticsData = async () => {
        try {
            const response = await get('/analytics/');
            conLog(response.data);
            const sortedData = {
                ...response.data,
                conversations_per_day: [...response.data.conversations_per_day].sort(
                    (a, b) => new Date(a.day).getTime() - new Date(b.day).getTime()
                )
            };
            setAnalyticsData(sortedData);
        } catch (error) {
            toast.error('Error fetching analytics data');
        }
    };

    if (!analyticsData) {
        return (
            <div className="fixed inset-0 z-50 flex justify-center items-center bg-white/50 dark:bg-slate-900/50 backdrop-blur-sm">
                <img src={logo_symbol} alt="logo" className="w-16 h-16 animate-bounce" />
            </div>
        );
    }

    const formatDate = (dateStr: string) => {
        const [month, day] = dateStr.split('-');
        return `${month}/${day}`;
    };

    const formatHour = (hour: number) => {
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const adjustedHour = hour % 12 || 12;
        return `${adjustedHour}${ampm}`;
    };

    return (
        <div className="p-12 overflow-y-auto max-h-[calc(100vh-1px)] scrollbar-thin grow">
            <h1 className='text-4xl font-bold flex items-center gap-2'>Analytics</h1>
            <p className='mt-4 text-sm text-muted-foreground'>View analytics for your agents.</p>  
            <Separator className="my-4" />
            
            {/* Stats Cards */}
            <div className="grid grid-cols-4 gap-4 mt-4">
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between pb-2">
                        <CardTitle className="text-sm font-medium">
                            Total Messages
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">
                            {analyticsData.total_messages_last_7_days}
                        </div>
                        <p className="text-xs text-muted-foreground">
                            Last 7 days
                        </p>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader className="flex flex-row items-center justify-between pb-2">
                        <CardTitle className="text-sm font-medium">
                            Total Conversations
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">
                            {analyticsData.total_conversations_last_7_days}
                        </div>
                        <p className="text-xs text-muted-foreground">
                            Last 7 days
                        </p>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader className="flex flex-row items-center justify-between pb-2">
                        <CardTitle className="text-sm font-medium">
                            Avg Messages/Conversation
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">
                            {analyticsData.total_conversations_last_7_days
                                ? (analyticsData.total_messages_last_7_days / analyticsData.total_conversations_last_7_days).toFixed(1)
                                : '0'}
                        </div>
                        <p className="text-xs text-muted-foreground">
                            Last 7 days
                        </p>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader className="flex flex-row items-center justify-between pb-2">
                        <CardTitle className="text-sm font-medium">
                            Avg Duration
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">
                            {analyticsData.average_conversation_duration_minutes.toFixed(1)}
                        </div>
                        <p className="text-xs text-muted-foreground">
                            Minutes per conversation
                        </p>
                    </CardContent>
                </Card>
            </div>

            {/* Charts Grid */}
            <div className="mt-4 grid grid-cols-2 gap-4">
                <Card>
                    <CardHeader>
                        <CardTitle>Messages Per Day</CardTitle>
                        <CardDescription>Daily message volume over the last 7 days</CardDescription>
                    </CardHeader>
                    <CardContent>
                        {analyticsData.messages_per_day.length === 0 ? (
                            <div className="h-[250px] flex items-center justify-center text-muted-foreground">
                                No data yet!
                            </div>
                        ) : (
                            <ChartContainer className="h-[250px]" config={chartConfig}>
                                <BarChart data={analyticsData.messages_per_day}>
                                    <CartesianGrid vertical={false} />
                                    <XAxis 
                                        dataKey="day" 
                                        tickFormatter={formatDate}
                                        tickLine={false}
                                        axisLine={false}
                                    />
                                    <YAxis tickLine={false} axisLine={false} />
                                    <ChartTooltip content={<ChartTooltipContent />} />
                                    <Bar 
                                        dataKey="message_count" 
                                        fill="var(--primary)" 
                                        radius={[4, 4, 0, 0]}
                                    />
                                </BarChart>
                            </ChartContainer>
                        )}
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader>
                        <CardTitle>Conversations Per Day</CardTitle>
                        <CardDescription>Daily conversation volume over the last 7 days</CardDescription>
                    </CardHeader>
                    <CardContent>
                        {analyticsData.conversations_per_day.length === 0 ? (
                            <div className="h-[250px] flex items-center justify-center text-muted-foreground">
                                No data yet!
                            </div>
                        ) : (
                            <ChartContainer className="h-[250px]" config={chartConfig}>
                                <BarChart data={analyticsData.conversations_per_day}>
                                    <CartesianGrid vertical={false} />
                                    <XAxis 
                                        dataKey="day" 
                                        tickFormatter={formatDate}
                                        tickLine={false}
                                        axisLine={false}
                                    />
                                    <YAxis tickLine={false} axisLine={false} />
                                    <ChartTooltip content={<ChartTooltipContent />} />
                                    <Bar 
                                        dataKey="conversation_count" 
                                        fill="var(--primary)" 
                                        radius={[4, 4, 0, 0]}
                                    />
                                </BarChart>
                            </ChartContainer>
                        )}
                    </CardContent>
                </Card>

                <Card className="col-span-2">
                    <CardHeader>
                        <CardTitle>Message Frequency by Hour</CardTitle>
                        <CardDescription>Distribution of messages across different hours of the day</CardDescription>
                    </CardHeader>
                    <CardContent>
                        {analyticsData.messages_by_hour.length === 0 ? (
                            <div className="h-[300px] flex items-center justify-center text-muted-foreground">
                                No data yet!
                            </div>
                        ) : (
                            <ChartContainer className="h-[300px]" config={chartConfig}>
                                <LineChart data={analyticsData.messages_by_hour}>
                                    <CartesianGrid vertical={false} />
                                    <XAxis
                                        dataKey="hour"
                                        tickFormatter={formatHour}
                                        interval={2}
                                        tickLine={false}
                                        axisLine={false}
                                    />
                                    <YAxis tickLine={false} axisLine={false} />
                                    <ChartTooltip 
                                        content={
                                            <ChartTooltipContent 
                                                labelFormatter={(hour) => `Time: ${formatHour(Number(hour))}`}
                                            />
                                        } 
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="message_count"
                                        stroke="var(--primary)"
                                        strokeWidth={2}
                                        dot={false}
                                    />
                                </LineChart>
                            </ChartContainer>
                        )}
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default Analytics;