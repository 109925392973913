import React from 'react';
import { Badge } from '@/components/ui/badge';
import useApi from '../api';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

interface LeadMetrics {
  lastActive: Date;
  totalSessions: number;
  avgDuration: number;
  engagementRate: number;
  aimdoc_parent_url: string;
}

interface SessionsProps {
  leadId?: string;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(date);
};

export function LeadScore({ leadId }: SessionsProps) {
  const [metrics, setMetrics] = React.useState<LeadMetrics | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { get } = useApi();

  React.useEffect(() => {
    const fetchMetrics = async () => {
      if (!leadId) return;
      
      try {
        setIsLoading(true);
        const response = await get(`/leads/${leadId}/conversations`);
        const conversations = response.data;

        // Total number of sessions
        const totalSessions = conversations.length;

        // Most recent activity
        const lastActive = new Date(Math.max(...conversations.map(
          (conv: any) => new Date(conv.updated_at).getTime()
        )));

        // Average messages per session (engagement rate)
        let totalMessages = 0;
        conversations.forEach((conv: any) => {
          for (const message of conv.messages) {
            if (message.role === 'user') {
              totalMessages++;
            }
          }
        });
        const engagementRate = Math.round((totalMessages / totalSessions) * 10) / 10;

        // Average duration in seconds
        const durations = conversations.map((conv: any) => {
          if (conv.messages.length < 2) return 0;
          const start = new Date(conv.messages[0].created_at).getTime();
          const end = new Date(conv.messages[conv.messages.length - 1].created_at).getTime();
          return Math.round((end - start) / 1000);
        });
        const avgDuration = durations.reduce((sum: number, dur: number) => 
          sum + dur, 0) / durations.length;

        setMetrics({
          lastActive,
          totalSessions,
          avgDuration,
          engagementRate,
          aimdoc_parent_url: conversations[0].form.aimdoc_parent_url
        });

      } catch (error) {
        console.error('Error fetching lead metrics:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMetrics();
  }, [leadId]);
  

  const calculateLeadScore = (metrics: LeadMetrics): number => {
    // Normalize each component to a 0-100 scale
    const sessionScore = Math.round(Math.min(metrics.totalSessions * 10, 100) * 10) / 10; // 10 sessions = 100%
    const durationScore = Math.round(Math.min((metrics.avgDuration / 300) * 100, 100) * 10) / 10; // 5 min = 100%
    
    // Recency score (higher if more recent)
    const daysSinceActive = (new Date().getTime() - metrics.lastActive.getTime()) / (1000 * 60 * 60 * 24);
    const recencyScore = Math.round(Math.max(100 - (daysSinceActive * 10), 0) * 10) / 10; // Loses 10 points per day
    
    // Engagement score (messages per session)
    const engagementScore = Math.round(Math.min((metrics.engagementRate / 10) * 100, 100) * 10) / 10; // 10 messages/session = 100%

    // Weighted average
    return Math.round(
      (sessionScore * 0.3 +     // 30% weight
      durationScore * 0.25 +    // 25% weight
      recencyScore * 0.25 +     // 25% weight
      engagementScore * 0.2)    // 20% weight
      * 10
    ) / 10;
  };

  const getScoreBadge = (score: number, type: 'lead' | 'engagement') => {
    const thresholds = type === 'lead' 
      ? { hot: 80, warm: 60, nurture: 40 }
      : { hot: 4, warm: 2, nurture: 1, cold: 0 };

    if (score >= thresholds.hot) {
      return <Badge className="bg-red-500">🔥 Hot ({score})</Badge>;
    } else if (score >= thresholds.warm) {
      return <Badge className="bg-yellow-500">🎯 Warm ({score})</Badge>;
    } else if (type === 'lead' && score >= thresholds.nurture) {
      return <Badge className="bg-green-500">🌱 Nurture ({score})</Badge>;
    } else {
      return <Badge className="bg-blue-500">❄️ Cold ({score})</Badge>;
    }
  };

  if (isLoading) {
    return <div className="border rounded-lg shadow overflow-hidden dark:border-slate-900" />;
  }

  if (!metrics) {
    return (
      <div className="border rounded-lg shadow overflow-hidden">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} className="h-24 text-center">
                No lead data found.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
  <div className="flex flex-col gap-4 mb-4">
    <div className="border rounded-lg shadow overflow-hidden">
        <Table>
            <TableHeader>
            <TableRow>
                <TableHead className="w-1/3">Metric</TableHead>
                <TableHead className="w-1/3">Value</TableHead>
                <TableHead className="w-1/3">Description</TableHead>
            </TableRow>
            </TableHeader>
            <TableBody>
            <TableRow>
              <TableCell className="font-medium w-1/3">Source URL</TableCell>
              <TableCell className="w-1/3">
                <a href={metrics.aimdoc_parent_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                  {metrics.aimdoc_parent_url}
                </a>
              </TableCell>
              <TableCell className="text-xs text-muted-foreground w-1/3">
                Page Where Lead Was Generated
              </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="font-medium w-1/3">Last Active</TableCell>
                <TableCell className="w-1/3">{formatDate(metrics.lastActive.toISOString())}</TableCell>
                <TableCell className="text-xs text-muted-foreground w-1/3">
                Most recent website visit
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="font-medium w-1/3">Total Conversations</TableCell>
                <TableCell className="w-1/3">{metrics.totalSessions}</TableCell>
                <TableCell className="text-xs text-muted-foreground w-1/3">
                Total conversations with this lead
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="font-medium w-1/3">Average Duration</TableCell>
                <TableCell className="w-1/3">
                {`${Math.floor(metrics.avgDuration / 60)}m ${Math.floor(metrics.avgDuration % 60)}s`}
                </TableCell>
                <TableCell className="text-xs text-muted-foreground w-1/3">
                Average session duration
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="font-medium w-1/3">Engagement Rate</TableCell>
                <TableCell className="w-1/3">{getScoreBadge(metrics.engagementRate, 'engagement')}</TableCell>
                <TableCell className="text-xs text-muted-foreground w-1/3">
                Average count of message interactions
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="font-medium w-1/3">Lead Score</TableCell>
                <TableCell className="w-1/3">{getScoreBadge(calculateLeadScore(metrics), 'lead')}</TableCell>
                <TableCell className="text-xs text-muted-foreground w-1/3">
                Aimdoc Composite Score
                </TableCell>
            </TableRow>
            </TableBody>
        </Table>
        </div>
        <div className='text-xs text-muted-foreground'>
        Lead score (0-100) based on: sessions (30%), duration (25%), recency (25%), and engagement (20%)
    </div>
  </div>
  );
}
