import { useState, useRef } from 'react';
import { useOutsideAlerter } from '../../useOutsideAlerter';
import CreateField from './CreateField';
import { toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import useAnalytics from '../../analytics/analytics';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Field, Label, Description } from '@headlessui/react';
import { Switch } from '@/components/ui/switch';

interface FormField {
    label: string;
    type: 'text' | 'email' | 'tel' | 'number' | 'select' | 'checkbox' | 'radio' | 'textarea';
    required: boolean;
    name: string;
    options?: string[];
    hidden?: boolean;
}

interface FormProps {
    form: FormField[];
    setForm: (form: FormField[]) => void;
    setNeedsUpdate: (value: boolean) => void;
    agId?: string;
    agentName: string;
    qualifyVisitor: boolean;
    setQualifyVisitor: (value: boolean) => void;
}

const Form = ({ form, setForm, setNeedsUpdate, agId, agentName, qualifyVisitor, setQualifyVisitor }: FormProps) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [activeField, setActiveField] = useState<FormField | null>(null);
    const { captureEvent } = useAnalytics();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = form.findIndex((item) => item.name === active.id);
            const newIndex = form.findIndex((item) => item.name === over.id);

            const newForm = arrayMove(form, oldIndex, newIndex);
            setForm(newForm);
            setNeedsUpdate(true);
        }
    };

    const toggleModal = (field?: FormField) => {
        if (modalVisible) {
            setActiveField(null);
        } else {
            setActiveField(field || null);
        }
        setModalVisible(!modalVisible);
    };

    const addField = (field: FormField) => {
        if (field.label === "") {
            toast.error('Field name is required', toastConfig);
            return false;
        }

        if ((field.type === 'select' || field.type === 'radio') && (!field.options || field.options.length === 0)) {
            toast.error('Select/Radio fields must have at least one option', toastConfig);
            return false;
        }

        const newField = {
            ...field,
            name: field.name || field.label.toLowerCase().replace(/\s+/g, '_').replace(/[^a-z0-9_]/g, '')
        };

        if (form.some((f) => f.name === newField.name)) {
            toast.error('Field with this name already exists', toastConfig);
            return false;
        }

        setForm([...form, newField]);
        setNeedsUpdate(true);
        
        captureEvent('agent_field_added', {
            agent_id: agId,
            agent_name: agentName,
        });
        
        return true;
    };

    const editField = (field: FormField) => {
        const originalName = field.name;

        if ((field.type === 'select' || field.type === 'radio') && (!field.options || field.options.length === 0)) {
            toast.error('Select/Radio fields must have at least one option', toastConfig);
            return false;
        }

        const updatedField = {
            ...field,
            name: originalName === 'email' ? 'email' : field.label.toLowerCase().replace(/\s+/g, '_').replace(/[^a-z0-9_]/g, '')
        };

        const updatedForm = form.map((f) => f.name === originalName ? updatedField : f);
        setForm(updatedForm);
        setNeedsUpdate(true);
        
        captureEvent('agent_field_updated', {
            agent_id: agId,
            agent_name: agentName,
        });

        toggleModal();
        return true;
    };

    const deleteField = (field: FormField) => {
        if (field.name === 'email') {
            toast.error('Email field cannot be removed', toastConfig);
            return;
        }
        
        if (form.length === 1) {
            toast.error('You must have at least one field', toastConfig);
            return;
        }
        
        const updatedForm = form.filter((f) => f.name !== field.name);
        setForm(updatedForm);
        setNeedsUpdate(true);
        
        captureEvent('agent_field_deleted', {
            agent_id: agId,
            agent_name: agentName,
        });
    };

    const modalRef = useRef(null);

    useOutsideAlerter(modalRef, () => toggleModal());

    return (
        <div className='my-6'>
            <div className="flex flex-row items-center justify-between">
                <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium text-slate-600 hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 transition duration-200 dark:bg-slate-600 dark:text-slate-50 dark:hover:bg-slate-600/50"
                    onClick={() => toggleModal()}
                >
                    Add field
                </button>
            </div>
            
            {modalVisible && (
                <CreateField
                    addField={addField}
                    editField={editField}
                    setModalVisible={setModalVisible}
                    field={activeField}
                />
            )}

            {form && form.length > 0 && (
                <DndContext 
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext 
                        items={form.map(f => f.name)}
                        strategy={verticalListSortingStrategy}
                    >
                        <div className="flex flex-col gap-4 mt-4">
                            {form.map((field) => (
                                <SortableField 
                                    key={field.name}
                                    field={field}
                                    toggleModal={toggleModal}
                                    deleteField={deleteField}
                                />
                            ))}
                        </div>
                    </SortableContext>
                </DndContext>
            )}
            <Field className='mt-4'>
                <Label className="text-md font-medium dark:text-slate-100">Qualify Visitor</Label>
                <Description className="text-sm/6 text-slate-400 dark:text-slate-300 mb-2">
                    Your AI agent will strategically collect required form fields from visitors throughout the conversation. If you have a scheduling action configured, your agent will collect required fields before scheduling, regardless of this setting.
                </Description>
                <Switch
                    checked={qualifyVisitor}
                    onCheckedChange={setQualifyVisitor}
                />
            </Field>
        </div>
    );
};

const SortableField = ({ field, toggleModal, deleteField }: { 
    field: FormField; 
    toggleModal: (field?: FormField) => void;
    deleteField: (field: FormField) => void;
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id: field.name });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 0,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`border shadow-sm rounded-lg p-4 flex flex-col leading-normal ${
                isDragging ? 'shadow-lg ring-2 ring-blue-500/50' : ''
            }`}
        >
            <div className="flex flex-row justify-between items-center">
                <div 
                    {...attributes}
                    {...listeners}
                    className="mr-2 cursor-move p-2 rounded-lg"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-400 hover:text-slate-200">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </div>
                <div className="flex-1">
                    <h2 onClick={() => toggleModal(field)} className="font-medium text-lg hover:cursor-pointer hover:text-slate-600 dark:text-slate-300 dark:font-semibold dark:hover:text-slate-300">
                        {field.label}
                    </h2>
                    <p className="text-sm text-slate-500 dark:text-slate-400">
                        {field.type} {field.required ? '(required)' : '(optional)'}{field.hidden ? ' • hidden' : ''}
                    </p>
                </div>
                <button
                    onClick={() => deleteField(field)}
                    className="p-2 rounded-full dark:hover:bg-slate-600"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 shrink-0 stroke-slate-400 hover:stroke-red-400 hover:cursor-pointer dark:stroke-slate-300">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default Form;