import * as React from "react";
import { Check, ChevronsUpDown, AlertCircle } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";

export interface SalesforceField {
    name: string;
    label: string;
    type: string;
    description: string;
    required: boolean;
    picklistValues?: Array<{ value: string; active: boolean }>;
}

interface SalesforceFieldSelectProps {
    fields: SalesforceField[];
    value?: string;
    onSelect: (value: string) => void;
    placeholder?: string;
    simpleDisplay?: boolean;
}

interface FieldWarning {
    field: SalesforceField;
    selectedFormField?: string;  // For mapping context
}

function getFieldWarningMessage(field: SalesforceField): string | null {
    switch (field.type) {
        case 'picklist':
            const validValues = field.picklistValues
                ?.filter(pv => pv.active)
                .map(pv => pv.value)
                .join(', ');
            return `This is a picklist field. Valid values are: ${validValues}`;
        case 'boolean':
            return "This field only accepts true/false values";
        case 'date':
            return "Date must be in YYYY-MM-DD format";
        case 'datetime':
            return "DateTime must be in YYYY-MM-DDThh:mm:ssZ format";
        case 'currency':
        case 'double':
        case 'int':
            return "This field must be a number";
        case 'email':
            return "Must be a valid email address";
        case 'url':
            return "Must be a valid URL (starting with http:// or https://)";
        case 'string':
        case 'textarea':
        case 'text':
            return null;
        default:
            return null;
    }
}

export function FieldWarningMessage({ field }: FieldWarning) {
    const warningMessage = getFieldWarningMessage(field);
    return (
        <>
            {warningMessage && (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger>
                            <AlertCircle className="h-4 w-4 text-amber-500" />
                        </TooltipTrigger>
                        <TooltipContent>
                            <div className="max-w-xs">{warningMessage}</div>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            )}
        </>
    );
}

export function SalesforceFieldSelect({ fields, value, onSelect, placeholder = "Select a field...", simpleDisplay = false }: SalesforceFieldSelectProps) {
    const [open, setOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState("");

    const selectedField = fields.find(field => field.name === value);
    const warningMessage = selectedField && getFieldWarningMessage(selectedField);

    const filteredFields = fields.filter(field => 
        field.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        field.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="space-y-2">
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="w-full justify-between"
                    >
                        {selectedField ? (
                            <div className="flex items-center gap-2">
                                <div className="flex flex-col items-start">
                                    <span>{selectedField.label}</span>
                                    {!simpleDisplay && (
                                        <span className="text-xs text-muted-foreground">
                                            {selectedField.name} • {selectedField.type}
                                        </span>
                                    )}
                                </div>
                                {warningMessage && (
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger>
                                                <AlertCircle className="h-4 w-4 text-amber-500" />
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <div className="max-w-xs">{warningMessage}</div>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                )}
                            </div>
                        ) : (
                            <span className="text-muted-foreground">{placeholder}</span>
                        )}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent 
                    className="p-0 w-[--radix-popover-trigger-width]" 
                    align="center" 
                    sideOffset={4}
                    onWheel={(e) => e.stopPropagation()}
                >
                    <div className="flex flex-col">
                        <div className="p-2">
                            <Input
                                placeholder="Search fields..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="h-8"
                            />
                        </div>
                        <ScrollArea className="h-[300px]">
                            <div className="flex flex-col gap-1 p-1">
                                {filteredFields.map((field) => (
                                    <Button
                                        key={field.name}
                                        variant="ghost"
                                        role="option"
                                        onClick={() => {
                                            onSelect(field.name);
                                            setOpen(false);
                                            setSearchQuery("");
                                        }}
                                        className="justify-start gap-2"
                                    >
                                        <div className="flex flex-col items-start">
                                            <span>{field.label}</span>
                                            <span className="text-xs text-muted-foreground">
                                                {field.name} • {field.type}
                                            </span>
                                        </div>
                                    </Button>
                                ))}
                            </div>
                        </ScrollArea>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
} 