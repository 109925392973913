import React from 'react';
import { Button } from "@/components/ui/button";
import { Download } from 'lucide-react';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import useApi from '../api';
import useAnalytics from '../analytics/analytics';
import { DateRange } from "react-day-picker";
import { formatDate } from '../utils/dateFormatter';

interface ExportSessionsButtonProps {
  agentId?: string;
  leadId?: string;
  selectedAgent: string;
  selectedLead: string;
  selectedStatus: string;
  selectedDateFilter: string;
}

const ExportSessionsButton: React.FC<ExportSessionsButtonProps> = ({
  agentId,
  leadId,
  selectedAgent,
  selectedLead,
  selectedStatus,
  selectedDateFilter,
}) => {
  const { get } = useApi();
  const { captureEvent } = useAnalytics();

  const exportToCSV = async () => {
    try {
      const params = new URLSearchParams({
        per_page: '1000000', // Large number to get all records
      });
      
      if (selectedDateFilter !== 'all') {
        const dateFilterMap: { [key: string]: string } = {
          'today': 'today',
          'last3days': 'last_3_days',
          'lastWeek': 'last_week',
          'lastMonth': 'last_month',
          'last90days': 'last_90_days',
          'lastYear': 'last_year'
        };
        
        const backendDateFilter = dateFilterMap[selectedDateFilter];
        if (backendDateFilter) {
          params.set('date_range', backendDateFilter);
        }
      }
      
      if (agentId) params.append('agent_id', agentId);
      if (leadId) params.append('lead_id', leadId);
      if (selectedAgent !== 'all') params.set('agent_id', selectedAgent);
      if (selectedLead !== 'all') params.set('lead_id', selectedLead);
      if (selectedStatus !== 'all') {
        params.set('is_active', selectedStatus === 'active' ? 'true' : 'false');
      }

      // Fetch all sessions
      const response = await get(`/sessions/?${params.toString()}`);
      const sessions = response.data.items;

      // Convert sessions to CSV format
      const headers = [
        'Session Started',
        'Session Started Time',
        'Last Active Date',
        'Last Active Time',
        'Duration',
        'Lead',
        'Status'
      ];

      const csvData = sessions.map((session: any) => {
        const start = new Date(session.created_at);
        const end = new Date(session.last_activity);
        const diffInSeconds = Math.floor((end.getTime() - start.getTime()) / 1000);
        const minutes = Math.floor(diffInSeconds / 60);
        const seconds = diffInSeconds % 60;

        return [
          formatDate(session.created_at),
          formatDate(session.last_activity),
          `${minutes}m ${seconds}s`,
          session.lead?.email || 'No user-provided information',
          session.status
        ].join(',');
      });

      // Create CSV content
      const csvContent = [
        headers.join(','),
        ...csvData
      ].join('\n');

      // Create and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `sessions-export-${new Date().toISOString()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      
      toast.success('Sessions exported successfully', toastConfig);
      captureEvent('export_sessions', {});
    } catch (error) {
      console.error('Error exporting sessions:', error);
      toast.error('Failed to export sessions', toastConfig);
    }
  };

  return (
    <Button 
      className='mt-4 w-full' 
      variant="outline" 
      onClick={exportToCSV}
    >
      <Download /> Export to CSV
    </Button>
  );
};

export default ExportSessionsButton; 