import React, { createContext, useContext, useEffect, useState, ReactNode, useRef } from 'react';
import { supabase } from '../supabaseClient';
import useApi from '../api';

interface User {
  email: string;
  id: string;
  is_active?: boolean;
  is_admin?: boolean;
  organization_id?: string;
  username?: string;
  walkthrough_complete?: boolean;
  was_invited?: boolean;
}

interface Organization {
  id: string;
  name: string;
  license_type: string;
  trial_end_date: string;
  operator: boolean;
  settings: {
    interaction_notification: boolean;
    new_lead_notification: boolean;
  };
}

interface UserContextType {
  user: User | null;
  organization: Organization | null;
  loading: boolean;
  refreshUser: () => void;
  refreshOrganization: () => void;
  checkUser: () => void;
  checkLicenseType: (license_type: string) => boolean;
  setOrg: (organization: Organization) => void;
  setAdmin: (user: User) => void;
  refresh: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem('aimdocAdmin');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [loading, setLoading] = useState(!user);
  const { get, post } = useApi();
  const isCheckingUser = useRef(false);

  const checkUser = async () => {
    if (isCheckingUser.current) {
      return;
    }

    isCheckingUser.current = true;
    setLoading(true);
    try {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        throw error;
      }

      if (session) {
        const inviteId = localStorage.getItem('pendingInviteId');
        const aimdocUser = await post('/users/', {
          auth_id: session.user.id,
          email: session.user.email,
          username: session.user.email,
          ...(inviteId && { invite_id: inviteId })
        });
        setUser(aimdocUser.data);
        localStorage.setItem('aimdocAdmin', JSON.stringify(aimdocUser.data));
        if (inviteId) localStorage.removeItem('pendingInviteId');

        try {
          const orgResponse = await get(`/users/me/organization`);
          setOrganization(orgResponse.data);
          localStorage.setItem('aimdocOrganization', JSON.stringify(orgResponse.data));
        } catch (error: any) {
          console.error('Error fetching organization:', error);
        }
      } else {
        setUser(null);
        setOrganization(null);
        localStorage.removeItem('aimdocAdmin');
        localStorage.removeItem('aimdocOrganization');
      }
    } catch (error) {
      console.error('Error in checkUser:', error);
      setUser(null);
      setOrganization(null);
      localStorage.removeItem('aimdocAdmin');
      localStorage.removeItem('aimdocOrganization');
    } finally {
      setLoading(false);
      isCheckingUser.current = false;
    }
  };

  useEffect(() => {
    checkUser();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' || event === 'SIGNED_OUT') {
        checkUser();
      }
    });

    const refreshInterval = setInterval(() => {
      if (user) {
        refreshUser();
        refreshOrganization();
      }
    }, 5 * 60 * 1000);

    return () => {
      authListener.subscription.unsubscribe();
      clearInterval(refreshInterval);
    };
  }, []);

  const refreshUser = async () => {
    try {
      const response = await get(`/users/me`);
      setUser(response.data);
      localStorage.setItem('aimdocAdmin', JSON.stringify(response.data));
    } catch (error: any) {
      console.error('Error fetching user:', error);
    }
  };

  const refreshOrganization = async () => {
    try {
      const response = await get(`/users/me/organization`);
      setOrganization(response.data);
      localStorage.setItem('aimdocOrganization', JSON.stringify(response.data));
    } catch (error: any) {
      console.error('Error fetching organization:', error);
    }
  };

  const setOrg = (organization: Organization) => {
    setOrganization(organization);
    localStorage.setItem('aimdocOrganization', JSON.stringify(organization));
  };

  const setAdmin = (user: User) => {
    setUser(user);
    localStorage.setItem('aimdocAdmin', JSON.stringify(user));
  };

  const checkLicenseType = (license_type: string) => {
    if (license_type === 'pro') {
      if (organization?.license_type === 'pro' || organization?.license_type === 'business' || organization?.license_type === 'aimdoc') {
        return true;
      }
    }
    if (license_type === 'standard') {
      if (organization?.license_type === 'standard' || organization?.license_type === 'pro' || organization?.license_type === 'business' || organization?.license_type === 'aimdoc' || organization?.license_type === 'trial') {
        return true;
      }
    }
    if (license_type === 'business') {
      if (organization?.license_type === 'business' || organization?.license_type === 'aimdoc') {
        return true;
      }
    }
    return false;
  };

  const refresh = async () => {
    try {
      await Promise.all([
        refreshUser(),
        refreshOrganization()
      ]);
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
  };

  return (
    <UserContext.Provider value={{ 
      user, 
      checkUser, 
      loading, 
      organization, 
      refreshUser, 
      refreshOrganization, 
      checkLicenseType, 
      setOrg, 
      setAdmin,
      refresh
    }}>
      {children}
    </UserContext.Provider>
  );
};
export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};