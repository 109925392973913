import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { HexColorPicker } from "react-colorful";
import clsx from 'clsx';
import FontDropdown from '../../ui/FontDropdown';
import GenericDropdown from '../../ui/GenericDropdown';
import { IconPicker } from '../../ui/heroicons-picker/IconPicker';
import { CustomizationTabProps } from './types';

const FormGroup = ({ children, className }: { children: React.ReactNode, className?: string }) => (
  <div className={clsx("space-y-2", className)}>
    {children}
  </div>
);

const Description = ({ children }: { children: React.ReactNode }) => (
  <p className="text-sm text-muted-foreground">
    {children}
  </p>
);

export default function AppearanceTab({
  color,
  setColor,
  endColor,
  setEndColor,
  colorScheme,
  setColorScheme,
  font,
  setFont,
  file,
  setFile,
  previewUrl,
  setPreviewUrl,
  setIconFile,
  iconPreviewUrl,
  setIconPreviewUrl,
  agentIconType,
  setAgentIconType,
  agentIcon,
  setAgentIcon,
  setFileChanged,
  setIconFileChanged
}: CustomizationTabProps) {
  const handleLogoFile = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setFileChanged(true);
    }
  };

  const handleIconFile = (event: any) => {
    const iconFile = event.target.files[0];
    if (iconFile) {
      setIconFile(iconFile);
      setIconFileChanged(true);
    }
  };

  const clearLogo = () => {
    setFile(null);
    setPreviewUrl('');
  };

  const clearIcon = () => {
    setIconFile(null);
    setIconPreviewUrl('');
  };

  return (
    <div className="mt-6 space-y-4">
      <div className="border rounded-lg p-6">
        <Label className="block text-xl font-semibold leading-6">
          Appearance
        </Label>
        <Label className="block text-sm font-light leading-6">
          Logo, colors, and font
        </Label>
        <div className="flex flex-row space-x-8 mt-6">
          <div className="w-1/2">
            <Label className="block text-md font-medium leading-6 mb-2">
              Logo
            </Label>
            {previewUrl === "" && (
              <div className="p-4 border rounded-lg shadow-sm">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoFile}
                  className="block w-full text-sm
                  file:mr-4 file:py-2 file:px-4
                  file:rounded file:border-0
                  file:text-sm file:font-semibold"
                />
              </div>
            )}
            {(file || previewUrl) && (
              <div className="flex mt-4">
                {previewUrl && (
                  <div className="flex flex-row justify-between p-4 rounded-lg shadow-md border">
                    <img
                      src={previewUrl}
                      alt="Preview"
                      style={{ maxWidth: '200px', maxHeight: '200px' }}
                      className="rounded-lg"
                    />
                    <div onClick={clearLogo} className="hover:cursor-pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-slate-400 hover:stroke-slate-800">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="mt-6">
              <Label className="block text-md font-medium leading-6">
                Font
              </Label>
              <FontDropdown value={font} setValue={setFont} />
            </div>
            <div className="mt-6">
              <Label className="block text-md font-medium leading-6">
                Color Scheme
              </Label>
              <GenericDropdown value={colorScheme} selectValues={["Solid", "Gradient"]} setValue={setColorScheme} defaultValue={'Solid'} />
            </div>
          </div>
          <div className="w-1/2">
            <div className="flex flex-row space-x-8">
              <div>
                <Label className="block text-md font-medium leading-6">
                  Primary Color
                </Label>
                <HexColorPicker color={color} onChange={setColor} />
                <Input
                  type="text"
                  className="mt-4 w-1/2"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </div>
              {colorScheme === "Gradient" && (
                <div>
                  <Label className="block text-md font-medium leading-6">
                    Gradient End Color
                  </Label>
                  <HexColorPicker color={endColor} onChange={setEndColor} />
                  <Input
                    type="text"
                    className="mt-4 w-1/2"
                    value={endColor}
                    onChange={(e) => setEndColor(e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="border rounded-lg p-6">
        <div className="flex flex-row space-x-6">
          <FormGroup>
            <Label className="text-xl font-semibold leading-6">Widget Icon</Label>
            <Description>Select an icon or upload an image</Description>
            <div className="flex flex-row items-center mt-2">
              <GenericDropdown value={agentIconType} selectValues={["Icon", "Image"]} setValue={setAgentIconType} defaultValue={'Icon'} />
            </div>
          </FormGroup>
          {agentIconType === "Icon" && (
            <FormGroup>
              <div className="flex flex-row items-center mt-2">
                <IconPicker
                  onChange={setAgentIcon}
                  library={'solid'}
                  value={agentIcon}
                />
              </div>
            </FormGroup>
          )}
          {agentIconType === "Image" && (
            <div>
              {iconPreviewUrl === "" && (
                <FormGroup className="">
                  <div className="p-4 border rounded-lg shadow-sm">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleIconFile}
                      className="block w-full text-sm
                      file:mr-4 file:py-2 file:px-4
                      file:rounded file:border-0
                      file:text-sm file:font-semibold"
                    />
                  </div>
                </FormGroup>
              )}
              {iconPreviewUrl && (
                <div className="flex flex-row justify-between p-4 rounded-3xl shadow-md border">
                  <img
                    src={iconPreviewUrl}
                    alt="Preview"
                    style={{ maxWidth: '50px', maxHeight: '50px' }}
                    className="rounded-lg"
                  />
                  <div onClick={clearIcon} className="hover:cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-slate-400 hover:stroke-slate-800">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 