import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Button } from "@/components/ui/button"

export default function FontDropdown({ value, setValue } : { value: string | undefined, setValue: any}) {
    const fontClassMap: { [key: string]: string } = {
        'poppins': 'Poppins',
        'open-sans': 'Open Sans',
        'roboto': 'Roboto',
        'lato': 'Lato',
        'montserrat': 'Montserrat'
    };
    
    const fonts = Object.keys(fontClassMap);
    const currentValue = value ? value : 'open-sans';
  
    return (
      <div className="w-72 mt-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="w-full justify-between">
              <span className={`${currentValue} block truncate`}>
                {fontClassMap[currentValue]}
              </span>
              <ChevronUpDownIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" className="w-72">
            {fonts.map((font: string) => (
              <DropdownMenuItem
                key={font}
                className={`${font} cursor-pointer`}
                onClick={() => setValue(font)}
              >
                {fontClassMap[font]}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    )
}
