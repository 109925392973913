import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import React from "react";
import useApi from "../api";
import { Link } from "react-router-dom";

interface LeadDetailsProps {
  leadId?: string;
  session?: any;
}

export function LeadDetails({ leadId }: LeadDetailsProps) {
  const [sessions, setSessions] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { get } = useApi();

  React.useEffect(() => {
    const fetchSessions = async () => {
      try {
        setIsLoading(true);
        const response = await get(`/sessions/?lead_id=${leadId}`);
        setSessions(response.data.items || []);
      } catch (error) {
        console.error('Error fetching sessions:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (leadId) {
      fetchSessions();
    }
  }, [leadId]);

  const session = sessions[0]; // Get the most recent session

  if (isLoading) {
    return (
      <div className="border rounded-lg shadow overflow-hidden">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} className="h-24 text-center">
                Loading lead details...
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  if (!session) {
    return (
      <div className="border rounded-lg shadow overflow-hidden">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} className="h-24 text-center">
                No lead details found.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <div className="flex flex-col gap-4 mb-4">
      <div className="border rounded-lg shadow overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/3">Metric</TableHead>
              <TableHead className="w-1/3">Value</TableHead>
              <TableHead className="w-1/3">Description</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {[
              {
                metric: "Agent ID",
                value: <Link to={`/agents/${session.agent_id}`} className="hover:underline text-blue-500">
                  {session.agent_id || 'N/A'}
                </Link>,
                description: "Assigned Agent Identifier"
              },
              {
                metric: "City", 
                value: session.city || 'N/A',
                description: "Lead's City Location"
              },
              {
                metric: "Region",
                value: session.region || 'N/A', 
                description: "Lead's Regional Location"
              },
              {
                metric: "Country",
                value: session.country || 'N/A',
                description: "Lead's Country Location" 
              }
            ].map((row, index) => (
              <TableRow key={index}>
                <TableCell className="font-medium w-1/3">{row.metric}</TableCell>
                <TableCell className="w-1/3">{row.value}</TableCell>
                <TableCell className="text-xs text-muted-foreground w-1/3">
                  {row.description}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
} 