import React, { useMemo, useEffect, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { format, parseISO, startOfDay, eachDayOfInterval, subDays, startOfWeek, isWithinInterval } from 'date-fns';
import useApi from '../api';
import { Button } from '@/components/ui/button';
import { ArrowRightIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { 
  ChartContainer, 
  ChartLegend, 
  ChartLegendContent, 
  ChartTooltip, 
  ChartTooltipContent,
  type ChartConfig 
} from "@/components/ui/chart";

interface Lead {
  id: string;
  created_at: string;
  // ... other lead properties
}

interface LeadsPerDayProps {
  days?: number; // Optional: number of days to show, defaults to 30
}

const chartConfig = {
  count: {
    label: "Leads",
    color: "hsl(var(--chart-1))"
  }
} satisfies ChartConfig;

const LeadsPerDay: React.FC<LeadsPerDayProps> = ({ days = 30 }) => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { get } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        setIsLoading(true);
        const response = await get('/leads/');
        setLeads(response.data || []);
      } catch (error) {
        console.error('Error fetching leads:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLeads();
  }, []);

  const chartData = useMemo(() => {
    // Create date range
    const endDate = startOfDay(new Date());
    const startDate = startOfDay(subDays(endDate, days - 1));
    
    // Get array of all dates in range
    const dateRange = eachDayOfInterval({ start: startDate, end: endDate });
    
    // Initialize counts for each date
    const dailyCounts = dateRange.map(date => ({
      date: format(date, 'yyyy-MM-dd'),
      count: 0
    }));

    // Count leads per day
    leads.forEach(lead => {
      const leadDate = format(parseISO(lead.created_at), 'yyyy-MM-dd');
      const dataPoint = dailyCounts.find(d => d.date === leadDate);
      if (dataPoint) {
        dataPoint.count++;
      }
    });

    return dailyCounts;
  }, [leads, days]);

  const newLeads = leads.filter(lead => {
    const leadDate = parseISO(lead.created_at);
    const thisWeek = startOfWeek(new Date());
    return isWithinInterval(leadDate, {
        start: thisWeek,
        end: new Date()
    });
  });

  return (
    <div className="px-2 pt-4 sm:px-6 sm:pt-6">
      {isLoading ? (
        <div className="h-[250px] flex items-center justify-center text-muted-foreground">
          Loading...
        </div>
      ) : leads.length === 0 ? (
        <div className="h-[250px] flex items-center justify-center text-muted-foreground">
          No data yet!
        </div>
      ) : (
        <ChartContainer
          config={chartConfig}
          className="aspect-auto h-[250px] w-full"
        >
          <AreaChart data={chartData}>
            <defs>
              <linearGradient id="fillLeads" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor="var(--color-count)"
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor="var(--color-count)"
                  stopOpacity={0.1}
                />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={(value) => format(parseISO(value), 'MMM d')}
            />
            <YAxis tickLine={false} axisLine={false} />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  className="w-[150px]"
                  labelFormatter={(value) => format(parseISO(value), 'MMM d, yyyy')}
                  indicator="dot"
                />
              }
            />
            <Area
              dataKey="count"
              type="monotone"
              fill="url(#fillLeads)"
              stroke="var(--color-count)"
              name={chartConfig.count.label}
            />
            <ChartLegend 
              content={<ChartLegendContent />}
            />
          </AreaChart>
        </ChartContainer>
      )}
      <Button 
        variant="outline" 
        className="mt-4 w-full"
        onClick={() => navigate('/leads?date_filter=today')}
      >
        View New Leads {`(${newLeads.length})`} <ArrowRightIcon className="w-4 h-4 ml-2" />
      </Button>
    </div>
  );
};

export default LeadsPerDay;
