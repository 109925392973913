import { useState } from 'react';
import { toast } from 'react-toastify';
import useApi from '../api';
import toastConfig from '../config/Toast';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';

interface QuestionProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
}

export default function CreateQuestion({ open, onOpenChange }: QuestionProps) {
    const [question, setQuestion] = useState<string>('');
    const [answer, setAnswer] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const { post } = useApi();

    const createQuestion = async () => {
        if (question === "") {
            toast.error('Question is required.', toastConfig);
            return;
        }
        if (answer === "") {
            toast.error('Answer is required.', toastConfig);
            return;
        }

        const data = {
            question: question,
            answer: answer
        }

        try {
            setLoading(true);
            const response = await post('/questions/', data);
            toast.success('Question successfully created.', toastConfig)
            onOpenChange(false); 
            return response.data.id;
        } catch (error) {
            console.error('Error creating question:', error);
            toast.error('Failed to create question.', toastConfig);
        }

        setLoading(false);
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="max-w-5xl">
                <DialogHeader>
                    <DialogTitle className="text-2xl text-center">Create Question</DialogTitle>
                </DialogHeader>
                <div className="space-y-6">
                    <div>
                        <label className="text-sm font-medium leading-6">
                            Question
                        </label>
                        <p className="text-sm text-muted-foreground mt-1">
                            Enter a question here.
                        </p>
                        <div className="mt-3">
                            <Textarea
                                id="question"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                placeholder="Enter your question..."
                            />
                        </div>
                    </div>

                    <div>
                        <label className="text-sm font-medium leading-6">
                            Answer
                        </label>
                        <p className="text-sm text-muted-foreground mt-1">
                            Enter the predefined answer to the question.
                        </p>
                        <div className="mt-3">
                            <Textarea
                                id="answer"
                                value={answer}
                                onChange={(e) => setAnswer(e.target.value)}
                                placeholder="Enter your answer..."
                                className="min-h-40"
                            />
                        </div>
                    </div>
                </div>

                <DialogFooter>
                    <Button
                        onClick={createQuestion}
                        disabled={loading}
                        variant="secondary"
                        className="w-full"
                    >
                        {loading ? 'Creating...' : 'Create Question'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}