import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardContent, CardFooter } from '@/components/ui/card';
import { ArrowRight } from 'lucide-react';

interface AgentSelectionDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSelectManual: () => void;
  onSelectAI: () => void;
}

export function AgentSelectionDrawer({ 
  open, 
  onOpenChange, 
  onSelectManual, 
  onSelectAI 
}: AgentSelectionDrawerProps) {
  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent className="h-[60%]">
        <DrawerHeader>
          <DrawerTitle className="text-center text-2xl">Create New Agent</DrawerTitle>
          <p className="text-center text-muted-foreground">
            Choose how you want to create your agent. Manually write descriptions and instructions or let AI help you quickstart.
          </p>
        </DrawerHeader>

        <div className="grid grid-cols-2 gap-8 p-6">
          {[
            {
              title: "Manual Creation",
              description: "Build your agent step by step and upload knowledge.",
              buttonText: "Create Manually",
              onClick: onSelectManual,
              icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
              ),
              bgClass: "bg-gradient-to-br from-background to-muted"
            },
            {
              title: "AI-Assisted", 
              description: "Scrape your website and let AI write descriptions and knowledge files.",
              buttonText: "Create with AI",
              onClick: onSelectAI,
              icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24 text-indigo-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                </svg>
              ),
              bgClass: "bg-gradient-to-br from-background to-muted"
            }
          ].map((option, index) => (
            <Card key={index} className="min-h-96">
              <CardHeader className="space-y-1">
                <div className={`w-full ${option.bgClass}`}>
                  <div className="min-h-40 flex items-center justify-center p-4">
                    {option.icon}
                  </div>
                </div>
              </CardHeader>
              <CardContent className="space-y-2 text-center">
                <h3 className="font-medium text-2xl">{option.title}</h3>
                <p className="text-lg text-muted-foreground">
                  {option.description}
                </p>
              </CardContent>
              <CardFooter>
                <Button
                  variant="default"
                  className="w-full text-lg"
                  onClick={option.onClick}
                >
                  {option.buttonText} <ArrowRight className="w-4 h-4" />
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </DrawerContent>
    </Drawer>
  );
} 