import { useState, useEffect } from 'react';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Plus, GripVertical, Pencil, Trash2 } from 'lucide-react';
import ProductDemoStepModal from './TaskDefinitionStepModal';

interface TaskDefinitionModalProps {
    isOpen: boolean;
    closeModal: () => void;
    onSave: (taskDefinition: { id: string; name: string; steps: string[] }) => void;
    initialTaskDefinition?: { id: string; name: string; steps: string[] };
    isEditing: boolean;
}

interface TaskDefinitionStep {
    id: string;
    content: string;
}

const SortableStep = ({ step, id, index, onEdit, onRemove }: { 
    step: string;
    id: string;
    index: number;
    onEdit: () => void;
    onRemove: () => void;
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 0,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`flex items-center justify-between p-3 rounded-md bg-muted ${
                isDragging ? 'shadow-lg ring-2 ring-primary/50' : ''
            }`}
        >
            <div className="flex items-center gap-3">
                <div
                    {...attributes}
                    {...listeners}
                    className="text-muted-foreground cursor-move"
                >
                    <GripVertical className="h-4 w-4" />
                </div>
                <span className="text-sm">
                    {index + 1}. {step}
                </span>
            </div>
            <div className="flex items-center gap-2">
                <Button
                    variant="ghost"
                    size="icon"
                    onClick={onEdit}
                >
                    <Pencil className="h-4 w-4" />
                </Button>
                <Button
                    variant="ghost"
                    size="icon"
                    onClick={onRemove}
                >
                    <Trash2 className="h-4 w-4" />
                </Button>
            </div>
        </div>
    );
};

export default function TaskDefinitionModal({
    isOpen,
    closeModal,
    onSave,
    initialTaskDefinition = { id: '', name: '', steps: [] },
    isEditing
}: TaskDefinitionModalProps) {
    const [taskDefinitionName, setTaskDefinitionName] = useState('');
    const [taskDefinitionSteps, setTaskDefinitionSteps] = useState<TaskDefinitionStep[]>([]);
    const [isStepModalOpen, setIsStepModalOpen] = useState(false);
    const [editingStepIndex, setEditingStepIndex] = useState<number | null>(null);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        if (isOpen && (taskDefinitionSteps.length === 0 || taskDefinitionName === '')) {
            setTaskDefinitionName(initialTaskDefinition.name);
            setTaskDefinitionSteps(initialTaskDefinition.steps.map((step, i) => ({
                id: `step-${i}`,
                content: step
            })));
        }
    }, [isOpen]);

    const handleStepSave = (step: string) => {
        if (editingStepIndex !== null) {
            const updatedSteps = [...taskDefinitionSteps];
            updatedSteps[editingStepIndex].content = step;
            setTaskDefinitionSteps(updatedSteps);
        } else {
            setTaskDefinitionSteps([...taskDefinitionSteps, {
                id: `step-${taskDefinitionSteps.length}`,
                content: step
            }]);
        }
        setEditingStepIndex(null);
    };

    const handleEditStep = (index: number) => {
        setEditingStepIndex(index);
        setIsStepModalOpen(true);
    };

    const handleRemoveStep = (index: number) => {
        const updatedSteps = taskDefinitionSteps.filter((_, i) => i !== index);
        setTaskDefinitionSteps(updatedSteps);
    };

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = taskDefinitionSteps.findIndex(step => step.id === active.id);
            const newIndex = taskDefinitionSteps.findIndex(step => step.id === over.id);

            setTaskDefinitionSteps(arrayMove(taskDefinitionSteps, oldIndex, newIndex));
        }
    };

    const handleSave = () => {
        if (taskDefinitionName.trim() && taskDefinitionSteps.length > 0) {
            const slug = taskDefinitionName.trim().toLowerCase().replace(/ /g, '-');
            onSave({ 
                id: slug,
                name: taskDefinitionName.trim(), 
                steps: taskDefinitionSteps.map(step => step.content) 
            });
            closeModal();
        }
    };

    return (
        <>
            <Dialog open={isOpen} onOpenChange={closeModal}>
                <DialogContent className="sm:max-w-2xl">
                    <DialogHeader>
                        <DialogTitle>
                            {isEditing ? 'Edit Task Definition' : 'Create Task Definition'}
                        </DialogTitle>
                    </DialogHeader>

                    <div className="mt-4">
                        <Input
                            value={taskDefinitionName}
                            onChange={(e) => setTaskDefinitionName(e.target.value)}
                            placeholder="Enter task definition name..."
                        />
                    </div>

                    <div className="mt-6 space-y-4">
                        <Button
                            onClick={() => setIsStepModalOpen(true)}
                            className="inline-flex items-center"
                        >
                            <Plus className="w-4 h-4 mr-2" />
                            Add Step
                        </Button>

                        {taskDefinitionSteps.length > 0 ? (
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                            >
                                <SortableContext
                                    items={taskDefinitionSteps.map(step => step.id)}
                                    strategy={verticalListSortingStrategy}
                                >
                                    <div className="space-y-2">
                                        {taskDefinitionSteps.map((step, index) => (
                                            <SortableStep
                                                key={step.id}
                                                step={step.content}
                                                id={step.id}
                                                index={index}
                                                onEdit={() => handleEditStep(index)}
                                                onRemove={() => handleRemoveStep(index)}
                                            />
                                        ))}
                                    </div>
                                </SortableContext>
                            </DndContext>
                        ) : (
                            <div className="text-center p-8 border border-dashed rounded-lg">
                                <p className="text-muted-foreground">
                                    No task definition steps added yet. Add steps to create a task definition.
                                </p>
                            </div>
                        )}
                    </div>

                    <DialogFooter>
                        <Button
                            variant="outline"
                            onClick={closeModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSave}
                            disabled={!taskDefinitionName.trim() || taskDefinitionSteps.length === 0}
                        >
                            {isEditing ? 'Save Changes' : 'Create Task Definition'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            <ProductDemoStepModal
                isOpen={isStepModalOpen}
                closeModal={() => {
                    setIsStepModalOpen(false);
                    setEditingStepIndex(null);
                }}
                onSave={handleStepSave}
                initialStep={editingStepIndex !== null ? taskDefinitionSteps[editingStepIndex].content : ''}
                isEditing={editingStepIndex !== null}
            />
        </>
    );
} 