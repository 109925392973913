import { useState, useEffect } from 'react'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"

interface starterProps {
    addField: any;
    editField: any;
    setModalVisible: any;
    field: any;
}

interface InputType {
    value: string;
    displayName: string;
}

const supportedInputTypes: InputType[] = [
    { value: 'text', displayName: 'Text' },
    { value: 'email', displayName: 'Email' },
    { value: 'tel', displayName: 'Telephone' },
    { value: 'number', displayName: 'Number' },
    { value: 'url', displayName: 'URL' },
    { value: 'date', displayName: 'Date' },
    { value: 'dropdown', displayName: 'Dropdown' }
];

export default function CreateField({ addField, editField, setModalVisible, field }: starterProps) {
    const [isOpen, setIsOpen] = useState(false)
    const [fieldLabel, setFieldLabel] = useState(field ? field.label : '');
    const [fieldName, setFieldName] = useState(field ? field.name : '');
    const [fieldType, setFieldType] = useState(field ? field.type : 'text');
    const [fieldRequired, setFieldRequired] = useState(field ? field.required : false);
    const [fieldOptions, setFieldOptions] = useState<string[]>(field?.options || []);
    const [newOption, setNewOption] = useState('');
    const [fieldHidden, setFieldHidden] = useState(field ? field.hidden || false : false);

    function closeModal() {
        setModalVisible(false)
        setIsOpen(false)
    }

    function validateField() {
        if (!fieldLabel.trim()) {
            return { valid: false, error: "Field label is required" };
        }

        if (fieldType === 'dropdown' && fieldOptions.length < 1) {
            return { valid: false, error: "Dropdown fields require at least one option" };
        }

        return { valid: true, error: null };
    }

    function updateField() {
        const validation = validateField();
        if (!validation.valid) {
            alert(validation.error);
            return;
        }

        const updated = editField({
            label: fieldLabel,
            type: fieldType,
            required: fieldRequired,
            name: fieldName,
            options: fieldType === 'dropdown' ? fieldOptions : undefined,
            hidden: fieldHidden
        });

        if (updated) {
            closeModal();
            setModalVisible(false);
        }
    }

    function addNewField() {
        const validation = validateField();
        if (!validation.valid) {
            alert(validation.error);
            return;
        }

        const newFieldName = fieldLabel.toLowerCase().replace(/\s+/g, '_');
        setFieldName(newFieldName);
        const added = addField({
            label: fieldLabel,
            type: fieldType,
            required: fieldRequired,
            name: newFieldName,
            options: fieldType === 'dropdown' ? fieldOptions : undefined,
            hidden: fieldHidden
        });
        
        if (added) {
            closeModal();
            setModalVisible(false);
        }
    }

    const addOption = () => {
        if (newOption.trim() && !fieldOptions.includes(newOption.trim())) {
            setFieldOptions([...fieldOptions, newOption.trim()]);
            setNewOption('');
        }
    };

    const removeOption = (optionToRemove: string) => {
        setFieldOptions(fieldOptions.filter(option => option !== optionToRemove));
    };

    useEffect(() => {
        setIsOpen(true)
    }, []);

    return (
        <Dialog open={isOpen} onOpenChange={closeModal}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Add Form Field</DialogTitle>
                </DialogHeader>

                <div className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="formFieldLabel">Label</Label>
                        <Input
                            id="formFieldLabel"
                            value={fieldLabel}
                            onChange={(e) => setFieldLabel(e.target.value)}
                            autoFocus
                        />
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="fieldType">Type</Label>
                        <select
                            id="fieldType"
                            className="flex h-9 w-full rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                            value={fieldType}
                            onChange={(e) => setFieldType(e.target.value)}
                        >
                            {supportedInputTypes.map((type) => (
                                <option key={type.value} value={type.value}>
                                    {type.displayName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            id="fieldRequired"
                            checked={fieldRequired}
                            onChange={(e) => setFieldRequired(e.target.checked)}
                            className="h-4 w-4 rounded border-input"
                        />
                        <Label htmlFor="fieldRequired">This field is required</Label>
                    </div>

                    <div className="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            id="fieldHidden"
                            checked={fieldHidden}
                            onChange={(e) => setFieldHidden(e.target.checked)}
                            className="h-4 w-4 rounded border-input"
                        />
                        <Label htmlFor="fieldHidden">Field Hidden</Label>
                    </div>

                    {fieldType === 'dropdown' && (
                        <div className="space-y-2">
                            <Label>Dropdown Options</Label>
                            <div className="flex gap-2">
                                <Input
                                    value={newOption}
                                    onChange={(e) => setNewOption(e.target.value)}
                                    placeholder="Add new option"
                                />
                                <Button onClick={addOption}>Add</Button>
                            </div>
                            {fieldOptions.length > 0 && (
                                <ul className="space-y-2">
                                    {fieldOptions.map((option, index) => (
                                        <li key={index} className="flex items-center justify-between rounded-md border p-2">
                                            <span>{option}</span>
                                            <Button 
                                                variant="ghost" 
                                                size="sm"
                                                onClick={() => removeOption(option)}
                                            >
                                                ✕
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                </div>

                <DialogFooter>
                    {field ? (
                        <Button onClick={updateField}>
                            Save field
                        </Button>
                    ) : (
                        <Button onClick={addNewField}>
                            Create field
                        </Button>
                    )}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
