import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { supabase } from '../supabaseClient';
import LogoSvg from '../../logo_main.svg';
import useAnalytics from '../analytics/analytics';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardContent, CardFooter, CardHeader } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";

function Login() {
  const navigate = useNavigate();
  const { captureEvent } = useAnalytics();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isConfirmationSent, setIsConfirmationSent] = useState(false);
  const [signupEmail, setSignupEmail] = useState('');

  useEffect(() => {
    const inviteId = searchParams.get('invite_id');
    if (inviteId) {
      localStorage.setItem('pendingInviteId', inviteId);
    }
  }, [searchParams]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      captureEvent('logged_in', {});
      navigate('/');
    } catch (error: any) {
      toast.error(error.message || 'An error occurred during login');
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) throw error;

      if (data.user?.identities?.length === 0) {
        toast.error('An account with this email already exists');
      } else {
        setSignupEmail(email);
        setIsConfirmationSent(true);
        captureEvent('signed_up', {});
      }
    } catch (error: any) {
      toast.error(error.message || 'An error occurred during sign up');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      });

      if (error) throw error;
    } catch (error: any) {
      toast.error(error.message || 'An error occurred during Google login');
    }
  };

  if (isConfirmationSent) {
    return (
      <div className="flex justify-center w-full items-center min-h-screen flex-col">
        <div className="w-full max-w-[400px] px-4">
          <div className="flex justify-center mb-8">
            <img src={LogoSvg} className="h-14" alt="Logo" />
          </div>
          
          <Card>
            <CardHeader className="space-y-1">
              <h2 className="text-2xl font-semibold text-center">Check your email</h2>
              <p className="text-sm text-muted-foreground text-center">
                We sent a confirmation link to {signupEmail}
              </p>
            </CardHeader>
            <CardContent className="space-y-4">
              <p className="text-sm text-muted-foreground text-center">
                Click the link in the email to confirm your account. If you don't see the email, check your spam folder.
              </p>
              <Button
                variant="outline"
                className="w-full"
                onClick={() => {
                  setIsConfirmationSent(false);
                  setIsSignUp(false);
                  setEmail('');
                  setPassword('');
                  setConfirmPassword('');
                }}
              >
                Back to Sign In
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center w-full items-center min-h-screen flex-col">
      <div className="w-full max-w-[400px] px-4">
        <div className="flex justify-center mb-8">
          <img src={LogoSvg} className="h-14" alt="Logo" />
        </div>
        
        <Card>
          <CardHeader className="space-y-1">
            <h2 className="text-2xl font-semibold text-center">
              {isSignUp ? 'Create Account' : 'Sign in'}
            </h2>
            <p className="text-sm text-muted-foreground text-center">
              {isSignUp ? 'Create your account below' : (
                <>
                  Don't have an account?{' '}
                  <button 
                    onClick={() => setIsSignUp(true)}
                    className="text-primary hover:underline font-medium"
                  >
                    Sign up
                  </button>
                </>
              )}
            </p>
          </CardHeader>
          <CardContent>
            <form onSubmit={isSignUp ? handleSignUp : handleLogin} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="me@example.com"
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              {isSignUp && (
                <div className="space-y-2">
                  <Label htmlFor="confirm-password">Confirm Password</Label>
                  <Input
                    id="confirm-password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
              )}

              <Button
                type="submit"
                className="w-full"
                disabled={loading}
              >
                {loading 
                  ? (isSignUp ? 'Creating Account...' : 'Signing in...') 
                  : (isSignUp ? 'Create Account' : 'Sign In')
                }
              </Button>

              {isSignUp && (
                <button
                  type="button"
                  onClick={() => {
                    setIsSignUp(false);
                    setConfirmPassword('');
                  }}
                  className="w-full text-sm text-muted-foreground hover:text-primary"
                >
                  Already have an account? Sign in
                </button>
              )}
            </form>

            <div className="relative my-4">
              <div className="absolute inset-0 flex items-center">
                <Separator />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-background px-2 text-muted-foreground">
                  Or continue with
                </span>
              </div>
            </div>

            <Button
              variant="outline"
              onClick={handleGoogleLogin}
              className="w-full"
            >
              <img src="https://www.google.com/favicon.ico" alt="Google" className="h-4 w-4 mr-2" />
              Google
            </Button>
          </CardContent>
          <CardFooter className="flex flex-col space-y-4 text-center text-sm text-muted-foreground">
            <p>
              By continuing, you agree to our{' '}
              <a 
                href="https://www.iubenda.com/privacy-policy/36587222" 
                className="underline hover:text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a 
                href="https://www.iubenda.com/terms-and-conditions/36587222"
                className="underline hover:text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              .
            </p>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}

export default Login;
