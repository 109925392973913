import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Import embedded bundle as a script
const script = document.createElement('script');
script.src = '/embedded.bundle.js';
script.async = true;
document.body.appendChild(script);

//Initialize agent after script loads
// script.onload = () => {
//     if ((window as any).Agent) {
//         (window as any).Agent.initAgentChat('ai-assistant', {
//             agentId: 'd4694b38-fc25-4d09-b0a6-6c42b733bd5c'  // Your agent ID here
//         });
//     }
// };

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    // <React.StrictMode>
        <App />
    // </React.StrictMode>
);

// Add Rewardful scripts
(function(w: any, r: any) {
    w._rwq = r;
    w[r] = w[r] || function() {
        (w[r].q = w[r].q || []).push(arguments);
    };
})(window, 'rewardful');

// Create and append the async script tag
const rewardfulScript = document.createElement('script');
rewardfulScript.src = 'https://r.wdfl.co/rw.js';
rewardfulScript.async = true;
rewardfulScript.setAttribute('data-rewardful', '6e1ec2');
document.body.appendChild(rewardfulScript);
