import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import SlackNotifications from './SlackNotifications';

interface SlackNotificationsModalProps {
    isOpen: boolean;
    closeModal: () => void;
    integration: any;
    value: any;
    onSave: (value: any) => void;
    escalationEnabled: boolean;
}

export default function SlackNotificationsModal({
    isOpen,
    closeModal,
    integration,
    value,
    onSave,
    escalationEnabled
}: SlackNotificationsModalProps) {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value, isOpen]);

    const handleSave = () => {
        onSave(localValue);
        closeModal();
    };

    const hasConfiguredChannels = localValue?.configs?.length > 0;

    return (
        <Dialog open={isOpen} onOpenChange={closeModal}>
            <DialogContent className="sm:max-w-2xl">
                <DialogHeader>
                    <DialogTitle>Configure Slack Notifications</DialogTitle>
                </DialogHeader>

                <div className="mt-4">
                    <SlackNotifications
                        integration={integration}
                        value={localValue}
                        onChange={setLocalValue}
                        escalationEnabled={escalationEnabled}
                    />
                </div>

                <DialogFooter>
                    <Button
                        variant="outline"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        disabled={!hasConfiguredChannels}
                    >
                        {hasConfiguredChannels ? 'Save' : 'Configure a channel to save'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
} 