import { useEffect, useState, Fragment } from 'react';
import useApi from '../api';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import SchedulingFieldsModal from './SchedulingFieldsModal';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import SlackNotificationsModal from './actions/SlackNotificationsModal';
import SlackIcon from '../icons/slack_logo.webp';
import ChiliPiperIcon from '../icons/chili_piper_logo.png';
import CalendlyIcon from '../icons/calendly_logo.png';
import TidyCalIcon from '../icons/tidycal_logo.jpeg';
import { Plus, Trash2, Pencil } from 'lucide-react';
import TaskDefinitionModal from './actions/TaskDefinitionModal';
import HubspotIcon from '../icons/hubspot_logo.webp';
import SalesforceIcon from '../icons/salesforce_logo.png';
import HubspotFieldsModal from './actions/HubspotFieldsModal';
import SalesforceFieldsModal from './actions/SalesforceFieldsModal';

interface Integration {
    id: string;
    name: string;
    description: string;
    details: any;
    type: string;
}

interface CrmIntegration extends Integration {
    details: {
        integration: string;
        type: string;
        token: string;
        refresh_token: string;
        portal_id?: string;
        form_guid?: string;
    }
}

interface ActionsProps {
    actions: Record<string, any>;
    setActions: (actions: Record<string, any>) => void;
    setNeedsUpdate: (needsUpdate: boolean) => void;
    formFields: any[];
    agentType: string;
    agentId?: string;
    agentName: string;
    escalationEnabled: boolean;
}

const LoadingSkeleton = () => (
    <div className="mt-6 space-y-4">
        {[1].map((i) => (
            <div key={i} className="p-4 rounded-lg border">
                <div className="animate-pulse flex items-center justify-between">
                    <div className="space-y-2">
                        <div className="h-5 rounded w-32"></div>
                        <div className="h-4 rounded w-48"></div>
                    </div>
                    <div className="w-6 h-6 rounded-full"></div>
                </div>
            </div>
        ))}
    </div>
);

const INTEGRATIONS_REQUIRING_MAPPING = ['google-calendar', 'chili-piper'];

export default function Actions({ actions, setActions, setNeedsUpdate, formFields, agentType, escalationEnabled }: ActionsProps) {
    const { get } = useApi();
    const [calendarIntegrations, setCalendarIntegrations] = useState<Integration[]>([]);
    const [selectedCalendarIntegration, setSelectedCalendarIntegration] = useState<string | null>(
        actions.scheduling?.id || null
    );
    const [isLoading, setIsLoading] = useState(true);
    const [isFieldMappingOpen, setIsFieldMappingOpen] = useState(false);
    const [pendingIntegration, setPendingIntegration] = useState<Integration | null>(null);
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [integrationToDisconnect, setIntegrationToDisconnect] = useState<Integration | null>(null);
    const [slackIntegration, setSlackIntegration] = useState<Integration | null>(null);
    const [isSlackModalOpen, setIsSlackModalOpen] = useState(false);
    const [selectedNotificationIntegration, setSelectedNotificationIntegration] = useState<Integration | null>(null);
    const [showSlackDisconnectModal, setShowSlackDisconnectModal] = useState(false);
    const [taskDefinitions, setTaskDefinitions] = useState<Array<{ id: string; name: string; steps: string[] }>>(
        actions.taskDefinitions || []
    );
    const [isTaskDefinitionModalOpen, setIsTaskDefinitionModalOpen] = useState(false);
    const [editingTaskDefinitionIndex, setEditingTaskDefinitionIndex] = useState<number | null>(null);
    const [crmIntegrations, setCrmIntegrations] = useState<CrmIntegration[]>([]);
    const [isHubspotModalOpen, setIsHubspotModalOpen] = useState(false);
    const [isSalesforceModalOpen, setIsSalesforceModalOpen] = useState(false);
    const [selectedCrmIntegration, setSelectedCrmIntegration] = useState<Integration | null>(null);

    useEffect(() => {
        const fetchIntegrations = async () => {
            try {
                const response = await get('/integrations/');
                const integrations = response.data;

                const slackInt = integrations.find((int: Integration) => int.name === 'slack');
                if (slackInt) {
                    setSlackIntegration(slackInt);
                }

                const calendarApps = ['calendly', 'tidycal', 'google-calendar', 'chili-piper'];
                const filteredIntegrations = integrations.filter((integration: Integration) =>
                    calendarApps.includes(integration.name)
                );
                setCalendarIntegrations(filteredIntegrations);

                const crmApps = ['hubspot', 'salesforce'];
                const filteredCrmIntegrations = integrations.filter((integration: Integration) =>
                    crmApps.includes(integration.name) && integration.details.type === 'crm'
                );
                setCrmIntegrations(filteredCrmIntegrations);
            } catch (error) {
                console.error('Error fetching integrations:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchIntegrations();
    }, []);

    const handleCardClick = (integration: Integration) => {
        if (selectedCalendarIntegration !== integration.id) {
            if (integration && INTEGRATIONS_REQUIRING_MAPPING.includes(integration.name)) {
                setPendingIntegration(integration);
                setIsFieldMappingOpen(true);
            } else {
                setSelectedCalendarIntegration(integration.id);
                setActions({
                    ...actions,
                    scheduling: {
                        id: integration.id,
                        name: integration.name,
                        details: integration.details
                    }
                });
                setNeedsUpdate(true);
            }
        }
    };

    const handleDisconnect = () => {
        setSelectedCalendarIntegration(null);
        setActions({
            ...actions,
            scheduling: null
        });
        setNeedsUpdate(true);
        setShowDisconnectModal(false);
        setIntegrationToDisconnect(null);
    };


    const handleFieldMappingSave = (fieldMappings: Record<string, string>) => {
        if (pendingIntegration && Object.keys(fieldMappings).length > 0) {
            setSelectedCalendarIntegration(pendingIntegration.id);
            setActions({
                ...actions,
                scheduling: {
                    id: pendingIntegration.id,
                    name: pendingIntegration.name,
                    details: pendingIntegration.details,
                    fields: fieldMappings
                }
            });
            setNeedsUpdate(true);
        }
        setPendingIntegration(null);
    };

    const handleModalClose = () => {
        setPendingIntegration(null);
        setIsFieldMappingOpen(false);
    };

    const handleSlackDisconnect = () => {
        setActions({
            ...actions,
            slack: null
        });
        setNeedsUpdate(true);
        setShowSlackDisconnectModal(false);
    };

    const handleTaskDefinitionSave = (taskDefinition: { id: string; name: string; steps: string[] }) => {
        let newTaskDefinitions;
        if (editingTaskDefinitionIndex !== null) {
            const updatedTaskDefinitions = [...taskDefinitions];
            updatedTaskDefinitions[editingTaskDefinitionIndex] = taskDefinition;
            setTaskDefinitions(updatedTaskDefinitions);
        } else {
            setTaskDefinitions([...taskDefinitions, taskDefinition]);
        }

        setActions({
            ...actions,
            taskDefinitions: editingTaskDefinitionIndex !== null
                ? taskDefinitions.map((d, i) => i === editingTaskDefinitionIndex ? taskDefinition : d)
                : [...taskDefinitions, taskDefinition]
        });
        setNeedsUpdate(true);
        setEditingTaskDefinitionIndex(null);
    };

    const handleEditTaskDefinition = (index: number) => {
        setEditingTaskDefinitionIndex(index);
        setTimeout(() => {
            setIsTaskDefinitionModalOpen(true);
        }, 0);
    };

    const handleRemoveTaskDefinition = (index: number) => {
        const updatedTaskDefinitions = taskDefinitions.filter((_, i) => i !== index);
        setTaskDefinitions(updatedTaskDefinitions);
        setActions({
            ...actions,
            taskDefinitions: updatedTaskDefinitions
        });
        setNeedsUpdate(true);
    };

    const handleCrmIntegrationClick = (integration: Integration) => {
        setSelectedCrmIntegration(integration);
        if (integration.name === 'hubspot') {
            setIsHubspotModalOpen(true);
        } else if (integration.name === 'salesforce') {
            setIsSalesforceModalOpen(true);
        }
    };

    const handleHubspotMappingSave = (fieldMappings: Record<string, string>, defaultFields: Record<string, string>) => {
        const existingCrms = actions.crm || [];
        const crmIndex = existingCrms.findIndex((crm: any) => crm.id === selectedCrmIntegration?.id);
        
        const updatedCrm = {
            id: selectedCrmIntegration?.id,
            name: selectedCrmIntegration?.name,
            fields: fieldMappings,
            default_fields: defaultFields
        };

        let updatedCrms;
        if (crmIndex >= 0) {
            updatedCrms = existingCrms.map((crm: any, index: number) => 
                index === crmIndex ? updatedCrm : crm
            );
        } else {
            updatedCrms = [...existingCrms, updatedCrm];
        }

        setActions({
            ...actions,
            crm: updatedCrms
        });
        setNeedsUpdate(true);
    };

    const handleRemoveCrmIntegration = (integration: Integration) => {
        const updatedCrms = actions.crm?.filter((crm: any) => crm.id !== integration.id) || [];
        setActions({
            ...actions,
            crm: updatedCrms
        });
        setNeedsUpdate(true);
    };

    const handleRemoveSlackConfig = (e: React.MouseEvent) => {
        e.stopPropagation();
        setActions({
            ...actions,
            slack: undefined
        });
        setNeedsUpdate(true);
    };

    const handleRemoveScheduling = (integration: Integration) => {
        setSelectedCalendarIntegration(null);
        setActions({
            ...actions,
            scheduling: undefined
        });
        setNeedsUpdate(true);
    };

    return (
        <div className="mt-6 space-y-4">
            {agentType !== 'product' && (
                <div className="p-6 border rounded-lg">
                    <label className="block text-xl font-semibold leading-6">
                        CRM
                    </label>
                    <label className="block text-sm font-light leading-6">
                        Configure CRM integrations to create and update contacts.
                    </label>

                    {isLoading ? (
                        <LoadingSkeleton />
                    ) : (
                        <div className="mt-6 space-y-4">
                            {crmIntegrations.map((integration) => {
                                const configuredCrm = actions.crm?.find((crm: any) => crm.id === integration.id);
                                
                                return (
                                    <div
                                        key={integration.id}
                                        className="p-4 rounded-lg border"
                                    >
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center space-x-4">
                                                <img
                                                    src={
                                                        integration.name === 'hubspot' ? HubspotIcon :
                                                        integration.name === 'salesforce' ? SalesforceIcon :
                                                        undefined
                                                    }
                                                    alt={`${integration.name} Icon`}
                                                    className={`${integration.name === 'salesforce' ? 'h-4' : 'h-8'}`}
                                                />
                                                <div>
                                                    <h3 className="text-md font-medium">
                                                        {integration.name === 'hubspot' ? 'HubSpot' : 'Salesforce'}
                                                    </h3>
                                                    <p className="text-sm text-slate-500 mt-1">
                                                        {configuredCrm ? (
                                                            <>
                                                                {Object.keys(configuredCrm.fields || {}).length} fields mapped
                                                                {Object.keys(configuredCrm.default_fields || {}).length > 0 && (
                                                                    <>, {Object.keys(configuredCrm.default_fields).length} default fields</>
                                                                )}
                                                            </>
                                                        ) : (
                                                            'Not configured'
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <Button
                                                    variant="ghost"
                                                    size="icon"
                                                    onClick={() => handleCrmIntegrationClick(integration)}
                                                    className="text-blue-600 hover:text-blue-800"
                                                >
                                                    <Pencil className="h-4 w-4" />
                                                </Button>
                                                {configuredCrm && (
                                                    <Button
                                                        variant="ghost"
                                                        size="icon"
                                                        onClick={() => handleRemoveCrmIntegration(integration)}
                                                        className="text-red-600 hover:text-red-800"
                                                    >
                                                        <Trash2 className="h-4 w-4" />
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {!isLoading && crmIntegrations.length === 0 && (
                                <div className="text-center p-8 border border-dashed rounded-lg">
                                    <p className="mb-4">
                                        No CRM integrations found. Add one to get started.
                                    </p>
                                    <Button
                                        variant="default"
                                        className="inline-flex items-center"
                                        asChild
                                    >
                                        <Link to="/integrations">
                                            <Plus className="mr-2 h-4 w-4" />
                                            Add Integration
                                        </Link>
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            {agentType !== 'product' && (
                <div className="p-6 border rounded-lg">
                    <label className="block text-xl font-semibold leading-6">
                        Scheduling
                    </label>
                    <label className="block text-sm font-light leading-6">
                        Configure calendar integrations to allow your agent to schedule meetings.
                    </label>

                    {isLoading ? (
                        <LoadingSkeleton />
                    ) : (
                        <div className="mt-6 space-y-4">
                            {calendarIntegrations.map((integration) => (
                                <div
                                    key={integration.id}
                                    className={clsx(
                                        'p-4 rounded-lg border transition-all duration-200'
                                    )}
                                >
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-4">
                                            <img
                                                src={
                                                    integration.name === 'chili-piper' ? ChiliPiperIcon :
                                                        integration.name === 'calendly' ? CalendlyIcon :
                                                            integration.name === 'tidycal' ? TidyCalIcon :
                                                                undefined
                                                }
                                                alt={`${integration.name} Icon`}
                                                className="h-8"
                                            />
                                            <div>
                                                <h3 className="text-md font-medium">
                                                    {integration.name === 'chili-piper' ? 'Chili Piper' :
                                                        integration.name === 'calendly' ? 'Calendly' :
                                                            integration.name === 'tidycal' ? 'TidyCal' :
                                                                integration.name.charAt(0).toUpperCase() + integration.name.slice(1)}
                                                </h3>
                                                <p className="text-sm text-slate-500 mt-1">
                                                    {selectedCalendarIntegration === integration.id ? 'Configured' : 'Not configured'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={() => handleCardClick(integration)}
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                <Pencil className="h-4 w-4" />
                                            </Button>
                                            {selectedCalendarIntegration === integration.id && (
                                                <Button
                                                    variant="ghost"
                                                    size="icon"
                                                    onClick={() => handleRemoveScheduling(integration)}
                                                    className="text-red-600 hover:text-red-800"
                                                >
                                                    <Trash2 className="h-4 w-4" />
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {!isLoading && calendarIntegrations.length === 0 && (
                                <div className="text-center p-8 border border-dashed rounded-lg">
                                    <p className="mb-4">
                                        No calendar integrations found. Add one to get started.
                                    </p>
                                    <Button
                                        variant="default"
                                        className="inline-flex items-center"
                                        asChild
                                    >
                                        <Link to="/integrations">
                                            <Plus className="mr-2 h-4 w-4" />
                                            Add Integration
                                        </Link>
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            <Dialog open={showDisconnectModal} onOpenChange={setShowDisconnectModal}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Action</DialogTitle>
                    </DialogHeader>
                    <div className="mt-2">
                        <p className="text-sm text-muted-foreground">
                            Are you sure you want to delete {integrationToDisconnect?.name}?
                        </p>
                    </div>
                    <DialogFooter>
                        <Button
                            variant="outline"
                            onClick={() => setShowDisconnectModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="destructive"
                            onClick={handleDisconnect}
                        >
                            Delete
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            <SchedulingFieldsModal
                isOpen={isFieldMappingOpen}
                closeModal={handleModalClose}
                formFields={formFields}
                onSave={handleFieldMappingSave}
                existingMappings={actions.scheduling?.fields || {}}
                integrationName={pendingIntegration?.name || ''}
            />

            <div className="p-6 shadow-sm border rounded-lg">
                <label className="block text-xl font-semibold leading-6">
                    Notifications
                </label>
                <label className="block text-sm font-light leading-6 mt-1">
                    Send notifications to external channels.
                </label>

                {isLoading ? (
                    <LoadingSkeleton />
                ) : (
                    <div className="mt-6 space-y-4">
                        {slackIntegration ? (
                            <div
                                className={clsx(
                                    'p-4 rounded-lg border transition-all duration-200'
                                )}
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <img src={SlackIcon} alt="Slack Icon" className="h-8" />
                                        <div>
                                            <h3 className="text-md font-medium">Slack</h3>
                                            <p className="text-sm text-slate-500 mt-1">
                                                {actions.slack ? 
                                                    `${actions.slack.configs?.length || 0} channels configured` : 
                                                    'Not configured'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => {
                                                setSelectedNotificationIntegration(slackIntegration);
                                                setIsSlackModalOpen(true);
                                            }}
                                            className="text-blue-600 hover:text-blue-800"
                                        >
                                            <Pencil className="h-4 w-4" />
                                        </Button>
                                        {actions.slack && (
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={(e) => handleRemoveSlackConfig(e)}
                                                className="text-red-600 hover:text-red-800"
                                            >
                                                <Trash2 className="h-4 w-4" />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="text-center p-8 border border-dashed rounded-lg">
                                <p className="mb-4">
                                    No notification integrations found. Add Slack to get started.
                                </p>
                                <Button
                                    variant="default"
                                    className="inline-flex items-center"
                                    asChild
                                >
                                    <Link to="/integrations">
                                        <Plus className="mr-2 h-4 w-4" />
                                        Add Integration
                                    </Link>
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <SlackNotificationsModal
                isOpen={isSlackModalOpen}
                closeModal={() => setIsSlackModalOpen(false)}
                integration={selectedNotificationIntegration}
                escalationEnabled={escalationEnabled}
                value={actions.slack}
                onSave={(value) => {
                    setActions({
                        ...actions,
                        slack: value
                    });
                    setNeedsUpdate(true);
                }}
            />

            <Dialog open={showSlackDisconnectModal} onOpenChange={setShowSlackDisconnectModal}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Action</DialogTitle>
                    </DialogHeader>
                    <div className="mt-2">
                        <p className="text-sm text-muted-foreground">
                            Are you sure you want to remove Slack notifications?
                        </p>
                    </div>
                    <DialogFooter>
                        <Button
                            variant="outline"
                            onClick={() => setShowSlackDisconnectModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="destructive"
                            onClick={handleSlackDisconnect}
                        >
                            Delete
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            <div className="p-6 border rounded-lg">
                <label className="block text-xl font-semibold leading-6">
                    Task Definitions
                </label>
                <label className="block text-sm font-light leading-6">
                    Create task definitions for the AI to execute for your users.
                </label>

                <div className="mt-6 space-y-4">
                    <Button
                        onClick={() => setIsTaskDefinitionModalOpen(true)}
                        className="inline-flex items-center"
                    >
                        <Plus className="mr-2 h-4 w-4" />
                        Create Task Definition
                    </Button>

                    {taskDefinitions.length > 0 ? (
                        <div className="space-y-4">
                            {taskDefinitions.map((taskDefinition, index) => (
                                <div key={index} className="p-4 rounded-lg border">
                                    <div className="flex items-center justify-between mb-4">
                                        <h3 className="text-lg font-medium">
                                            {taskDefinition.name}
                                        </h3>
                                        <div className="flex items-center gap-2">
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={() => handleEditTaskDefinition(index)}
                                            >
                                                <Pencil className="h-4 w-4" />
                                            </Button>
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={() => handleRemoveTaskDefinition(index)}
                                            >
                                                <Trash2 className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    </div>
                                    <ol className="space-y-2">
                                        {taskDefinition.steps.map((step, stepIndex) => (
                                            <li key={stepIndex} className="text-sm">
                                                {stepIndex + 1}. {step}
                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center p-8 border border-dashed rounded-lg">
                            <p className="">
                                Create a task definition for Operator to execute for your users
                            </p>
                        </div>
                    )}
                </div>
            </div>

            <TaskDefinitionModal
                isOpen={isTaskDefinitionModalOpen}
                closeModal={() => {
                    setIsTaskDefinitionModalOpen(false);
                    setEditingTaskDefinitionIndex(null);
                }}
                onSave={handleTaskDefinitionSave}
                initialTaskDefinition={editingTaskDefinitionIndex !== null ? taskDefinitions[editingTaskDefinitionIndex] : undefined}
                isEditing={editingTaskDefinitionIndex !== null}
            />

            <HubspotFieldsModal
                isOpen={isHubspotModalOpen}
                closeModal={() => setIsHubspotModalOpen(false)}
                formFields={formFields}
                onSave={handleHubspotMappingSave}
                existingMappings={actions.crm?.find((crm: any) => crm.id === selectedCrmIntegration?.id)?.fields || {}}
                defaultFields={actions.crm?.find((crm: any) => crm.id === selectedCrmIntegration?.id)?.default_fields || {}}
                integrationId={selectedCrmIntegration?.id || ''}
            />

            <SalesforceFieldsModal
                isOpen={isSalesforceModalOpen}
                closeModal={() => setIsSalesforceModalOpen(false)}
                formFields={formFields}
                onSave={handleHubspotMappingSave}
                existingMappings={actions.crm?.find((crm: any) => crm.id === selectedCrmIntegration?.id)?.fields || {}}
                defaultFields={actions.crm?.find((crm: any) => crm.id === selectedCrmIntegration?.id)?.default_fields || {}}
                integrationId={selectedCrmIntegration?.id || ''}
            />

            <HubspotFieldsModal
                isOpen={isHubspotModalOpen}
                closeModal={() => setIsHubspotModalOpen(false)}
                formFields={formFields}
                onSave={handleHubspotMappingSave}
                existingMappings={actions.crm?.find((crm: any) => crm.id === selectedCrmIntegration?.id)?.fields || {}}
                defaultFields={actions.crm?.find((crm: any) => crm.id === selectedCrmIntegration?.id)?.default_fields || {}}
                integrationId={selectedCrmIntegration?.id || ''}
            />

            <SalesforceFieldsModal
                isOpen={isSalesforceModalOpen}
                closeModal={() => setIsSalesforceModalOpen(false)}
                formFields={formFields}
                onSave={handleHubspotMappingSave}
                existingMappings={actions.crm?.find((crm: any) => crm.id === selectedCrmIntegration?.id)?.fields || {}}
                defaultFields={actions.crm?.find((crm: any) => crm.id === selectedCrmIntegration?.id)?.default_fields || {}}
                integrationId={selectedCrmIntegration?.id || ''}
            />
        </div>
    );
}
