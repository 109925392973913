import { AimdocComponentContext } from '@aimdoc/sdk-react';
import Actions from '../Actions';
import { ActionsTabProps } from './types';

export default function ActionsTab({
  agId,
  agentName,
  actions,
  setActions,
  agentType,
  form,
  setNeedsUpdate,
  escalationEnabled
}: ActionsTabProps) {
  return (
    <div className="mt-6">
      <AimdocComponentContext
        componentDescription="You are in the actions tab of the agent builder. Here you can configure the actions that your agent can perform."
        componentName="Actions Tab"
      />

      <Actions
        agentId={agId}
        agentName={agentName}
        actions={actions}
        setActions={setActions}
        setNeedsUpdate={setNeedsUpdate}
        formFields={form}
        agentType={agentType}
        escalationEnabled={escalationEnabled}
      />
    </div>
  );
} 