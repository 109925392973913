import React, { useEffect, useState, useRef } from "react";
import useApi from '../api';
// import SpinnerLocal from "../ui/SpinnerLocal";
import { toast } from "react-toastify";
import HubSpotIcon from '../icons/hubspot_logo.webp';
// import OntraportIcon from '../icons/ontraport_logo.webp'
// import GoogleCalendarIcon from '../icons/google_calendar.webp';
// import Ontraport from './Ontraport';
import ChiliPiper from './ChiliPiper';
import Calendly from './Calendly';
import TidyCal from './TidyCal';
import ChiliPiperIcon from '../icons/chili_piper_logo.png';
import SalesforceIcon from '../icons/salesforce_logo.png';
import CalendlyIcon from '../icons/calendly_logo.png';
import TidyCalIcon from '../icons/tidycal_logo.jpeg';
import SlackIcon from '../icons/slack_logo.webp';

import { useUser } from "../user/UserContext";
import { Separator } from "@/components/ui/separator";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Loader2 } from "lucide-react"

interface Integration {
    id: string;
    name: string;
    description: string;
    details: any;
}

interface IntegrationParams {
    appId?: string;
    apiKey?: string;
    domainName?: string;
    inboundRouter?: string;
    calendlyUrl?: string;
    tidyCalUrl?: string;
    appName?: string;
}

const typeMap = {
    'google-calendar': 'user',
    'chili-piper': 'organization',
    'hubspot': 'organization',
    'salesforce': 'organization',
    'ontraport': 'organization',
    'calendly': 'user',
    'tidycal': 'user',
    'slack': 'organization'
}

interface IntegrationConfig {
    name: string;
    sysName: string;
    description: string;
    icon: string;
    connectHandler: () => Promise<void>;
}

const Integrations: React.FC = () => {
    const { get, post, remove } = useApi();
    const [integrations, setIntegrations] = useState<Integration[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);
    const [creatingIntegration, setCreatingIntegration] = useState<boolean>(false);
    const [ontraportModalVisible, setOntraportModalVisible] = useState<boolean>(false);
    const [chiliPiperModalVisible, setChiliPiperModalVisible] = useState<boolean>(false);
    const [calendlyModalVisible, setCalendlyModalVisible] = useState<boolean>(false);
    const [tidyCalModalVisible, setTidyCalModalVisible] = useState<boolean>(false);
    const [checkingLicense, setCheckingLicense] = useState<boolean>(true);
    const { user, organization, checkLicenseType } = useUser();
    const [isOperator, setIsOperator] = useState<boolean>(false);

    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    const name = queryParams.get('name');

    const creatingIntegrationRef = useRef(creatingIntegration);
    creatingIntegrationRef.current = creatingIntegration;

    const connectGoogle = async () => {
        const response = await post('/google-drive-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const connectGoogleCalendar = async () => {
        const response = await post('/google-calendar-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const connectHubSpot = async () => {
        const response = await post('/hubspot-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const connectSalesforce = async () => {
        const response = await post('/salesforce-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const connectOntraport = async () => {
        setOntraportModalVisible(true);
    };

    const connectChiliPiper = async () => {
        setChiliPiperModalVisible(true);
    };

    const connectCalendly = async () => {
        setCalendlyModalVisible(true);
    };

    const connectTidyCal = async () => {
        setTidyCalModalVisible(true);
    };

    const connectSlack = async () => {
        const response = await post('/slack-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const createIntegration = async (integration: IntegrationParams) => {
        if (creatingIntegrationRef.current) return;

        const integrationType = integration.appName || name;
        if (!integrationType || !(integrationType in typeMap)) {
            toast.error('Invalid integration type');
            return;
        }

        setCreatingIntegration(true);
        try {
            await post('/integrations/', {
                code,
                state,
                name: integrationType,
                type: typeMap[integrationType as keyof typeof typeMap],
                app_id: integration.appId,
                api_key: integration.apiKey,
                domain_name: integration.domainName,
                inbound_router: integration.inboundRouter,
                calendly_url: integration.calendlyUrl,
                tidy_cal_url: integration.tidyCalUrl
            });
            if (code) {
                window.location.href = '/integrations';
            }
            toast.success('Integration created successfully', { autoClose: 2000 });
        } catch (error) {
            toast.error('Failed to create integration');
        } finally {
            setCreatingIntegration(false);
            setOntraportModalVisible(false);
            setChiliPiperModalVisible(false);
            setCalendlyModalVisible(false);
            setTidyCalModalVisible(false);
            getIntegrations();
        }
    };

    const deleteIntegration = async (id: string) => {
        try {
            await remove(`/integrations/${id}`);
            toast.success('Integration disconnected successfully', { autoClose: 2000 });
            setIntegrations(integrations.filter(integration => integration.id !== id));
        } catch (error: any) {
            const errorDetail = error?.detail;
            let errorMessage = 'Failed to delete integration';

            if (errorDetail) {
                errorMessage = typeof errorDetail === 'object' ? errorDetail.message : errorDetail;

                if (errorDetail.agents && Array.isArray(errorDetail.agents)) {
                    errorMessage += `\nAffected agents: ${errorDetail.agents.join(', ')}`;
                }
            }

            toast.error(errorMessage, { autoClose: 5000 });
        }
    };

    const getIntegrations = async () => {
        setFetching(true);
        try {
            const response = await get('/integrations/');
            setIntegrations(response.data);
        } catch (error) {
            toast.error('Failed to fetch integrations', { autoClose: 2000 });
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        getIntegrations();
    }, []);

    useEffect(() => {
        if (name && code && !creatingIntegrationRef.current) {
            createIntegration({
                appId: undefined,
                apiKey: undefined,
                domainName: undefined,
                inboundRouter: undefined,
                appName: name
            });
        }
    }, [name]);

    useEffect(() => {
        if (user && organization) {
            setCheckingLicense(false);
        }
    }, [user, organization]);

    useEffect(() => {
        const selectedTab = localStorage.getItem('selectedTab');
        setIsOperator(selectedTab === 'operator');
    }, []);

    const SkeletonIntegrationCard: React.FC = () => {
        return (
            <Card>
                <CardHeader className="space-y-1">
                    <div className="flex justify-between items-center">
                        <div className="h-7 bg-muted rounded w-40 animate-pulse"></div>
                        <div className="h-10 w-10 bg-muted rounded animate-pulse"></div>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="space-y-2">
                        <div className="h-4 bg-muted rounded w-full animate-pulse"></div>
                        <div className="h-4 bg-muted rounded w-full animate-pulse"></div>
                        <div className="h-4 bg-muted rounded w-3/4 animate-pulse"></div>
                    </div>
                </CardContent>
                <CardFooter>
                    <div className="h-10 w-full bg-muted rounded animate-pulse"></div>
                </CardFooter>
            </Card>
        );
    };

    const integrationConfigs: IntegrationConfig[] = [
        {
            name: 'Slack',
            sysName: 'slack',
            description: 'Send notifications about visitor interactions and leads to your Slack channels.',
            icon: SlackIcon,
            connectHandler: connectSlack
        },
        ...(isOperator ? [] : [
            {
                name: 'HubSpot',
                sysName: 'hubspot',
                description: 'Creates an Aimdoc form in your HubSpot instance and submits lead data when a lead is created in Aimdoc.',
                icon: HubSpotIcon,
                connectHandler: connectHubSpot
            },
            {
                name: 'Salesforce',
                sysName: 'salesforce',
                description: 'Creates a contact in Salesforce when a lead is created in Aimdoc.',
                icon: SalesforceIcon,
                connectHandler: connectSalesforce
            },
            {
                name: 'Chili Piper',
                sysName: 'chili-piper',
                description: 'Schedule meetings with customers via Chili Piper.',
                icon: ChiliPiperIcon,
                connectHandler: connectChiliPiper
            },
            {
                name: 'Calendly',
                sysName: 'calendly',
                description: 'Schedule meetings with customers via Calendly.',
                icon: CalendlyIcon,
                connectHandler: connectCalendly
            },
            {
                name: 'TidyCal',
                sysName: 'tidycal',
                description: 'Schedule meetings with customers via TidyCal.',
                icon: TidyCalIcon,
                connectHandler: connectTidyCal
            }
        ])
    ];

    const renderIntegrationCard = (config: IntegrationConfig) => {
        const existingIntegration = integrations.find(integration => integration.name === config.sysName);
        const isFreeTierIntegration = config.sysName === 'calendly' || config.sysName === 'tidycal';

        if (fetching) {
            return <SkeletonIntegrationCard />
        }

        return (
            <Card key={config.sysName} className="transition-all duration-200 hover:shadow-md flex flex-col">
                <CardHeader>
                    <div className="flex justify-between items-center">
                        <CardTitle className="text-xl font-semibold">{config.name}</CardTitle>
                        <div className="flex-shrink-0">
                            <img src={config.icon} alt={`${config.name} Icon`} className="h-10 w-auto" />
                        </div>
                    </div>
                    <CardDescription className="text-md min-h-[60px]">
                        {config.description}
                    </CardDescription>
                </CardHeader>
                <CardFooter className="mt-auto">
                    {existingIntegration ? (
                        <Button 
                            variant="secondary"
                            className="w-full"
                            onClick={() => deleteIntegration(existingIntegration.id)}
                        >
                            DISCONNECT
                        </Button>
                    ) : (
                        <Button
                            variant="default"
                            className="w-full"
                            onClick={config.connectHandler}
                            disabled={checkingLicense || (!isFreeTierIntegration && !checkLicenseType('pro'))}
                        >
                            {checkingLicense ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Loading...
                                </>
                            ) : !isFreeTierIntegration && !checkLicenseType('pro') ? (
                                'UPGRADE TO PRO'
                            ) : (
                                'CONNECT'
                            )}
                        </Button>
                    )}
                </CardFooter>
                {config.sysName === 'chili-piper' && chiliPiperModalVisible && (
                    <ChiliPiper
                        createIntegration={createIntegration}
                        setModalVisible={setChiliPiperModalVisible}
                        modalVisible={chiliPiperModalVisible}
                        cpDomainName={existingIntegration?.details.domain_name}
                        cpInboundRouter={existingIntegration?.details.inbound_router}
                    />
                )}
                {config.sysName === 'calendly' && calendlyModalVisible && (
                    <Calendly
                        calendlyBookingUrl={existingIntegration?.details.calendly_url}
                        createIntegration={createIntegration}
                        setModalVisible={setCalendlyModalVisible}
                        modalVisible={calendlyModalVisible}
                    />
                )}
                {config.sysName === 'tidycal' && tidyCalModalVisible && (
                    <TidyCal
                        tidyCalBookingUrl={existingIntegration?.details.tidy_cal_url}
                        createIntegration={createIntegration}
                        setModalVisible={setTidyCalModalVisible}
                        modalVisible={tidyCalModalVisible}
                    />
                )}
            </Card>
        );
    };

    return (
        <div className="p-12 overflow-y-auto max-h-[calc(100vh-1px)] scrollbar-thin grow">
            <div className="space-y-0.5">
                <h1 className='text-4xl font-bold tracking-tight'>Integrations</h1>
                <p className='text-muted-foreground'>
                    Connect your agents to other tools and platforms to automate your workflows.
                </p>
            </div>
            <Separator className="my-6" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {integrationConfigs.map(config => renderIntegrationCard(config))}
            </div>
            {fetching && (
                <div className="w-full flex justify-center mt-8">
                    <Loader2 className="h-8 w-8 animate-spin" />
                </div>
            )}
        </div>
    );
};

export default Integrations;