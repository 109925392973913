import { useState, useEffect } from 'react';
import useApi from '../../api';
import { conLog } from '../../utils/conLog';
import { toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import BeatLoader from '../../ui/BeatLoader';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

export default function SelectFiles({ agentId, setShowFiles, setFilesSelected }: { agentId: string, setShowFiles: any, setFilesSelected: any }) {
  const [isOpen, setIsOpen] = useState(true);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set()); 
  const { get, post } = useApi();

  function closeModal() {
    setIsOpen(false);
    setShowFiles(false);
  }

  function toggleFileSelection(fileId: string) {
    setSelectedFiles((prevSelectedFiles) => {
      const updatedSelectedFiles = new Set(prevSelectedFiles);
      if (updatedSelectedFiles.has(fileId)) {
        updatedSelectedFiles.delete(fileId);
      } else {
        updatedSelectedFiles.add(fileId);
      }
      return updatedSelectedFiles;
    });
  }

  function associateFilesWithAgent() {
    const selectedFilesArray = Array.from(selectedFiles);

    post(`/agents/${agentId}/files`, { file_ids: selectedFilesArray })
      .then((response) => {
        setFilesSelected(selectedFilesArray);
        setShowFiles(false); 
        closeModal();
        toast.success('Files associated successfully', toastConfig);
        return response.data;
      })
      .catch((error) => {
        console.error('Error associating files:', error);
      });
  }

  useEffect(() => {
    if (!isOpen) return;

    const url = agentId ? `/files/?exclude_agent_id=${agentId}` : '/files/';

    get(url)
      .then((response) => {
        conLog(response.data);
        setFiles(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching files:', error);
      });
  }, [isOpen, agentId]);

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && closeModal()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select Files</DialogTitle>
        </DialogHeader>

        <div className="overflow-y-auto max-h-96 space-y-3">
          {files && files.length > 0 && (
            files.map((file: any) => (
              <div key={file.id} className="flex items-center space-x-3 p-2 rounded-md">
                <input
                  type="checkbox"
                  id={file.id}
                  checked={selectedFiles.has(file.id)}
                  onChange={() => toggleFileSelection(file.id)}
                  className="form-checkbox h-5 w-5"
                />
                <label htmlFor={file.id} className="text-sm break-all">
                  {file.name}
                </label>
              </div>
            ))
          )}
          {loading && (
            <BeatLoader />
          )}
          {files.length === 0 && !loading && (
            <p>No files available</p>
          )}
        </div>

        <DialogFooter>
          <Button
            disabled={selectedFiles.size === 0}
            onClick={associateFilesWithAgent}
          >
            Select Files
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}