import { useState, useEffect } from 'react';
import useApi from '../api';
import { toast } from 'react-toastify';
import {CartesianGrid, XAxis, YAxis, Area, AreaChart } from 'recharts';
import { conLog } from '../utils/conLog';
import logo_symbol from '../../logo_symbol.png';
import { ArrowDownIcon, ArrowUpIcon, Clock, ExpandIcon, MessageCircle, MessagesSquare, Users } from "lucide-react";

// shadcn imports
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { type ChartConfig } from "@/components/ui/chart"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { ChartLegend, ChartLegendContent } from "@/components/ui/chart";
import { InteractiveBarChart } from "./InteractiveBarChart"
import LeadsPerDay from './LeadsPerDay';
import { Button } from '@/components/ui/button';
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";


interface AnalyticsData {
    total_messages_last_7_days: number;
    total_conversations_last_7_days: number;
    messages_per_day: {
        day: string;
        message_count: number;
    }[];
    conversations_per_day: {
        day: string;
        conversation_count: number;
    }[];
    messages_by_hour: {
        hour: number;
        message_count: number;
    }[];
    average_conversation_duration_minutes: number;
    previous_week: {
        total_messages: number;
        total_conversations: number;
        average_messages_per_conversation: number;
        average_conversation_duration_minutes: number;
    };
    combined_data: {
        day: string;
        message_count: number;
        conversation_count: number;
    }[];
}

const chartConfig = {
    messages: {
        label: "Messages",
        color: "hsl(210, 80%, 70%)"  // Light blue
    },
    conversations: {
        label: "Conversations",
        color: "hsl(210, 80%, 40%)"  // Dark blue
    }
} satisfies ChartConfig

type MetricCard = {
    title: string;
    icon: React.ComponentType<{ className?: string }>;
    getValue: (data: AnalyticsData) => number;
    getPreviousValue: (data: AnalyticsData) => number;
    suffix?: string;
};

const METRIC_CARDS: MetricCard[] = [
    {
        title: "Total Messages",
        icon: MessageCircle,
        getValue: (data) => data.total_messages_last_7_days,
        getPreviousValue: (data) => data.previous_week?.total_messages ?? 0,
    },
    {
        title: "Total Conversations",
        icon: Users,
        getValue: (data) => data.total_conversations_last_7_days,
        getPreviousValue: (data) => data.previous_week?.total_conversations ?? 0,
    },
    {
        title: "Avg Messages/Conversation",
        icon: MessagesSquare,
        getValue: (data) => data.total_conversations_last_7_days 
            ? (data.total_messages_last_7_days / data.total_conversations_last_7_days)
            : 0,
        getPreviousValue: (data) => data.previous_week?.average_messages_per_conversation ?? 0,
    },
    {
        title: "Avg Duration",
        icon: Clock,
        getValue: (data) => data.average_conversation_duration_minutes,
        getPreviousValue: (data) => data.previous_week?.average_conversation_duration_minutes ?? 0,
        suffix: "min"
    },
];

const TrendIndicator = ({ currentValue, previousValue }: { currentValue: number, previousValue: number }) => {
    // If both values are 0, show "No data"
    if (currentValue === 0 && previousValue === 0) {
        return (
            <div className="hidden lg:block text-xs text-muted-foreground">
                No data yet
            </div>
        );
    }
    
    // If previousValue is 0 but currentValue isn't, show "New"
    if (previousValue === 0 && currentValue > 0) {
        return (
            <div className="hidden lg:block text-xs text-yellow-500 font-medium">
                {"< "} 1 week of data
            </div>
        );
    }

    // If currentValue is 0 but previousValue isn't, show "100% down"
    if (currentValue === 0 && previousValue > 0) {
        return (
            <div className="hidden lg:flex flex-col items-end">
                <div className="flex items-center gap-1 text-xs font-medium text-red-500">
                    <ArrowDownIcon className="w-3 h-3" />
                    <span>100%</span>
                </div>
                <span className="text-[10px] text-muted-foreground">
                    down from last week
                </span>
            </div>
        );
    }
    
    // Simple percentage change calculation
    let percentageChange = 0;
    if (previousValue !== 0) {
        percentageChange = ((currentValue - previousValue) / previousValue) * 100;
    }
    
    const isPositive = percentageChange > 0;
    
    if (Math.abs(percentageChange) < 0.1) {
        return (
            <div className="hidden lg:block text-xs text-muted-foreground">
                No change from last week
            </div>
        );
    }
    
    return (
        <div className="hidden lg:flex flex-col items-end">
            <div className={`flex items-center gap-1 text-xs font-medium ${isPositive ? 'text-green-500' : 'text-red-500'}`}>
                {isPositive ? <ArrowUpIcon className="w-3 h-3" /> : <ArrowDownIcon className="w-3 h-3" />}
                <span>{Math.abs(percentageChange).toFixed(1)}%</span>
            </div>
            <span className="text-[10px] text-muted-foreground">
                {isPositive ? 'up' : 'down'} from last week
            </span>
        </div>
    );
};

const Analytics = () => {
    const { get } = useApi();
    const [analyticsData, setAnalyticsData] = useState<AnalyticsData | null>(null);
    const [agent, setAgent] = useState(() => {
        const selectedTab = localStorage.getItem('selectedTab');
        // Map selectedTab to appropriate agent type
        switch (selectedTab) {
            case 'sales':
                return 'website';
            case 'operator':
                return 'product';
            default:
                return 'all';
        }
    });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    // Update localStorage when agent changes
    const handleAgentChange = (value: string) => {
        setAgent(value);
        // Map agent type back to tab type
        switch (value) {
            case 'website':
                localStorage.setItem('selectedTab', 'sales');
                break;
            case 'product':
                localStorage.setItem('selectedTab', 'operator');
                break;
            case 'all':
                localStorage.setItem('selectedTab', 'all');
                break;
        }
    };

    useEffect(() => {
        fetchAnalyticsData();
    }, [agent]);

    const fetchAnalyticsData = async () => {
        try {
            // Modify the API request to handle 'all' case
            const agentParam = agent === 'all' ? '' : `?agent_type=${agent}`;
            const response = await get(`/analytics/${agentParam}`);
            conLog(response.data);
            
            // Create combined data for messages and conversations
            const combinedData = response.data.messages_per_day.map((msgData: { day: any; message_count: any; }) => {
                const convData = response.data.conversations_per_day.find(
                    (conv: { day: any; }) => conv.day === msgData.day
                );
                return {
                    day: msgData.day,
                    message_count: msgData.message_count,
                    conversation_count: convData?.conversation_count || 0
                };
            }).sort((a: { day: string | number | Date; }, b: { day: string | number | Date; }) => new Date(a.day).getTime() - new Date(b.day).getTime());

            const sortedData = {
                ...response.data,
                conversations_per_day: [...response.data.conversations_per_day].sort(
                    (a: { day: string | number | Date; }, b: { day: string | number | Date; }) => new Date(a.day).getTime() - new Date(b.day).getTime()
                ),
                combined_data: combinedData
            };
            setAnalyticsData(sortedData);
        } catch (error) {
            toast.error('Error fetching analytics data');
        }
    };

    if (!analyticsData) {
        return (
            <div className="fixed inset-0 z-50 flex justify-center items-center bg-white/50 dark:bg-slate-900/50 backdrop-blur-sm">
                <img src={logo_symbol} alt="logo" className="w-16 h-16 animate-bounce" />
            </div>
        );
    }

    const formatDate = (dateStr: string) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    };

    const selectedTab = localStorage.getItem('selectedTab') || 'sales';


    return (
        <div className="grow">
            {/* Stats Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                {METRIC_CARDS.map((card, index) => (
                    <Card key={index}>
                        <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0">
                            <CardTitle className="text-sm font-medium">
                                {card.title}
                            </CardTitle>
                            <card.icon className="w-4 h-4 text-muted-foreground" />
                        </CardHeader>
                        <CardContent>
                            <div className="flex flex-row items-baseline justify-between">
                                <div className="text-2xl font-bold">
                                    {card.getValue(analyticsData).toFixed(1)}
                                    {card.suffix && <span className="text-sm ml-1">{card.suffix}</span>}
                                </div>
                                <TrendIndicator 
                                    currentValue={card.getValue(analyticsData)}
                                    previousValue={card.getPreviousValue(analyticsData)}
                                />
                            </div>
                            <p className="text-xs text-muted-foreground mt-1">

                            </p>
                        </CardContent>
                    </Card>
                ))}
            </div>

            {/* Combined Area Chart */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                {/* Combined Area Chart */}
                <Card>
                    <CardHeader className="flex items-center gap-2 space-y-0 border-b py-5 sm:flex-row">
                        <div className="grid flex-1 gap-1 text-center sm:text-left">
                            <CardTitle>Messages & Conversations Overview</CardTitle>
                            <CardDescription>
                                Combined view of daily messages and conversations
                            </CardDescription>
                        </div>
                        <Select value={agent} onValueChange={handleAgentChange}>
                            <SelectTrigger
                                className="w-[160px] sm:ml-auto"
                                aria-label="Select time range"
                            >
                                <SelectValue placeholder="Select agent type" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="all">All agents</SelectItem>
                                <SelectItem value="website">Sales agents</SelectItem>
                                <SelectItem value="product">Operator agents</SelectItem>
                            </SelectContent>
                        </Select>
                    </CardHeader>
                    <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">
                        {analyticsData.messages_per_day.length === 0 ? (
                            <div className="h-[250px] flex items-center justify-center text-muted-foreground">
                                No data yet!
                            </div>
                        ) : (
                            <ChartContainer
                                config={chartConfig}
                                className="aspect-auto h-[250px] w-full"
                            >
                                <AreaChart data={analyticsData.combined_data}>
                                    <defs>
                                        <linearGradient id="fillMessages" x1="0" y1="0" x2="0" y2="1">
                                            <stop
                                                offset="5%"
                                                stopColor="var(--color-messages)"
                                                stopOpacity={0.8}
                                            />
                                            <stop
                                                offset="95%"
                                                stopColor="var(--color-messages)"
                                                stopOpacity={0.1}
                                            />
                                        </linearGradient>
                                        <linearGradient id="fillConversations" x1="0" y1="0" x2="0" y2="1">
                                            <stop
                                                offset="5%"
                                                stopColor="var(--color-conversations)"
                                                stopOpacity={0.8}
                                            />
                                            <stop
                                                offset="95%"
                                                stopColor="var(--color-conversations)"
                                                stopOpacity={0.1}
                                            />
                                        </linearGradient>
                                    </defs>
                                    <CartesianGrid vertical={false} />
                                    <XAxis
                                        dataKey="day"
                                        tickLine={false}
                                        axisLine={false}
                                        tickMargin={8}
                                        minTickGap={32}
                                        tickFormatter={formatDate}
                                    />
                                    <YAxis tickLine={false} axisLine={false} />
                                    <ChartTooltip
                                        cursor={false}
                                        content={
                                            <ChartTooltipContent
                                                labelFormatter={(value) => formatDate(value)}
                                                indicator="dot"
                                            />
                                        }
                                    />
                                    <Area
                                        dataKey="message_count"
                                        type="monotone"
                                        fill="url(#fillMessages)"
                                        stroke="var(--color-messages)"
                                        name="messages"
                                        stackId="1"
                                    />
                                    <Area
                                        dataKey="conversation_count"
                                        type="monotone"
                                        fill="url(#fillConversations)"
                                        stroke="var(--color-conversations)"
                                        name="conversations"
                                        stackId="2"
                                    />
                                    <ChartLegend 
                                        content={<ChartLegendContent nameKey="name" />}
                                        verticalAlign="bottom"
                                        align="center"
                                    />
                                </AreaChart>
                            </ChartContainer>
                        )}
                        <Button 
                            variant="outline" 
                            className="mt-4 w-full"
                            onClick={() => setIsDrawerOpen(true)}
                        >
                            Expand <ExpandIcon className="w-4 h-4 ml-2" />
                        </Button>
                    </CardContent>
                </Card>

                {/* Leads Per Day Chart */}
                <Card>
                <CardHeader className="flex items-center gap-2 space-y-0 border-b py-5 sm:flex-row">
                    <div className="grid flex-1 gap-1 text-center sm:text-left">
                        <CardTitle>Leads Per Day</CardTitle>
                        <CardDescription>
                            Daily view of leads created
                            </CardDescription>
                        </div>
                    </CardHeader>
                    <CardContent>
                        <LeadsPerDay />
                    </CardContent>
                </Card>
            </div>

        {/* Activity Overview Drawer */}
        <Sheet open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
            <SheetContent side="bottom" className="h-[60vh] w-full">
                <SheetHeader className="mb-4">
                    <SheetTitle>Browse by Activity Type</SheetTitle>
                </SheetHeader>
                {/* Replace the two cards with InteractiveBarChart */}
                <div className="mt-4">
                    <InteractiveBarChart data={analyticsData.combined_data} />
                </div>
            </SheetContent>
        </Sheet>
        </div>
    );
};

export default Analytics;