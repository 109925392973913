import { Label } from '@/components/ui/label';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { InstructionsTabProps } from './types';

export default function InstructionsTab({
  agId,
  goal,
  setGoal,
  persona,
  setPersona,
  agentType,
  setAgentType
}: InstructionsTabProps) {
  return (
    <div className="w-full mt-6">
      {!agId && (
        <Card className="mb-6">
          <CardHeader>
            <CardTitle>Agent Type</CardTitle>
            <CardDescription>
              Choose whether this is a website agent for converting visitors, or a product agent for helping users.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="mt-3 w-1/2">
              <div className="grid grid-cols-4 gap-4">
                {['product', 'website'].map((type) => (
                  <Button
                    key={type}
                    onClick={() => setAgentType(type)}
                    className={`
                      p-2 rounded-xl text-md font-medium text-center
                      transition-all duration-200 ease-in-out
                      border-2 
                      ${type.toLowerCase() === agentType?.toLowerCase()
                        ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300'
                        : 'border-gray-200 dark:border-slate-700 hover:border-blue-300 dark:hover:border-blue-700 text-gray-800 dark:text-slate-200'
                      }
                    `}
                  >
                    {type}
                  </Button>
                ))}
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      <div className="space-y-4">
        <div className="border rounded-lg p-6">
          <Label className="block text-xl font-semibold leading-6">
            Persona
          </Label>
          <Label className="mt-2 block text-sm font-light leading-6">
            This is the buyer persona you expect to be interacting with the agent. A more detailed description yields higher quality results. Example: "Pre-seed Startups, 1-5 employees, Automotive Industry".
          </Label>
          <div className="mt-3">
            <Textarea
              name="persona"
              id="persona"
              autoComplete="off"
              placeholder="Enter your persona..."
              value={persona}
              onChange={(e) => setPersona(e.target.value)}
            />
          </div>
        </div>

        <div className="border rounded-lg p-6">
          <Label className="block text-xl font-semibold leading-6">
            Goal
          </Label>
          <Label className="mt-2 block text-sm font-light leading-6">
            How should your agent interact with prospects? What is your agent's personality? What should it try and achieve? Example: "Engage prospects in a friendly manner, provide information about our product, and schedule a demo."
          </Label>
          <div className="mt-3">
            <Textarea
              name="goal"
              id="goal"
              autoComplete="off"
              placeholder="Enter your goal..."
              value={goal}
                onChange={(e) => setGoal(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
} 