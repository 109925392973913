import { useState } from 'react'
import useApi from '../api'
import { toast } from 'react-toastify'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"

interface TidyCalProps {
    tidyCalBookingUrl: string;
    createIntegration: any;
    modalVisible: boolean;
    setModalVisible: any;
}

export default function TidyCal({ tidyCalBookingUrl, createIntegration, modalVisible, setModalVisible }: TidyCalProps) {
    const [tidyCalUrl, setTidyCalUrl] = useState(tidyCalBookingUrl ? tidyCalBookingUrl : '');
    const { get, getStream, post, postStream, put, patch, remove } = useApi();

    function validateTidyCalUrl(url: string): boolean {
        const tidyCalRegex = /^https:\/\/tidycal\.com\/[\w-]+$/;
        return tidyCalRegex.test(url);
    }

    function createTidyCalIntegration() {
        const trimmedUrl = tidyCalUrl.trim();
        if (!validateTidyCalUrl(trimmedUrl)) {
            toast.error('Please enter a valid TidyCal URL (e.g., https://tidycal.com/username)');
            return;
        }
        createIntegration({
            tidyCalUrl: trimmedUrl,
            appName: 'tidycal'
        });
    }

    return (
        <Dialog open={modalVisible} onOpenChange={setModalVisible}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Connect TidyCal</DialogTitle>
                    <DialogDescription>
                        Enter your booking URL for your desired meeting type.
                    </DialogDescription>
                </DialogHeader>

                <div className="grid gap-4 py-4">
                    <Input
                        value={tidyCalUrl}
                        onChange={(e) => setTidyCalUrl(e.target.value)}
                        placeholder="https://tidycal.com/username"
                        className={!validateTidyCalUrl(tidyCalUrl) && tidyCalUrl ? 'border-red-500' : ''}
                    />
                </div>

                <DialogFooter>
                    <Button
                        variant="outline"
                        onClick={() => setModalVisible(false)}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={createTidyCalIntegration}
                    >
                        Connect
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
