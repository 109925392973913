import { useState, useEffect } from 'react'
import useApi from '../api';
import Spinner from '../documentation/Spinner';
import useAnalytics from '../analytics/analytics';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Card, CardHeader, CardContent, CardFooter } from "@/components/ui/card"
import { Check } from 'lucide-react'

const plans = [
    {
        id: 'price_1PLtPgRrIYh07XPi9QhWz1Ad',
        name: 'Standard',
        price: '$49/month',
        features: [
            "2 Agents and 2 Seats",
            "2,000 Agent Responses",
            "5M Character Knowledge Base",
            "Session Transcript",
            "Lead Capture",
            "Unlimited Website Embeds",
            "Google Drive Integration"
        ]
    },
    {
        id: 'price_1QswrXRrIYh07XPiny0kWcXn',
        name: 'Pro',
        price: '$199/month',
        features: [
            "Everything in Standard Plan",
            "5 Agents and 5 Seats",
            "5,000 Agent Responses",
            "10M Character Knowledge Base",
            "Advanced Session Analytics",
            "HubSpot Integration",
            "Calendar Integrations",
            "Lead Qualification",
            "Email Campaign Integration"
        ]
    },
    {
        id: 'price_1Qsws2RrIYh07XPi0LvgOvfj',
        name: 'Business',
        price: '$699/month',
        features: [
            "Everything in Pro Plan",
            "20 Agents and 20 Seats",
            "30,000 Agent Responses",
            "40M Character Knowledge Base",
            "AI Session Analysis",
            "AI Lead Scoring",
            "Webhooks",
            "REST API (coming soon)",
            "Dedicated Resource",
            "Priority Support"
        ]
    }
];

declare global {
    interface Window {
        rewardful: any;
        Rewardful: any;
    }
}

export default function Upgrade({ showModal, setShowModal, sessionId, trialExpired }: { showModal: any, setShowModal: any, sessionId: string | null, trialExpired: boolean }) {
    const { post } = useApi();
    const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const { captureEvent } = useAnalytics();
    const [referralId, setReferralId] = useState<string | null>(null);

    useEffect(() => {
        if (window.rewardful) {
            window.rewardful('ready', function () {
                if (window.Rewardful && window.Rewardful.referral) {
                    setReferralId(window.Rewardful.referral);
                } else {
                }
            });
        }
    }, []);

    const createCheckoutSession = async (planId: string) => {
        setLoading(true);

        const requestBody = referralId !== null ? { referral_id: referralId } : {};

        const response = await post(`/create-checkout-session/${planId}`, requestBody);
        setLoading(false);
        if (response.data.checkout_url) {
            window.location.href = response.data.checkout_url;
        } else {
            console.error("Failed to create checkout session");
        }
    };

    const handlePlanSelect = (planId: string) => {
        setSelectedPlan(planId);
        createCheckoutSession(planId);
        captureEvent('plan_selected', {
            plan_id: planId,
            plan_name: plans.find(plan => plan.id === planId)?.name
        });
    };

    const closeAndClearSession = () => {
        if (!trialExpired) {
            setShowModal(false);
        }
        window.history.pushState({}, document.title, window.location.pathname);
        captureEvent('plan_window_closed', {});
    };

    return (
        <Dialog open={showModal} onOpenChange={closeAndClearSession}>
            <DialogContent className="max-w-5xl h-[85vh]">
                {!sessionId ? (
                    <div className="w-full h-full max-w-6xl p-8">
                        <DialogHeader>
                            <DialogTitle className="text-2xl text-center">
                                Select a Plan
                            </DialogTitle>
                        </DialogHeader>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
                            {plans.map(plan => (
                                <Card key={plan.id} className="flex flex-col justify-between">
                                    <CardHeader className="bg-muted">
                                        <h2 className="text-lg font-light">{plan.name}</h2>
                                        <div className="text-2xl font-bold mt-2">{plan.price}</div>
                                    </CardHeader>
                                    
                                    <CardContent className="mt-2 space-y-3">
                                        {plan.features.map((feature, index) => (
                                            <div key={index} className="flex items-center gap-2">
                                                <Check className="h-5 w-5 text-primary" />
                                                <span className="text-sm">
                                                    {feature}
                                                </span>
                                            </div>
                                        ))}
                                    </CardContent>

                                    <CardFooter>
                                        <Button 
                                            onClick={() => handlePlanSelect(plan.id)}
                                            className="w-full"
                                            variant="secondary"
                                        >
                                            Upgrade
                                        </Button>
                                    </CardFooter>
                                </Card>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="w-full h-full p-8 flex items-center justify-center">
                        <DialogHeader>
                            <DialogTitle className="text-4xl text-center">
                                Thank you for upgrading!
                            </DialogTitle>
                        </DialogHeader>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
}