import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useApi from '../api';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import CreateAgent from '../agents/ConfigureAgent';
import RowDelete from '../ui/RowDelete';
import useAnalytics from '../analytics/analytics';
import { formatDate } from '../utils/dateFormatter';
import { Separator } from '@/components/ui/separator';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/components/ui/table';
import { useReactTable, getCoreRowModel, flexRender, getSortedRowModel, SortingState } from '@tanstack/react-table';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Download, ArrowUpDown, ArrowRight } from 'lucide-react';
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { ShinyButton } from '../magicui/shiny-button';
import { LeadScore } from './LeadScore';
import { LeadDetails } from './LeadDetails';
import SalesforceIcon from '../../components/icons/salesforce_logo.png';
import HubspotIcon from '../../components/icons/hubspot_logo.webp';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

interface Lead {
  id: string;
  organization_id: string;
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  email_verified: boolean | null;
  job_title: string;
  integrations: {
    salesforce: string;
    salesforce_error?: string;
    hubspot: string;
    hubspot_error?: string;
  };
  created_at: string;
  updated_at: string;
  attributes: {
    loc: string | null;
    org: string | null;
    city: string | null;
    email: string;
    postal: string | null;
    region: string | null;
    country: string | null;
    hostname: string | null;
    last_name: string;
    first_name: string;
    ip_address: string | null;
    aimdoc_parent_url?: string;
  };
}

type SelectedLead = Lead | null;

const LeadLists: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [leads, setLeads] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedLead, setSelectedLead] = useState<SelectedLead>(null);
  const navigate = useNavigate();
  const { get, remove } = useApi();
  const { captureEvent } = useAnalytics();

  const toggleModal = () => {
    setShowModal(!showModal);
    navigate('/leads');
  };

  useEffect(() => {
    getLeads();
  }, []);

  const getLeads = async () => {
    try {
      setLoading(true);

      const data = await get('/leads/');

      setLeads(data.data);
      captureEvent('viewed_leads', {});
    } catch (error) {
      console.error('Error fetching products:', error);

    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {

    try {
      const response = await remove(`/leads/${id}`);
      setLeads(leads.filter((lead: any) => lead.id !== id));
      toast.success('Lead deleted successfully', toastConfig);
      return response.data;
    } catch (error) {
      console.error('Error deleting Lead:', error);
    }
    setLoading(false);
  };

  const handleSearch = async (search: string) => {
    setSearchInput(search);
    try {
      setLoading(true);
      const data = await get(`/leads/?search=${search}`);
      setLeads(data.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setLoading(false);
  };

  const handleExportCSV = () => {
    // Convert leads data to CSV format
    const headers = ['Email', 'First Name', 'Last Name', 'Company', 'Date Created'];
    const csvData = leads.map((lead: any) => [
      lead.email || '',
      lead.first_name || '',
      lead.last_name || '',
      lead.company || '',
      formatDate(lead.created_at) || ''
    ]);

    // Combine headers and data
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.join(','))
    ].join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `Aimdoc_leads_export_${formatDate(new Date())}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Define columns for TanStack Table
  const columns = React.useMemo(() => [
    {
      accessorKey: 'email',
      header: ({ column }: any) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="-ml-4"
        >
          Email
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }: any) => (
        // <Link
        //   to={`/leads/${row.original.id}`}
        //   className="text-md font-semibold hover:underline"
        //   onClick={(e) => e.stopPropagation()}
        // >
        //   {row.original.email || 'N/A'}
        // </Link>
        <span className="text-md hover:underline" onClick={() => setSelectedLead(row.original)}>
          {row.original.email || 'N/A'}
        </span>
      ),
    },
    {
      accessorKey: 'first_name',
      header: ({ column }: any) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="-ml-4"
        >
          First Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ getValue }: any) => (
        <span className="text-md">
          {getValue() || 'N/A'}
        </span>
      ),
    },
    {
      accessorKey: 'last_name',
      header: ({ column }: any) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="-ml-4"
        >
          Last Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ getValue }: any) => (
        <span className="text-md">
          {getValue() || 'N/A'}
        </span>
      ),
    },
    {
      accessorKey: 'company',
      header: ({ column }: any) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="-ml-4"
        >
          Company
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ getValue }: any) => (
        <span className="text-md">
          {getValue() || 'N/A'}
        </span>
      ),
    },
    {
      accessorKey: 'created_at',
      header: ({ column }: any) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="-ml-4"
        >
          Date Created
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ getValue }: any) => (
        <span className="text-md">
          {formatDate(getValue())}
        </span>
      ),
    },
    {
      id: 'actions',
      header: 'Action',
      cell: ({ row }: any) => (
        <div data-delete-action>
          <RowDelete deleteCallback={() => handleDelete(row.original.id)} />
        </div>
      ),
    },
  ], [handleDelete]);

  // Create table instance
  const table = useReactTable({
    data: leads,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const LeadsLoadingSkeleton = () => (
    <TableBody>
      {[1, 2].map((i) => (
        <TableRow key={i} className="animate-pulse">
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-3/4 text-md" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-1/2 text-md" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-3/4 text-md" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-1/2 text-md" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-3/4 text-md" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-1/2 text-md" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <>
      {leads && (
        <div className="p-12 overflow-y-auto max-h-[calc(100vh-1px)] scrollbar-thin grow">
          <h1 className='text-4xl font-bold flex items-center gap-2'>Leads</h1>
          <p className='mt-4 text-sm text-slate-500'>Browse through leads collected by your agents.</p>
          <Separator className="my-4" />
          <div className='mx-auto rounded-md flex flex-row justify-between items-center'>
            <Input
              type="text"
              id="search"
              placeholder="Search for leads.."
              className='w-1/2'
              autoComplete='off'
              value={searchInput}
              onChange={(e: any) => setSearchInput(e.target.value)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleSearch(e.currentTarget.value);
                }
              }}
            />
            <Button onClick={handleExportCSV}>
              <Download className="w-4 h-4" />
              Export to CSV
            </Button>
          </div>
          <div className="flex flex-col mt-4">
            <div className="-m-1.5 overflow-x-auto pb-12">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="border rounded-lg shadow overflow-hidden">
                  <Table>
                    <TableHeader>
                      {table.getHeaderGroups().map(headerGroup => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map(header => (
                            <TableHead key={header.id}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                            </TableHead>
                          ))}
                        </TableRow>
                      ))}
                    </TableHeader>
                    {loading ? (
                      <LeadsLoadingSkeleton />
                    ) : (
                      <TableBody>
                        {table.getRowModel().rows.length ? (
                          table.getRowModel().rows.map(row => (
                            <TableRow
                              key={row.id}
                              className="text-md cursor-pointer"
                              onClick={(event) => {
                                if ((event.target as HTMLElement).closest('[data-delete-action]')) {
                                  return;
                                }
                                setSelectedLead(row.original);
                              }}
                            >
                              {row.getVisibleCells().map(cell => (
                                <TableCell key={cell.id}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={columns.length} className="h-24 text-center">
                              No leads
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div className="place-content-center fixed inset-0 bg-slate-600 bg-opacity-50 backdrop-blur-sm overflow-y-auto h-screen w-screen z-40 flex items-center justify-center">
          <div className="flex h-5/6 w-2/3 items-center justify-center">
            <div className="flex h-full w-full">

              <CreateAgent
                toggleModal={toggleModal}
                modalVisible={showModal}
              />
            </div>
          </div>
        </div>
      )}
      <Sheet open={selectedLead !== null} onOpenChange={() => setSelectedLead(null)}>
        <SheetContent className="min-w-[50%] grow overflow-y-auto">
          {selectedLead && (
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <div className="flex items-center gap-4">
                  <div className="h-12 w-12 rounded-full bg-gradient-to-br from-blue-500 to-purple-500 flex items-center justify-center">
                    <span className="text-xl font-semibold text-white">
                      {selectedLead.first_name ? selectedLead.first_name[0].toUpperCase() : selectedLead.email[0].toUpperCase()}
                    </span>
                  </div>
                  <h2 className="text-2xl font-bold">
                    {selectedLead.first_name ? `${selectedLead.first_name} ${selectedLead.last_name || ''}` : selectedLead.email}
                  </h2>
                </div>
                <div className="flex items-center gap-2">
                  {selectedLead.integrations && (
                    <>
                      {(selectedLead.integrations.salesforce || selectedLead.integrations.salesforce_error) && (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <a
                                href={selectedLead.integrations.salesforce}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={cn(
                                  "rounded-md p-1",
                                  selectedLead.integrations.salesforce_error && "border border-red-500"
                                )}
                              >
                                <img
                                  src={SalesforceIcon}
                                  alt="Salesforce"
                                  className="w-12 h-12 object-contain"
                                />
                              </a>
                            </TooltipTrigger>
                            {selectedLead.integrations.salesforce_error && (
                              <TooltipContent>
                                <p>Error: {selectedLead.integrations.salesforce_error}</p>
                              </TooltipContent>
                            )}
                          </Tooltip>
                        </TooltipProvider>
                      )}
                      {(selectedLead.integrations.hubspot || selectedLead.integrations.hubspot_error) && (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <a
                                href={selectedLead.integrations.hubspot}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={cn(
                                  "rounded-md p-1",
                                  selectedLead.integrations.hubspot_error && "border border-red-500"
                                )}
                              >
                                <img
                                  src={HubspotIcon}
                                  alt="HubSpot"
                                  className="w-10 h-10 object-contain"
                                />
                              </a>
                            </TooltipTrigger>
                            {selectedLead.integrations.hubspot_error && (
                              <TooltipContent>
                                <p>Error: {selectedLead.integrations.hubspot_error}</p>
                              </TooltipContent>
                            )}
                          </Tooltip>
                        </TooltipProvider>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="space-y-5">
                <div className="mt-6 border rounded-lg overflow-hidden">
                  <Table>
                    <TableBody>
                      {[
                        { label: 'Email', value: selectedLead.email },
                        { label: 'ID', value: selectedLead.id },
                        { label: 'Company', value: selectedLead.company },
                        { label: 'Created', value: selectedLead.created_at }
                      ].map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.label}</TableCell>
                          <TableCell>{typeof row.value === 'string' ? (row.value || 'N/A') : row.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <h2 className="mt-4 text-2xl font-bold">Details</h2>
                <LeadDetails
                  leadId={selectedLead.id}
                />
                <h2 className="mt-4 text-2xl font-bold">Evaluation</h2>
                <LeadScore
                  leadId={selectedLead.id}
                />
              </div>
            </div>
          )}
          <ShinyButton
            className='mt-4 w-full'
            onClick={() => navigate(`/sessions/?lead_id=${selectedLead?.id}`)}>
            <div className='flex items-center justify-center'>
              View Sessions <ArrowRight className='w-4 h-4 ml-2' />
            </div>
          </ShinyButton>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default LeadLists;
