import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { X } from 'lucide-react';
import { cn } from "@/lib/utils";
import useApi from '../../api';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { ChevronDown, Check } from 'lucide-react';
import { HubspotFieldSelect } from "./HubspotFieldSelect";
import { HubspotDefaultField } from "./HubspotDefaultField";
import { ScrollArea } from "@/components/ui/scroll-area";

interface HubspotField {
    name: string;
    label: string;
    type: string;
    description: string;
    groupName: string;
    required: boolean;
}

interface SystemField {
    name: string;
    label: string;
    description: string;
}

interface HubspotFieldsModalProps {
    isOpen: boolean;
    closeModal: () => void;
    formFields: any[];
    onSave: (fieldMappings: Record<string, string>, defaultFields: Record<string, string>) => void;
    existingMappings?: Record<string, string>;
    defaultFields?: Record<string, string>;
    integrationId: string;
}

const SYSTEM_FIELDS: SystemField[] = [
    { name: 'aimdoc_parent_url', label: 'Source URL', description: 'URL where the form was submitted' },
    { name: 'city', label: 'City', description: 'Visitor city' },
    { name: 'region', label: 'Region', description: 'Visitor region/state' },
    { name: 'country', label: 'Country', description: 'Visitor country' }
];

export default function HubspotFieldsModal({
    isOpen,
    closeModal,
    formFields,
    onSave,
    existingMappings = {},
    defaultFields = {},
    integrationId
}: HubspotFieldsModalProps) {
    const { get } = useApi();
    const [fieldMappings, setFieldMappings] = useState<Record<string, string>>(existingMappings);
    const [selectedField, setSelectedField] = useState<string | null>(null);
    const [hubspotFields, setHubspotFields] = useState<HubspotField[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [defaultFieldMappings, setDefaultFieldMappings] = useState<Record<string, string>>(defaultFields);

    useEffect(() => {
        if (isOpen) {
            setFieldMappings(existingMappings);
            setDefaultFieldMappings(defaultFields);
            fetchHubspotFields();
        }
    }, [isOpen, integrationId, existingMappings, defaultFields]);

    const fetchHubspotFields = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await get(`/integrations/${integrationId}/contact-fields`);
            if (response.data.status === 'success') {
                setHubspotFields(response.data.fields);
            } else {
                setError('Failed to load HubSpot fields');
            }
        } catch (err) {
            setError('Failed to load HubSpot fields');
            console.error('Error fetching HubSpot fields:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFieldSelect = (fieldName: string) => {
        setSelectedField(fieldName);
    };

    const handleHubspotFieldSelect = (hubspotFieldName: string) => {
        if (selectedField) {
            setFieldMappings(prev => ({
                ...prev,
                [selectedField]: hubspotFieldName
            }));
            setSelectedField(null);
        }
    };

    const handleClearMapping = (fieldName: string, e: React.MouseEvent) => {
        e.stopPropagation();
        const newMappings = { ...fieldMappings };
        delete newMappings[fieldName];
        setFieldMappings(newMappings);
    };

    const handleCancel = () => {
        setFieldMappings(existingMappings);
        setSelectedField(null);
        closeModal();
    };

    const handleSaveAll = () => {
        if (Object.keys(fieldMappings).length === 0) {
            return;
        }
        onSave(fieldMappings, defaultFieldMappings);
        closeModal();
    };

    const getHubspotFieldLabel = (fieldName: string) => {
        const field = hubspotFields.find(f => f.name === fieldName);
        return field ? field.label : fieldName;
    };

    const isEditing = Object.keys(existingMappings).length > 0;

    const handleDefaultFieldUpdate = (hubspotField: string, value: string) => {
        setDefaultFieldMappings(prev => {
            const newMappings = { ...prev };
            delete newMappings[""];
            newMappings[hubspotField] = value;
            return newMappings;
        });
    };

    const handleRemoveDefaultField = (hubspotField: string) => {
        const newMappings = { ...defaultFieldMappings };
        delete newMappings[hubspotField];
        setDefaultFieldMappings(newMappings);
    };

    const handleAddDefaultField = () => {
        setDefaultFieldMappings(prev => ({
            ...prev,
            "": ""
        }));
    };

    const getSystemFieldLabel = (fieldName: string) => {
        const field = SYSTEM_FIELDS.find(f => f.name === fieldName);
        return field?.label || fieldName;
    };

    if (error) {
        return (
            <Dialog open={isOpen} onOpenChange={closeModal}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Error</DialogTitle>
                    </DialogHeader>
                    <div className="text-red-500">{error}</div>
                    <DialogFooter>
                        <Button onClick={closeModal}>Close</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Dialog open={isOpen} onOpenChange={closeModal}>
            <DialogContent className="sm:max-w-2xl h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>Configure HubSpot Contact Sync</DialogTitle>
                    <DialogDescription>
                        Map your form fields to HubSpot contact properties. Type to search available fields.
                    </DialogDescription>
                </DialogHeader>

                <ScrollArea className="flex-1 -mx-6 px-6">
                    {isLoading ? (
                        <div className="py-8 text-center min-h-[90vh]">Loading HubSpot fields...</div>
                    ) : (
                        <div className="space-y-6">
                            <div className="space-y-4">
                                <h3 className="text-sm font-medium mb-2">Form Fields</h3>
                                {formFields.map((field) => (
                                    <div
                                        key={field.name}
                                        className={cn(
                                            'p-4 rounded-lg border cursor-pointer transition-all duration-200',
                                            selectedField === field.name
                                                ? 'border-primary bg-primary/5'
                                                : 'hover:border-primary/50'
                                        )}
                                        onClick={() => handleFieldSelect(field.name)}
                                    >
                                        <div className="flex items-center justify-between">
                                            <div>
                                                <h3 className="text-md font-medium">
                                                    {field.label}
                                                </h3>
                                                {fieldMappings[field.name] && (
                                                    <div className="flex items-center gap-2">
                                                        <p className="text-sm text-muted-foreground mt-1">
                                                            Mapped to: {getHubspotFieldLabel(fieldMappings[field.name])}
                                                        </p>
                                                        <Button
                                                            variant="ghost"
                                                            size="icon"
                                                            onClick={(e) => handleClearMapping(field.name, e)}
                                                            className="h-4 w-4 text-destructive hover:text-destructive/90"
                                                        >
                                                            <X className="h-4 w-4" />
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {selectedField && selectedField === field.name && (
                                            <div className="mt-6">
                                                <label className="block text-sm font-medium mb-2">
                                                    Select HubSpot Field
                                                </label>
                                                <HubspotFieldSelect
                                                    fields={hubspotFields}
                                                    value={fieldMappings[selectedField]}
                                                    onSelect={handleHubspotFieldSelect}
                                                    placeholder="Select a HubSpot field"
                                                    simpleDisplay={true}
                                                />
                                            </div>
                                        )}

                                    </div>
                                ))}
                            </div>

                            <div className="border-t pt-6">
                                <h3 className="text-sm font-medium mb-2">System Fields</h3>
                                <p className="text-sm text-muted-foreground mb-4">
                                    Map automatically collected system data to HubSpot fields.
                                </p>
                                <div className="space-y-4">
                                    {SYSTEM_FIELDS.map((field) => (
                                        <div
                                            key={field.name}
                                            className={cn(
                                                'p-4 rounded-lg border cursor-pointer transition-all duration-200',
                                                selectedField === field.name
                                                    ? 'border-primary bg-primary/5'
                                                    : 'hover:border-primary/50'
                                            )}
                                            onClick={() => handleFieldSelect(field.name)}
                                        >
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <h3 className="text-md font-medium">
                                                        {field.label}
                                                    </h3>
                                                    <p className="text-sm text-muted-foreground">
                                                        {field.description}
                                                    </p>
                                                    {fieldMappings[field.name] && (
                                                        <div className="flex items-center gap-2 mt-2">
                                                            <p className="text-sm text-muted-foreground">
                                                                Mapped to: {getHubspotFieldLabel(fieldMappings[field.name])}
                                                            </p>
                                                            <Button
                                                                variant="ghost"
                                                                size="icon"
                                                                onClick={(e) => handleClearMapping(field.name, e)}
                                                                className="h-4 w-4 text-destructive hover:text-destructive/90"
                                                            >
                                                                <X className="h-4 w-4" />
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {selectedField && selectedField === field.name && (
                                                <div className="mt-6">
                                                    <label className="block text-sm font-medium mb-2">
                                                        Select HubSpot Field
                                                    </label>
                                                    <HubspotFieldSelect
                                                        fields={hubspotFields}
                                                        value={fieldMappings[selectedField]}
                                                        onSelect={handleHubspotFieldSelect}
                                                        placeholder="Select a HubSpot field"
                                                        simpleDisplay={true}
                                                    />
                                                </div>
                                            )}

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-sm font-medium mb-2">Default Fields</h3>
                                <p className="text-sm text-muted-foreground mb-4">
                                    Add fields that should always be sent to HubSpot with specific values.
                                </p>
                                <div className="space-y-2">
                                    {Object.entries(defaultFieldMappings).map(([hubspotField, value]) => (
                                        <HubspotDefaultField
                                            key={hubspotField || 'new'}
                                            hubspotField={hubspotField}
                                            value={value}
                                            onUpdate={handleDefaultFieldUpdate}
                                            onRemove={() => handleRemoveDefaultField(hubspotField)}
                                            availableFields={hubspotFields}
                                        />
                                    ))}
                                </div>
                                <Button
                                    variant="outline"
                                    className="mt-2"
                                    onClick={handleAddDefaultField}
                                >
                                    Add Default Field
                                </Button>
                            </div>
                        </div>
                    )}
                </ScrollArea>

                <DialogFooter className="mt-4">
                    <Button variant="outline" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveAll}
                        disabled={Object.keys(fieldMappings).length === 0}
                    >
                        {isEditing ? 'Save Mappings' : 'Create Mappings'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
} 