import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useApi from '../api';
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import CreateAgent from './CreateAgent';
import RowDelete from '../ui/RowDelete';
import useAnalytics from '../analytics/analytics';
import AgentFromAI from '../AgentFromAI';
import { AimdocComponentContext } from '@aimdoc/sdk-react';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/components/ui/table';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { Separator } from '@/components/ui/separator';
import { PlusCircle, Copy, Cog } from 'lucide-react';
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { AgentSelectionDrawer } from './AgentSelectionDrawer';
import { CopyCodeComponent } from './CopyCodeModal';

// Define Agent type
type Agent = {
  id: string;
  parent_agent_id: string;
  name: string;
  type: string;
  persona: string;
  goal: string;
};

// Add this type definition near other types
type SelectedAgent = Agent | null;

const AgentLists: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [showEmbedCodeModal, setShowEmbedCodeModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [showCreateAgent, setShowCreateAgent] = useState(false);
  const [showAgentFromAI, setShowAgentFromAI] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<SelectedAgent>(null);
  const [filteredAgents, setFilteredAgents] = useState<Agent[]>([]);
  const navigate = useNavigate();
  const { get, remove } = useApi();
  const { captureEvent } = useAnalytics();

  const search = new URLSearchParams(window.location.search);
  const newAgent = search.get('new');

  const toggleModal = () => {
    setShowCreateAgent(false);
    setShowAgentFromAI(false);
    setShowSelectionModal(false);
    navigate('/agents');
  };

  useEffect(() => {
    getAgents();
  }, []);

  // Add effect to filter agents based on localStorage selectedTab
  useEffect(() => {
    const handleStorageChange = () => {
      const selectedTab = localStorage.getItem('selectedTab');
      if (selectedTab === 'sales') {
        setFilteredAgents(agents.filter(agent => agent.type === 'website' || agent.type === 'Outreach' || agent.type === ''));
      } else {
        setFilteredAgents(agents.filter(agent => agent.type === 'product'));
      }
    };

    // Initial filter
    handleStorageChange();

    // Listen for storage changes
    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [agents]);

  const getAgents = async () => {
    try {
      setLoading(true);
      const data = await get('/agents/');
      setAgents(data.data);
      
      // Filter agents based on selectedTab
      const selectedTab = localStorage.getItem('selectedTab');
      if (selectedTab === 'sales') {
        setFilteredAgents(data.data.filter((agent: Agent) => agent.type === 'website'));
      } else {
        setFilteredAgents(data.data.filter((agent: Agent) => agent.type === 'product'));
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this agent? This action cannot be undone.')) {
      return;
    }

    try {
      await remove(`/agents/${id}`);
      setAgents(agents.filter((agent: any) => agent.parent_agent_id !== id));
      toast.success('Agent deleted successfully', toastConfig);
      captureEvent('delete_agent', {
        agent_id: id,
      });
    } catch (error) {
      console.error('Error deleting Agent:', error);
      toast.error('Failed to delete agent', toastConfig);
    }
    setLoading(false);
  };

  const handleSearch = async (search: string) => {
    setSearchInput(search);
    try {
      setLoading(true);
      const data = await get(`/agents/?search=${search}`);
      setAgents(data.data);
      
      // Filter search results based on selectedTab
      const selectedTab = localStorage.getItem('selectedTab');
      if (selectedTab === 'sales') {
        setFilteredAgents(data.data.filter((agent: Agent) => agent.type === 'website'));
      } else {
        setFilteredAgents(data.data.filter((agent: Agent) => agent.type === 'product'));
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (newAgent === 'true') {
      setShowSelectionModal(true);
    }
  }, [newAgent]);

  // Add this function near other state handlers
  const handleRowClick = (event: React.MouseEvent, agent: Agent) => {
    // Don't open sheet if clicking delete button or link
    if ((event.target as HTMLElement).closest('[data-delete-action]') || 
        (event.target as HTMLElement).closest('a')) {
      return;
    }
    setSelectedAgent(agent);
  };

  // New: Define columns for TanStack Table
  const columns = React.useMemo(() => [
    {
      accessorKey: 'name',
      header: 'Name', 
      cell: ({ row }: any) => {
        const agent = row.original;
        return (
          <>
            <Link to={`/agents/${agent.parent_agent_id}`} className="hover:underline">
              {agent.name || <Badge variant="outline">-</Badge>}
            </Link>
            <AimdocComponentContext
              componentDescription="You need to click this to go to this agent's details page. Here is where you modify anything for this agent."
              componentName="Agent Details"
            />
          </>
        );
      },
    },
    {
      accessorKey: 'parent_agent_id',
      header: 'ID',
      cell: ({ getValue }: any) => {
        const value = getValue();
        return value ? <span>{value}</span> : <Badge variant="outline">-</Badge>;
      },
    },
    {
      accessorKey: 'type',
      header: 'Type',
      cell: ({ getValue }: any) => {
        const value = getValue();
        return value ? <Badge variant="secondary">{value === "website" ? "Sales" : value === "product" ? "Operator" : "-"}</Badge> : <Badge variant="outline">-</Badge>;
      },
    },
    {
      accessorKey: 'persona',
      header: 'Persona',
      cell: ({ getValue }: any) => {
        const value = getValue() as string;
        if (!value) return <Badge variant="outline">-</Badge>;
        return value.length > 200 ? value.substring(0, 200) + '...' : value;
      },
    },
    {
      accessorKey: 'goal',
      header: 'Goal',
      cell: ({ getValue }: any) => {
        const value = getValue() as string;
        if (!value) return <Badge variant="outline">-</Badge>;
        return value.length > 200 ? value.substring(0, 200) + '...' : value;
      },
    },
    {
      accessorKey: 'parent_agent_id',
      header: '',
      cell: ({ row }: any) => (
        <div data-delete-action>
          <RowDelete
            deleteCallback={() => handleDelete(row.original.parent_agent_id)}
            deleteMessage={`Are you sure you want to delete ${row.original.name}? This action cannot be undone.`}
          />
        </div>
      ),
    },
  ], [handleDelete]);

  // Create table instance using TanStack Table
  const table = useReactTable({
    data: filteredAgents,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const AgentsLoadingSkeleton = () => (
    <TableBody>
      {[1, 2].map((i) => (
        <TableRow key={i} className="animate-pulse">
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-3/4" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-full" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-full" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-full" />
          </TableCell>
          <TableCell className="px-6 py-4">
            <div className="h-4 bg-muted rounded w-full" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <>
      <div className="p-12 overflow-y-auto max-h-[calc(100vh-1px)] scrollbar-thin grow">
        <h1 className='text-4xl font-bold flex items-center gap-2'>Agents</h1>
        <p className='mt-4 text-sm text-slate-500'>Create and manage your agents here. Select an agent to customize and copy configuration details.</p>
        <AimdocComponentContext
          componentDescription="You are currently in the Agents list page. Here you can see all of the users agents. You can create a new agent, modify an existing agent, or delete an agent."
          componentName="Agents List"
        />
        <Separator className="my-4" />
        <div className='mt-4 mx-auto rounded-md flex flex-row'>
          <div className="relative flex w-full">
            <Input
              type="text"
              id="search"
              placeholder="Search agents..."
              autoComplete="off"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleSearch(e.currentTarget.value);
                }
              }}
            />
          </div>
          <div className="relative w-full rounded-lg overflow-hidden">
            <Button onClick={() => setShowSelectionModal(true)} className="absolute right-0 top-0 h-full px-4 rounded-lg focus-within:shadow-lg">
              Create New Agent <PlusCircle className="w-4 h-4" />
            </Button>
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <div className="-m-1.5 overflow-x-auto pb-12">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="border rounded-lg shadow overflow-hidden dark:border-slate-900">
                {loading ? (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Name</TableHead>
                        <TableHead>ID</TableHead>
                        <TableHead>Type</TableHead>
                        <TableHead>Persona</TableHead>
                        <TableHead>Goal</TableHead>
                        <TableHead className="text-end"></TableHead>
                      </TableRow>
                    </TableHeader>
                    <AgentsLoadingSkeleton />
                  </Table>
                ) : (
                  <Table>
                    <TableHeader>
                      {table.getHeaderGroups().map(headerGroup => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map(header => (
                            <TableHead
                              key={header.id}
                              className={header.column.id === 'name' ? 'w-1/6' : ''}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                            </TableHead>
                          ))}
                        </TableRow>
                      ))}
                    </TableHeader>
                    <TableBody>
                      {table.getRowModel().rows.length ? (
                        table.getRowModel().rows.map(row => (
                          <TableRow 
                            key={row.id} 
                            className='cursor-pointer' 
                            onClick={(event) => handleRowClick(event, row.original)}
                          >
                            {row.getVisibleCells().map(cell => (
                              <TableCell
                                key={cell.id}
                                className={cell.column.id === 'name' ? 'w-1/6 font-semibold' : '' }
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} className="h-24 text-center">
                            No agents
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AgentSelectionDrawer 
        open={showSelectionModal}
        onOpenChange={(open) => !open && toggleModal()}
        onSelectManual={() => {
          setShowSelectionModal(false);
          setShowCreateAgent(true);
        }}
        onSelectAI={() => {
          setShowSelectionModal(false);
          setShowAgentFromAI(true);
        }}
      />
      {showCreateAgent && (
        <CreateAgent
          showModal={showCreateAgent}
          setShowModal={setShowCreateAgent}
          setAgents={setAgents}
        />
      )}

      {showAgentFromAI && (
        <AgentFromAI setComplete={setShowAgentFromAI} />
      )}

      <Sheet open={selectedAgent !== null} onOpenChange={() => setSelectedAgent(null)}>
        <SheetContent className="min-w-[50%] grow overflow-y-auto">
          {selectedAgent && (
            <div className="space-y-4">
              <h2 className="text-2xl font-bold">{selectedAgent.name}</h2>
              <Button 
                className='mt-4 flex w-full' 
                variant="outline" 
                onClick={() => {
                  navigator.clipboard.writeText(selectedAgent?.parent_agent_id || '');
                  toast.success(`Agent Id: ${selectedAgent?.parent_agent_id} copied to clipboard`, toastConfig); 
                }}
              >
                <Copy className='w-4 h-4' />
                Copy Agent ID
              </Button>
              <Button 
                className='mt-4 flex w-full' 
                variant="secondary" 
                onClick={() => { 
                  navigate(`/agents/${selectedAgent?.parent_agent_id}`); 
                
                }}
              >
                <Cog className='w-4 h-4' />
                Edit Agent
              </Button>
              <Separator className='my-4' />
              <h2 className="text-2xl font-bold">Agent Details</h2>
              <div className="border rounded-lg shadow overflow-hidden">
                <Table>
                  <TableBody>
                    {[
                      { label: 'ID', value: selectedAgent.parent_agent_id || '-' },
                      { label: 'Type', value: selectedAgent.type === "website" ? "Sales Agent" : selectedAgent.type === "product" ? "Operator Agent" : "-" },
                      { label: 'Persona', value: selectedAgent.persona || '-' },
                      { label: 'Goal', value: selectedAgent.goal || '-' }
                    ].map(({ label, value }) => (
                      <TableRow key={label}>
                        <TableCell className="ml-4 font-medium">{label}</TableCell>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <Separator className='my-4' />
              <h2 className="text-2xl font-bold">Deploy Agent</h2>
                  <CopyCodeComponent 
                    agId={selectedAgent.parent_agent_id} 
                    onCopy={(platform) => {
                      captureEvent('copy_embed_code', {
                        agent_id: selectedAgent.parent_agent_id,
                        platform: platform
                      });
                      toast.success(`Embed code copied to clipboard`, toastConfig);
                    }} 
                  />
            </div>
          )}
        </SheetContent>
      </Sheet>
    </>
  );
};

export default AgentLists;