import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import useApi from "../api";
import { Trash2, Upload, File } from "lucide-react";
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from '../config/Toast';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Card,
  CardContent,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";

interface FileUploaderProps {
  open: boolean;
  onClose: () => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({ open, onClose }) => {
  const [files, setFiles] = useState<{[key: string]: File}>({});
  const [galleryItems, setGalleryItems] = useState<any[]>([]);
  const [draggedOver, setDraggedOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { post } = useApi();

  const hiddenInputRef = useRef<HTMLInputElement | null>(null);

  const formatSize = (size: number) => {
    return size > 1024
      ? size > 1048576
        ? Math.round(size / 1048576) + "mb"
        : Math.round(size / 1024) + "kb"
      : size + "b";
  };

  const addFile = (file: File) => {
    const objectURL = URL.createObjectURL(file);

    const fileData = {
      id: objectURL,
      name: file.name,
      size: formatSize(file.size),
      type: "file",
    };

    setGalleryItems((prevItems) => [fileData, ...prevItems]);
    setFiles((prevFiles) => ({ ...prevFiles, [objectURL]: file }));
  };

  const handleSelectFilesClick = () => {
    hiddenInputRef.current?.click();
  };

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      Array.from(files).forEach(addFile);
    }
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.dataTransfer.types.includes("Files")) {
      setDraggedOver(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setDraggedOver(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    Array.from(e.dataTransfer.files).forEach(addFile);
    setDraggedOver(false);
  };

  const handleDeleteFile = (id: string) => {
    setGalleryItems((prevItems) => prevItems.filter((item) => item.id !== id));
    setFiles((prevFiles) => {
      const { [id]: removed, ...rest } = prevFiles;
      return rest;
    });
  };

  const handleFileUpload = async () => {
    if (Object.keys(files).length === 0) {
      toast.error("Please select a file first.");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    Object.values(files).forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await post("/documents/file", formData, 'file');
      toast.success(`Training started for ${Object.keys(files).length} documents`);
      onClose();

    } catch (error: any) {
      console.error("Error uploading Document:", error);
      toast.error(error.message || 'Error uploading Document');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setGalleryItems([]);
    setFiles({});
    onClose();
  };

  const GalleryItem = ({ fileData, onDelete }: { fileData: any; onDelete: (id: string) => void }) => (
    <Card className="relative group">
      <CardContent className="p-4">
        <div className="flex items-center gap-2">
          <File className="h-8 w-8 text-muted-foreground" />
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium truncate">{fileData.name}</p>
            <p className="text-xs text-muted-foreground">{fileData.size}</p>
          </div>
          <Button
            variant="ghost"
            size="icon"
            className="opacity-0 group-hover:opacity-100 transition-opacity"
            onClick={() => onDelete(fileData.id)}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-2xl">
        <DialogHeader>
          <DialogTitle>Upload Documents</DialogTitle>
        </DialogHeader>
        
        <div
          className={`border-2 border-dashed rounded-lg p-12 text-center ${
            draggedOver ? 'border-primary' : 'border-muted'
          }`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDragEnter={handleDragEnter}
        >
          <Upload className="w-12 h-12 mx-auto text-muted-foreground" />
          <p className="mt-2 text-sm text-muted-foreground">
            Drag and drop your files anywhere or
          </p>
          <input
            type="file"
            multiple
            className="hidden"
            ref={hiddenInputRef}
            onChange={handleFilesChange}
            accept=".pdf,.doc,.docx"
          />
          <Button
            variant="secondary"
            onClick={handleSelectFilesClick}
            className="mt-4"
          >
            Upload your documents
          </Button>
        </div>

        <div className="space-y-4">
          <h2 className="text-lg font-semibold">
            Files to Upload (Only PDFs and Word Documents)
          </h2>
          <div className="space-y-2">
            {galleryItems.length === 0 ? (
              <div className="text-center py-8 text-muted-foreground">
                No files selected
              </div>
            ) : (
              galleryItems.map((item) => (
                <GalleryItem
                  key={item.id}
                  fileData={item}
                  onDelete={handleDeleteFile}
                />
              ))
            )}
          </div>
        </div>

        <div className="flex justify-end gap-2 mt-4">
          {loading && <Progress value={33} className="w-full" />}
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleFileUpload} disabled={loading}>
            Upload now
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FileUploader;
